import React, { useState, useLayoutEffect } from 'react'
import firebase, { db } from '../../utils/firebase';
import { isLogged } from '../../utils/functions'
import { pathHome } from '../../utils/constans'
import { Redirect } from 'react-router-dom';

const SignInCrm = ({ location }) => {

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [authenticated, setAuthenticated] = useState(false)
  const [redirectTo, setRedirectTo] = useState({pathname: location?.state?.redirect ?? pathHome})


  useLayoutEffect(() => {
    isLogged()
    .then(()=>{
      setAuthenticated(true)
    })
  }, [])

  const signIn = () => {
    db.child('Empleados').orderByChild('username').equalTo(username).limitToLast(1).once("value", snapshot => {
      if(!snapshot.exists()){
        setError("El usuario o la contraseña son incorrectos")
        return false
      }
      const { email } = Object.entries(snapshot.val()).find( ([idUser, user]) => user )[1]
      firebase.auth().signInWithEmailAndPassword(email, password)
      .then((data)=>{
        setAuthenticated(true)
      })
      .catch(error => setError("Ha occurrido un error"));
    })
  }

  return(
    authenticated ? <Redirect to={redirectTo} /> :

    <div className="login-crm">
      <form>
        <div className="containerApp contLogin">
            <h1 className="is">Iniciar sesión</h1>
            <div >
              <div className={`container-simple-input `}>
                <div className='title-input'>Username:</div>
                <div className='container-input'>
                  <input id="username" type="text" value={username} className="TextInput TextInput_large" onChange={ e => setUsername(e.target.value)} />
                </div>
              </div>
              <div className={`container-simple-input `}>
                <div className='title-input'>Password:</div>
                <div className='container-input'>
                  <input id="password" type="password" value={password} className="TextInput TextInput_large" onChange={ e => setPassword(e.target.value)} />
                </div>
              </div>
              <div className="login_info"><span >{error}</span></div>
              <div className='container-btn-log-in'>
                <div onClick={signIn} className="btnContinuar">Log in</div>
              </div>
            </div>
        </div>
      </form>
    </div>

  )
}

export default SignInCrm