import React, { useState, useEffect } from 'react'
import Input from '../../../../../Shared/Input'
import InputMenu from '../../../../../Shared/InputMenu'
import Switch from '../../../../../Shared/Switch'
import UpdateStateInputs from '../../../../../Shared/UpdateStateInputs'
import { provincias } from '../../../../../../utils/constans'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../utils/firebase'
import ScrollContainer from '../../../../../Shared/ScrollContainer'
import _ from 'underscore'

const PanelColegioNuevo = ({datosColegio}) => {
  return(
    <ScrollContainer _class = {`container-table`}>
      <div className='container-informacion-alumno'>
        <div className='sub-container-informacion-alumno'>

          <p className='title-informacion-alumno'>1. Información del colegio</p>

          <Input type='block' title='Código' _class='div_text_mitad' _class_input='dni-input' text={datosColegio.colegio.codigo}/>

          <Input type='block' title='Nombre del colegio' _class_input='camelcase'  text={datosColegio.colegio.nombre.toLowerCase()}/>

          <div className='col-2-input'>
            <Input type='block' title='Tipo de colegio' text={datosColegio.colegio.tipoColegio}/>
            <Input type='block' title='Ciudad' _class_input='camelcase' text={datosColegio.ciudad.toLowerCase()}/>
          </div>

          <div className='col-2-input'>
            <Input type='block' title='Sede en la que realizará la prueba del SAT'  text={datosColegio.sedeSat}/>
            <Input type='block' title='Email del tutor' text={datosColegio.colegio.emailTutor?datosColegio.colegio.emailTutor:''}/>
          </div>

        </div>
      </div>
    </ScrollContainer>
    
  )
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(PanelColegioNuevo);