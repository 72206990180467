import React, { useState, useLayoutEffect } from 'react'
import firebase, { db } from '../../../utils/firebase';
import { isLogged } from '../../../utils/functions'
import { pathAreaAlumno, pathFormularioNewAlumno } from '../../../utils/constans'
import { Redirect } from 'react-router-dom';

const MyForm = ({location}) => {

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [authenticated, setAuthenticated] = useState(false)
  const [redirectTo, setRedirectTo] = useState({pathname: location?.state?.redirect ?? pathAreaAlumno})
  const [sendRecoveryEmail, setSendRecoveryEmail] = useState(false)
  const [msgPassword, setMsgPassword] = useState({text:'', ok: true})
  const [email, setEmail] = useState("")

  useLayoutEffect(() => {
    isLogged()
    .then(()=>{
      setAuthenticated(true)
    })
  }, [])

  const signIn = () => {
    const type = username.includes('@') && username.substr(username.indexOf('@')+1, username.length) ? 'email' : 'dni'
    db.child('Alumnos').orderByChild(`informacionAlumno/${type}`).equalTo(type === "dni" ? username.toUpperCase() : username ).limitToLast(1).once("value", snapshot => {
      if(!snapshot.exists()){
        setError("El usuario o la contraseña son incorrectos")
        return false
      }
      const alumno = Object.entries(snapshot.val())[0][1]
      const { email } = alumno.informacionAlumno
      firebase.auth().signInWithEmailAndPassword(email, password)
      .then((data)=>{
        setAuthenticated(true)
      })
      .catch(error => {
        if(error?.code==="auth/wrong-password"){
          setError("El usuario o la contraseña son incorrectos")
        }else{
          setError("Ha occurrido un error")
        }
      });
      
    })
  }

  const mySubmitHandler = (event) => {
    event.preventDefault();
    signIn()
  }

  const mySubmitHandlerRecovery = (event) => {
    event.preventDefault();
    recoveryPassword() 
  }

  const recoveryPassword = () => {
    firebase.auth().sendPasswordResetEmail(email)
    .then(()=>{
      setMsgPassword({text: "Se ha enviado correctamente", ok: true})
    })  
    .catch(err=>{
      setMsgPassword({text: "No existe este email", ok: false})
    })
  }

  return (
    authenticated ? <Redirect to={redirectTo} /> :
    <div className="container-login-alumnos">
      <div class={`flip-card ${sendRecoveryEmail?'back-panel':''}`}>
        <div class="flip-card-inner">
          <div class="flip-card-front contLogin">

            <form onSubmit={mySubmitHandler} >
              <div className="containerApp">
                <h1 className="is">Iniciar sesión</h1>
                <h5 className="subtitle-login">ÁREA ALUMNO</h5>
                <div>
                  <div className={`container-simple-input `}>
                    <div className='title-input'>Email o DNI:</div>
                    <div className='container-input'>
                      <input type='submit' id="username" type="text" value={username} className="TextInput TextInput_large" onChange={ e => setUsername(e.target.value)} />
                    </div>
                  </div>
                  <div className={`container-simple-input `}>
                    <div className='title-input'>Password:</div>
                    <div className='container-input'>
                      <input id="password" type="password" value={password} className="TextInput TextInput_large" onChange={ e => setPassword(e.target.value)} />
                    </div>
                  </div>
                  <div className="login_info"><span >{error}</span></div>
                  <div className='container-btn-log-in'>
                    <div onClick={(e)=>mySubmitHandler(e)} className="btnContinuar">Log in</div>
                  </div>
                </div>
                <input type='submit' style={{display: "none"}} />
              </div>
            </form>

          </div>
          <div class="flip-card-back contLogin">

            <form onSubmit={mySubmitHandlerRecovery} >
                <div className="containerApp">
                  <h1 className="is title-recovey-pass">Restaurar contraseña</h1>
                  <div>
                    <div className={`container-simple-input `}>
                      <div className='title-input'>Email:</div>
                      <div className='container-input'>
                        <input type='submit' id="email" type="text" value={email} className="TextInput TextInput_large" onChange={ e => setEmail(e.target.value)} />
                      </div>
                    </div>
                    <div className={`login_info ${msgPassword.ok?'good-msg':''}`}><span >{msgPassword.text}</span></div>
                    <div className='container-btn-log-in btn-send-new-password'>
                      <div onClick={(e)=>mySubmitHandlerRecovery(e)} className="btnContinuar">Enviar</div>
                    </div>
                  </div>
                  <input type='submit' style={{display: "none"}} />
                </div>
              </form>

          </div>
        </div>
      </div>


      <div className="btns-login-alumno">
        {!sendRecoveryEmail ? 
          <>
            <div onClick={()=>setSendRecoveryEmail(true)}>Recuperar contraseña</div>
            <div onClick={()=>window.location.href=pathFormularioNewAlumno}>Inscribirse</div>
          </>
        : 
          <>
            <div onClick={()=>setSendRecoveryEmail(false)}>Volver</div>
          </>
        }
      </div>
    
    </div>
    
  );
}

export default MyForm