import React, { memo } from 'react'

const Checkbox = memo(({changeValue, checked = false}) => {
  return(
    <div className='container-item-check-box' onClick={()=>changeValue(!checked)}>
      <div>
        <div className={`checkbox-container-w2a checkbox-w2a ${checked?'checkbox-w2a-active':''}`} ></div>
      </div>
    </div>
  )
})

export default Checkbox