import React, { useLayoutEffect, useState, useEffect, memo, useMemo } from 'react'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTime } from '../../redux/actions/alumnosActions';
import DateCountdown from 'react-date-countdown-timer';
import { localesDateCountdown, localespluralDateCountdown, pathAreaAlumno } from '../../utils/constans'
import PanelVideo from './Panels/PanelVideo';
import PanelIngles from './Panels/PanelIngles'
import PanelMatematicas from './Panels/PanelMatematicas'
import Descanso from './Panels/Descanso'
import ExamenTerminado from './Panels/ExamenTerminado'
import LoadingExamen from './Panels/LoadingExamen'
import _ from 'underscore'
import moment from 'moment-timezone'
import { db } from '../../utils/firebase'
import io from 'socket.io-client'

const DURACION_VIDEO = 0
const DURACION_INGLES_SECCION_1 = 35.5
const DURACION_INGLES_SECCION_1_DESCANSO = 1.1
const DURACION_INGLES_SECCION_2 = 35.5
const DURACION_INGLES_SECCION_2_DESCANSO = 5.1
const DURACION_MATES_SECCION_3 = 15.5
const DURACION_MATES_SECCION_3_DESCANSO = 1.1
const DURACION_MATES_SECCION_4 = 30.5

const STATUS_INICIANDO = 'STATUS_INICIANDO'

const STATUS_VIDEO = "STATUS_VIDEO"
const STATUS_INGLES_SECCION_1 = "STATUS_INGLES_SECCION_1"
const STATUS_INGLES_SECCION_1_DESCANSO = "STATUS_INGLES_SECCION_1_DESCANSO"
const STATUS_INGLES_SECCION_2 = "STATUS_INGLES_SECCION_2"
const STATUS_INGLES_SECCION_2_DESCANSO = "STATUS_INGLES_SECCION_2_DESCANSO"
const STATUS_MATES_SECCION_3 = "STATUS_MATES_SECCION_3"
const STATUS_MATES_SECCION_3_DESCANSO = "STATUS_MATES_SECCION_3_DESCANSO"
const STATUS_MATES_SECCION_4 = "STATUS_MATES_SECCION_4"
const STATUS_FIN_EXAMEN = "STATUS_FIN_EXAMEN"
const STATUS_LOADING_EXAMEN = "STATUS_LOADING_EXAMEN"



const ExamenSat = memo(({location, currentAlumno, currentColegio, inscripcion, actions}) => {

  const [currentAlumnoState, setCurrentAlumnoState] = useState(currentAlumno)

  const [loading, setLoading] = useState(true)
  const [hasAccess, setHasAccess] = useState(false)

  const [statusExamen, setStatusExamen] = useState(null)

  const [tiempos, setTiempos] = useState({})
  const [now, setNow] = useState(null)


  useLayoutEffect(() => {


    let fechaExamen = moment.tz( `${currentColegio.examen.fechaExamen} ${currentColegio.examen.horaExamen}`, "Europe/Madrid");
    
    //const fechaFinVideo = moment.tz(moment(fechaExamen), "Europe/Madrid").add(DURACION_VIDEO, 'minutes')

    const fechaFinInglesSeccion1 = moment.tz(moment(fechaExamen), "Europe/Madrid").add(DURACION_INGLES_SECCION_1, 'minutes')

    const fechaFinInglesSeccion1Descanso = moment.tz(moment(fechaFinInglesSeccion1), "Europe/Madrid").add(DURACION_INGLES_SECCION_1_DESCANSO, 'minutes')

    const fechaFinInglesSeccion2 = moment.tz(moment(fechaFinInglesSeccion1Descanso), "Europe/Madrid").add(DURACION_INGLES_SECCION_2, 'minutes')

    const fechaFinInglesSeccion2Descanso = moment.tz(moment(fechaFinInglesSeccion2), "Europe/Madrid").add(DURACION_INGLES_SECCION_2_DESCANSO, 'minutes')

    const fechaFinMatesSeccion3 = moment.tz(moment(fechaFinInglesSeccion2Descanso), "Europe/Madrid").add(DURACION_MATES_SECCION_3, 'minutes')

    const fechaFinMatesSeccion3Descanso = moment.tz(moment(fechaFinMatesSeccion3), "Europe/Madrid").add(DURACION_MATES_SECCION_3_DESCANSO, 'minutes')

    const fechaFinMatesSeccion4 = moment.tz(moment(fechaFinMatesSeccion3Descanso), "Europe/Madrid").add(DURACION_MATES_SECCION_4, 'minutes')
    
    const fechaFinExamen = moment.tz(moment(fechaExamen), "Europe/Madrid").add(DURACION_INGLES_SECCION_1+ DURACION_INGLES_SECCION_1_DESCANSO+ DURACION_INGLES_SECCION_2+ DURACION_INGLES_SECCION_2_DESCANSO+ DURACION_MATES_SECCION_3+ DURACION_MATES_SECCION_3_DESCANSO+ DURACION_MATES_SECCION_4, 'minutes')
    const fechaFinExamenPlus2 = moment.tz(moment(fechaFinExamen), "Europe/Madrid").add(1, 'minutes')

    const tiempos = {
      fechaExamen, 
      //fechaFinVideo,
      fechaFinInglesSeccion1,
      fechaFinInglesSeccion1Descanso,
      fechaFinInglesSeccion2,
      fechaFinInglesSeccion2Descanso,
      fechaFinMatesSeccion3,
      fechaFinMatesSeccion3Descanso,
      fechaFinMatesSeccion4,
      fechaFinExamen,
      fechaFinExamenPlus2
    }
    setTiempos(tiempos)
  }, [currentColegio.examen.fechaExamen, currentColegio.examen.horaExamen])

  useLayoutEffect(() => {
    const path = location.pathname
    const idColegio = path.substr(path.indexOf('/examen/')+8, path.length)
    if(!currentColegio.examen.habilitado){
      setHasAccess(false)
    }else if(currentColegio.examen.habilitado && !currentColegio.examen.alumnosHabilitados[currentAlumno.id]){
      setHasAccess(false)
    }else if(idColegio!==currentAlumno.inscripcionAlumno.datosColegio.colegio.id){
      setHasAccess(true)
    }else{
      setHasAccess(true)
    }
    setLoading(false)

  }, [currentAlumno, currentColegio])

  /*useLayoutEffect(() => {
    if(currentAlumno.inscripcionAlumno.isSatDone() && tiempos?.fechaFinExamenPlus2 && now > tiempos?.fechaFinExamenPlus2 ){
      setHasAccess(false)
    }
  }, [tiempos, now])
  */

  useLayoutEffect(()=>{
    //getTime()
    const socket = io('/', { path: '/examenSat' })
    socket.on('examenSat', date => {
      setNow(moment.tz(moment(date), "Europe/Madrid"))
    })
  }, [])

  useEffect(()=>{ setPanelExamen() }, [now]) 

  const setPanelExamen = (cargandoNextPanel) => {
    let rightNow = now
    if(!rightNow || cargandoNextPanel){
      setStatusExamen(STATUS_LOADING_EXAMEN)
      return false
    }
    //const rightNow = +new Date()
    if(rightNow<tiempos.fechaExamen && statusExamen!==STATUS_INICIANDO){
      setStatusExamen(STATUS_INICIANDO)
    }/*else if(rightNow>tiempos.fechaExamen && rightNow<tiempos.fechaFinVideo){
      setStatusExamen(STATUS_VIDEO)
    }*/else if(rightNow>tiempos.fechaExamen && rightNow<tiempos.fechaFinInglesSeccion1 && statusExamen!==STATUS_INGLES_SECCION_1){
      setStatusExamen(STATUS_INGLES_SECCION_1)
    }else if(rightNow>tiempos.fechaFinInglesSeccion1 && rightNow<tiempos.fechaFinInglesSeccion1Descanso && statusExamen!==STATUS_INGLES_SECCION_1_DESCANSO){
      setStatusExamen(STATUS_INGLES_SECCION_1_DESCANSO)
      saveData()
    }else if(rightNow>tiempos.fechaFinInglesSeccion1Descanso && rightNow<tiempos.fechaFinInglesSeccion2 && statusExamen!==STATUS_INGLES_SECCION_2){
      setStatusExamen(STATUS_INGLES_SECCION_2)
    }else if(rightNow>tiempos.fechaFinInglesSeccion2 && rightNow<tiempos.fechaFinInglesSeccion2Descanso && statusExamen!==STATUS_INGLES_SECCION_2_DESCANSO){
      setStatusExamen(STATUS_INGLES_SECCION_2_DESCANSO)
      saveData()
    }else if(rightNow>tiempos.fechaFinInglesSeccion2Descanso && rightNow<tiempos.fechaFinMatesSeccion3 && statusExamen!==STATUS_MATES_SECCION_3){
      setStatusExamen(STATUS_MATES_SECCION_3)
    }else if(rightNow>tiempos.fechaFinMatesSeccion3 && rightNow<tiempos.fechaFinMatesSeccion3Descanso && statusExamen!==STATUS_MATES_SECCION_3_DESCANSO){
      setStatusExamen(STATUS_MATES_SECCION_3_DESCANSO)
      saveData()
    }else if(rightNow>tiempos.fechaFinMatesSeccion3Descanso && rightNow<tiempos.fechaFinMatesSeccion4 && statusExamen!==STATUS_MATES_SECCION_4){
      setStatusExamen(STATUS_MATES_SECCION_4)
    }else if(rightNow>tiempos.fechaFinMatesSeccion4){
      setStatusExamen(STATUS_FIN_EXAMEN)
      saveData()
      setTimeout(()=>{window.location.href=pathAreaAlumno}, 3 * 60  * 1000)
    }else if(!statusExamen){
      setStatusExamen(STATUS_LOADING_EXAMEN)
    }
  }

  const saveData = () => {
    const examenState = currentAlumnoState.inscripcionAlumno.sat.examen
    const examen = currentAlumno.inscripcionAlumno.sat.examen
    if(!_.isEqual(examenState,examen)){
      let multiPath = {}
      multiPath[`Inscripciones/Alumnos/${inscripcion}/${currentAlumno.id}/sat/examen`] = examen
      db.update(multiPath)
      .then(()=>{})
      .catch(err=>{})
      setCurrentAlumnoState(currentAlumno)
    }
  }

  const panelInicio = useMemo(()=>
    <div className="panel-info-examen-count-down">
      <h1>El examen empezará en:</h1>
      <div className="contador">
        <DateCountdown dateTo={tiempos?.fechaExamen?.toString() ?? ''} callback={()=>setPanelExamen(true)} mostSignificantFigure="min" locales={localesDateCountdown} locales_plural={localespluralDateCountdown}/>
      </div>
    </div>
  , [tiempos])
  const descanso1 = useMemo(()=> <Descanso time={tiempos.fechaFinInglesSeccion1Descanso} setPanelExamen={()=>setPanelExamen(true)} setCurrentAlumnoProps={currentAlumno=>setCurrentAlumnoState(currentAlumno)}/>, [tiempos])
  
  //const descanso2 = useMemo(()=> <Descanso time={tiempos.fechaFinInglesSeccion2Descanso} setPanelExamen={getTime} setCurrentAlumnoProps={currentAlumno=>setCurrentAlumnoState(currentAlumno)}/>, [tiempos])
  const descanso2 = useMemo(()=> <PanelVideo time={tiempos.fechaFinInglesSeccion2Descanso} setPanelExamen={()=>setPanelExamen(true)} setCurrentAlumnoProps={currentAlumno=>setCurrentAlumnoState(currentAlumno)}/>, [tiempos])
  
  const descanso3 = useMemo(()=> <Descanso time={tiempos.fechaFinMatesSeccion3Descanso} setPanelExamen={()=>setPanelExamen(true)} setCurrentAlumnoProps={currentAlumno=>setCurrentAlumnoState(currentAlumno)}/>, [tiempos])

  const inglesS1 = useMemo(()=> <PanelIngles time={tiempos.fechaFinInglesSeccion1} setPanelExamen={()=>setPanelExamen(true)} type={'reading'} setCurrentAlumnoProps={currentAlumno=>setCurrentAlumnoState(currentAlumno)}/>, [tiempos])
  const inglesS2 = useMemo(()=> <PanelIngles time={tiempos.fechaFinInglesSeccion2} setPanelExamen={()=>setPanelExamen(true)} type={'writing'} setCurrentAlumnoProps={currentAlumno=>setCurrentAlumnoState(currentAlumno)}/>, [tiempos])
  
  const matesS3 = useMemo(()=> <PanelMatematicas time={tiempos.fechaFinMatesSeccion3} setPanelExamen={()=>setPanelExamen(true)}  type={3} setCurrentAlumnoProps={currentAlumno=>setCurrentAlumnoState(currentAlumno)}/>, [tiempos])
  const matesS4 = useMemo(()=> <PanelMatematicas time={tiempos.fechaFinMatesSeccion4} setPanelExamen={()=>setPanelExamen(true)}  type={4} setCurrentAlumnoProps={currentAlumno=>setCurrentAlumnoState(currentAlumno)}/>, [tiempos])

  return(
    loading ? <div>Loading...</div> : !hasAccess ? <Redirect to={'/area-alumno'} /> :
  
    <div className="page-examen-sat">
      {statusExamen===STATUS_INICIANDO && panelInicio }
      {/*statusExamen===STATUS_VIDEO && <PanelVideo time={tiempos.fechaFinVideo} setPanelExamen={setPanelExamen}/>*/}

      {statusExamen===STATUS_INGLES_SECCION_1 && inglesS1 }
      
      {statusExamen===STATUS_INGLES_SECCION_1_DESCANSO && descanso1 }
      
      {statusExamen===STATUS_INGLES_SECCION_2 && inglesS2}
      
      {statusExamen===STATUS_INGLES_SECCION_2_DESCANSO && descanso2}

      {statusExamen===STATUS_MATES_SECCION_3 && matesS3}

      {statusExamen===STATUS_MATES_SECCION_3_DESCANSO && descanso3}

      {statusExamen===STATUS_MATES_SECCION_4 && matesS4}

      {statusExamen===STATUS_FIN_EXAMEN && <ExamenTerminado setCurrentAlumnoProps={currentAlumno=>setCurrentAlumnoState(currentAlumno)}/>}

      {statusExamen===STATUS_LOADING_EXAMEN && <LoadingExamen />}

    </div>
  )
})

const mapStateToProps = ({ alumnos, colegios, global }) => ({
  currentAlumno: alumnos.currentAlumno,
  currentColegio: colegios.currentColegio,
  inscripcion: global.inscripcion
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      getTime,
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(ExamenSat);