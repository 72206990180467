import React, { useState, useEffect, memo } from 'react'
import Input from '../../../../../../Shared/Input'
import InputMenu from '../../../../../../Shared/InputMenu'
import UpdateStateInputs from '../../../../../../Shared/UpdateStateInputs'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../../utils/firebase'
import _ from 'underscore'
import { opcionesTiposActividadAcademica, opcionesYearsDedication, opcionesHorasSemanales } from '../../../../../../../utils/constans'
import { getNumber } from '../../../../../../../utils/functions'
import Textarea from '../../../../../../Shared/Textarea';
import Switch from '../../../../../../Shared/Switch';

const InformacionExtraescolar = memo((props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  const saveData = () => {
    if (!state.nombreActividad.trim() || !state.centroActividad.trim()) {
      props.actions.setNotification({ visibility: true, text: "Exiten errores en los campos", status: "close", moment: Date.now() })
      return false;
    }

    var multiPath = {};
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/tipoActividad`] = state.tipoActividad;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/nombreActividad`] = state.nombreActividad;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/dedicationYears`] = state.dedicationYears;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/horasSemanales`] = state.horasSemanales;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/centroActividad`] = state.centroActividad;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/siguePracticando`] = state.siguePracticando;

    db.update(multiPath)
    .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  }
  

  return(
    <div className='sub-container-informacion-alumno'>

      {edited && <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> }

      <p className="title-informacion-alumno"> 2. Información de la actividad extraescolar </p>

      <div className="col-2-input">
        <InputMenu list={opcionesTiposActividadAcademica} title="Tipo de actividad" text={state.tipoActividad} changeValue={tipoActividad => setState({ ...state, tipoActividad })} />
        <Input title="Nombre de la actividad" _class_container={ !state.nombreActividad.trim()? "error-form-input" : '' } _class_input="camelcase" text={state.nombreActividad} changeValue={nombreActividad => { setState({ ...state, nombreActividad }); }} />
      </div>

      <div className="col-2-input">
        <Input title="Centro en el que lo realiza" _class_container={ !state.centroActividad.trim()? "error-form-input": ''} text={state.centroActividad} changeValue={centroActividad => {setState({ ...state, centroActividad });}}/>
        <InputMenu list={opcionesYearsDedication} title="Años de dedicación" text={state.dedicationYears} changeValue={dedicationYears =>setState({ ...state, dedicationYears })}/>
      </div>

      <div className="col-2-input">

        {state.siguePracticando && 
          <InputMenu list={opcionesHorasSemanales} title="Horas de prácticas semanales este año" text={state.horasSemanales} changeValue={horasSemanales => setState({ ...state, horasSemanales }) }/>
        }

        <div className={`display_flex container-simple-input ${ state.siguePracticando ? "pdd-top-40" : "pdd-top-20" }`} >
          <div className="title-input align-center mg-right-10 pdd-v-0"> Sigue practicándolo: </div>
          <span className="options-switch">NO</span>
          <Switch class_div="switch-table" json={{ id: "siguePracticando" }} value={state.siguePracticando} callbackSwitch={json => { setState({ ...state, siguePracticando: json.value }); }} />
          <span className="options-switch">SI</span>
        </div>
      </div>
    </div>
  )
})

const mapStateToProps = ({ global }) => {
  return {
    inscripcion: global.inscripcion
  };
}
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(InformacionExtraescolar);