import React from 'react'
import { onFocusInscripciones, onBlurInscripciones, validarTelefono } from '../../../utils/functions'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentColegio } from '../../../redux/actions/colegiosActions';
const Responsale = (props) => {
  const { errors } = props

  return(
    <div>
      
        <h4 className='h4-formulario-inscripciones'>Responsable {props.num+1}</h4>
        <div className="pr width_100 colums_form_2">
          {/*NOMBRE*/}
          <div className="pr width_100 colums_form_2_margin_right">
            <input onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ${errors[`nombreR${props.num}`]?'obligatorio':''}`} value={props.nombre} 
              onChange={e=>props.actions.modifyCurrentColegio({value: e.target.value, path:`responsables.${props.num}.nombre`})}/>
            <label className="label_form">Nombre</label>
          </div>
          {/*APELLIDOS*/}
          <div className="pr width_100 colums_form_2_margin_left">
            <input onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ${errors[`apellidosR${props.num}`]?'obligatorio':''}`} value={props.apellidos} 
              onChange={e=>props.actions.modifyCurrentColegio({value: e.target.value, path:`responsables.${props.num}.apellidos`})}
            />
            <label className="label_form">Apellidos</label>
          </div>

        </div>

        <div className="pr">
          <input onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ${errors[`emailR${props.num}`]?'obligatorio':''}`} value={props.email} 
            onChange={e=>props.actions.modifyCurrentColegio({value: e.target.value, path:`responsables.${props.num}.email`})}
          />
          <label className="label_form">Email</label>
        </div>

        <div className="pr width_100 colums_form_2">
          {/*Teléfono móvil */}
          <div className="pr width_100 colums_form_2_margin_right">
            <input onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ${errors[`telefonoMovilR${props.num}`]?'obligatorio':''}`} value={props.telefonoMovil} 
            onChange={e=>props.actions.modifyCurrentColegio({value: validarTelefono(e.target.value), path:`responsables.${props.num}.telefonoMovil`})} />
            <label className="label_form">Teléfono móvil</label>
          </div>
          {/*Teléfono fijo */}
          <div className="pr width_100 colums_form_2_margin_left">
            <input onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ${errors[`telefonoFijoR${props.num}`]?'obligatorio':''}`} value={props.telefonoFijo} 
            onChange={e=>props.actions.modifyCurrentColegio({value: validarTelefono(e.target.value), path:`responsables.${props.num}.telefonoFijo`})}/>
            <label className="label_form">Teléfono fijo</label>
          </div>
        </div>

        <div className="pr width_100 colums_form_2">
          {/*CARGO */}
          <div className="pr width_100 colums_form_2_margin_right">
            <input onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ${errors[`cargoR${props.num}`]?'obligatorio':''}`} value={props.cargo} 
              onChange={e=>props.actions.modifyCurrentColegio({value: e.target.value, path:`responsables.${props.num}.cargo`})}/>
            <label className="label_form">Cargo en el colegio</label>
          </div>
          {/*HORARIO */}
          <div className="pr width_100 colums_form_2_margin_left">
            <input onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ${errors[`horarioPreferenteR${props.num}`]?'obligatorio':''}`} value={props.horarioPreferenteR1} 
            onChange={e=>props.actions.modifyCurrentColegio({value: e.target.value, path:`responsables.${props.num}.horarioPreferente`})}/>
            <label className="label_form">Horario preferente para llamadas</label>
          </div>
        </div>


    </div>
  )
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentColegio
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(Responsale);