import React, {useState} from 'react'
import DatosDeporte from './DatosDeporte'
import DatosAlumno from './DatosAlumno'
import Autorizacion from './Autorizacion'
import { connect } from "react-redux";
import { isRepeatedDni, isRepeatedEmail, ocultarCargandoFormAlumnos, visualizarCargandoFormAlumnos } from '../../../../../../utils/functions'
import ErrorFormulario from '../../../Shared/ErrorFormulario'
import { bindActionCreators } from 'redux';
import { modifyCurrentAlumno, saveAlumno, modifyAlumno } from '../../../../../../redux/actions/alumnosActions';
import PanelConfirmacionAutorizaciones from '../PanelConfirmacionAutorizaciones'
import { pathFormularioNewAlumno, pathFormularioModifyAlumno } from '../../../../../../utils/constans';

const FaseDeportiva = ({currentAlumno, setPanelSelected, setPanelSelectedBack, actions, setUsuarioRepetido, setModificacionGuardado}) => {

  const [errors, setErrors] = useState({})
  const [showMensaje, setShowMensaje] = useState(false)
  const [showPanelConfirmacion, setShowPanelConfirmacion] = useState(false)

  const nextPanel = async() => {
    visualizarCargandoFormAlumnos({bottom: true})
    let errors = currentAlumno.checkFaseDeportiva()
    setErrors(errors)

    if(Object.keys(errors).length>0){
      setShowMensaje(true)
      ocultarCargandoFormAlumnos()
      return false
    }
    
    const repeatedDni = await isRepeatedDni(currentAlumno.informacionAlumno.dni, currentAlumno.id)
    if(repeatedDni){
      setShowMensaje(true)
      ocultarCargandoFormAlumnos()
      setUsuarioRepetido()
      return false
    }
    if(!currentAlumno.isAuthenticated && window.location.pathname===pathFormularioNewAlumno){
      const repeatedEmail = await isRepeatedEmail(currentAlumno.informacionAlumno.email)
      if(repeatedEmail){
        setShowMensaje(true)
        ocultarCargandoFormAlumnos()
        setUsuarioRepetido()
        return false
      }
    }

    if(!showPanelConfirmacion && (!currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.colaboraciones || !currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.imagen)){
      setShowPanelConfirmacion(true)
      return false
    }else{
      saveData()
    }
  }

  const saveData = async() => {
    //guardar en base de datos 
    let ok = false
    if(currentAlumno.isAuthenticated && window.location.pathname===pathFormularioModifyAlumno){
      ok = await actions.modifyAlumno()
    }else if(!currentAlumno.isAuthenticated && window.location.pathname===pathFormularioNewAlumno){
      ok = await actions.saveAlumno()
    }
    ocultarCargandoFormAlumnos()
    if(ok){
      if(currentAlumno.inscripcionAlumno.isValidCuestionario()){
        setModificacionGuardado()
      }else{
        setPanelSelected()
      }
    }
  }

  const rejected = () => {
    setShowPanelConfirmacion(false)
    saveData()
  }

  const acepted = () => {
    actions.modifyCurrentAlumno({value:true, path:'inscripcionAlumno.inscripcion.beca.deportiva.colaboraciones'})
    actions.modifyCurrentAlumno({value:true, path:'inscripcionAlumno.inscripcion.beca.deportiva.imagen'})
    setShowPanelConfirmacion(false)
    saveData()
  }

  return(
    <div>
      <div className='title-panel-form-alumnos'>Beca deportiva</div>

      <DatosDeporte
        deporte={currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.deporte}
        club={currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.club}
        categoria={currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.categoria}
        federado={currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.federado}
        errors={errors}
      />

      <DatosAlumno 
        tipoDeporte={currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.tipoDeporte}
        division={currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.division}
        ranking={currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.ranking}
        csd={currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.csd}
        informacionAdicional={currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.informacionAdicional}
        errors={errors}
      />

      <Autorizacion 
        colaboraciones={currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.colaboraciones}
        imagen={currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.imagen}
        terminos={currentAlumno.inscripcionAlumno.inscripcion.beca.deportiva.terminos}
        errors={errors}
      />

      {showMensaje &&
        <ErrorFormulario updateError={()=>setShowMensaje(false)} />
      }

      {showPanelConfirmacion &&
        <PanelConfirmacionAutorizaciones rejected={rejected} acepted={acepted}/>
      }

      <div className='direccions_panel'>
        <div className='btn_send_form pointer noselect float_atras btn-volver-form' onClick={setPanelSelectedBack}>Atrás</div>
        <div className='btn_send_form pointer noselect float_siguiente' onClick={nextPanel}>
          {`${currentAlumno.inscripcionAlumno.isValidCuestionario()?"Guardar":"Siguiente"}`}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ alumnos }) => {
  return {
    currentAlumno: alumnos.currentAlumno,
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno,
      saveAlumno,
      modifyAlumno
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(FaseDeportiva);
