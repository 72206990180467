import React, { useState, useEffect } from 'react'
import Input from '../../../../../Shared/Input'
import InputMenu from '../../../../../Shared/InputMenu'
import Switch from '../../../../../Shared/Switch'
import UpdateStateInputs from '../../../../../Shared/UpdateStateInputs'
import { provincias } from '../../../../../../utils/constans'
import { validHora, validFecha } from '../../../../../../utils/functions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../utils/firebase'
import _ from 'underscore'
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  TimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import moment from 'moment-timezone'

const InformacionExamen = (props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedTime, setSelectedTime] = React.useState(null);

  const handleDateChange = date => {
    
    setSelectedDate(date);
  };

  const saveData = () => {

    var multiPath = {}
    multiPath[`Colegios/${props.id}/examen/habilitado`] = state.habilitado
    multiPath[`Colegios/${props.id}/examen/fechaExamen`] = state.fechaExamen
    multiPath[`Colegios/${props.id}/examen/horaExamen`] = state.horaExamen

    db.update(multiPath)
    .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  }

  const changeDate = (value) => {
    if(!value){
      setState({...state, fechaExamen: null})
    }else{
      const date = moment.tz( `${moment(value).format('YYYY-MM-DD').toString()}`, "Europe/Madrid").format('YYYY-MM-DD');
      setState({...state, fechaExamen: date})
    }
  }

  const changeTime = (value) => {
    if(!value){
      setState({...state, horaExamen: null})
    }else{
      //const time = moment(value).format('HH:mm')
      const time = moment.tz( `${moment(value).format('YYYY-MM-DD HH:mm').toString()}`, "Europe/Madrid").format('HH:mm');
      setState({...state, horaExamen: time})
    }
  }

  return(
    <div className='sub-container-informacion-alumno'>

      {edited && !props.block? <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> :null }

      <p className='title-informacion-alumno'>1. Información examen</p>

      <div className='display_flex container-simple-input'>
        <div className='title-input'>Habilitar Examen:</div>
        <span className="options-switch">NO</span>
        <Switch class_div='switch-table' json={{id:'habilitado'}} value={state.habilitado ?? false} callbackSwitch={json=>setState({...state, habilitado:json.value})}/>
        <span className="options-switch">SI</span>
      </div>

      <div className="container-date col-2-input"> 
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="container-simple-input ">
            <div className="title-input">Fecha del Examen:</div>
            <div className="container-input ">
            <DatePicker
              clearable

              format="dd/MM/yyyy"
              value={state.fechaExamen ? moment(state.fechaExamen).format('MM-DD-YYYY').toString() : null}
              onChange={fechaExamen => changeDate(fechaExamen)}
              disablePast={true}
              emptyLabel=""
              invalidLabel=""
              autoOk={true}
              animateYearScrolling
              inputVariant={'standard'}
            />
          </div>
          </div>
          <div className="container-simple-input ">
            <div className="title-input">Hora del Examen:</div>
            <div className="container-input ">

            <TimePicker
              clearable
              ampm={false}
              autoOk={true}
              value={state.horaExamen ? new Date(`${moment().format('YYYY-MM-DD')} ${state.horaExamen}`): null}
              inputVariant={'standard'}
              onChange={changeTime}
            />
          </div>
          </div>

        </MuiPickersUtilsProvider>
      </div>
    </div>
  )
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(InformacionExamen);