import React from 'react'
import { Redirect } from 'react-router-dom'

const UsuarioRepetido = ({cancel}) => {
  const redirect = () => {
    window.location.href = "/login"
  }
  return(
    <div className="formulario_fase_comun pr">
      <div>
        <div className="shadow pop-up-cuestionario pr items-login conatiner-inicio-sesion">
          <div className="title-inicio-sesion-alumno mensaje-repetido-alumno">{`Vaya!!, parece que ya se han registrado con este DNI o EMAIL`}</div>
          <div className='div-opciones-inscripcion'>
            <div className='btn_send_form btn-volver-form' onClick={cancel}>Cancelar</div>
            <div className='btn_send_form' onClick={()=>redirect()}>Iniciar sesion</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UsuarioRepetido