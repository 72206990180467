//import { limpiarString } from "../functions";
import InscripcionAlumno from './InscripcionAlumno'
import { isEmail, isTelefono, isCorrectDni, isValidFechaNacimiento } from '../utils/functions'
//import {isCorrectEmail, isCorrectTelefono, isCorrectDni} from '../functions'

class Alumno {
  isAuthenticated = false
  id = null;
  type = "alumno"
  informacionAlumno = {
    nombre: "",
    nombreCompleto: "",
    apellidos: "",
    email: "",
    telefono: "",
    dni: "",
    password: "",
    repetirPassword: "",
    ciudad: "",
    fechaNacimiento: "",
    sexo: "",
    direccion: "",
    provincia: "",
    piso: "",
    numero: "",
    letra: "",
    codigoPostal: ""
  }
  informacionTutor = {
    nombre: "",
    apellidos: "",
    email: "",
    telefono: ""
  };
  inscripcionAlumno = new InscripcionAlumno();
  timestamp = 0;
  inscripciones = {}; // {2018-2019:true, 2019-2020:true}

  //con este atributo indicaremos al alumno que los datos de su formulario son correctos o incorrectos una vez haya dado en el boton continuar del formilario
  datosValidados = false

  constructor(obj){
    if(obj){
      this.id = obj.id ?? null;
      this.informacionAlumno = {
        ...obj.informacionAlumno,
        dni: obj.informacionAlumno.dni.toUpperCase(),
        email: obj.informacionAlumno.email,
        nombreCompleto: `${obj.informacionAlumno.nombre.toLowerCase()} ${obj.informacionAlumno.apellidos.toLowerCase()}`
      } ?? this.informacionAlumno
      this.informacionTutor = obj.informacionTutor ?? this.informacionTutor
      this.inscripcionAlumno = obj.inscripcionAlumno ? new InscripcionAlumno(obj.inscripcionAlumno) : this.inscripcionAlumno
      this.timestamp = obj.timestamp ?? this.timestamp
      //this.datosValidados = obj.datosValidados?obj.datosValidados:false
      this.inscripciones =  obj.inscripciones ?? this.inscripciones
      this.isAuthenticated = obj.isAuthenticated ?? this.isAuthenticated
    }
  }

  checkFaseComun = () => {
    let errors = {}
    if(!isCorrectDni(this.informacionAlumno.dni.trim())){errors.dni=true}
    if( !this.isAuthenticated && !(this.informacionAlumno.password.length>=6 && this.informacionAlumno.password === this.informacionAlumno.repetirPassword ) ){
      errors.password=true
      errors.repetirPassword=true
    }
    if(!isEmail(this.informacionAlumno.email.trim())){errors.email=true}
    if(!this.informacionAlumno.nombre.trim()){errors.nombre=true}
    if(!this.informacionAlumno.apellidos.trim()){errors.apellidos=true}
    if(!isTelefono(this.informacionAlumno.telefono.trim())){errors.telefono=true}
    if(!isValidFechaNacimiento(this.informacionAlumno.fechaNacimiento.trim())){errors.fechaNacimiento=true}
    if(!this.informacionAlumno.sexo.trim()){errors.sexo=true}
    if(!this.informacionAlumno.direccion.trim()){errors.direccion=true}
    if(!this.informacionAlumno.piso.trim()){errors.piso=true}
    if(!this.informacionAlumno.numero.trim()){errors.numero=true}
    if(!this.informacionAlumno.codigoPostal.trim()){errors.codigoPostal=true}
    if(!this.informacionAlumno.ciudad.trim()){errors.ciudad=true}
    if(!this.informacionAlumno.provincia.trim()){errors.provincia=true}



    if(!this.informacionTutor.nombre.trim()){errors.nombreTutor=true}
    if(!this.informacionTutor.apellidos.trim()){errors.apellidosTutor=true}
    if(!isEmail(this.informacionTutor.email.trim())){errors.emailTutor=true}
    if(!isTelefono(this.informacionTutor.telefono.trim())){errors.telefonoTutor=true}


    if(!this.inscripcionAlumno.datosColegio.colegio.id){errors.colegioId=true}
    if(!this.inscripcionAlumno.datosColegio.sedeSat.trim()){errors.colegioSedeSat=true}
    if(!this.inscripcionAlumno.datosColegio.ciudad.trim()){errors.colegioCiudad=true}
    if(this.inscripcionAlumno.datosColegio.colegio.id==="nuevo"){
      if(!this.inscripcionAlumno.datosColegio.colegio.nombre.trim()){errors.colegioNombre=true}
      if(!this.inscripcionAlumno.datosColegio.colegio.tipoColegio.trim()){errors.colegioTipo=true}
      if(this.inscripcionAlumno.datosColegio.colegio.emailTutor.trim() && !isEmail(this.inscripcionAlumno.datosColegio.colegio.emailTutor.trim())){errors.colegioEmailTutor=true}
    }

    if(!this.inscripcionAlumno.inscripcion.tipo.trim()){errors.tipoInscripcion=true}
    if(!this.inscripcionAlumno.cursoActual.curso.trim()){errors.cursoActual=true}
    if(!this.inscripcionAlumno.cursoAnterior.mediaGlobal.trim()){errors.mediaGlobal=true}
    if(!this.inscripcionAlumno.cursoAnterior.mediaMatematicas.trim()){errors.mediaMatematicas=true}
    if(!this.inscripcionAlumno.cursoAnterior.mediaIngles.trim()){errors.mediaIngles=true}
    if(!this.inscripcionAlumno.ingles.nivel.trim()){errors.nivelIngles=true}
    if(!this.inscripcionAlumno.ingles.titulacion.trim()){errors.titulacionIngles=true}
    if(!this.inscripcionAlumno.estudiarFueraSpain.trim()){errors.estudiarFueraSpain=true}
    if(!this.inscripcionAlumno.tipoCarrera.trim()){errors.tipoCarrera=true}



    return errors
  }

  checkFaseAcademica = () => {
    let errors = {}

    if(!this.inscripcionAlumno.inscripcion.beca.academica.tipoActividad.trim()){errors.tipoActividad=true}
    if(!this.inscripcionAlumno.inscripcion.beca.academica.nombreActividad.trim()){errors.nombreActividad=true}
    if(!this.inscripcionAlumno.inscripcion.beca.academica.dedicationYears.trim()){errors.dedicationYears=true}
    if(this.inscripcionAlumno.inscripcion.beca.academica.siguePracticando){
      if(!this.inscripcionAlumno.inscripcion.beca.academica.horasSemanales.trim()){errors.horasSemanales=true}
    }
    if(!this.inscripcionAlumno.inscripcion.beca.academica.centroActividad.trim()){errors.centroActividad=true}


    if(this.inscripcionAlumno.inscripcion.beca.academica.experienciaExtranjero){
      if(!this.inscripcionAlumno.inscripcion.beca.academica.lugarExperiencia.trim()){errors.lugarExperiencia=true}
      if(!this.inscripcionAlumno.inscripcion.beca.academica.mesesExperiencia.trim()){errors.mesesExperiencia=true}
      if(!this.inscripcionAlumno.inscripcion.beca.academica.objetivoExperiencia.trim()){errors.objetivoExperiencia=true}
      if(!this.inscripcionAlumno.inscripcion.beca.academica.experienciaDescripcion.trim()){errors.experienciaDescripcion=true}
    }


    if(this.inscripcionAlumno.inscripcion.beca.academica.voluntariado){
      if(!this.inscripcionAlumno.inscripcion.beca.academica.voluntariadoDescripcion.trim()){errors.voluntariadoDescripcion=true}
    }

    if(!this.inscripcionAlumno.inscripcion.beca.academica.terminos){errors.terminos=true}
  
    return errors
  }

  checkFaseDeportiva = () => {
    let errors = {}

    if(!this.inscripcionAlumno.inscripcion.beca.deportiva.deporte.trim()){errors.deporte=true}
    if(!this.inscripcionAlumno.inscripcion.beca.deportiva.club.trim()){errors.club=true}
    if(!this.inscripcionAlumno.inscripcion.beca.deportiva.categoria.trim()){errors.categoria=true}
    if(!this.inscripcionAlumno.inscripcion.beca.deportiva.federado.trim()){errors.federado=true}

    if(!this.inscripcionAlumno.inscripcion.beca.deportiva.tipoDeporte.trim()){errors.tipoDeporte=true}
    if(this.inscripcionAlumno.inscripcion.beca.deportiva.tipoDeporte==="Equipo"){
      if(!this.inscripcionAlumno.inscripcion.beca.deportiva.division.trim()){errors.division=true}
    }else if(this.inscripcionAlumno.inscripcion.beca.deportiva.tipoDeporte==="Individual"){
      if(!this.inscripcionAlumno.inscripcion.beca.deportiva.ranking.trim()){errors.ranking=true}
    }
    if(!this.inscripcionAlumno.inscripcion.beca.deportiva.informacionAdicional.trim()){errors.informacionAdicional=true}

    if(!this.inscripcionAlumno.inscripcion.beca.deportiva.terminos){errors.terminos=true}



    return errors

  }



/*
  isValid = () => {
    var isValid = true;
    
    //DATOS ALUMNO
    if(!isCorrectDni(this.informacionAlumno.dni)){ 
      isValid=false 
    }
    if(!isCorrectEmail(this.informacionAlumno.email)){ 
      isValid=false 
    }
    if(this.id.trim()==="" && this.informacionAlumno.password.trim()==='' || this.informacionAlumno.password!==this.informacionAlumno.repetirPassword || this.informacionAlumno.password.length<6){
      isValid=false 
    }
    
    if(this.informacionAlumno.nombre.trim()===''){ 
      isValid=false
    }
    if(this.informacionAlumno.apellidos.trim()===''){ 
      isValid=false 
    }
    if(!isCorrectTelefono(this.informacionAlumno.telefono)){ 
      isValid=false 
    }
    if(this.informacionAlumno.fechaNacimiento.trim()===''){ 
      isValid=false 
    }
    if(this.informacionAlumno.sexo.trim()===''){ 
      isValid=false 
    }
    if(this.informacionAlumno.direccion.trim()===''){ 
      isValid=false 
    }
    if(this.informacionAlumno.piso.trim()===''){ 
      isValid=false 
    }
    if(this.informacionAlumno.numero.trim()===''){ 
      isValid=false 
    }
    if(this.informacionAlumno.codigoPostal.trim()===''){ 
      isValid=false 
    }
    if(this.informacionAlumno.ciudad.trim()===''){ 
      isValid=false 
    }
    if(this.informacionAlumno.provincia.trim()===''){ 
      isValid=false 
    }
    

    
    //DATOS TUTOR
    if(this.informacionTutor.nombre.trim()===''){ 
      isValid=false
    }
    if(this.informacionTutor.apellidos.trim()===''){ 
      isValid=false 
    }
    if(!isCorrectEmail(this.informacionTutor.email)){ 
      isValid=false 
    }
    if(!isCorrectTelefono(this.informacionTutor.telefono)){ 
      isValid=false 
    }
    

    const datosColegio = this.inscripcionAlumno.datosColegio

    if(datosColegio.colegio.id.trim()===''){
      isValid=false 
    }
    if(datosColegio.colegio.id==='nuevo'){
      if(datosColegio.colegio.nombre.trim()===''){
        isValid=false 
      }
      if(datosColegio.colegio.codigo.trim()===''){
        isValid=false 
      }
      if(datosColegio.colegio.tipoColegio.trim()===''){
        isValid=false 
      }
      if(datosColegio.colegio.emailTutor.trim()!=='' && !isCorrectEmail(datosColegio.colegio.emailTutor)){
        isValid=false 
      }
    }
    if(datosColegio.sedeSat.trim()===''){
      isValid=false 
    }
    if(datosColegio.ciudad.trim()===''){
      isValid=false 
    }
    
    
    
    //DATOS ACADEMICOS
    if(this.inscripcionAlumno.inscripcion.tipo===''){
      isValid=false
    }
    if(this.inscripcionAlumno.cursoActual.curso.trim()===''){ 
      isValid=false
    }
    if(this.inscripcionAlumno.cursoAnterior.mediaGlobal.trim()===''){ 
      isValid=false
    }
    if(this.inscripcionAlumno.cursoAnterior.mediaIngles.trim()===''){ 
      isValid=false
    }
    if(this.inscripcionAlumno.cursoAnterior.mediaMatematicas.trim()===''){ 
      isValid=false
    }
    if(this.inscripcionAlumno.ingles.nivel===''){
      isValid=false
    }
    if(this.inscripcionAlumno.ingles.titulacion===''){
      isValid=false
    }
    if(this.inscripcionAlumno.estudiarFueraSpain===''){
      isValid=false
    }
    if(this.inscripcionAlumno.tipoCarrera===''){
      isValid=false
    }
    if(this.inscripcionAlumno.tipoCarrera===''){
      isValid=false
    }
    
    return {isValid}
  }
*/



}
/*
isValid = (self) => {
  var isValid = true;
  
  //DATOS ALUMNO
  if(!isCorrectDni(self.informacionAlumno.dni)){ 
    isValid=false 
  }
  if(!isCorrectEmail(self.informacionAlumno.email)){ 
    isValid=false 
  }
  if(self.id.trim()==="" && self.informacionAlumno.password.trim()==='' || self.informacionAlumno.password!==self.informacionAlumno.repetirPassword || self.informacionAlumno.password.length<6){
    isValid=false 
  }
  
  if(self.informacionAlumno.nombre.trim()===''){ 
    isValid=false
  }
  if(self.informacionAlumno.apellidos.trim()===''){ 
    isValid=false 
  }
  if(!isCorrectTelefono(self.informacionAlumno.telefono)){ 
    isValid=false 
  }
  if(self.informacionAlumno.fechaNacimiento.trim()===''){ 
    isValid=false 
  }
  if(self.informacionAlumno.sexo.trim()===''){ 
    isValid=false 
  }
  if(self.informacionAlumno.direccion.trim()===''){ 
    isValid=false 
  }
  if(self.informacionAlumno.piso.trim()===''){ 
    isValid=false 
  }
  if(self.informacionAlumno.numero.trim()===''){ 
    isValid=false 
  }
  if(self.informacionAlumno.codigoPostal.trim()===''){ 
    isValid=false 
  }
  if(self.informacionAlumno.ciudad.trim()===''){ 
    isValid=false 
  }
  if(self.informacionAlumno.provincia.trim()===''){ 
    isValid=false 
  }
  

  
  //DATOS TUTOR
  if(self.informacionTutor.nombre.trim()===''){ 
    isValid=false
  }
  if(self.informacionTutor.apellidos.trim()===''){ 
    isValid=false 
  }
  if(!isCorrectEmail(self.informacionTutor.email)){ 
    isValid=false 
  }
  if(!isCorrectTelefono(self.informacionTutor.telefono)){ 
    isValid=false 
  }
  

  const datosColegio = self.inscripcionAlumno.datosColegio

  if(datosColegio.colegio.id.trim()===''){
    isValid=false 
  }
  if(datosColegio.colegio.id==='nuevo'){
    if(datosColegio.colegio.nombre.trim()===''){
      isValid=false 
    }
    if(datosColegio.colegio.codigo.trim()===''){
      isValid=false 
    }
    if(datosColegio.colegio.tipoColegio.trim()===''){
      isValid=false 
    }
    if(datosColegio.colegio.emailTutor.trim()!=='' && !isCorrectEmail(datosColegio.colegio.emailTutor)){
      isValid=false 
    }
  }
  if(datosColegio.sedeSat.trim()===''){
    isValid=false 
  }
  if(datosColegio.ciudad.trim()===''){
    isValid=false 
  }
  
  
  
  //DATOS ACADEMICOS
  if(self.inscripcionAlumno.inscripcion.tipo===''){
    isValid=false
  }
  if(self.inscripcionAlumno.cursoActual.curso.trim()===''){ 
    isValid=false
  }
  if(self.inscripcionAlumno.cursoAnterior.mediaGlobal.trim()===''){ 
    isValid=false
  }
  if(self.inscripcionAlumno.cursoAnterior.mediaIngles.trim()===''){ 
    isValid=false
  }
  if(self.inscripcionAlumno.cursoAnterior.mediaMatematicas.trim()===''){ 
    isValid=false
  }
  if(self.inscripcionAlumno.ingles.nivel===''){
    isValid=false
  }
  if(self.inscripcionAlumno.ingles.titulacion===''){
    isValid=false
  }
  if(self.inscripcionAlumno.estudiarFueraSpain===''){
    isValid=false
  }
  if(self.inscripcionAlumno.tipoCarrera===''){
    isValid=false
  }
  if(self.inscripcionAlumno.tipoCarrera===''){
    isValid=false
  }
  
  return {isValid}
}*/



export default Alumno