import fb from "firebase";

//PRODUCCION W2AMERICANDREAM
var config = {
  apiKey: "AIzaSyCx57BcABvX91agfwOHBrG2An_IgWtAyOU",
  authDomain: "w2a-2-0.firebaseapp.com",
  databaseURL: "https://w2a-2-0.firebaseio.com",
  projectId: "w2a-2-0",
  storageBucket: "w2a-2-0.appspot.com",
  messagingSenderId: "562268777794",
  appId: "1:562268777794:web:788dd303543d60184facdb",
};

//DESARROLLO
/*var config = {
  apiKey: "AIzaSyBxijHxzmbHXCbye7ivy6Wo7lGyIJgM63s",
  authDomain: "w2americandream-desarrollo.firebaseapp.com",
  databaseURL: "https://w2americandream-desarrollo.firebaseio.com",
  projectId: "w2americandream-desarrollo",
  storageBucket: "w2americandream-desarrollo.appspot.com",
  messagingSenderId: "208081448290"
};*/

//PRODUCCION UNIDREAM
// var config = {
//   apiKey: "AIzaSyA6jS9vGB8UiROFPZ0i-uIgvIza8nxdMb0",
//   authDomain: "unidream-63670.firebaseapp.com",
//   databaseURL: "https://unidream-63670-default-rtdb.firebaseio.com",
//   projectId: "unidream-63670",
//   storageBucket: "unidream-63670.appspot.com",
//   messagingSenderId: "388418201940",
//   appId: "1:388418201940:web:d78aa5154eef4c585140c1",
//   measurementId: "G-9BDPQ0NJ08"
// }

const firebase = fb.initializeApp(config);

export const db = firebase.database().ref();
export const signOut = () => firebase.auth().signOut();

export default firebase;
