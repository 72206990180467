import React, { memo } from 'react'

const UpdateStateInputs = memo(({undoData, saveData}) => {
  return(
    <div className='settings-panels'>
      <div className='div-undo-icon' onClick={undoData}><i className="material-icons">undo</i></div>
      <div className='div-save-icon' onClick={saveData}><i className="material-icons">save</i></div>
    </div>
  )
})

export default UpdateStateInputs