import React, { memo } from 'react'

const ScrollContainer = memo(({id, _class, children}) => {
  return(
    <div id={id} className={`sub-container-panels scroll-container ${_class?_class:''}`}>
      {children}
    </div>
  )
})

export default ScrollContainer