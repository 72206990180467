import React, { useState, useEffect, memo } from 'react'
import Input from '../../../../../../Shared/Input'
import InputMenu from '../../../../../../Shared/InputMenu'
import UpdateStateInputs from '../../../../../../Shared/UpdateStateInputs'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../../utils/firebase'
import _ from 'underscore'
import { opcionesLugarExperiencia, opcionesObjetivoExperiencia, opcionesMesesExperiencia } from '../../../../../../../utils/constans'
import { getNumber } from '../../../../../../../utils/functions'
import Textarea from '../../../../../../Shared/Textarea';
import Switch from '../../../../../../Shared/Switch';

const InformacionExperiencia = memo((props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  const saveData = () => {

    if(!state.experienciaDescripcion.trim()){
      props.actions.setNotification({ visibility: true, text: "Exiten errores en los campos", status: "close", moment: Date.now() })
      return false
    }
    var multiPath = {};
    //los años y las horas hay que guardarlas como enteros
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/experienciaExtranjero`] = state.experienciaExtranjero;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/lugarExperiencia`] = state.lugarExperiencia;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/objetivoExperiencia`] = state.objetivoExperiencia;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/mesesExperiencia`] = state.mesesExperiencia;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/experienciaDescripcion`] = state.experienciaDescripcion;
    
    db.update(multiPath)
    .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  }
  

  return(
    <div className='sub-container-informacion-alumno'>

      {edited && <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> }

      <p className="title-informacion-alumno">3. Experiencia internacional</p>

      <div className="col-2-input">
        {state.experienciaExtranjero && 
          <InputMenu list={opcionesLugarExperiencia} title="Lugar de la experiencia" text={state.lugarExperiencia} changeValue={lugarExperiencia => setState({ ...state, lugarExperiencia }) } />
        }

        <div className={`display_flex container-simple-input ${ state.experienciaExtranjero ? "pdd-top-40" : "" }`} >
          <div className="title-input align-center mg-right-10 pdd-v-0"> Experiencia en el extranjero: </div>
          <span className="options-switch">NO</span>
          <Switch class_div="switch-table" json={{ id: "experienciaExtranjero" }} value={state.experienciaExtranjero} callbackSwitch={json => { setState({ ...state, experienciaExtranjero: json.value }); }} />
          <span className="options-switch">SI</span>
        </div>
      </div>

      {state.experienciaExtranjero && 
        <div className="col-2-input">
          <InputMenu list={opcionesObjetivoExperiencia} title="Consistió en" text={state.objetivoExperiencia} changeValue={objetivoExperiencia => setState({ ...state, objetivoExperiencia }) } />
          <InputMenu list={opcionesMesesExperiencia} title="Meses de experiencia" _class_input="camelcase" text={state.mesesExperiencia} changeValue={mesesExperiencia => setState({ ...state, mesesExperiencia }) } />
        </div>
      }
      {state.experienciaExtranjero && 
        <Textarea _class_input={ !state.experienciaDescripcion.trim() ? "error-form-input error-bg" : '' } _class="pdd-top-10" title="Descripción de la experiencia" text={state.experienciaDescripcion} changeValue={experienciaDescripcion => { setState({ ...state, experienciaDescripcion }); }} />
      }
    </div>
  )
})

const mapStateToProps = ({ global }) => {
  return {
    inscripcion: global.inscripcion
  };
}
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(InformacionExperiencia);