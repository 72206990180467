import React, { useLayoutEffect, memo, useRef } from 'react';

const ViewList = memo(({options, viewSelected, onClose, changeView}) => {

  const refElement = useRef()
  const clickOutSide = ({target}) => !refElement.current?.contains(target) && onClose()
  useLayoutEffect(()=>{
    document.addEventListener('mousedown',clickOutSide, false)
    return () =>document.removeEventListener('mousedown',clickOutSide, false)
  },[])

  return(
    <div className='opciones-search opciones-vistas opciones-search-show' onClick={e=>e.stopPropagation()} ref={refElement}>
      {
        Object.entries(options).map(([idOption, option])=>{
          return(
            <li key={idOption} className={`${viewSelected===idOption?'color-azul':''}`} onClick={()=>changeView(idOption)} >
              {option.valor}
            </li>
          )
        })
      }
    </div>
  )
})


export default ViewList;
