import React, { useState, useEffect, memo } from 'react'
import Input from '../../../../../../Shared/Input'
import InputMenu from '../../../../../../Shared/InputMenu'
import UpdateStateInputs from '../../../../../../Shared/UpdateStateInputs'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../../utils/firebase'
import _ from 'underscore'
import { opcionesSexo, opcionesCategoriaDeporte, opcionesFederado, opcionesTipoDeporte, opcionesDivisionDeporte } from '../../../../../../../utils/constans'
import { getNumber } from '../../../../../../../utils/functions'
import Textarea from '../../../../../../Shared/Textarea';
import Switch from '../../../../../../Shared/Switch';

const InformacionAlumno = memo((props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  const saveData = () => {
    if ( !state.informacionAdicional.trim() ) {
      props.actions.setNotification({ visibility: true, text: "Exiten errores en los campos", status: "close", moment: Date.now() })
      return false;
    }
    var multiPath = {};
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/categoria`] = state.categoria;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/informacionAdicional`] = state.informacionAdicional;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/csd`] = state.csd;
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/sexo`]=state.sexo.trim();
    db.update(multiPath)
    .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  }
  

  return(
    <div className='sub-container-informacion-alumno'>

      {edited && <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> }

      <p className="title-informacion-alumno">3. Información adicional</p>

      <div className="col-2-input">
        <InputMenu list={opcionesSexo} title="Sexo" text={state.sexo} changeValue={sexo => setState({ ...state, sexo }) }/>

        <div className='display_flex container-simple-input pdd-top-40'>
          <div className="title-input align-center mg-right-10 pdd-v-0">Deportista de alto nivel por el CSD:</div>
          <span className='options-switch'>NO</span>
          <Switch class_div="switch-table" json={{ id: "csd" }} value={state.csd} callbackSwitch={json => setState({ ...state, csd: json.value })} />
          <span className="options-switch">SI</span>
        </div>
      </div>

      <Textarea _class_input={ !state.informacionAdicional.trim() ? "error-form-input error-bg" : '' } _class="pdd-top-10" title="Perfil deportivo" text={state.informacionAdicional} changeValue={informacionAdicional => setState({ ...state, informacionAdicional })} />

    </div>
  )
})

const mapStateToProps = ({ global }) => {
  return {
    inscripcion: global.inscripcion
  };
}
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(InformacionAlumno);