import React from 'react'
import { pathFormularioNewAlumno } from '../../../../../utils/constans';

const GuardadoCorrectamente = () => {

  const refreshPage = () => {
    window.location.reload();
  }

  return(
    <div className="div_success">
      <div className="center-tag">
        <div className="info_success shadow pr">
          <div>Se han guardado los datos correctamente</div>
          <div className="btn_cerrar_pop_up pointer" onClick={() => window.location.href=pathFormularioNewAlumno}><i className="material-icons">close</i></div>
          <div className="margin_top_15"><i className="material-icons check_send_formulario">check</i></div>
        </div>
      </div>
    </div>
  )
}

export default GuardadoCorrectamente