import React, { useState, useEffect, memo } from 'react'
import Input from '../../../../../../Shared/Input'
import InputMenu from '../../../../../../Shared/InputMenu'
import UpdateStateInputs from '../../../../../../Shared/UpdateStateInputs'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../../utils/firebase'
import _ from 'underscore'
import { opcionesDeporte, opcionesCategoriaDeporte, opcionesFederado, opcionesTipoDeporte, opcionesDivisionDeporte } from '../../../../../../../utils/constans'
import { getNumber } from '../../../../../../../utils/functions'
import Textarea from '../../../../../../Shared/Textarea';

const InformacionDeporte = memo((props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  const saveData = () => {
    
    if ( state.tipoDeporte === "Individual" && !state.ranking.trim() ) {
      props.actions.setNotification({ visibility: true, text: "Introduce una marca", status: "close", moment: Date.now() })
      return false;
    } if ( state.tipoDeporte === "Equipo" && !state.division.trim() ) {
      props.actions.setNotification({ visibility: true, text: "Selecciona un división", status: "close", moment: Date.now() })      
      return false;
    } else if (!state.club || !state.club.trim()) {
      props.actions.setNotification({ visibility: true, text: "Introcude un club", status: "close", moment: Date.now() })      
      return false;
    }

    var multiPath = {};
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/deporte`] = state.deporte;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/categoria`] = state.categoria;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/tipoDeporte`] = state.tipoDeporte;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/division`] = state.division;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/federado`] = state.federado;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/ranking`] = state.ranking.trim();;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/club`] = state.club.trim();;

    db.update(multiPath)
    .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  
  }
  

  return(
    <div className='sub-container-informacion-alumno'>

      {edited && <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> }

      <p className="title-informacion-alumno">2. Información del deporte</p>

      <div className="col-2-input">
        <InputMenu list={opcionesDeporte} title="Deporte" text={state.deporte} changeValue={deporte => setState({...state, deporte })} />
        <Input title="Club" _class_container={ state.club.trim() === "" ? "error-form-input" : null } text={state.club} changeValue={club => setState({...state, club })} />
      </div>

      <div className="col-2-input">
        <InputMenu list={opcionesCategoriaDeporte} title="Categoría" text={state.categoria} changeValue={categoria => setState({...state, categoria })} />
        <InputMenu list={opcionesFederado} title="Federado" text={state.federado} changeValue={federado => setState({...state, federado })} />

      </div>

      <div className="col-2-input">
        <InputMenu list={opcionesTipoDeporte} title="Tipo de deporte" text={state.tipoDeporte} changeValue={tipoDeporte => setState({...state, tipoDeporte })} />
        {state.tipoDeporte==="Individual" ? 
          <Input _class_container={ !state.ranking.trim() ? "error-form-input" : null } title="Marca / Handicap / Ranking" text={state.ranking} changeValue={ranking => setState({...state, ranking })} />
        :
          <InputMenu list={opcionesDivisionDeporte} title="División" text={state.division} changeValue={division => setState({...state, division })} />
        }
      </div>
    </div>
  )
})

const mapStateToProps = ({ global }) => {
  return {
    inscripcion: global.inscripcion
  };
}
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(InformacionDeporte);