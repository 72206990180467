import React, { useState } from 'react'
import { opcionesCuestionario } from '../../../../../utils/constans'
import RadioButton from '../../Shared/RadioButton'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../redux/actions/alumnosActions';
import ErrorFormulario from '../../Shared/ErrorFormulario'

const Pregunta5 = ({idPregunta,currentAlumno, actions, setPanelSelectedBack, setPanelSelected}) => {

    const [errors, setErrors] = useState(false)
    const [showMensaje, setShowMensaje] = useState(false)

    const callbackCheckItem = (json) =>{
        if(!currentAlumno.inscripcionAlumno.cuestionario[idPregunta]) return false
        var cuestionario = {...currentAlumno.inscripcionAlumno.cuestionario}
        cuestionario[idPregunta][json.idArrayPregunta][json.tipo] = `${json.value}`
        actions.modifyCurrentAlumno({value:cuestionario, path:'inscripcionAlumno.cuestionario'})
    }

    const nextPanel = () => {
        if(!currentAlumno.inscripcionAlumno.isValidCuestionario(idPregunta)){
            setErrors(true)
            setShowMensaje(true)
            return false
        }
        setPanelSelected()
    }

  return(
    <div>
      <div className="pregunta-texto">{opcionesCuestionario.preguntas[idPregunta].pregunta}</div>
      <div className='center-tag'>
          <div className="contenedor-preguntas-selectivas container-preguntas-dobles">
              {currentAlumno.inscripcionAlumno.cuestionario[idPregunta].map((r,k2) => {
                  if(!opcionesCuestionario.preguntas[idPregunta]) return false
                  let respuesta = opcionesCuestionario.preguntas[idPregunta].respuestas[k2]
                  return(
                      <div className={`item-preguntas-selectivas ${ errors && (r.nota==="0" || (r.nota!=='nunca' && r.gusto==="0")) ? "error-respuesta" : "" }`} key={k2} >
                          <div className="texto-preguntas-selectivas"> {respuesta.text} </div>
                          <div className={`item-preguntas-selectivas width_100`} >
                              <div className="texto-preguntas-selectivas"> ¿Qué nota sueles sacar? </div>
                              <div className="opciones-preguntas-selectivas">
                                  <div className='option-cursado-nunca'>
                                      <div className="id-radio-btn">No la he cursado nunca</div>
                                      <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "nunca", idArrayPregunta: k2, tipo:"nota" }} checked={r.nota === "nunca" ? true : false}/>
                                  </div>
                                  <div className="display_inline_flex">
                                      
                                      <div>
                                          <div className="id-radio-btn">1</div>
                                          <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "1", idArrayPregunta: k2, tipo:"nota" }} checked={r.nota === "1" ? true : false}/>
                                      </div>
                                      <div>
                                          <div className="id-radio-btn">2</div>
                                          <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "2", idArrayPregunta: k2, tipo:"nota" }} checked={r.nota === "2" ? true : false}/>
                                      </div>
                                      <div>
                                          <div className="id-radio-btn">3</div>
                                          <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "3", idArrayPregunta: k2, tipo:"nota" }} checked={r.nota === "3" ? true : false}/>
                                      </div>
                                      <div>
                                          <div className="id-radio-btn">4</div>
                                          <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "4", idArrayPregunta: k2, tipo:"nota" }} checked={r.nota === "4" ? true : false}/>
                                      </div>
                                      <div>
                                          <div className="id-radio-btn">5</div>
                                          <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "5", idArrayPregunta: k2, tipo:"nota" }} checked={r.nota === "5" ? true : false}/>
                                      </div>
                                  </div>
                                  <div className="display_inline_flex">
                                      <div>
                                          <div className="id-radio-btn">6</div>
                                          <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "6", idArrayPregunta: k2, tipo:"nota" }} checked={r.nota === "6" ? true : false}/>
                                      </div>
                                      <div>
                                          <div className="id-radio-btn">7</div>
                                          <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "7", idArrayPregunta: k2, tipo:"nota" }} checked={r.nota === "7" ? true : false}/>
                                      </div>
                                      <div>
                                          <div className="id-radio-btn">8</div>
                                          <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "8", idArrayPregunta: k2, tipo:"nota" }} checked={r.nota === "8" ? true : false}/>
                                      </div>
                                      <div>
                                          <div className="id-radio-btn">9</div>
                                          <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "9", idArrayPregunta: k2, tipo:"nota" }} checked={r.nota === "9" ? true : false}/>
                                      </div>
                                      <div>
                                          <div className="id-radio-btn">10</div>
                                          <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "10", idArrayPregunta: k2, tipo:"nota" }} checked={r.nota === "10" ? true : false}/>
                                      </div>
                                  </div>
                                  
                              
                              </div>
                          </div>

                          {r.nota!=="nunca"?
                          <div className={`item-preguntas-selectivas width_100`} >
                              <div className="texto-preguntas-selectivas"> ¿Cuánto te gusta esta asignatura? </div>
                              <div className="opciones-preguntas-selectivas opciones-4-p-5">
                              
                                  <div>
                                      <div className="id-radio-btn">1</div>
                                      <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "1", idArrayPregunta: k2, tipo:"gusto" }} checked={r.gusto === "1" ? true : false}/>
                                  </div>
                                  <div>
                                      <div className="id-radio-btn">2</div>
                                      <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "2", idArrayPregunta: k2, tipo:"gusto" }} checked={r.gusto === "2" ? true : false}/>
                                  </div>
                                  <div>
                                      <div className="id-radio-btn">3</div>
                                      <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "3", idArrayPregunta: k2, tipo:"gusto" }} checked={r.gusto === "3" ? true : false}/>
                                  </div>
                                  <div>
                                      <div className="id-radio-btn">4</div>
                                      <RadioButton callbackCheckItem={callbackCheckItem} json={{ value: "4", idArrayPregunta: k2, tipo:"gusto" }} checked={r.gusto === "4" ? true : false}/>
                                  </div>
                              
                              </div>
                          </div>
                          :null}
                          


                          
                      </div>
                  )
              })}
          </div>
      </div>
      
        {showMensaje &&
            <>
                <br/>
                <ErrorFormulario updateError={()=>setShowMensaje(false)} />
            </>
        }

      <div className='direccions_panel'>
          <div className="btn_send_form btn-volver-form pointer noselect float_atras" onClick={setPanelSelectedBack}> Atrás </div>
          <div className='btn_send_form pointer noselect float_siguiente' onClick={nextPanel}>Siguiente</div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ alumnos }) => {
  return {
    currentAlumno: alumnos.currentAlumno,
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(Pregunta5);