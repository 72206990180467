import React, { Component } from 'react';

class FooterW2A extends Component {

  constructor(props){
    super(props);
    this.state = {

    }
  }


  render() {



    return (

      <div className='footer-w2a'>

        <a className="" tabIndex="0" target="_blank" rel="noopener noreferrer" href="https://w2americandream.es/avisos-legales/">Aviso legal</a>
        <a className="" tabIndex="0" target="_blank" rel="noopener noreferrer" href="https://w2americandream.es/politica-de-cookies/">Política de cookies</a>
        <a className="" tabIndex="0" target="_blank" rel="noopener noreferrer" href="https://w2americandream.es/politica-de-privacidad/">Política de Privacidad</a>

      </div>
    )
  }
}

export default FooterW2A;































///
