import React, { useState, useRef, useLayoutEffect, memo } from 'react'

const InputMenu = memo(({type, text, list, title, pointInfo, _class, _class_input, point_class, changeValue }) => {
  const [showMenu, setShowMenu] = useState(false)
  return(
    <div className={`container-simple-input ${_class?_class:''}`}>
      <div className='title-input'>{title}:</div>
      <div className={`container-input pr ${showMenu?'activate-container-selec':''}`} onClick={()=>setShowMenu(type && type==="block"?false:true)}>
        <div className={`text-seleccionable ${_class_input?_class_input:''}`}>
          {pointInfo && point_class &&
            <div className={`points_lista punto-status ${point_class}`}></div>
          }
          {text?text.trim():''}
        </div>
        <div className='arrow-seleccionable-input'>
          <i className={`material-icons arrow-down-select ${showMenu?'arrow-top-select':''}`}>arrow_drop_down</i>
        </div>
        {/*si no hay tipo por defecto esperaremos un array*/}
        {!type && showMenu &&
          <ArrayList list={list} onClose={()=>setShowMenu(false)} changeValue={(value)=>changeValue(value)}/>
        }
        {type && type==='object' && showMenu &&
          <ObjectList list={list} pointInfo={pointInfo} onClose={()=>setShowMenu(false)} changeValue={(value)=>changeValue(value)}/>
        }
      </div>
    </div>
  )
})
export default InputMenu

const ArrayList = memo(({list, changeValue, onClose}) => {

  const refElement = useRef()
  const clickOutSide = ({target}) => !refElement.current?.contains(target) && onClose()
  useLayoutEffect(()=>{
    document.addEventListener('mousedown',clickOutSide, false)
    return () => document.removeEventListener('mousedown',clickOutSide, false)
  },[])

  return (
    <div className='lista-seleccionable-input' onClick={e=>e.stopPropagation()} ref={refElement}>
      {list.map((item,idItem)=>
        <div key={idItem} onClick={()=>{changeValue(item);onClose()}}>{item}</div>
      )}
    </div>
  );
})

const ObjectList = memo(({list, pointInfo, changeValue, onClose}) => {

  const refElement = useRef()
  const clickOutSide = ({target}) => !refElement.current?.contains(target) && onClose()
  useLayoutEffect(()=>{
    document.addEventListener('mousedown',clickOutSide, false)
    return () => document.removeEventListener('mousedown',clickOutSide, false)
  },[])

  return (
    <div className='lista-seleccionable-input' onClick={e=>e.stopPropagation()} ref={refElement}>
      {Object.entries(list).map(([idItem, item])=>{
        //if(pointInfo) return <div key={idItem} onClick={()=>{changeValue(idItem);onClose()}}>{item.texto}</div>
        return(
          <div key={idItem} className='display_flex' onClick={()=>{changeValue(idItem, item);onClose()}}>
            {pointInfo && <div className={`points_lista punto-status ${item.class}`}></div>}
            {item.texto}
          </div>
        )
      })
      }
    </div>
  );
  
})