import React, { memo } from 'react'

const FiltersItem = memo(({filters, updateFilters}) => {

  const updateItem = (idParent, idChild, value)=>{
    filters[idParent].items[idChild].checked = value
    var encontrado = Object.entries(filters[idParent].items).some(([k,o])=> o.checked )
    if(!encontrado && filters[idParent].todos && filters[idParent].type==='radiobutton'){
      filters[idParent].todos.checked=true;
    }else if(!encontrado && filters[idParent].todos && filters[idParent].type==='checkbox'){
      filters[idParent].todos.checked=true;
      Object.entries(filters[idParent].items).forEach(([k,o])=>{ o.checked=true; })
    }
    updateFilters(filters)
  }

  return(
    <div className='container-filtros'>
      {Object.entries(filters).map(([k,i])=>{
          var anyTrue = Object.entries(i.items).some(([key,obj])=>!obj.checked)
          if(anyTrue===false)return null;
          return(
            Object.entries(i.items).map(([k2,i2])=>
              <Item  key={k2} text={i2.text_info} checked={i2.checked} changeValue={value=>updateItem(k,k2,value)}/>
            )
          )
      })}
    </div>
  )
})

export default FiltersItem

const Item = memo(({ checked, text, changeValue }) => {
  return(
    !checked ? null : 
    <div>
      {text}
      <svg onClick={()=>changeValue(false)} className="delete_item_filter" height="24" viewBox="0 0 24 24" width="17"><path className="btn_detele_item_filter" d="M12 2c-5.53 0-10 4.47-10 10s4.47 10 10 10 10-4.47 10-10-4.47-10-10-10zm5 13.59l-1.41 1.41-3.59-3.59-3.59 3.59-1.41-1.41 3.59-3.59-3.59-3.59 1.41-1.41 3.59 3.59 3.59-3.59 1.41 1.41-3.59 3.59 3.59 3.59z"></path></svg>
    </div>
  )
})