import React, { memo } from 'react'

const RadioButton = memo( ({ checked, changeValue, text}) => {
  return(
    <div className='container-item-check-box' onClick={()=>changeValue(true)}>
      <div className="checkbox-container"> 
        <div className={`checkbox-container-w2a radiobutton-w2a ${checked && 'radiobutton-w2a-active'}`} ></div>
      </div>
      <span>{text}</span>
    </div>
  )
})

export default RadioButton