import React, {useState, useEffect} from 'react'
import { connect } from "react-redux";
import Extraescolares from './Extraescolares'
import ExperienciaInternacional from './ExperienciaInternacional'
import Voluntariado from './Voluntariado'
import Autorizacion from './Autorizacion'
import $ from 'jquery'
import ErrorFormulario from '../../../Shared/ErrorFormulario'
import { isRepeatedDni, isRepeatedEmail, ocultarCargandoFormAlumnos, visualizarCargandoFormAlumnos } from '../../../../../../utils/functions'
import PanelConfirmacionAutorizaciones from '../PanelConfirmacionAutorizaciones'
import { bindActionCreators } from 'redux';
import { modifyCurrentAlumno, saveAlumno, modifyAlumno } from '../../../../../../redux/actions/alumnosActions';
import { pathFormularioNewAlumno, pathFormularioModifyAlumno } from '../../../../../../utils/constans';

const FaseAcademica = ({setPanelSelected, setPanelSelectedBack, currentAlumno, actions, setUsuarioRepetido, setModificacionGuardado}) => {
  const [errors, setErrors] = useState({})
  const [showMensaje, setShowMensaje] = useState(false)
  const [showPanelConfirmacion, setShowPanelConfirmacion] = useState(false)
  const nextPanel = async() => {
    visualizarCargandoFormAlumnos({bottom: true})
    let errors = currentAlumno.checkFaseAcademica()
    setErrors(errors)

    if(Object.keys(errors).length>0){
      setShowMensaje(true)
      ocultarCargandoFormAlumnos()
      return false
    }
    
    const repeatedDni = await isRepeatedDni(currentAlumno.informacionAlumno.dni, currentAlumno.id)
    if(repeatedDni){
      setShowMensaje(true)
      ocultarCargandoFormAlumnos()
      setUsuarioRepetido()
      return false
    }
    if(!currentAlumno.isAuthenticated && window.location.pathname===pathFormularioNewAlumno){
      const repeatedEmail = await isRepeatedEmail(currentAlumno.informacionAlumno.email)
      if(repeatedEmail){
        setShowMensaje(true)
        ocultarCargandoFormAlumnos()
        setUsuarioRepetido()
        return false
      }
    }
    
    if(!showPanelConfirmacion && (!currentAlumno.inscripcionAlumno.inscripcion.beca.academica.colaboraciones || !currentAlumno.inscripcionAlumno.inscripcion.beca.academica.imagen)){
      setShowPanelConfirmacion(true)
      return false
    }else{
      saveData()
    }
    
  }

  const saveData = async() => {
    //guardar en base de datos 
    let ok = false
    if(currentAlumno.isAuthenticated && window.location.pathname===pathFormularioModifyAlumno){
      ok = await actions.modifyAlumno()
    }else if(!currentAlumno.isAuthenticated && window.location.pathname===pathFormularioNewAlumno){
      ok = await actions.saveAlumno()
    }
    ocultarCargandoFormAlumnos()
    if(ok){
      if(currentAlumno.inscripcionAlumno.isValidCuestionario()){
        setModificacionGuardado()
      }else{
        setPanelSelected()
      }
    }
  }

  const rejected = () => {
    setShowPanelConfirmacion(false)
    saveData()
  }

  const acepted = () => {
    actions.modifyCurrentAlumno({value:true, path:'inscripcionAlumno.inscripcion.beca.academica.colaboraciones'})
    actions.modifyCurrentAlumno({value:true, path:'inscripcionAlumno.inscripcion.beca.academica.imagen'})
    setShowPanelConfirmacion(false)
    saveData()
  }

  return(
    <div>
      <div className='title-panel-form-alumnos'>Beca académica</div>

      <Extraescolares 
        tipoActividad={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.tipoActividad}
        nombreActividad={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.nombreActividad}
        dedicationYears={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.dedicationYears}
        siguePracticando={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.siguePracticando}
        horasSemanales={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.horasSemanales}
        centroActividad={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.centroActividad}
        errors={errors}
      />
      <ExperienciaInternacional 
        experienciaExtranjero={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.experienciaExtranjero}
        lugarExperiencia={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.lugarExperiencia}
        mesesExperiencia={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.mesesExperiencia}
        objetivoExperiencia={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.objetivoExperiencia}
        experienciaDescripcion={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.experienciaDescripcion}
        errors={errors}
      />
      <Voluntariado
        voluntariado={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.voluntariado}
        voluntariadoDescripcion={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.voluntariadoDescripcion}
        practicas={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.practicas}
        errors={errors}
      />
      <Autorizacion 
        colaboraciones={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.colaboraciones}
        imagen={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.imagen}
        terminos={currentAlumno.inscripcionAlumno.inscripcion.beca.academica.terminos}
        errors={errors}
      />

      {showMensaje &&
        <ErrorFormulario updateError={()=>setShowMensaje(false)} />
      }

      {showPanelConfirmacion &&
        <PanelConfirmacionAutorizaciones rejected={rejected} acepted={acepted}/>
      }

      <div className='direccions_panel'>
        <div className='btn_send_form pointer noselect float_atras btn-volver-form' onClick={setPanelSelectedBack}>Atrás</div>
        <div className='btn_send_form pointer noselect float_siguiente' onClick={nextPanel}>
          {`${currentAlumno.inscripcionAlumno.isValidCuestionario()?"Guardar":"Siguiente"}`}
        </div>
      </div>

    </div>
  )
}

const mapStateToProps = ({ alumnos }) => {
  return {
    currentAlumno: alumnos.currentAlumno,
  };
}
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno,
      saveAlumno,
      modifyAlumno
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(FaseAcademica);