import { globalTypes } from '../actions/globalActions';
import { getInscripcion } from '../../utils/functions'
var initialState = {
  tabHome: "colegios", // colegios | alumnos  
  inscripcion : getInscripcion(),
  notification: { visibility: false, text: '', status: false, moment: Date.now() },
}

if (typeof Storage !== 'undefined') {
  initialState = {
    ...initialState,
    inscripcion: localStorage.getItem('inscripcion') ? localStorage.getItem('yearSelected') : initialState.inscripcion,
  };
} else {
  console.warn("Your browser doesn't support localstorage");
}

export default function (state = initialState, action) {
  switch (action.type) {

    case globalTypes.SET_TAB_HOME:{
        return{ ...state, tabHome: action.payload }
    }
    case globalTypes.SET_NOTIFICATION:{
      return { ...state, 
        notification: {
          ...state.notification,
          ...action.payload 
        }
      }
    }
    default:{
      return state;
    }
  }
}