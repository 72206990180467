import React, { memo } from 'react'
import { localesDateCountdown, localespluralDateCountdown } from '../../../utils/constans'

import DateCountdown from 'react-date-countdown-timer';
import bannerBottom from '../../../images/banner-bottom.gif'
import bannerCentral from '../../../images/banner-central.gif'
import bannerLeft from '../../../images/banner-left.gif'
import bannerRight from '../../../images/banner-rigth.gif'
const Descanso = ({time, setPanelExamen}) => {
  
  return(
    <div className="panel-ingles all-screen">
      <div className="contador contador-fixed">
        <DateCountdown dateTo={time.toString()} callback={()=>setPanelExamen()} mostSignificantFigure="min" locales={localesDateCountdown} locales_plural={localespluralDateCountdown}/>
      </div>

      <div className="resumen-preguntas">
        <div>
          <h1 className="text-resumen">Tómate un descanso</h1>
          <br/>
          <div>
            <img src={bannerCentral}/>
          </div>
        </div>
      </div>

      <div className="banners">
        {/*<div className='bottom-banner'>
          <img src={bannerBottom}/>
        </div>
         <div className='left-banner'>
          <img src={bannerLeft}/>
        </div>
        <div className='rigth-banner'>
          <img src={bannerRight}/>
        </div> */}
      </div>

    </div>
  )
}

export default Descanso