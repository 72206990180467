import React, { Component } from 'react';
import {provincias} from '../../../../utils/constans'
import {hasKey} from '../../../../utils/functions'
import Colegio from '../../../../models/Colegio';

const AIS = "-LrmfZgOkU3Hcje__m9T"

class ListaColegios extends Component {

  constructor(props){
    super(props);
    this.state={
      search:'',
    }
  }

  componentWillMount = () => {document.addEventListener('mousedown',this.clickOutSide, false);}
  componentWillUnmount = () => {document.removeEventListener('mousedown',this.clickOutSide, false);}
  clickOutSide = (e) => {if(!this.node.contains(e.target)){this.closeListColegios()}}
  closeListColegios = () => {this.props.showOptionsColegio(false);}

  callbackCheckItem = (json) => {/*
    if(json.valor){this.props.opcionSelectedUpdate(json.id);
    }else{this.props.opcionSelectedUpdate(null);}
    this.closeListColegios()
    */
  }

  selectColegio = (colegio) =>{
    this.props.setColegio(colegio)
    this.closeListColegios()
  }
  selectAis = () => {
    var colegioAis = Object.entries(this.props.colegios).find(([k,colegio])=>{ return colegio.id===AIS})
    if(colegioAis){
      this.selectColegio(colegioAis[1])
    }
  }

  render() {    
    
    return (
      <div className="pa shadow lista_sedes" ref={node=>this.node=node} onClick={event => event.stopPropagation()}>

        <div className='container-search'>
          <div className='cont-icon-search'><i className="material-icons"> search </i></div>
          <div className='cont-input-search'><input placeholder='Busca tú colegio por su nombre' value={this.state.search} onChange={e=>this.setState({search:e.target.value})} /></div>
        </div>

        <div className="width_100 display_inline_grid overflow_provincias">
          <table id='tabla-colegios-add-alumnos'>
            <thead>
              <tr>
                <th className='col-codigo-pna'>Código</th>
                <th className='col-nombre-pna'>Nombre del colegio</th>
                <th className='col-provincias-pna'>Provincia</th>
              </tr>
            </thead>


            <tbody>

              { this.props.colegios ?

                this.state.search.trim()!=='' && ( !('ais').includes(this.state.search.trim().toLowerCase()) ) ? null :

                <tr className={`tr-select  ${this.props.idColegioSelected===AIS ?'color_secundario':''}`} onClick={()=>this.selectAis()}>
                  <td className='col-codigo-pna td-codigo-colegio'>AIS</td>
                  <td className='col-nombre-pna'></td>
                  <td className='col-provincias-pna td-provincia-colegio'></td>
                </tr>
                :null
              }

              { this.props.colegios ?
                <tr className={`container-no-existe-colegio ${this.props.idColegioSelected==='nuevo' ?'color_secundario':''}`} onClick={()=>this.selectColegio({id:'nuevo', nombre:''})}>
                  <td className='col-codigo-pna td-codigo-colegio'>No encuentro mi colegio</td>
                  <td className='col-nombre-pna'></td>
                  <td className='col-provincias-pna td-provincia-colegio'></td>

                </tr> :null
              }

              {this.props.colegios ?

                Object.entries(this.props.colegios).map( ([k,colegio]) => {
                  if(k===AIS)return null//Si el colegio es AIS no lo ponemos ya que lo ponemos al principio de la lista
                  if(k==="nuevo")return null
                  var search = this.state.search.trim()
                  if(search!=='' &&
                    (!colegio.nombre.toLowerCase().includes(search.toLowerCase())  ) ){
                    return null
                  }

                  return (
                    <tr className={`tr-select  ${this.props.idColegioSelected===k ?'color_secundario':''}`} key={k} onClick={()=>this.selectColegio(colegio)}>
                      <td className='col-codigo-pna td-codigo-colegio'>{colegio.id}</td>
                      <td className='col-nombre-pna'>{colegio.nombre}</td>
                      <td className='col-provincias-pna td-provincia-colegio'>{provincias[colegio.provincia]?.texto ?? ''}</td>
                    </tr>
                  )
                }):null
              }

            </tbody>
          </table>


        </div>



      </div>
    )
  }
}

export default ListaColegios;
