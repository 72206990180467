import React, { useState } from 'react'
import { opcionesTipoDeporte, opcionesDivisionDeporte, opcionesFederado } from '../../../../../../utils/constans'
import ListaOpciones from '../../../Shared/ListaOpciones'
import { onFocusInscripciones, onBlurInscripciones } from '../../../../../../utils/functions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../../redux/actions/alumnosActions';
import Switch from '../../../../../Shared/Switch'

const DatosAlumno = (props) => {

  const { errors } = props

  const [showOptionsTipoDeporte, setShowOptionsTipoDeporte] = useState(false)
  const [showOptionsDivision, setShowOptionsDivision] = useState(false)
  const [showOptionsFederado, setShowOptionsFederado] = useState(false)

  const callbackSwitch = (json) => {
    if (json.id === 'csd') {
      props.actions.modifyCurrentAlumno({value: json.value, path:'inscripcionAlumno.inscripcion.beca.deportiva.csd'})
    }
  }

  return(
    <div>

      <div className="pr width_100 colums_form_2">

        {/*TIPO DE DEPORTE */}
        <div className={`pr pointer select_form colums_form_2_margin_right ${errors.tipoDeporte ? 'obligatorio':''}`} onClick={()=>setShowOptionsTipoDeporte(true)}>
          <div className="item_selected">{props.tipoDeporte}</div>
          <label className="label_form pointer">Tipo de deporte</label>
          {showOptionsTipoDeporte &&
            <ListaOpciones 
              editOpciones={(value) => setShowOptionsTipoDeporte(value)} 
              opciones={opcionesTipoDeporte}
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.inscripcion.beca.deportiva.tipoDeporte'}) } 
              opcionSelected={props.tipoDeporte}
            /> }
        </div>
        {!props.tipoDeporte.trim() && <div className="pr width_100 colums_form_2_margin_left opacity_0"><input className={`input_form`} value={''} readOnly={true} /></div>}

        {props.tipoDeporte==='Equipo' &&
          <div className={`pr pointer select_form colums_form_2_margin_left ${errors.division ? 'obligatorio':''}`} onClick={()=>setShowOptionsDivision(true)}>
            <div className="item_selected">{props.division}</div>
            <label className="label_form pointer">División</label>
            {showOptionsDivision &&
              <ListaOpciones 
                editOpciones={(value) => setShowOptionsDivision(value)} 
                opciones={opcionesDivisionDeporte}
                opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.inscripcion.beca.deportiva.division'}) } 
                opcionSelected={props.division}
              />}
          </div>
        }

        {props.tipoDeporte==='Individual' &&
          <div className="pr width_100 colums_form_2_margin_left">
            <input onFocus={e => onFocusInscripciones(e)} onBlur={e => onBlurInscripciones(e)} className={`input_form ${errors.ranking ? 'obligatorio' : ''}`} value={props.ranking} 
              onChange={e => props.actions.modifyCurrentAlumno({value:e.target.value,path:'inscripcionAlumno.inscripcion.beca.deportiva.ranking'})} />
            <label className="label_form">MARCA / HANDICAP / RANKING</label>
          </div>
        }
      </div>

      <div className="display_inline_flex width_100 item-switch-form">
        <span className="span_academica margin_auto_vertical">¿Estás acreditado como deportista de alto nivel por el CSD?</span>
        <div className="display_inline_flex">
          <span className="info_academica margin_auto_vertical">no</span>
          <Switch class_div={'switch_academica'} callbackSwitch={callbackSwitch} json={{ id: 'csd' }} value={props.csd} />
          <span className="info_academica margin_auto_vertical">si</span>
        </div>
      </div>
      
      {/* MAS INFORMACION DEL ALUMNO */}
      <div className={`pr container-ta ${errors.informacionAdicional ? 'obligatorio' : ''}`}>
        <label className="label_form pr">IMPORTANTE: para conocer mejor tu perfil deportivo desarrolla tu trayectoria deportiva, tus logros y todo aquello que consideres relevante.</label>
        <textarea onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ta-forms`} value={props.informacionAdicional} onChange={e=>props.actions.modifyCurrentAlumno({value:e.target.value, path:'inscripcionAlumno.inscripcion.beca.deportiva.informacionAdicional'})}/>
      </div>  



    </div>
  )
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(DatosAlumno);
