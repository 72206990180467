import React, { useState, useEffect } from 'react'
import Input from '../../../../../Shared/Input'
import InputMenu from '../../../../../Shared/InputMenu'
import Switch from '../../../../../Shared/Switch'
import UpdateStateInputs from '../../../../../Shared/UpdateStateInputs'
import { provincias } from '../../../../../../utils/constans'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../utils/firebase'
import { isEmail, isTelefono } from '../../../../../../utils/functions'
import _ from 'underscore'

const InformacionResponsable = (props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  const saveData = () => {
    const { nombre, apellidos, email, telefonoMovil, telefonoFijo, cargo, horarioPreferente, required } = state

    if(required && ( !nombre.trim() || !apellidos.trim() || !isEmail(email) || !isTelefono(telefonoMovil) || !isTelefono(telefonoFijo) || !cargo.trim() || !horarioPreferente.trim()==='')){
      props.actions.setNotification({ visibility: true, text: "Exiten errores en los campos", status: "close", moment: Date.now() })
      return false;
    }

    if(!required && ( (email.trim() && !isEmail(email) ) ||  (telefonoMovil.trim() && !isTelefono(telefonoMovil) ) || (telefonoFijo.trim() && !isTelefono(telefonoFijo)) ) ){
      props.actions.setNotification({ visibility: true, text: "Exiten errores en los campos", status: "close", moment: Date.now() })
      return false;
    }

    var multiPath = {};
    multiPath[`Colegios/${props.id}/responsables/${props.responsable}/nombre`] = nombre.trim();
    multiPath[`Colegios/${props.id}/responsables/${props.responsable}/apellidos`] = apellidos.trim();
    multiPath[`Colegios/${props.id}/responsables/${props.responsable}/email`] = email.trim();
    multiPath[`Colegios/${props.id}/responsables/${props.responsable}/telefonoMovil`] = telefonoMovil.trim();
    multiPath[`Colegios/${props.id}/responsables/${props.responsable}/telefonoFijo`] = telefonoFijo.trim();
    multiPath[`Colegios/${props.id}/responsables/${props.responsable}/cargo`] = cargo.trim();
    multiPath[`Colegios/${props.id}/responsables/${props.responsable}/horarioPreferente`] = horarioPreferente.trim();
    
    db.update(multiPath)
    .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
    
  }


  return(
    <div className='sub-container-informacion-alumno'>
      {edited && !props.block && <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> }
      <p className='title-informacion-alumno'>{props.title}</p>
      <div className='col-2-input'>
        <Input type={`${props.block?'block':''}`} _class_input='camelcase' _class_container={props.required && !state.nombre.trim()?'error-form-input':null} title='Nombre' text={state.nombre} changeValue={nombre => setState({...state, nombre})} />
        <Input type={`${props.block?'block':''}`} _class_input='camelcase' _class_container={props.required && !state.apellidos.trim()?'error-form-input':null} title='Apellidos' text={state.apellidos} changeValue={apellidos => setState({...state, apellidos})} />
      </div>
      <div className='col-2-input'>
        <Input type={`${props.block?'block':'email'}`} title='Email' text={state.email} changeValue={email => setState({...state, email})} _class_container={(props.required && (!state.email.trim() || !isEmail(state.email))) || (!props.required && (state.email.trim(state.email) && !isEmail(state.email)))?'error-form-input':null}  />
      </div>
      <div className='col-2-input'>
        <Input type={`${props.block?'block':'phone'}`} _class_input='dni-input' _class_container={(props.required && (!state.telefonoMovil.trim() || !isTelefono(state.telefonoMovil)))  || (!props.required && (state.telefonoMovil.trim() && !isTelefono(state.telefonoMovil))) ?'error-form-input':null} title='Teléfono móvil' text={state.telefonoMovil} changeValue={telefonoMovil => setState({...state, telefonoMovil})} />
        <Input type={`${props.block?'block':'phone'}`} _class_input='dni-input' _class_container={(props.required && (!state.telefonoFijo.trim() || !isTelefono(state.telefonoFijo))) || (!props.required && (state.telefonoFijo.trim() && !isTelefono(state.telefonoFijo)))  ?'error-form-input':null} title='Teléfono fijo' text={state.telefonoFijo} changeValue={telefonoFijo => setState({...state, telefonoFijo})}/>
      </div>
      <div className='col-2-input'>
        <Input type={`${props.block?'block':''}`} _class_container={props.required && !state.cargo.trim()?'error-form-input':null} title='Cargo' text={state.cargo} changeValue={cargo => setState({...state, cargo})}/>
        <Input type={`${props.block?'block':''}`} _class_container={props.required && !state.horarioPreferente.trim()?'error-form-input':null} title='Horario preferente para llamadas' text={state.horarioPreferente} changeValue={horarioPreferente => setState({...state, horarioPreferente}) }/>
      </div>
  
    </div>
  )
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(InformacionResponsable);