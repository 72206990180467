import React, { useLayoutEffect, useState, useRef, memo } from 'react';

const ListaOpciones = memo(({opciones, opcionSelected, changeOpcion}) => {

  const refElement = useRef()
  const [visible, setVisivility] = useState(false)
  const clickOutSide = ({target}) => !refElement.current?.contains(target) && closeElement()
  const closeElement = () => setVisivility(false)
    
  useLayoutEffect(()=>{
    document.addEventListener('mousedown',clickOutSide, false)
    return () => {
      document.removeEventListener('mousedown',clickOutSide, false)
    }
  },[])

  return (
    <div className='container-opt-search' ref={refElement}>
      <span onClick={()=>setVisivility(true)} >{opciones[opcionSelected].valor}
        <i className={`material-icons arrow-opc-search ${visible?'arrow-opc-search-active':''}`}> expand_more </i>
      </span>
      <div className={`opciones-search ${visible?'opciones-search-show':''}`}>
        {Object.entries(opciones).map(([k,o])=>
          <li className={`${opcionSelected===k?'color-azul':''}`} key={k} onClick={()=>{changeOpcion(k); closeElement()}}>{o.valor}</li>
        )}
      </div>
    </div>
  );
})

export default ListaOpciones;
