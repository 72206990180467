import React, { useState, memo } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { changeHeader, downloadAlumnos } from '../../../../../../redux/actions/alumnosActions'
import FiltersList from "../../../../../Shared/FiltersList"
import FiltersItem from "../../../../../Shared/FiltersItem"
import ViewList from '../../../../../Shared/ViewList'
import { generatePDF } from '../../../../../../utils/functions'
import XLSX from 'xlsx';
import DescriptionRounded from '@material-ui/icons/DescriptionRounded';

const Options = memo(({filters, views, viewSelected, tabSelected, alumnos, alumnoSelected, inscripcion, actions}) => {

  const [showFilters, setShowFilters] = useState(false)
  const [showViews, setShowViews] = useState(false)

  const changeView = (idOption) => {
    if(viewSelected===idOption) return false
    actions.changeHeader({tab:'listado', field:'viewSelected', value: idOption})
    setShowViews(false)
  }

  const descargarListado = async() => {
    const { listAlumnos, alumnosDeportivos, alumnosAcademicos} = await actions.downloadAlumnos()
    if (listAlumnos.length > 0) {
      var ws = XLSX.utils.json_to_sheet(listAlumnos);
      var ws_deportivos = XLSX.utils.json_to_sheet(alumnosDeportivos);
      var ws_academicos = XLSX.utils.json_to_sheet(alumnosAcademicos);

      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Alumnos");
      XLSX.utils.book_append_sheet(wb, ws_deportivos, "Alumnos Deportivos");
      XLSX.utils.book_append_sheet(wb, ws_academicos, "Alumnos Académicos");
      XLSX.writeFile(wb, `Alumnos.xlsx`);
    }
  }

  const openInformeAD20 = async () => {
    const fileURL = await generatePDF(alumnos[alumnoSelected], inscripcion)
    window.open(fileURL); 
  }

  const isSatDone = alumnos[alumnoSelected]?.inscripcionAlumno?.isSatDone()

  return(
    <div className='pr'>
      <FiltersItem filters={{...filters}} updateFilters={(filters=>actions.changeHeader({tab:"listado", field:"filters", value: filters}))} />
      <div className='opciones-alumnos'>
        <div className='deg-opt'></div>

        <div className='item-container-icon-top-bar' onClick={()=>descargarListado()}>
          <i className="material-icons hover-azul">save_alt</i>
        </div>
        {tabSelected === 'perfil' &&
          <div className='item-container-icon-top-bar last-item' onClick={openInformeAD20}>
            <DescriptionRounded className='hover-azul' fontSize="large" />
          </div>
        }

        <div className='btn-options pr' onClick={() => setShowViews(!showViews)}>
          <i className="material-icons">visibility</i> <span>Vistas</span>
          {showViews &&
            <ViewList options={views} viewSelected={viewSelected} optionSelected={viewSelected} changeView={ idOption => changeView(idOption)} onClose={()=>setShowViews(false)} />
          }
        </div>

        <div className='btn-options pr' onClick={()=>setShowFilters(!showFilters)}>
          <i className="material-icons"> filter_list </i> <span>Filtros</span>
          {showFilters &&
            <FiltersList filters={{...filters}} updateFilters={(filters=>actions.changeHeader({tab:"listado", field:"filters", value: filters}))} onClose={()=>setShowFilters(false)}/>
          }
        </div>
        
      </div>
    </div>
  )
})

const mapStateToProps = ({ alumnos, global }) => {
  return {
    filters: alumnos.tabs.listado.header.filters,
    views: alumnos.tabs.listado.header.views,
    viewSelected: alumnos.tabs.listado.header.viewSelected,
    alumnos: alumnos.alumnos,
    alumnoSelected: alumnos.alumnoSelected,
    tabSelected: alumnos.tabSelected,
    inscripcion: global.inscripcion
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      changeHeader,
      downloadAlumnos
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(Options);

