import React, { useEffect, useLayoutEffect, useState, memo } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { sortData, changeHeader } from '../../../../../../../redux/actions/alumnosActions'
import ItemLista from './ItemLista';
import ScrollContainer from '../../../../../../Shared/ScrollContainer'
import InfiniteScroll from "react-infinite-scroll-component";
import { addItemsLoaded } from '../../../../../../../utils/constans'

const PanelListado = memo(({sortBy, des, alumnos, sortedAlumnos, itemsLoaded, actions}) => {

  const fetchMoreData = () => {
    actions.changeHeader({ tab: "listado", field:'itemsLoaded', value: itemsLoaded+addItemsLoaded, skipSort: true})
  };
  const setSortBy = value => {
    let descendant = false
    if(value===sortBy){
      descendant = !des
    }
    actions.changeHeader({ tab:'listado', field: "sortBy", value, des: descendant})
  }
  var i = 0
  return(
    <ScrollContainer _class={`container-table`} id="scrollListadoAlumnos">
      <InfiniteScroll scrollableTarget="scrollListadoAlumnos" scrollThreshold={0.959} dataLength={itemsLoaded} next={fetchMoreData} hasMore={true} /*loader={<h4>Loading...</h4>}*/>
        <div>
          <table id='table-alumnos'>
            <thead>
              <tr>
                <th onClick={() => setSortBy('dni')}>
                  <span>DNI</span>
                  {sortBy === 'dni' && <i className={`material-icons sort-arrow ${des ? 'des-arrow' : ''}`}>arrow_downward</i>}
                </th>

                <th onClick={() => setSortBy('nombreCompleto')}>
                  <span>Nombre</span>
                  {sortBy === 'nombreCompleto' && <i className={`material-icons sort-arrow ${des ? 'des-arrow' : ''}`}>arrow_downward</i> }
                </th>
                <th onClick={() => setSortBy('tipoInscripcion')}>
                  <span>Inscripción</span>
                  {sortBy === 'tipoInscripcion' && <i className={`material-icons sort-arrow ${des ? 'des-arrow' : ''}`}>arrow_downward</i>}
                </th>
                <th onClick={() => setSortBy('cuestionarioDone')}>
                  <span>Cuestionario</span>
                  {sortBy === 'cuestionarioDone' && <i className={`material-icons sort-arrow ${des ? 'des-arrow' : ''}`}>arrow_downward</i>}
                </th>

                <th onClick={() => setSortBy('endesa')}>
                  <span>Endesa</span>
                  {sortBy === 'endesa' && <i className={`material-icons sort-arrow ${des ? 'des-arrow' : ''}`}>arrow_downward</i> }
                </th>

                <th onClick={() => setSortBy('sat')}>
                  <span>SAT</span>
                  {sortBy === 'sat' && <i className={`material-icons sort-arrow ${des ? 'des-arrow' : ''}`}>arrow_downward</i> }
                </th>

                <th></th>
              </tr>
            </thead>
            <tbody>


              {sortedAlumnos.map( (idAlumno, index ) => {
                if(index>itemsLoaded) return null
                
                return(
                  <ItemLista alumno={alumnos[idAlumno]} />
                )
              })}

              
                {/*state.items.map((i, index) => (
                  <div style={style} key={index}>
                    div - #{index}
                  </div>
                ))*/}
              
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </ScrollContainer>
  )
})

const mapStateToProps = ({ alumnos }) => {
  return {
    alumnos: alumnos.alumnos,
    sortBy: alumnos.tabs.listado.header.sortBy,
    des: alumnos.tabs.listado.header.des,
    itemsLoaded: alumnos.tabs.listado.header.itemsLoaded,
    sortedAlumnos: alumnos.sortedAlumnos,
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      sortData,
      changeHeader
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(PanelListado);