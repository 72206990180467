import React from 'react'

export const ErrorFormulario = ({updateError}) =>{
  return(
    <div className={`alert_form pointer pr alert_wrong`}>
      Existen errores en los campos
      <div className="close_alert_form" onClick={updateError} >×</div>
    </div>
  )
}

export default ErrorFormulario