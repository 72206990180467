import React from 'react'
import { localesDateCountdown, localespluralDateCountdown } from '../../../utils/constans'
import { Link } from 'react-router-dom'
import { pathFormularioModifyAlumno } from '../../../utils/constans'

const Descanso = ({time, setPanelExamen}) => {
  return(
    <div className="panel-ingles all-screen">
      <div className="resumen-preguntas">
        <div>
          <h1 className="text-resumen">¡Enhorabuena!</h1>
          <h4 className="text-resumen text-fin">
            <br/>
            Has terminado la prueba de nivel de American Dream.
            Entra en tu perfil para acabar el <Link to={pathFormularioModifyAlumno} className="link-examen-fin">cuestionario de orientación</Link> para completar tu perfil, si aún no lo has hecho. 
            En unas semanas te mandaremos el resultado de las pruebas
            <br/>
            <br/>
            ¡Atento a tu correo electrónico!
          </h4>
        </div>
      </div>
    </div>
  )
}

export default Descanso