import React, { useState } from 'react'
import { opcionesCuestionario } from '../../../../../utils/constans'
import CheckItem from '../../Shared/CheckItem'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../redux/actions/alumnosActions';

const Pregunta10 = ({idPregunta,currentAlumno, actions, setPanelSelectedBack, setPanelSelected}) => {

    const [showMensaje, setShowMnesaje] = useState(false)

    const callbackCheckItem = (json) =>{
    if(!currentAlumno.inscripcionAlumno.cuestionario[idPregunta]) return false
        var cuestionario = {...currentAlumno.inscripcionAlumno.cuestionario}
        cuestionario[idPregunta][json.idArrayPregunta] = `${json.value}`
        actions.modifyCurrentAlumno({value:cuestionario, path:'inscripcionAlumno.cuestionario'})
    }
    const changeInput = (json) => {
        actions.modifyCurrentAlumno({value:`${json.value}`, path:`inscripcionAlumno.cuestionario.${idPregunta}.${json.idArrayPregunta}`})
    }

  return(
    <div>
        <div className="pregunta-texto">{opcionesCuestionario.preguntas[idPregunta].pregunta}</div>
        <div className='center-tag'>
            <div className="contenedor-preguntas-selectivas">
                {currentAlumno.inscripcionAlumno.cuestionario[idPregunta].map((r,k2) => {
                    if(!opcionesCuestionario.preguntas[idPregunta]) return false
                    let respuesta = opcionesCuestionario.preguntas[idPregunta].respuestas[k2]
        
                    return(
                        <div className={`item-preguntas-selectivas`} key={k2} >
                            <div className="texto-preguntas-selectivas"> {respuesta.text} </div>
                            <div className="opciones-preguntas-selectivas">
                                <div className={`${respuesta.type && respuesta.type==="input"?'container-input-pregunta-cuestionario':''}`}>
                                    {respuesta.type && respuesta.type==="input" &&
                                        <input className='width_100' value={r} onChange={e=>changeInput({idArrayPregunta: k2, value:e.target.value})}/>
                                    }
                                    {respuesta.type && respuesta.type==="checkbox" &&
                                        <CheckItem 
                                            span_checkbox_class={false} 
                                            callbackCheckItem={callbackCheckItem} 
                                            json={{idArrayPregunta: k2}} 
                                            checked={r==="true"} 
                                    />}
                                    
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
        
        <div className='direccions_panel'>
            <div className="btn_send_form btn-volver-form pointer noselect float_atras" onClick={setPanelSelectedBack}> Atrás </div>
            <div className='btn_send_form pointer noselect float_siguiente' onClick={setPanelSelected}>Siguiente</div>
        </div>
    </div>
  )
}

const mapStateToProps = ({ alumnos }) => {
  return {
    currentAlumno: alumnos.currentAlumno,
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(Pregunta10);