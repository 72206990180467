import React, { useLayoutEffect, useState } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { getAlumno, getAlumnoInscripcion } from '../../redux/actions/alumnosActions'
import { getColegio } from '../../redux/actions/colegiosActions'
import { Link } from 'react-router-dom';
import { DescriptionRounded, AssignmentIndRounded, ExitToAppRounded, GetAppRounded, AssignmentRounded } from '@material-ui/icons'
import HeaderW2A from '../W2AViews/HeaderW2A'
import FooterW2A from '../W2AViews/FooterW2A'
import { pathFormularioModifyAlumno, pathSigninAlumno } from '../../utils/constans'
import moment from 'moment'
import { signOut } from '../../utils/firebase';
import { db } from '../../utils/firebase'
import { generatePDF } from '../../utils/functions'

const AreaAlumno = ({currentAlumno, currentColegio, inscripcion, actions}) => {

  const [loading, setLoading] = useState(true)
  const [file, setFile] = useState(null)

  useLayoutEffect(()=>{
    getData()
  },[])

  const getData = async () => {
    await actions.getAlumno()
    const inscripcionAlumno = await actions.getAlumnoInscripcion()
    //if(inscripcionAlumno.datosColegio.colegio.id!=="nuevo"){
      await actions.getColegio({idColegio: inscripcionAlumno.datosColegio.colegio.id})
    //}
    setLoading(false)
  }

  const logout = () => {
    signOut()
    window.location.href = pathSigninAlumno
  }

  const checkExamen = (e) => {
    if(!currentColegio.examen.habilitado || !currentColegio.examen.alumnosHabilitados[currentAlumno.id]){
      e.preventDefault()
    }
  }

  const uploadTemplate = () => {
    const blob = new Blob([file],{type: "application/pdf"})

    var reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = function() {
        var base64data = reader.result;                
        let multipath = {}
        multipath[`Settings/informeAlumno/template`] = base64data
        db.update(multipath)
        .then(() => console.log('OK') )
        .catch((err) => console.log(err))
    }
  }

  const openInformeAD20 = async () => {
    const fileURL = await generatePDF(currentAlumno, inscripcion)
    window.open(fileURL); 
  }

  const isSatDone = currentAlumno.inscripcionAlumno.isSatDone()

  return(
    loading ? <div>Loading...</div> :
    <div className='bg-w2a'>
      <HeaderW2A categoria='Área Alumno' />

      <div className="container-area-alumo">

        <div className="content-area-alumno"> 

          <div className="title-area">
            {`Bienvenido ${currentAlumno.informacionAlumno.nombre}`}
            <div className="logout-btn" onClick={logout}>
              <ExitToAppRounded />
              <span>Logout</span>
            </div>
            {/* <input type='file' onChange={e => setFile(e.target.files[0])} /> */}
          </div>
          
          <div className="panels-area">

          <div className="panel-area data-user-card ">
            <Link to={pathFormularioModifyAlumno}>
              <div className="card-area">
                <div className="card-icon">
                  <div className="container-icon"><AssignmentIndRounded /></div>

                </div>

                <div className="card-content">
                    <div className="title-card">Datos del alumno</div>
                    <div className="description-card">Puedes ver o editar los datos de tu inscripción</div>
                </div>
              </div>
            </Link>
            </div>
            <div className="panel-area examen-sat-card">
              <Link to={`/examen/${currentColegio.id}`} onClick={(e)=>checkExamen(e)}>
                <div className="card-area">
                  <div className="card-icon">
                    <div className="container-icon"><DescriptionRounded /></div>
                  </div>
                  <div className="card-content">
                      <div className="title-card">Examen SAT</div>
                        {/*isSatDone && 
                          <div className="description-card">Ya has realizado el examen</div>
                        */}

                        {/*!isSatDone && */ !currentColegio.examen.habilitado && 
                          <div className="description-card">No se ha habilitado ningún examen para tu colegio</div>
                        }
                        {/*!isSatDone && */ currentColegio.examen.habilitado && !currentColegio.examen.alumnosHabilitados[currentAlumno.id] && 
                          <div className="description-card">No estás convocado a ningún examen</div>
                        }
                        {/*!isSatDone && */ currentColegio.examen.habilitado && currentColegio.examen.alumnosHabilitados[currentAlumno.id] && 
                          <div className="description-card">Tienes un examen el día {moment(currentColegio.examen.fechaExamen).format('DD/MM/YYYY')} a las {currentColegio.examen.horaExamen}</div>
                        }
                      
                  </div>
                </div>
              </Link>
            </div>
            <div className="panel-area informe-sat-card" onClick={openInformeAD20}>
              <div className="card-area">
                <div className="card-icon">
                  <div className="container-icon"><AssignmentRounded /></div>
                </div>
                <div className="card-content">
                    <div className="title-card">Informe AD2020</div>
                    <div className="description-card">Descarga tu informe AD2020 aquí</div>
                </div>
              </div>
            </div>
            
          </div>
        </div>


      </div>
      
      <FooterW2A />
    </div>
  )
}

const mapStateToProps = ({ alumnos, colegios, global }) => {
  return {
    currentAlumno: alumnos.currentAlumno,
    currentColegio: colegios.currentColegio,
    inscripcion: global.inscripcion
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      getAlumno,
      getAlumnoInscripcion,
      getColegio
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(AreaAlumno);


