import React from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../../redux/actions/alumnosActions';

const OrdenPaises = ({preferenciaPaises, actions}) => {

  const onDragEnd = (result, id) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let newPreferenciaPaises = preferenciaPaises
    let itemMoved = newPreferenciaPaises[source.index];
    newPreferenciaPaises.splice(source.index, 1);
    newPreferenciaPaises.splice(destination.index, 0, itemMoved);
    actions.modifyCurrentAlumno({value:newPreferenciaPaises, path:'inscripcionAlumno.preferenciaPaises'})
  };

  return(
    <div className="container-preferencia-paises" >
      <div className="title-div-preferencia-paises">Ordena los países en los que estudiarías por orden de preferencia (arriba el que más te guste, abajo el que menos)</div>
      <DragDropContext onDragEnd={(result) => onDragEnd(result, 1)}>
        <Droppable droppableId={"preferenciaPaises"}>

        {(provided) => (
          <div
            className="display_inline_grid"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >

            {preferenciaPaises.map(
              (r, k) => {
                return (
                  <Draggable draggableId={r.id} index={k} key={r.id}>
                    {(provided, snapshot) => (
                      <div
                        className={`item-pregunta-drag ${
                          snapshot.isDragging ? "shadow" : ""
                        }`}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        key={k}
                      >
                        <div className="num-respuest">{k + 1}</div>
                        <div className="text-respuest">{r.text.replace(',','')}</div>
                        <div className="icon-respuest">
                          <i className="material-icons">
                            drag_handle
                          </i>
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              }
            )}
            {provided.placeholder}
          </div>
        )}

        </Droppable>
      </DragDropContext>
    </div>
  )
}


const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(OrdenPaises);