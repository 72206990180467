import React from 'react'
import { Link } from 'react-router-dom';

const PanelInformativo = ({setPanelSelected}) => {
  return(
    <div className='panel-view-info'>
      <center className='shadow pop-up-cuestionario'>
        <div className='text-explicativo-panel'>
          Esta inscripción te tomará aproximadamente 10 minutos y es <b>totalmente gratuita</b>. Tras realizar el proceso completo, conseguirás saber las universidades de EE.UU, Canadá, Irlanda e Inglaterra en las que tienes opciones de conseguir una admisión.
          <br /><br />
          Lo puedes realizar con tu teléfono móvil, un ordenador o una tablet.
          <br />
          ¡Busca un lugar tranquilo, acompáñalo de un Cola Cao y mucha suerte!
        </div>
        <div className='div-opciones-inscripcion'>
          <Link to={'/area-alumno'}>
            <div className='btn_send_form btn-volver-form'>Iniciar sesión</div>
          </Link>
          <div className='btn_send_form' onClick={setPanelSelected}>Empezar</div>
        </div>
      </center>
    </div>
  )
}

export default PanelInformativo