import React, { useState } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { changeHeader, downloadColegios } from '../../../../../../redux/actions/colegiosActions'
import FiltersList from "../../../../../Shared/FiltersList"
import FiltersItem from "../../../../../Shared/FiltersItem"
import XLSX from 'xlsx';
const Options = ({filters, actions}) => {

  const [showFilters, setShowFilters] = useState(false)

  const handleDownload = () => {
    actions.downloadColegios()
    .then((result)=>{
      if(result.colegios.length>0){
        var ws = XLSX.utils.json_to_sheet(result.colegios);
        var wsColegiosNuevos = XLSX.utils.json_to_sheet(result.colegiosNuevos);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Colegios");
        XLSX.utils.book_append_sheet(wb, wsColegiosNuevos, "Colegios Nuevos");
        XLSX.writeFile(wb, `Colegios.xlsx`);
      }
    })
    .catch(err=>{
      console.log(err);
    })
  }

  return(
    <div className='pr'>
      <FiltersItem filters={{...filters}} updateFilters={(filters=>actions.changeHeader({tab:"listado", field:"filters", value: filters}))} />
      <div className='opciones-alumnos'>
        <div className='deg-opt'></div>

        <div className='item-container-icon-top-bar last-item'>
          <i onClick={handleDownload} className="material-icons hover-azul">save_alt</i>
        </div>
        
        <div className='btn-options pr' onClick={()=>setShowFilters(!showFilters)}>
          <i className="material-icons"> filter_list </i> <span>Filtros</span>
          {showFilters &&
            <FiltersList filters={{...filters}} updateFilters={(filters=>actions.changeHeader({tab:"listado", field:"filters", value: filters}))} onClose={()=>setShowFilters(false)}/>
          }
        </div>
        
      </div>
    </div>
  )
}

const mapStateToProps = ({ colegios }) => {
  return {
    filters: colegios.tabs.listado.header.filters
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      changeHeader,
      downloadColegios
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(Options);

