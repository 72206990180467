import React, { useState } from 'react'
import { onFocusInscripciones, onBlurInscripciones } from '../../../utils/functions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentColegio } from '../../../redux/actions/colegiosActions';
import { provincias }from '../../../utils/constans'
import ListaOpciones from '../Shared/ListaOpciones'
import Switch from '../../Shared/Switch'

const DatosColegio = (props) => {
  const { errors } = props
  const [showProvincia, setShowProvincia] = useState(false)

  const callbackSwitch = (json) => {
    if (json.id === 'participacionAnterior') {
      props.actions.modifyCurrentColegio({value: json.value, path:'participacionAnterior'})
    }
  }

  return(
    <div>

      <h3 className="title_form">DATOS GENERALES DEL COLEGIO</h3>

      {/*NOMBRE DEL COLEGIO*/}
      <div className="pr">
        <input onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ${errors.nombre ? 'obligatorio':''}`} value={props.nombre ?? ''} 
          onChange={e=>props.actions.modifyCurrentColegio({value:e.target.value, path:'nombre'})}
        />
        <label className="label_form">Nombre del colegio</label>
      </div>


      <div className="pr width_100 colums_form_2">
        {/*PROVINCIA*/}
        <div id={'lista_provincias'} className={`pr pointer select_form colums_form_2_margin_right ${errors.provincia?'obligatorio':''}`} onClick={()=>setShowProvincia(true)}>
          <div className="item_selected">{provincias[props.provincia]?.texto ?? ''}</div>
          <label className="label_form pointer">Provincia</label>
          {showProvincia &&
            <ListaOpciones 
              editOpciones={(value) => setShowProvincia(value)} 
              opciones={provincias} 
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentColegio({value: value??"", path:'provincia'}) } 
              opcionSelected={props.provincia} 
            />
          }
        </div>
        {/*DIRECCION*/}
        <div className="pr width_100 colums_form_2_margin_left">
          <input onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ${errors.direccion?'obligatorio':''}`} value={props.direccion} 
          onChange={e=>props.actions.modifyCurrentColegio({value:e.currentTarget.value, path:'direccion'})}
          />
          <label className="label_form">Dirección</label>
        </div>
      </div>


      <div className="pr width_100 colums_form_2">
        {/*  */}
        <div className="pr width_100 colums_form_2_margin_right fundacion-excepcion">
          <input onFocus={e => { onFocusInscripciones(e) }} onBlur={e => { onBlurInscripciones(e) }} className={`input_form`} value={props.fundacion} 
            onChange={e=>props.actions.modifyCurrentColegio({value:e.currentTarget.value, path:'fundacion'})}
          />
          <label className="label_form">Si perteneces a un grupo educativo o fundación, por favor anótala</label>
        </div>

        {/* BACHILLERATO DUAL*/}
        <div className="display_inline_flex parent_academica colums_form_2_margin_left width_100 container-switch-inscripcion">
          <span className="span_academica margin_auto_vertical">¿Has participado anteriormente en <span className="color_secundario">American Dream</span>?</span>
          <div className="display_inline_flex">
            <span className="info_academica margin_auto_vertical">no</span>
            <Switch class_div={'switch_academica'} callbackSwitch={callbackSwitch} json={{ id: 'participacionAnterior' }} value={props.participacionAnterior} />
            <span className="info_academica margin_auto_vertical">si</span>
          </div>
        </div>

      </div>





    </div>
  )
}

// const mapStateToProps = ({ colegios }) => {
//   return {
//     colegio: colegios.currentColegio,
//   };
// }

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentColegio
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(DatosColegio);