import React from 'react'
import BecaDeportiva from './BecaDeportiva/BecaDeportiva'
import BecaAcademica from './BecaAcademica/BecaAcademica'
const PanelBeca = ({alumno}) => {
  return(
    alumno.inscripcionAlumno.inscripcion.tipo==='Deportiva'?
      <BecaDeportiva alumno={alumno}/>
    :
      <BecaAcademica alumno={alumno}/>
  )
}


export default PanelBeca;