import React, { useState, useEffect } from 'react'
import { validarDni, onFocusInscripciones, onBlurInscripciones, validarTelefono, validFechaNacimiento } from '../../../../../../utils/functions'
import { opcionesSexo } from '../../../../../../utils/constans'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../../redux/actions/alumnosActions';
import Switch from '../../../../../Shared/Switch'
const Voluntariado = (props) => {
  
  const { errors } = props

  const callbackSwitch = (json) => {
    if (json.id === 'voluntariado') {
      props.actions.modifyCurrentAlumno({value: json.value, path:'inscripcionAlumno.inscripcion.beca.academica.voluntariado'})
    }else if (json.id === 'practicas') {
      props.actions.modifyCurrentAlumno({value: json.value, path:'inscripcionAlumno.inscripcion.beca.academica.practicas'})
    }
  }
  return(
    <div>
      <h3 className="title_form">Voluntariado</h3>
      {/*VOLUNTARIADO */}
      <div className="display_inline_flex width_100 item-switch-form">
        <span className="span_academica margin_auto_vertical">Voluntariado</span>
        <div className="display_inline_flex">
          <span className="info_academica margin_auto_vertical">no</span>
          <Switch class_div={'switch_academica'} callbackSwitch={callbackSwitch} json={{ id: 'voluntariado' }} value={props.voluntariado} />
          <span className="info_academica margin_auto_vertical">si</span>
        </div>
      </div>

      {props.voluntariado &&
        <div className={`pr container-ta margin-bottom-15 ${errors.voluntariadoDescripcion?'obligatorio':''}`}>
          <label className="label_form pr">Explícanos en qué ha consistido tu experiencia en voluntariado</label>
          <textarea onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ta-forms`} value={props.voluntariadoDescripcion} onChange={e=>props.actions.modifyCurrentAlumno({value:e.target.value,path:'inscripcionAlumno.inscripcion.beca.academica.voluntariadoDescripcion'})}/>
        </div>
      }

      {/*PRACTICAS */}
      <div className="display_inline_flex width_100 item-switch-form">
        <span className="span_academica margin_auto_vertical">Prácticas</span>
        <div className="display_inline_flex">
          <span className="info_academica margin_auto_vertical">no</span>
          <Switch class_div={'switch_academica'} callbackSwitch={callbackSwitch} json={{ id: 'practicas' }} value={props.practicas} />
          <span className="info_academica margin_auto_vertical">si</span>
        </div>
      </div>

      {/* MAS INFORMACION DEL ALUMNO */}
      <div className="pr container-ta">
        <label className="label_form pr">Comenta cualquier circunstancia sobre tu curriculum, notas o experiencia que creas que nos ayudaría a entender mejor tu perfil</label>
        <textarea onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ta-forms`} value={props.informacionAdicional} onChange={e=>props.actions.modifyCurrentAlumno({value:e.target.value,path:'inscripcionAlumno.inscripcion.beca.academica.informacionAdicional'})}/>
      </div>
    </div>
  )
}



const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(Voluntariado);