const ADMIN = "Administrador"

const ALL = ["ALL"]
const ADMIN_ONLY = [ADMIN]

export {
  ADMIN,
  
  ALL,
  ADMIN_ONLY,
}