
class InscripcionAcademica {

  tipoActividad = "";
  nombreActividad = "";
  dedicationYears = "";
  siguePracticando = false;
  horasSemanales = "";
  centroActividad = "";

  experienciaExtranjero = false;
  lugarExperiencia = "";
  mesesExperiencia = "";
  objetivoExperiencia = "";
  experienciaDescripcion  = "";

  voluntariado = false;
  voluntariadoDescripcion = "";
  practicas = false;
  informacionAdicional = "";
  colaboraciones  = false;
  imagen = false;
  terminos = false
  evaluacionW2A = {
    habilidades: "",
    status: "",
    valoracion: ""
  } 

   constructor(obj){
      if(obj){
        this.tipoActividad = obj.tipoActividad ?? this.tipoActividad
        this.nombreActividad = obj.nombreActividad ?? this.nombreActividad
        this.dedicationYears = obj.dedicationYears ?? this.dedicationYears
        this.siguePracticando = obj.siguePracticando ?? this.siguePracticando
        this.horasSemanales = obj.horasSemanales ?? this.horasSemanales
        this.centroActividad = obj.centroActividad ?? this.centroActividad
        this.experienciaExtranjero = obj.experienciaExtranjero ?? this.experienciaExtranjero
        this.lugarExperiencia = obj.lugarExperiencia ?? this.lugarExperiencia
        this.mesesExperiencia = obj.mesesExperiencia ?? this.mesesExperiencia
        this.objetivoExperiencia = obj.objetivoExperiencia ?? this.objetivoExperiencia
        this.experienciaDescripcion = obj.experienciaDescripcion ?? this.experienciaDescripcion
        this.voluntariado = obj.voluntariado ?? this.voluntariado
        this.voluntariadoDescripcion = obj.voluntariadoDescripcion ?? this.voluntariadoDescripcion
        this.practicas = obj.practicas ?? this.practicas
        this.informacionAdicional = obj.informacionAdicional ?? this.informacionAdicional
        this.colaboraciones = obj.colaboraciones ?? this.colaboraciones
        this.imagen = obj.imagen ?? this.imagen
        this.terminos = obj.terminos ?? this.terminos
        this.evaluacionW2A = { 
          habilidades: obj.evaluacionW2A?.habilidades ?? this.evaluacionW2A, 
          status: obj.evaluacionW2A?.status ?? this.evaluacionW2A.status, 
          valoracion: obj.evaluacionW2A?.valoracion ?? this.evaluacionW2A.valoracion
        }
      }
  }

  isValid = () => {
    var isValid = true;

    if(this.tipoActividad.trim()===''){
      isValid=false;
    }
    if(this.nombreActividad.trim()===''){
      isValid=false;
    }
    if(this.centroActividad.trim()===''){
      isValid=false;
    }
    if(this.dedicationYears.trim()===''){
      isValid=false;
    }
    if(this.siguePracticando && this.horasSemanales.trim()===""){
      isValid=false;
    }

    if(this.experienciaExtranjero && this.lugarExperiencia.trim()===""){
      isValid=false;
    }
    if(this.experienciaExtranjero && this.mesesExperiencia.trim()===""){
      isValid=false;
    }
    if(this.experienciaExtranjero && this.objetivoExperiencia.trim()===""){
      isValid=false;
    }
    if(this.experienciaExtranjero && this.experienciaDescripcion.trim()===""){
      isValid=false;
    }

    if(this.voluntariado && this.voluntariadoDescripcion.trim()===''){
      isValid=false;
    }

    if(!this.terminos){
      isValid=false;
    }
    return { isValid }
  }

}

export default InscripcionAcademica;