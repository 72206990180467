import React, { useEffect, useLayoutEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { changeHeader, setTabSelected, setColegioSelected } from '../../../../../../redux/actions/colegiosActions'
import ItemLista from './ItemLista';
import ScrollContainer from '../../../../../Shared/ScrollContainer'
import InfiniteScroll from "react-infinite-scroll-component";
import { addItemsLoaded } from '../../../../../../utils/constans'

const PanelListado = ({sortBy, des, colegios, sortedColegios, itemsLoaded, actions}) => {

  const fetchMoreData = () => {
    actions.changeHeader({ tab: "listado", field:'itemsLoaded', value: itemsLoaded+addItemsLoaded, skipSort: true})
  };
  
  const setSortBy = value => {
    let descendant = false
    if(value===sortBy){
      descendant = !des
    }
    actions.changeHeader({ tab:'listado', field: "sortBy", value, des: descendant})
  }

  const selectColegioNuevo = () => {
    actions.setColegioSelected('nuevo')
    actions.setTabSelected('alumnos')
  }
  
  return(
    <ScrollContainer _class={`container-table`} id="scrollListadoColegios">
      <InfiniteScroll scrollableTarget="scrollListadoColegios" scrollThreshold={0.959} dataLength={itemsLoaded} next={fetchMoreData} hasMore={true} /*loader={<h4>Loading...</h4>}*/>

        <div>
          <table id='table-colegios'>
            <thead>
              <tr>

              <th className='colegios-listado-codigo' onClick={()=>setSortBy('codigo')}>
                <span>Código</span>{sortBy==='codigo' && <i className={`material-icons sort-arrow ${des && 'des-arrow'}`}>arrow_downward</i>}
              </th>

              <th className='colegios-listado-nombre' onClick={()=>setSortBy('nombre')}>
                <span>Nombre</span>
                {sortBy==='nombre' && <i className={`material-icons sort-arrow ${des && 'des-arrow'}`}>arrow_downward</i> }
              </th>

              <th className='colegios-listado-provincia' onClick={()=>setSortBy('provincia')}>
                <span>Provincia</span> {sortBy==='provincia' && <i className={`material-icons sort-arrow ${des && 'des-arrow'}`}>arrow_downward</i>}
              </th>

              <th className='colegios-listado-alumnos' onClick={()=>setSortBy('alumnos')}>
                <span>Alumnos</span> {sortBy==='alumnos' && <i className={`material-icons sort-arrow ${des && 'des-arrow'}`}>arrow_downward</i>}
              </th>

                <th className='colegios-listado-more'></th>

              </tr>
            </thead>
            <tbody>
              {/* COLEGIOS NUEVOS */}
              <tr data-id='nuevos-colegios'>
                <td className='colegios-listado-codigo'> <div className='edit-container'> <span className='width_100'> NUEVOS </span> </div> </td>
                <td className='colegios-listado-nombre'> <div className='edit-container'> <span> Colegios no registrados </span> </div> </td>
                <td className='colegios-listado-provincia'>-</td>
                <td className='colegios-listado-alumnos' onClick={()=>{selectColegioNuevo()}}>
                  <div className='align-center'> <span>{colegios.nuevo ? Object.keys(colegios.nuevo.alumnos).length : 0}</span> </div>
                </td>
                <td className='colegios-listado-more' onClick={()=>selectColegioNuevo()}>
                  <i className="material-icons align-center">chevron_right</i>
                </td>
              </tr>
              {
                sortedColegios.map( (idColegio, index ) => {
                  if(index>itemsLoaded) return null
                  return(
                    <ItemLista key={index} colegio={colegios[idColegio]} />
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </ScrollContainer>
  )
}

const mapStateToProps = ({ colegios }) => {
  return {
    colegios: colegios.colegios,
    sortBy: colegios.tabs.listado.header.sortBy,
    des: colegios.tabs.listado.header.des,
    itemsLoaded: colegios.tabs.listado.header.itemsLoaded,
    sortedColegios: colegios.sortedColegios
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      changeHeader,
      setColegioSelected,
      setTabSelected
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(PanelListado);
