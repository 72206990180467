import React, { useEffect, useLayoutEffect, useState, memo } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { sortData, changeHeader } from '../../../../../../../redux/actions/alumnosActions'
import ItemLista from './ItemLista';
import ScrollContainer from '../../../../../../Shared/ScrollContainer'
import InfiniteScroll from "react-infinite-scroll-component";
import { addItemsLoaded } from '../../../../../../../utils/constans'

const PanelListado = memo(({sortBy, des, alumnos, sortedAlumnos, itemsLoaded, actions}) => {

  const fetchMoreData = () => {
    actions.changeHeader({ tab: "listado", field:'itemsLoaded', value: itemsLoaded+addItemsLoaded, skipSort: true})
  };
  const setSortBy = value => {
    let descendant = false
    if(value===sortBy){
      descendant = !des
    }
    actions.changeHeader({ tab:'listado', field: "sortBy", value, des: descendant})
  }
  var i = 0
  return(
    <ScrollContainer _class={`container-table`} id="scrollListadoAlumnos">
      <InfiniteScroll scrollableTarget="scrollListadoAlumnos" scrollThreshold={0.959} dataLength={itemsLoaded} next={fetchMoreData} hasMore={true} /*loader={<h4>Loading...</h4>}*/>
        <div>
          <table id='table-alumnos-vista-deportiva'>
            <thead>
              <tr>

                <th className='pr' onClick={()=>setSortBy('status')} >
                  <div className='div-container-status'><span>Estado</span> {sortBy==='status' && <i className={`material-icons sort-arrow position-arrow-estado ${des?'des-arrow':''}`}>arrow_downward</i>}</div>
                </th>

                <th onClick={()=>setSortBy('nombreCompleto')}>
                  <span>Nombre</span>
                  {sortBy==='nombreCompleto' && <i className={`material-icons sort-arrow ${des?'des-arrow':''}`}>arrow_downward</i>}
                </th>

                <th onClick={()=>setSortBy('deporte')}>
                  <span>Deporte</span>
                  {sortBy==='deporte' && <i className={`material-icons sort-arrow ${des?'des-arrow':''}`}>arrow_downward</i>}
                </th>

                <th onClick={()=>setSortBy('club')} className='pr' >
                  <span>Club</span>
                  {sortBy==='club' && <i className={`material-icons sort-arrow ${des?'des-arrow':''}`}>arrow_downward</i>}
                </th>

                <th >
                  <span>Año</span>
                </th>

                <th className='pr' >
                  <span>Teléfono<span className="subtitle-header-table">Tutor</span> </span>
                </th>

                <th></th>
              </tr>
            </thead>
            <tbody>


              {sortedAlumnos.map( (idAlumno, index ) => {
                if(index>itemsLoaded) return null
                
                return(
                  <ItemLista alumno={alumnos[idAlumno]} status={alumnos[idAlumno].inscripcionAlumno.inscripcion.beca.deportiva.evaluacionW2A.status}/>
                )
              })}

              
                {/*state.items.map((i, index) => (
                  <div style={style} key={index}>
                    div - #{index}
                  </div>
                ))*/}
              
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </ScrollContainer>
  )
})

const mapStateToProps = ({ alumnos }) => {
  return {
    alumnos: alumnos.alumnos,
    sortBy: alumnos.tabs.listado.header.sortBy,
    des: alumnos.tabs.listado.header.des,
    itemsLoaded: alumnos.tabs.listado.header.itemsLoaded,
    sortedAlumnos: alumnos.sortedAlumnos,
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      sortData,
      changeHeader,
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(PanelListado);