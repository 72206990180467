import React, { useState, useLayoutEffect } from 'react'
import { validarDni, onFocusInscripciones, onBlurInscripciones, validarTelefono, validFechaNacimiento } from '../../../../../../utils/functions'
import { opcionesSedeSat, opcionesTipoColegios } from '../../../../../../utils/constans'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../../redux/actions/alumnosActions';
import { getColegios } from '../../../../../../redux/actions/colegiosActions';
import ListaOpciones from '../../../Shared/ListaOpciones'
import ListaColegios from '../../../Shared/ListaColegios'

const DatosColegio = (props) => {
  const { errors } = props

  useLayoutEffect(() => { props.actions.getColegios({}) }, [])

  const [showOptionsSede, setShowOptionsSede] = useState(false)
  const [showOptionsColegios, setShowOptionsColegios] = useState(false)
  const [showOptionsTipoColegio, setShowOptionsTipoColegio] = useState(false)

  const setColegio = (colegio) => {
    const {id, codigo, nombre, tipoColegio, emailTutor} = colegio
    props.actions.modifyCurrentAlumno({
      value: {id, codigo, nombre, tipoColegio: '', emailTutor: ""},
      path:'inscripcionAlumno.datosColegio.colegio'
    })
  }

  return(
    <div>
      <h3 className="title_form"><span className="fw_thin">Datos del</span> colegio</h3>

      {/*COLEGIO*/}
      <div className={`pr pointer select_form  ${errors.colegioId?'obligatorio':''}`} onClick={() => setShowOptionsColegios(true) }>
        <div className="item_selected">{props.colegio.id==='nuevo'?'No encuentro mi colegio': props.colegio.nombre}</div>
        <label className="label_form pointer">Colegio</label>
        {showOptionsColegios &&
          <ListaColegios
            setColegio={(colegio) => setColegio(colegio) } 
            showOptionsColegio={(value) => setShowOptionsColegios(value)} 
            colegios={props.colegios} 
            idColegioSelected={props.colegio.id}
          />
        }
      </div>

      {props.colegio.id === 'nuevo' &&
        <div>
  
          <div className="pr">
            <input onFocus={e => { onFocusInscripciones(e) }} onBlur={e => { onBlurInscripciones(e) }} className={`input_form ${errors.colegioNombre ? 'obligatorio' : ''}`} value={props.nombre} onChange={e => props.actions.modifyCurrentAlumno({value:e.target.value, path:'inscripcionAlumno.datosColegio.colegio.nombre'})} />
            <label className="label_form">Nombre del colegio</label>
          </div>
      
          <div className={`pr pointer select_form  ${errors.colegioTipo? 'obligatorio' : ''}`} onClick={() => setShowOptionsTipoColegio(true)}>
            <div className="item_selected">{props.tipoColegio}</div>
            <label className="label_form pointer">Tipo de colegio</label>
            {showOptionsTipoColegio &&
              <ListaOpciones 
                editOpciones={(value) => setShowOptionsTipoColegio(value)} 
                opciones={opcionesTipoColegios} 
                opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value: value ?? '', path:'inscripcionAlumno.datosColegio.colegio.tipoColegio'})} 
                opcionSelected={props.tipoColegio} 
              />
            }
          </div>
          <div className="pr">
            <input onFocus={e => { onFocusInscripciones(e) }} onBlur={e => { onBlurInscripciones(e) }} className={`input_form ${errors.colegioEmailTutor ? 'obligatorio' : ''}`} value={props.emailTutor} onChange={e => props.actions.modifyCurrentAlumno({value:e.target.value, path:'inscripcionAlumno.datosColegio.colegio.emailTutor'})} />
            <label className="label_form">Mail de tu tutor (opcional)</label>
          </div>
        </div>
      }


      <div className="pr width_100 colums_form_2">
        {/*CURSO ACTUAL*/}
        <div className={`pr pointer select_form colums_form_2_margin_right  ${errors.colegioSedeSat?'obligatorio':''}`} onClick={() => setShowOptionsSede(true)}>
          <div className="item_selected">{props.sedeSat}</div>
          <label className="label_form pointer">Sede SAT</label>
          {showOptionsSede &&
            <ListaOpciones 
              editOpciones={(value) => setShowOptionsSede(value)} 
              opciones={opcionesSedeSat} 
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.datosColegio.sedeSat'})}
              opcionSelected={props.sedeSat} 
            />
          }
        </div>
        
        <div className="pr width_100 colums_form_2_margin_left">
          <input onFocus={e => { onFocusInscripciones(e) }} onBlur={e => { onBlurInscripciones(e) }} className={`input_form ${errors.colegioCiudad ? 'obligatorio' : ''}`} value={props.ciudad} onChange={e => props.actions.modifyCurrentAlumno({value:e.target.value, path:'inscripcionAlumno.datosColegio.ciudad'})} />
          <label className="label_form">Ciudad</label>
        </div>
      </div>
      <div className='text-explicativo'>Elige la sede donde harás la Prueba del SAT de manera presencial</div>


    </div>
  )
}

const mapStateToProps = ({ colegios }) => {
  return {
    colegios: colegios.colegios,
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      getColegios,
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(DatosColegio);