import { combineReducers } from 'redux';

import intl from './reducers/intlReducer'
import user from './reducers/userReducer'
import global from './reducers/globalReducer'
import colegios from './reducers/colegiosReducer'
import alumnos from './reducers/alumnosReducer'

const reducer = combineReducers({
  intl,
  user,
  global,
  colegios,
  alumnos
});

export default reducer;