import React from 'react'
import searchIcon from '../../../../../../images/search.svg';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { changeHeader, setTabSelected } from '../../../../../../redux/actions/colegiosActions'
import { setNotification } from '../../../../../../redux/actions/globalActions'

import ListaOpciones from '../../../../../Shared/ListaOpciones'
import SubtitleHeader from '../../../../../Shared/SubtitleHeader'
import Options from './Options'

const HeaderListado = ({search, searchOpciones, searchBy, tabSelected, colegios, sortedColegios, colegioSelected, itemsLoaded, actions}) => {

  const handleTabSlected = (tab) => {
    if(!colegioSelected && tab!=='listado' ){
      actions.setNotification({ visibility: true, text: "Selecciona un colegio", status: "close", moment: Date.now() })
      return false
    }
    if(colegioSelected==='nuevo' && tab==='info' ){
      actions.setNotification({ visibility: true, text: "Agrupación de colegios sin registrar", status: "close", moment: Date.now() })
      return false
    }
    // if(colegioSelected==='nuevo' && tab==='examen' ){
    //   actions.setNotification({ visibility: true, text: "Este panel no está disponile para colegios sin registrar", status: "close", moment: Date.now() })
    //   return false
    // }
    if(Object.keys(colegios[colegioSelected].alumnos).length===0 && tab==="alumnos"){
      actions.setNotification({ visibility: true, text: "No se han inscrito alumnos en este colegio", status: "close", moment: Date.now() })
      return false
    }
    actions.setTabSelected(tab)
    
  }
  const handleInfo = () => {}
  const handleAlumnos = () => {}

  return(
    <div className='container-header-panels pr'>

      <div className='top-bar-panel'>
        <div className='container-search-panel pr'>
          <img className='icon-search-panel' src={searchIcon} alt=''/>
          <input placeholder='Buscar colegios por' value={search} onChange={(e)=>actions.changeHeader({tab:"listado", field:"search", value: e.target.value})}/>
          {search.trim()==='' && 
            <ListaOpciones opciones={searchOpciones} opcionSelected={searchBy} changeOpcion={ id => actions.changeHeader({tab:"listado", field:"searchBy", value: id})}/>
          }
        </div>
      </div>

      <p className='title-header'>
        <span>Colegios</span>
        {colegioSelected ? <i className="material-icons align-center color-gris">chevron_right</i>:""}
        {colegioSelected ? <span className='camelcase'>{colegios[colegioSelected].nombre}</span>:"" }
      </p>
      
      <SubtitleHeader text={`${itemsLoaded<sortedColegios.length?itemsLoaded:sortedColegios.length} de ${sortedColegios.length} colegios`} />
      <Options />

      <div className='barra-opciones-alumnos'>
        <div onClick={()=>handleTabSlected("listado")} className={`${tabSelected==='listado' && 'active-option'}`} >Listado</div>
        <div onClick={()=>handleTabSlected("info")} className={`${tabSelected==='info' && 'active-option'} ${ (!colegioSelected || colegioSelected==="nuevo") && 'disable-opciones-alumno' }`} >Información</div>
        <div onClick={()=>handleTabSlected("alumnos")} className={` ${tabSelected==='alumnos' && 'active-option'} ${ (!colegioSelected || Object.keys(colegios[colegioSelected].alumnos).length<=0 ) && 'disable-opciones-alumno'}`}>Alumnos</div>
        <div onClick={()=>handleTabSlected("examen")} className={` ${tabSelected==='examen' && 'active-option'} ${ (!colegioSelected /*|| colegioSelected==='nuevo'*/ ) ? 'disable-opciones-alumno':''}`}>Examen</div>
      </div>

    </div>
  )
}

const mapStateToProps = ({ colegios }) => {
  return {
    colegios: colegios.colegios,
    colegioSelected: colegios.colegioSelected,
    search: colegios.tabs.listado.header.search,
    searchOpciones: colegios.tabs.listado.header.searchOpciones,
    searchBy: colegios.tabs.listado.header.searchBy,
    itemsLoaded: colegios.tabs.listado.header.itemsLoaded,
    sortedColegios: colegios.sortedColegios,
    tabSelected: colegios.tabSelected,
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      changeHeader,
      setTabSelected,
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(HeaderListado);
