import React from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setTabHome } from '../../redux/actions/globalActions'
import { AccountBalanceRounded, PersonRounded, BallotRounded } from '@material-ui/icons'

const SideBar = ({ actions, tabHome }) => {
  return(
    <div className="menu-bar">
      <div className="container-icons-bar">
        <div onClick={()=>actions.setTabHome("colegios")} className={`${tabHome==="colegios"?"active":""}`}><AccountBalanceRounded/></div>
        <div onClick={()=>actions.setTabHome("alumnos")} className={`${tabHome==="alumnos"?"active":""}`}><PersonRounded/></div>
        <div onClick={()=>actions.setTabHome("resultados")} className={`${tabHome==="resultados"?"active":""}`}><BallotRounded/></div>
      </div>
    </div>
  )
}

function mapStateToProps({ global }) {
  return {
    tabHome: global.tabHome,
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setTabHome
    }, dispatch)	
	};
};

export default connect(mapStateToProps,matchDispatchToProps)(SideBar);
