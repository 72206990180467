import React, { memo } from 'react'
import searchIcon from '../../../../../../images/search.svg';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { changeHeader, setTabSelected } from '../../../../../../redux/actions/alumnosActions'
import { setNotification } from '../../../../../../redux/actions/globalActions'
import ListaOpciones from '../../../../../Shared/ListaOpciones'
import SubtitleHeader from '../../../../../Shared/SubtitleHeader'
import Options from './Options'


const HeaderListado = memo(({search, searchOpciones, searchBy, tabSelected, alumnos, alumnoSelected, itemsLoaded, sortedAlumnos, actions}) => {

  const handleTabSlected = (tab) => {

    if(!alumnoSelected && tab!=='listado' ){
      actions.setNotification({ visibility: true, text: "Selecciona un alumno", status: "close", moment: Date.now() })
      return false
    }

    if(tab==="cuestionario" && alumnos[alumnoSelected] && !alumnos[alumnoSelected].inscripcionAlumno.isValidCuestionario()){
      actions.setNotification({ visibility: true, text: "Este alumno no terminó el formulario", status: "close", moment: Date.now() })
      return false
    }

    actions.setTabSelected(tab)
    
  }
  const handleInfo = () => {}
  const handleAlumnos = () => {}

  return(
    <div className='container-header-panels pr'>

      <div className='top-bar-panel'>
        <div className='container-search-panel pr'>
          <img className='icon-search-panel' src={searchIcon} alt=''/>
          <input placeholder='Buscar colegios por' value={search} onChange={(e)=>actions.changeHeader({tab:"listado", field:"search", value: e.target.value})}/>
          {search.trim()==='' && 
            <ListaOpciones opciones={searchOpciones} opcionSelected={searchBy} changeOpcion={ id => actions.changeHeader({tab:"listado", field:"searchBy", value: id})}/>
          }
        </div>
      </div>

      <p className='title-header'>
        <span>Alumnos</span>
        {alumnoSelected ? <i className="material-icons align-center color-gris">chevron_right</i>:""}
        {alumnoSelected ? <span className='camelcase'>{alumnos[alumnoSelected].informacionAlumno.nombre.toLowerCase()} {alumnos[alumnoSelected].informacionAlumno.apellidos.toLowerCase()}</span>:"" }
      </p>

      <SubtitleHeader text={`${itemsLoaded<sortedAlumnos.length?itemsLoaded:sortedAlumnos.length} de ${sortedAlumnos.length} alumnos`} />
      <Options />

      <div className='barra-opciones-alumnos'>
        <div onClick={()=>handleTabSlected("listado")} className={`${tabSelected==='listado'?'active-option':''}`} >Listado</div>
        <div onClick={()=>handleTabSlected("perfil")} className={`${tabSelected==='perfil'?'active-option':''} ${ !alumnoSelected?'disable-opciones-alumno':'' }`} >Perfil</div>
        <div onClick={()=>handleTabSlected("beca")} className={` ${tabSelected==='beca'?'active-option':''} ${ !alumnoSelected?'disable-opciones-alumno':''}`}>
          {!alumnoSelected && 'Beca'}
          {alumnoSelected && `Beca ${alumnos[alumnoSelected].inscripcionAlumno.inscripcion.tipo}`}
        </div>
        <div onClick={()=>handleTabSlected("colegio")} className={`${tabSelected==='colegio'?'active-option':''} ${!alumnoSelected?'disable-opciones-alumno':''}`} >Colegio</div>
        <div onClick={()=>handleTabSlected("cuestionario")} className={`${tabSelected==='cuestionario'?'active-option':''} ${!alumnoSelected || !alumnos[alumnoSelected].inscripcionAlumno.isValidCuestionario()?'disable-opciones-alumno':''}`} >Cuestionario</div>
        <div onClick={()=>handleTabSlected("sat")} className={`${tabSelected==='sat'?'active-option':''} ${!alumnoSelected?'disable-opciones-alumno':''}`} >SAT</div>

      </div>

    </div>
  )
})

const mapStateToProps = ({ alumnos }) => {
  return {
    alumnos: alumnos.alumnos,
    alumnoSelected: alumnos.alumnoSelected,
    search: alumnos.tabs.listado.header.search,
    searchOpciones: alumnos.tabs.listado.header.searchOpciones,
    searchBy: alumnos.tabs.listado.header.searchBy,
    itemsLoaded: alumnos.tabs.listado.header.itemsLoaded,
    sortedAlumnos: alumnos.sortedAlumnos, 
    tabSelected: alumnos.tabSelected,
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      changeHeader,
      setTabSelected,
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(HeaderListado);
