import React, { Component } from 'react';
import CheckeItem from '../../AreaAlumno/Inscripcion/Shared/CheckItem'

class ListaOpciones extends Component {

  constructor(props){
      super(props);
      this.state={
        opciones: this.props.opciones
      }
  }

  componentWillMount = () => {document.addEventListener('mousedown',this.clickOutSide, false);}
  componentWillUnmount = () => {document.removeEventListener('mousedown',this.clickOutSide, false);}
  clickOutSide = (e) => {if(!this.node.contains(e.target)){this.closeListOpciones()}}
  closeListOpciones = () => {this.props.editOpciones(false);}

  callbackCheckItem = json => {

    if(json.value){this.props.opcionSelectedUpdate(json.id);
    }else{this.props.opcionSelectedUpdate('');}
    this.closeListOpciones()

  }

  render() {
    return (
      <div className="pa shadow lista_sedes" ref={node=>this.node=node} onClick={event => event.stopPropagation()}>
        <div className="width_100 display_inline_grid overflow_provincias">
          {
            Object.entries(this.state.opciones).map( ([k,m]) => {

              return (
                <CheckeItem key={k} hiddeCheckBox={true} listItem={true}  clss_div={'hvr_text_checkbox pointer'} clss_span={'span_checkbox_provincias'} callbackCheckItem={this.callbackCheckItem} json={{id:k}} checked={this.props.opcionSelected===k} text={m.texto}/>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default ListaOpciones;
