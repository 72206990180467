import React, { memo } from 'react'
import NotasSat from './NotasSat'
import ScrollContainer from '../../../../../Shared/ScrollContainer'
const Sat = ({alumno}) => {
  return(
    <ScrollContainer _class = {`container-table`}>
      <div className='container-informacion-alumno'>
        <NotasSat 
          alumno = {alumno}
          idAlumno = {alumno.id}
          matematicas = {alumno.inscripcionAlumno.sat?.notas?.matematicas ?? "0"}
          ingles = {alumno.inscripcionAlumno.sat?.notas?.ingles ?? "0"}
        />
      </div>
    </ScrollContainer>
  )
}

export default Sat