import React, { useState } from 'react'
import { connect } from "react-redux";
import DatosAlumno from './DatosAlumno';
import DatosTutor from './DatosTutor';
import DatosColegio from './DatosColegio';
import DatosAcademicos from './DatosAcademicos'
import OrdenPaises from './OrdenPaises'
import ErrorFormulario from '../../../Shared/ErrorFormulario'
import { isRepeatedDni, isRepeatedEmail, ocultarCargandoFormAlumnos, visualizarCargandoFormAlumnos } from '../../../../../../utils/functions'
const FaseComun = ({setPanelSelected, setPanelSelectedBack, currentAlumno, setUsuarioRepetido}) => {

  const [errors, setErrors] = useState({})
  const [showMensaje, setShowMensaje] = useState(false)

  const nextPanel = async() => {
    visualizarCargandoFormAlumnos({bottom: true})

    let errors = currentAlumno.checkFaseComun()
    setErrors(errors)
    
    if(Object.keys(errors).length>0){
      setShowMensaje(true)
      ocultarCargandoFormAlumnos()
      return false
    }

    const repeatedDni = await isRepeatedDni(currentAlumno.informacionAlumno.dni, currentAlumno.id)
    if(repeatedDni){
      setShowMensaje(true)
      ocultarCargandoFormAlumnos()
      setUsuarioRepetido()
      return false
    }
    
    if(!currentAlumno.isAuthenticated){
      const repeatedEmail = await isRepeatedEmail(currentAlumno.informacionAlumno.email)
      if(repeatedEmail){
        setShowMensaje(true)
        ocultarCargandoFormAlumnos()
        setUsuarioRepetido()
  
        return false
      }
    }
    

    setPanelSelected()
    ocultarCargandoFormAlumnos()
    
  }

  return(
    <div>
      <div className='title-panel-form-alumnos'>Fase común</div>

      <DatosAlumno 
        isAuthenticated={currentAlumno.isAuthenticated}
        dni={currentAlumno.informacionAlumno.dni}
        email={currentAlumno.informacionAlumno.email}
        password={currentAlumno.informacionAlumno.password}
        repetirPassword={currentAlumno.informacionAlumno.repetirPassword}
        nombre={currentAlumno.informacionAlumno.nombre}
        apellidos={currentAlumno.informacionAlumno.apellidos}
        telefono={currentAlumno.informacionAlumno.telefono}
        fechaNacimiento={currentAlumno.informacionAlumno.fechaNacimiento}
        sexo={currentAlumno.informacionAlumno.sexo}
        direccion={currentAlumno.informacionAlumno.direccion}
        piso={currentAlumno.informacionAlumno.piso}
        numero={currentAlumno.informacionAlumno.numero}
        letra={currentAlumno.informacionAlumno.letra}
        codigoPostal={currentAlumno.informacionAlumno.codigoPostal}
        ciudad={currentAlumno.informacionAlumno.ciudad}
        provincia={currentAlumno.informacionAlumno.provincia}
        errors={errors}
      />

      <DatosTutor
        nombre={currentAlumno.informacionTutor.nombre}
        apellidos={currentAlumno.informacionTutor.apellidos}
        email={currentAlumno.informacionTutor.email}
        telefono={currentAlumno.informacionTutor.telefono}
        errors={errors}

      />

      <DatosColegio 
        colegio={currentAlumno.inscripcionAlumno.datosColegio.colegio}
        colegioNombre={currentAlumno.inscripcionAlumno.datosColegio.colegio.nombre}
        tipoColegio={currentAlumno.inscripcionAlumno.datosColegio.colegio.tipoColegio}
        emailTutor={currentAlumno.inscripcionAlumno.datosColegio.colegio.emailTutor}
        sedeSat={currentAlumno.inscripcionAlumno.datosColegio.sedeSat}
        ciudad={currentAlumno.inscripcionAlumno.datosColegio.ciudad}
        errors={errors}

      />

      <DatosAcademicos 
        tipoInscripcion={currentAlumno.inscripcionAlumno.inscripcion.tipo}
        bachilleratoDual={currentAlumno.inscripcionAlumno.bachilleratoDual}
        cursoActual={currentAlumno.inscripcionAlumno.cursoActual.curso}
        mediaGlobal={currentAlumno.inscripcionAlumno.cursoAnterior.mediaGlobal}
        mediaMatematicas={currentAlumno.inscripcionAlumno.cursoAnterior.mediaMatematicas}
        mediaIngles={currentAlumno.inscripcionAlumno.cursoAnterior.mediaIngles}
        nivelIngles={currentAlumno.inscripcionAlumno.ingles.nivel}
        titulacionIngles={currentAlumno.inscripcionAlumno.ingles.titulacion}
        estudiarFueraSpain={currentAlumno.inscripcionAlumno.estudiarFueraSpain}
        tipoCarrera={currentAlumno.inscripcionAlumno.tipoCarrera}
        errors={errors}

      />

      <OrdenPaises 
        preferenciaPaises={currentAlumno.inscripcionAlumno.preferenciaPaises}
      />
      

      <div><strong>Una vez rellenes este campo, tienes que ir a la siguiente página y presionar 'GUARDAR' para que los cambios se guarden</strong></div>


      {showMensaje &&
        <>
          <br/>
          <ErrorFormulario updateError={()=>setShowMensaje(false)} />
        </>
      }

      <div className='direccions_panel'>
        <div className='btn_send_form pointer noselect float_atras btn-volver-form' onClick={setPanelSelectedBack}>Atrás</div>
        <div className='btn_send_form pointer noselect float_siguiente' onClick={nextPanel}>Siguiente</div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ alumnos }) => {
  return {
    currentAlumno: alumnos.currentAlumno,
  };
}

export default connect(mapStateToProps, null)(FaseComun);