import React, { memo } from 'react'

const Switch = memo(({value = false, json, class_div, callbackSwitch, block=false}) => {
  const updateChekbox = () => {
    if(block) return false
    if(!callbackSwitch) return false
    json.value = !value;
    callbackSwitch(json);
  }
  return(
    <div className={`div_switch ${class_div?class_div:''}`} >
      <label ckecked={(value).toString()} onClick={ event => updateChekbox(event.currentTarget)} className="switch label_switch">
        <span className={`slider round ${value?'active_switch':''}`}></span>
      </label>
    </div>
  )
})

export default Switch