import React, { useState, useLayoutEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { setCurrentAlumno, getAlumno, getAlumnoInscripcion, modifyCurrentAlumno } from '../../../redux/actions/alumnosActions'
import { getColegio } from '../../../redux/actions/colegiosActions'
import { checkPermissions } from './PrivateUtils';
import { isLogged } from '../../../utils/functions'

import User from '../../../models/User';

import { db, signOut } from '../../../utils/firebase';
import { pathSigninAlumno } from '../../../utils/constans';

const PrivateRouteExam = ({ component: Component, currentAlumno, access, actions, location, ...rest}) => {
  const [loading, setLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState({pathname:pathSigninAlumno, state: { redirect: location.pathname }})
  
  useLayoutEffect(() => {
    if(!currentAlumno.isAuthenticated){
      isLogged()
      .then(({email})=>{
        return new Promise( (resolve, reject) => {
          db.child('Alumnos').orderByChild(`informacionAlumno/email`).equalTo(email).limitToLast(1).once("value", snapshot => {
            if(!snapshot.exists()) return reject({code: 3023, msg:'Existe email pero no existe alumno, por lo que quiere decir que se ha logeado siendo un empleado'})
            const alumno = Object.entries(snapshot.val())[0][1]
            actions.setCurrentAlumno({...alumno, id: Object.entries(snapshot.val())[0][0] })
            resolve()
          })
        })
      })
      .then(()=>{
        return new Promise( async (resolve, reject) => {
          try {
            await actions.getAlumno()
            const inscripcionAlumno = await actions.getAlumnoInscripcion()
            //if(inscripcionAlumno.datosColegio.colegio.id!=="nuevo"){
              await actions.getColegio({idColegio: inscripcionAlumno.datosColegio.colegio.id})
            //}
            actions.modifyCurrentAlumno({path:'isAuthenticated', value: true})
            return resolve()
          } catch (error) {
            return reject({code:5673, msg:'Error en las peticiones iniciales'})
          }
        })
      })
      .catch(err => {
        if(err?.code===3023){
          signOut()
          setLoading(false);
        }else if(err?.code===5673){
          setRedirectTo({pathname:'/403'})
          setLoading(false);
        }else{
          setLoading(false);
        }
      })
    }
    if (currentAlumno.isAuthenticated) setLoading(false);
  }, [currentAlumno.isAuthenticated]);

    

  return (
    <Route
      {...rest}
      render={props =>
        loading ?
          <div>Loading...</div>
        : currentAlumno.isAuthenticated /* && checkPermissions(currentAlumno.roles, access) */ ? 
            <Component {...props} />
          :
            <Redirect to={redirectTo} />
      }
    />
  );
} 

const mapStateToProps = ({ alumnos }) => ({
  currentAlumno: alumnos.currentAlumno
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setCurrentAlumno,
      modifyCurrentAlumno,
      getAlumno,
      getAlumnoInscripcion,
      getColegio
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(PrivateRouteExam);