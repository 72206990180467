import React from 'react';
import DateCountdown from 'react-date-countdown-timer';
import { localesDateCountdown, localespluralDateCountdown } from '../../../utils/constans'

const PanelVideo = ({time, setPanelExamen}) => {
  return(
    <div className="panel-video all-screen ">

      <div className="contador time-video">
        <DateCountdown dateTo={time.toString()} callback={()=>setPanelExamen()} mostSignificantFigure="min" locales={localesDateCountdown} locales_plural={localespluralDateCountdown}/>
      </div>
      <div class="video-responsive">
        <iframe class="img-responsive"  width="100%" height="" src="https://www.youtube.com/embed/IX5109CDlGM?autoplay=1&mute=0&enablejsapi=1&rel=0&control=0" frameborder="0" allowfullscreen></iframe>
      </div>

      
    </div>
  )
}

export default PanelVideo