import { alumnosTypes } from '../actions/alumnosActions';
import { filtrosAlumnosListado, addItemsLoaded } from '../../utils/constans'
import dotProp from 'dot-prop-immutable';
import Alumno from '../../models/Alumno'

var initialState = {
  alumnos: {},
  alumnoSelected: null, 
  sortedAlumnos: [],
  tabSelected: "listado",
  currentAlumno: new Alumno(),
  tabs: {
    listado: {
      header: {
        search: "",
        searchBy: "nombreCompleto",
        searchOpciones: {
          'dni':{valor:'dni'},
          'nombreCompleto':{valor:'nombre'}
        },
        sortBy: "nombre",
        des: false,
        itemsLoaded: addItemsLoaded,
        filters: filtrosAlumnosListado,
        views:{
          'general':{valor:'General'},
          'deportiva':{valor:'Deportiva'},
        },
        viewSelected: "general"
      }
    }
  }
}

export default function (state = initialState, action) {
  switch (action.type) {

    case alumnosTypes.SET_ALUMNOS:{
        return{ ...state, alumnos: action.payload }
    }
    case alumnosTypes.CHANGE_HEADER:{
      const { tab, field, value, des} = action.payload
      let newState = dotProp.set(state, `tabs.${tab}.header.${field}`, value);
      if(field==="sortBy"){
        newState = dotProp.set(newState, `tabs.${tab}.header.des`, des);
      }
      return newState
    }
    case alumnosTypes.SET_TAB_SELECTED:{
      return dotProp.set(state, `tabSelected`, action.payload);
    }
    case alumnosTypes.SET_SORTED_DATA:{
      return dotProp.set(state, `sortedAlumnos`, action.payload);
    }
    case alumnosTypes.SET_ALUMNO_SELECTED:{
      return dotProp.set(state, `alumnoSelected`, action.payload);
    }
    case alumnosTypes.SET_CURRENT_ALUMNO:{
      return {...state, currentAlumno: new Alumno({...action.payload}) }
    }
    // case alumnosTypes.MODIFY_CURRENT_ALUMNO:{
    //   return {...state, currentAlumno: new Alumno({...state.currentAlumno, ...action.payload}) }
    // }
    case alumnosTypes.MODIFY_CURRENT_ALUMNO:{
      const { path, value } = action.payload
      let currentAlumno = dotProp.set(state.currentAlumno, `${path}`, value)
      return {...state, currentAlumno: new Alumno(currentAlumno)}
    }
    case alumnosTypes.MODIFY_CURRENT_ALUMNO_EXAMEN:{
      const { path, value } = action.payload
      return dotProp.set(state, `currentAlumno.inscripcionAlumno.sat.examen.${path}`, value);
    }
    default:{
      return state;
    }
  }
}