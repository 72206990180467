import React from 'react'
import { localesDateCountdown, localespluralDateCountdown } from '../../../utils/constans'


const LoadingExamen = ({time, setPanelExamen}) => {
  return(
    <div className="panel-ingles all-screen">
      <div className="resumen-preguntas">
        <div>
          <h1 className="text-resumen loading-text">Cargando examen</h1>
        </div>
      </div>

    </div>
  )
}

export default LoadingExamen