import React, { useState, useEffect } from 'react'
import { validarDni, onFocusInscripciones, onBlurInscripciones, validarTelefono, validFechaNacimiento } from '../../../../../../utils/functions'
import { opcionesSexo } from '../../../../../../utils/constans'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../../redux/actions/alumnosActions';
import ListaOpciones from '../../../Shared/ListaOpciones'
const DatosTutor = (props) => {
  const { errors } = props

  return(
    <div>
      <h3 className="title_form"><span className="fw_thin">Datos del</span> padre / madre / tutor</h3>

      <div className="pr width_100 colums_form_2">
        {/*NOMBRE DEL Tutor*/}
        <div className="pr width_100 colums_form_2_margin_right">
          <input onFocus={e => onFocusInscripciones(e)} onBlur={e => onBlurInscripciones(e)} className={`input_form ${errors.nombreTutor ? 'obligatorio' : ''}`} value={props.nombre} onChange={e => props.actions.modifyCurrentAlumno({value:e.target.value,path:'informacionTutor.nombre'})} />
          <label className="label_form">Nombre</label>
        </div>

        {/*APELLIDO DEL Tutor*/}
        <div className="pr width_100 colums_form_2_margin_left">
          <input onFocus={e => onFocusInscripciones(e)} onBlur={e => onBlurInscripciones(e)} className={`input_form ${errors.apellidosTutor ? 'obligatorio' : ''}`} value={props.apellidos} onChange={e => props.actions.modifyCurrentAlumno({value:e.target.value,path:'informacionTutor.apellidos'})} />
          <label className="label_form">Apellidos</label>
        </div>
      </div>


      <div className="pr width_100 colums_form_2">
        {/*EAMIL*/}
        <div className="pr width_100 colums_form_2_margin_right">
          <input onFocus={e => onFocusInscripciones(e)} onBlur={e => onBlurInscripciones(e)} className={`input_form ${errors.emailTutor ? 'obligatorio' : ''}`} value={props.email} onChange={e => !props.isAuthenticated?props.actions.modifyCurrentAlumno({value:e.target.value, path:'informacionTutor.email'}): null } />
          <label className="label_form">Email</label>
        </div>

        {/*TELEFONO*/}
        <div className="pr width_100 colums_form_2_margin_left">
          <input onFocus={e => onFocusInscripciones(e)} onBlur={e => onBlurInscripciones(e)} className={`input_form ${errors.telefonoTutor ? 'obligatorio' : ''}`} value={props.telefono} 
            onChange={e => props.actions.modifyCurrentAlumno({value: validarTelefono(e.target.value) ,path:'informacionTutor.telefono'}) } />
          <label className="label_form">Teléfono</label>
        </div>
      </div>

    </div>
  )
}



const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(DatosTutor);