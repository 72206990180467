import React, { useState } from 'react'
import { opcionesDeporte, opcionesCategoriaDeporte, opcionesFederado } from '../../../../../../utils/constans'
import ListaOpciones from '../../../Shared/ListaOpciones'
import { onFocusInscripciones, onBlurInscripciones } from '../../../../../../utils/functions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../../redux/actions/alumnosActions';

const DatosDeporte = (props) => {

  const { errors } = props

  const [showOptionsDeporte, setShowOptionsDeporte] = useState(false)
  const [showOptionsCategoria, setShowOptionsCategoria] = useState(false)
  const [showOptionsFederado, setShowOptionsFederado] = useState(false)

  return(
    <div>

      <div className="pr width_100 colums_form_2">

        {/*DEPORTE*/}
        <div className={`pr pointer select_form text-large-forms colums_form_2_margin_right ${errors.deporte ? 'obligatorio':''}`} onClick={()=>setShowOptionsDeporte(true)}>
          <div className="item_selected">{props.deporte}</div>
          <label className="label_form pointer">Deporte</label>
          {showOptionsDeporte &&
            <ListaOpciones 
              editOpciones={(value) => setShowOptionsDeporte(value)} 
              opciones={opcionesDeporte}
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.inscripcion.beca.deportiva.deporte'}) } 
              opcionSelected={props.deporte}
            />}
        </div>
        {/*CLUB*/}
        <div className="pr width_100 colums_form_2_margin_left">
          <input onFocus={e => onFocusInscripciones(e)} onBlur={e => onBlurInscripciones(e)} className={`input_form ${errors.club ? 'obligatorio' : ''}`} value={props.club} 
            onChange={e => {
              props.actions.modifyCurrentAlumno({value:e.target.value, path:'inscripcionAlumno.inscripcion.beca.deportiva.club'})
            }} />
          <label className="label_form">Club</label>
        </div>

      </div>


      <div className="pr width_100 colums_form_2">

        {/*Categoria*/}
        <div className={`pr pointer select_form colums_form_2_margin_right ${errors.categoria ?'obligatorio':''}`} onClick={()=>setShowOptionsCategoria(true)}>
          <div className="item_selected">{props.categoria}</div>
          <label className="label_form pointer">Categoría</label>
          {showOptionsCategoria &&
            <ListaOpciones 
              editOpciones={(value) => setShowOptionsCategoria(value)} 
              opciones={opcionesCategoriaDeporte}
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.inscripcion.beca.deportiva.categoria'}) } 
              opcionSelected={props.categoria}
            />}
        </div>

        {/*FEDERADO*/}
        <div className={`pr pointer select_form colums_form_2_margin_left ${errors.federado?'obligatorio':''}`} onClick={()=>setShowOptionsFederado(true)}>
          <div className="item_selected">{props.federado}</div>
          <label className="label_form pointer">¿Estás federado?</label>
          {showOptionsFederado &&
            <ListaOpciones 
              editOpciones={(value) => setShowOptionsFederado(value)} 
              opciones={opcionesFederado}
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value,path:'inscripcionAlumno.inscripcion.beca.deportiva.federado'}) } 
              opcionSelected={props.federado}
            />}
        </div>

      </div>  

    </div>
  )
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(DatosDeporte);
