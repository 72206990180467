import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/Shared/Private/PrivateRoute';
import PrivateRouteAlumno from './components/Shared/Private/PrivateRouteAlumno'
import Layout from './components/Layout/Layout';

import Home from './components/Home/Home'
import SignIn from './components/SignIn/SignInCrm'
import SignInAlumno from './components/SignIn/Alumnos/SignIn'
import Forbidden from './components/Forbidden/Forbidden'
import NotFound from './components/NotFound/NotFound'
import AreaAlumno from './components/AreaAlumno/AreaAlumno'
import ExamenSat from './components/ExamenSat/ExamenSat'
import Oauth2 from './components/Shared/Oauth2'
import { ALL, ADMIN } from './utils/roleList'
import { pathForbidden, pathHome, pathSignin, pathSigninAlumno, pathExamen, pathAreaAlumno, pathFormularioNewAlumno, pathFormularioModifyAlumno, pathFormularioNewColegio } from './utils/constans'
import InscripcionAlumno from './components/AreaAlumno/Inscripcion/Inscripcion';
import InscripcionColegio from './components/AreaColegio/Inscripcion/Inscripcion';

const Routes = () => {
  return(
    <Router>
      <Layout>
        <Switch>

          <PrivateRoute exact path={pathHome} component={Home} access={ALL} redirect={pathHome} />
          <Route exact path={pathSignin} component={SignIn} />

          <PrivateRouteAlumno exact path={pathAreaAlumno} component={AreaAlumno} /* redirect={PATH_EXAM} *//>
          <PrivateRouteAlumno exact path={pathExamen} component={ExamenSat} /* redirect={"itself"} *//>
          <Route exact path={pathSigninAlumno} component={SignInAlumno} />

          <Route exact path={pathFormularioNewAlumno} component={InscripcionAlumno} />
          <PrivateRouteAlumno exact path={pathFormularioModifyAlumno} component={InscripcionAlumno} />

          <Route exact path={pathFormularioNewColegio} component={InscripcionColegio} />
          

          
          <Route exact path={pathForbidden} component={Forbidden} />
          <Route path="*" component={NotFound} />

        </Switch>
      </Layout>
    </Router>
  )
}

export default Routes