import React from 'react'
import w2aLogo from '../../images/logos/w2aLogo.png'
import volvoLogo from '../../images/logos/volvoLogo.png'
import backinterLogo from '../../images/logos/backinterLogo.bmp'
import endesaLogo from '../../images/logos/endesaLogo.png'
const LogosContainer = () => {
  return(
    <div className="container-logos">

      <div className={`item-logo`}>
        <img src={w2aLogo}/>
      </div>

      <div className={`item-logo`}>
        <img src={endesaLogo}/>
      </div>

      <div className={`item-logo`}>
        <img src={volvoLogo}/>
      </div>

      <div className={`item-logo`}>
        <img src={backinterLogo}/>
      </div>

      


    </div>
  )
}

export default LogosContainer