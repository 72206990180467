import React from 'react'
import HeaderListado from './Headers/HeaderListado/HeaderListado'
import PanelListado from './Panels/PanelListado/PanelListado'
import PanelInformacion from './Panels/PanelInformacion/PanelInformacion'
import PanelAlumnos from './Panels/PanelAlumnos/PanelAlumnos'
import PanelExamen from './Panels/PanelExamen/PanelExamen'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

const Colegios = ({tabSelected, colegios, colegioSelected}) => {
  return(
    <div className={`panel-colegios`} >
      <HeaderListado />
      {tabSelected==="listado" && <PanelListado />}
      {tabSelected==="info" && colegios[colegioSelected] && <PanelInformacion colegio={colegios[colegioSelected]} />}
      {tabSelected==="alumnos" && colegios[colegioSelected] && <PanelAlumnos colegio={colegios[colegioSelected]} />}
      {tabSelected==="examen" && colegios[colegioSelected] && <PanelExamen colegio={colegios[colegioSelected]} />}
    
    </div>
  )
}

const mapStateToProps = ({ colegios }) => {
  return {
    tabSelected: colegios.tabSelected,
    colegios: colegios.colegios,
    colegioSelected: colegios.colegioSelected
  };
}

export default connect(mapStateToProps)(Colegios);
