import React from 'react'

const InicioCuestionario = ({initForm}) => {
  return(
    <div className="panel-view-info">
      <div className="shadow pop-up-cuestionario">
        <div className="text-explicativo-panel">
          A partir de aquí empiezas la sección de orientación académica.
          <br />
          <br />
          Responderás una serie de preguntas que sirven para orientarte
          sobre tu elección de tipo de carrera universitaria.
          <br />
          <br />
          Estas preguntas no suman en la puntuación final del Informe,
          simplemente sirven de guía por lo que intenta responder de la
          manera más honesta posible.
          <br />
          <br />
          ¡Mucha suerte!
          <br />
          <br />
        </div>
        <div
          className="btn_send_form pointer noselect"
          onClick={initForm}
        >
          Aceptar
        </div>
      </div>
    </div>
  )
}

export default InicioCuestionario