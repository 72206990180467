import React, { memo } from 'react'
import { isNumber, isEmail, isTelefono } from '../../utils/functions'
const Input = memo(({title, type, _class, _class_container, _class_input, text = "", changeValue}) => {

  const changeFloat = text => {
    if(isNumber(text)){
      changeValue(text.trim())
    }
  }

  const changeEmail = text => {
    //if(isEmail(text)){
      changeValue(text)
    //}
  }
  const changePhone = text => {
    if(isTelefono(text)){
      changeValue(text)
    }
  }

  return(
    <div className={`container-simple-input ${_class?_class:''}`}>
      <div className='title-input'>{title}:</div>
      <div className={`container-input ${_class_container?_class_container:''}`}>

        {!type &&
          <input className={` ${_class_input?_class_input:''}`} value={text?text:''} onChange={e=>changeValue(e.target.value)}/>
        }

        {type && type==='float' &&
          <input className={` ${_class_input?_class_input:''}`} value={text?text:''} onChange={e=>changeFloat(e.target.value)}/>
        }

        {type && type==='email' &&
          <input className={` ${_class_input?_class_input:''}`} value={text?text:''} onChange={e=>changeEmail(e.target.value)}/>
        }

        {type && type==='phone' &&
          <input className={` ${_class_input?_class_input:''}`} value={text?text:''} onChange={e=>changePhone(e.target.value)}/>
        }

        {type && type==='block' &&
          <input className={` ${_class_input?_class_input:''}`} value={text?text:''} readOnly={true}/>
        }

      </div>
    </div>
  )
})

export default Input