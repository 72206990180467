import React, { useState, useEffect, memo } from 'react'
import Input from '../../../../../Shared/Input'
import InputMenu from '../../../../../Shared/InputMenu'
import Switch from '../../../../../Shared/Switch'
import UpdateStateInputs from '../../../../../Shared/UpdateStateInputs'
import { isEmail, isTelefono, isValidFechaNacimiento, cleanDNI, isCorrectDni, validFecha } from '../../../../../../utils/functions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../utils/firebase'
import _ from 'underscore'
import { genderOptions } from '../../../../../../utils/constans'

const InformacionDireccion = memo((props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  const saveData = () => {

    if(!state.direccion.trim() || !state.piso.trim() || !state.numero.trim() || !state.letra.trim() || !state.codigoPostal.trim() || !state.ciudad.trim() || !state.provincia.trim() ){
      props.actions.setNotification({ visibility: true, text: "Exiten errores en los campos", status: "close", moment: Date.now() })
      return false;
    }

    var multiPath = {};
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/direccion`] = state.direccion;
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/piso`] = state.piso;
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/numero`] = state.numero;
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/letra`] = state.letra;
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/codigoPostal`] = state.codigoPostal;
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/ciudad`] = state.ciudad;
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/provincia`] = state.provincia;
    db.update(multiPath)
    .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  }
  

  return(
    <div className='sub-container-informacion-alumno'>

      {edited? <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> :null }

      <p className='title-informacion-alumno'>2. Dirección del alumno</p>

      <div className='col-2-input'>
        <Input _class_container={!state.direccion.trim()?'error-form-input':null} title='Dirección' text={state.direccion} changeValue={direccion=>setState({...state, direccion})}/>
      </div>

      <div className='col-2-input'>
        <Input title='Piso' _class_container={!state.piso.trim()?'error-form-input':null} text={state.piso} changeValue={piso=>setState({...state, piso})}/>
        <Input title='Número' _class_container={!state.numero.trim()?'error-form-input':null} text={state.numero} changeValue={numero=>setState({...state, numero})}/>
      </div>

      <div className='col-2-input'>
        <Input title='Letra' _class_container={!state.letra.trim()?'error-form-input':null} text={state.letra} changeValue={letra=>setState({...state, letra})}/>
        <Input title='Código Postal'_class_container={!state.codigoPostal.trim()?'error-form-input':null} text={state.codigoPostal} changeValue={codigoPostal=>setState({...state, codigoPostal})}/>
      </div>

      <div className='col-2-input'>
        <Input title='Ciudad' _class_container={!state.ciudad.trim()?'error-form-input':null} text={state.ciudad} changeValue={ciudad=>setState({...state, ciudad})}/>
        <Input title='Provincia'_class_container={!state.provincia.trim()?'error-form-input':null} text={state.provincia} changeValue={provincia=>setState({...state, provincia})}/>
      </div>
    </div>
  )
})

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(InformacionDireccion);