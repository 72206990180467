import React from 'react'
import ScrollContainer from '../../../../../Shared/ScrollContainer'
import { connect } from "react-redux";
import Switch from '../../../../../Shared/Switch'
import { bindActionCreators } from 'redux';
import { setTabHome } from '../../../../../../redux/actions/globalActions'
import { setAlumnoSelected, setTabSelected } from '../../../../../../redux/actions/alumnosActions'




const PanelAlumnos = ({colegio, alumnos, alumnoSelected, actions}) => {
  const selectAlumno = (alumno,tab) => {
    actions.setTabHome('alumnos')
    actions.setAlumnoSelected(alumno.id)
    actions.setTabSelected(tab)
  }
  return(
    <ScrollContainer _class={`container-table`}>
      <div>
        <table id='table-alumnos'>
          <thead>
            <tr>
              <th>DNI</th>
              <th>Nombre</th>
              <th>Inscripción</th>
              <th>Cuestionario</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {
            Object.entries(colegio.alumnos).map(([idAlumno, dni])=>{
              var alumno = alumnos[idAlumno];
              if(!alumno) return null
              return(
                <tr key={idAlumno} data-id={idAlumno} className={`${alumnoSelected===alumno.id?'active-row-table':''}`}>

                  <td>
                    <div className='edit-container'>
                      <span className='width_100'> {alumno.informacionAlumno.dni} </span>
                    </div>
                  </td>

                  <td>
                    <div className='edit-container'>
                      <span className='width_100 camelcase'>{alumno.informacionAlumno.nombre.toLowerCase()} {alumno.informacionAlumno.apellidos.toLowerCase()}</span>
                    </div>
                  </td>

                  <td onClick={()=>{selectAlumno(alumno,'beca')}}>
                    <div className='edit-container'>
                      <span className='width_100'> {alumno.inscripcionAlumno.inscripcion.tipo} </span>
                    </div>
                  </td>

                  <td>
                    <div className='align-center'>
                      <Switch class_div={'switch-table'} value={alumno.inscripcionAlumno.isValidCuestionario()}/>
                    </div>

                  </td>

                  <td onClick={()=>{selectAlumno(alumno,'perfil')}}>
                    <i className="material-icons align-center">chevron_right</i>
                  </td>

                </tr>
              )
            })
          }

          </tbody>
        </table>
      </div>
    </ScrollContainer>
  )
}

const mapStateToProps = ({ alumnos }) => {
  return {
    alumnos: alumnos.alumnos,
    alumnoSelected: alumnos.alumnoSelected
  };
}
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setTabHome,
      setAlumnoSelected,
      setTabSelected
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(PanelAlumnos);
