import React, { useLayoutEffect, memo, useRef } from 'react';

const FiltersList = memo(({filters, onClose, updateFilters}) => {

  const refElement = useRef()
  const clickOutSide = ({target}) => !refElement.current?.contains(target) && onClose()
  useLayoutEffect(()=>{
    document.addEventListener('mousedown',clickOutSide, false)
    return () =>document.removeEventListener('mousedown',clickOutSide, false)
  },[])

  const updateCheckBox = (idParent, idChild, value)=>{
    if(idChild==='todos'){
      filters[idParent].todos.checked = value;
      Object.entries(filters[idParent].items).forEach(([k,o])=>{ o.checked = value; })
    }else{
      filters[idParent].items[idChild].checked = value
      //si existe alguno desmarcado, la casilla de todos la desmarcaremos tambien
      var all = !Object.entries(filters[idParent].items).some(([k,f])=>f.checked === false)
      if(filters[idParent].todos){
        filters[idParent].todos.checked = all
      }
    }
    updateFilters(filters)
  }

  const updateRadioButton = (idParent, idChild, value)=>{
    if(idChild==='todos'){
      filters[idParent].todos.checked = value;
      Object.entries(filters[idParent].items).forEach(([k,i])=>{ i.checked = false; })
    }else{
      filters[idParent].items[idChild].checked = value
      if(filters[idParent].todos){ filters[idParent].todos.checked=false; }
      Object.entries(filters[idParent].items).forEach(([k,i])=>{ if(idChild!==k) i.checked = false; })
    }
    updateFilters(filters)
  }

  return(
    <div className='pop-up-lista-filtros' onClick={e=>e.stopPropagation()} ref={refElement}>
      {Object.entries(filters).map(([k,i])=>{
        if(i.hidden)return false
        return(
          <div key={k} className='container-list-filter'>
            <div className='title-option-filter'>{i.title}</div>

            {i.type==='checkbox' && 
              <div>
                {i.todos && <Checkbox text={i.todos.text} checked={i.todos.checked} changeValue={value=>updateCheckBox(k,'todos',value)}/> }
                {Object.entries(i.items).map(([k2,i2])=>
                  <Checkbox key={k2} text={i2.text} checked={i2.checked} changeValue={value=>updateCheckBox(k,k2,value)}/>
                )}
              </div>
            }

            {i.type==='radiobutton' &&
              <div>
                {i.todos && <RadioButton text={i.todos.text} checked={i.todos.checked} changeValue={value=>updateRadioButton(k,'todos',value)}/> }
                {Object.entries(i.items).map(([k2,i2])=>
                  <RadioButton key={k2} text={i2.text} checked={i2.checked} changeValue={value=>updateRadioButton(k,k2,value)}/>
                )}
              </div>
            }

          </div>
        )
      })}
    </div>
  )
})

const Checkbox = memo( ({ checked, changeValue, text}) => {
  return(
    <div className='container-item-check-box' onClick={()=>changeValue(!checked)}>
      <div> <div className={`checkbox-container-w2a checkbox-w2a ${checked?'checkbox-w2a-active':''}`} ></div> </div>
      <span>{text}</span>
    </div>
  )
})

const RadioButton = memo( ({ checked, changeValue, text}) => {
  return(
    <div className='container-item-check-box' onClick={()=>changeValue(true)}>
      <div> <div className={`checkbox-container-w2a radiobutton-w2a ${checked && 'radiobutton-w2a-active'}`} ></div> </div>
      <span>{text}</span>
    </div>
  )
})

export default FiltersList;

