import React, { memo } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Switch from '../../../../../../Shared/Switch'
import { setAlumnoSelected, setTabSelected } from '../../../../../../../redux/actions/alumnosActions'
import { provincias } from '../../../../../../../utils/constans'

const ItemLista = memo(({alumno, alumnoSelected, actions}) => {

  const selectAlumno = (tab) => {
    actions.setAlumnoSelected(alumno.id)
    actions.setTabSelected(tab)
  }
  
  
  return(
    <tr data-id={alumno.id} className={`${alumnoSelected===alumno.id?'active-row-table':''}`}>

      <td>
        <div className='edit-container'> <div className='width_100 break_sentence'>{alumno.informacionAlumno.dni}</div> </div>
      </td>

      <td>
        <div className='edit-container'> <span className='width_100 camelcase'>{alumno.informacionAlumno.nombre.toLowerCase()} {alumno.informacionAlumno.apellidos.toLowerCase()}</span> </div>
      </td>

        <td onClick={()=>selectAlumno("beca")}>
          <div className='edit-container'> <span className='width_100'>{alumno.inscripcionAlumno.inscripcion.tipo}</span> </div>
        </td>

        <td>
          <div className='align-center'> <Switch class_div={'switch-table'} value={alumno.inscripcionAlumno.isValidCuestionario()}/> </div>
        </td>

        <td>
          <div className='align-center'> <Switch class_div={'switch-table'} value={alumno.inscripcionAlumno.endesa}/> </div>
        </td>

        <td>
          <div className='align-center'> <Switch class_div={'switch-table'} value={alumno.inscripcionAlumno.isSatDone()}/> </div>
        </td>

        <td className='next-view' onClick={()=>selectAlumno("perfil")}>
          <i className="material-icons align-center">chevron_right</i>
        </td>


    </tr>

  )
})

const mapStateToProps = ({ alumnos }) => {
  return {
    alumnoSelected: alumnos.alumnoSelected,
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setAlumnoSelected,
      setTabSelected
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(ItemLista);