import { db } from '../../utils/firebase'
import { provincias, addItemsLoaded } from '../../utils/constans'
import { cleanText } from '../../utils/functions'
import Colegio from '../../models/Colegio'

export const colegiosTypes = {
  SET_COLEGIOS: "COLEGIOS_SET_COLEGIOS",
  CHANGE_HEADER: "COLEGIOS_CHANGE_HEADER",
  SET_TAB_SELECTED: "COLEGIOS_SET_TAB_SELECTED",
  SET_SORTED_DATA: "COLEGIOS_SET_SORTED_DATA",
  SET_COLEGIO_SELECTED: "COLEGIOS_SET_COLEGIO_SELECTED",
  SET_CURRENT_COLEGIO: "COLEGIOS_SET_CURRENT_COLEGIO",
  MODIFY_CURRENT_COLEGIO: "COLEGIOS_MODIFY_CURRENT_COLEGIO"
}
export const setColegios = (payload) => {
  return { type: colegiosTypes.SET_COLEGIOS, payload };
}
export function setCurrentColegio(payload) {
  return { type: colegiosTypes.SET_CURRENT_COLEGIO, payload };
}
export const setNewData = (payload) => {
  return { type: colegiosTypes.CHANGE_HEADER, payload };
}

export const changeHeader = (payload) => {
  return async (dispatch, getState) => {
    return new Promise( (resolve, reject) => {
      dispatch(setNewData(payload))
      if(!payload.skipSort)
        dispatch(sortData())
    });
  }
};

export const setColegioSelected = (payload) => {
  return { type: colegiosTypes.SET_COLEGIO_SELECTED, payload };
}
export const setTabSelected = (payload) => {
  return { type: colegiosTypes.SET_TAB_SELECTED, payload };
}



export const getColegios = ({sort}) => {
  return async (dispatch, getState) => {
    return new Promise( (resolve, reject) => {
      let { global } = getState()
      //let multiPath = {}
      db.child("Colegios").orderByChild(`inscripciones/${global.inscripcion}`).equalTo(true).on("value", snapshot => {
        let { colegios:colegiosStore } = getState()
        let colegios = { 
          //nuevo: new Colegio({id: "nuevo", nombre:'Colegios no registrados'}) 
        };
        snapshot.forEach( colegio => {
          colegios[colegio.key] = new Colegio({...colegio.val(), alumnos: colegiosStore.colegios[colegio.key]?.alumnos });
          //multiPath[`Colegios/${colegio.key}/inscripciones/2020-2021`] = true;
        });
        //console.log("colegios", colegios)
        //console.log("multiPath", multiPath)
        //db.update(multiPath)

        dispatch(setColegios(colegios))
        if(sort){
          dispatch(sortData())
        }
        return resolve()
      })
    });
  }
};

export const getColegio = ({idColegio}) => {
  return async (dispatch, getState) => {
    return new Promise( (resolve, reject) => {
      db.child(`Colegios/${idColegio}`).on("value", snapshot => {
        if(!snapshot.exists()) return reject('No existe colegio con este id')
        dispatch(setCurrentColegio(snapshot.val()))
        return resolve()
      })
    });
  }
};


export const downloadColegios = () => {
  return async (dispatch, getState) => {
    return new Promise( (resolve, reject) => {
      let { colegios, alumnos } = getState()
      colegios = colegios.colegios
      alumnos = alumnos.alumnos
      let result = { colegios: [], colegiosNuevos: [] }
      try {
        //colegios
        Object.entries(colegios).forEach( ([k, colegio]) =>{
          if(k==="nuevo")return false
          result = {
            ...result,
            colegios: [
              ...result.colegios,
              {
                'CODIGO':colegio.id,
                'COLEGIO': colegio.nombre,
                'ALUMNOS': Object.keys(colegio.alumnos).length,
                'PROVINCIA': provincias[colegio.provincia].texto ,
      
                'NOMBRE RES.1':`${colegio.responsables[0].nombre} ${colegio.responsables[0].apellidos}`,
                'EMAIL RES.1':`${colegio.responsables[0].email}`,
                'TELÉFONO MOVIL RES.1':`${colegio.responsables[0].telefonoMovil}`,
                'TELEFONO FIJO RES.1':`${colegio.responsables[0].telefonoFijo}`,
                'CARGO RES.1':`${colegio.responsables[0].cargo}`,
                'HORARIO PREFERENTE LLAMADAS RES.1':`${colegio.responsables[0].horarioPreferente}`,
      
                'NOMBRE RES.2':`${colegio.responsables[1].nombre} ${colegio.responsables[1].apellidos}`,
                'EMAIL RES.2':`${colegio.responsables[1].email}`,
                'TELÉFONO MOVIL RES.2':`${colegio.responsables[1].telefonoMovil}`,
                'TELEFONO FIJO RES.2':`${colegio.responsables[1].telefonoFijo}`,
                'CARGO RES.2':`${colegio.responsables[1].cargo}`,
                'HORARIO PREFERENTE LLAMADAS RES.2':`${colegio.responsables[1].horarioPreferente}`,
              }
            ]
          }
        })
        //colegios nuevos
        Object.entries(colegios.nuevo.alumnos).forEach(([idAlumno])=>{
          const datosColegio = alumnos[idAlumno].inscripcionAlumno.datosColegio;
          result = {
            ...result,
            colegiosNuevos: [
              ...result.colegiosNuevos,
              {
                'CODIGO':'No registrado',
                'COLEGIO': datosColegio.colegio.nombre,
                '¿Es colegio ACADEMICA':'',
                'ALUMNOS': `${alumnos[idAlumno].informacionAlumno.nombre} ${alumnos[idAlumno].informacionAlumno.apellidos}`,
                'PROVINCIA': datosColegio.ciudad,
                'SEDE':datosColegio.sedeSat,
      
                'NOMBRE RES.ACADEMICO': datosColegio.colegio.emailTutor ?? '',
                'EMAIL RES.ACADEMICO':'',
                'TELEFONO RES.ACADEMICO':'',
      
                'NOMBRE RES.EDUCACION FISICA':'',
                'EMAIL RES.EDUCACION FISICA':'',
                'TELEFONO RES.EDUCACION FISICA':'',
      
                'NOMBRE RES.REDES SOCIALES':'',
                'EMAIL RES.REDES SOCIALES':'',
                'TELEFONO RES.REDES SOCIALES':'',
                'TIPO': datosColegio.colegio.tipoColegio,
              }
            ]
          }
        })
      } catch (err) {
        reject(err)
      }
      return resolve(result)
    });
  }
}

export const setSortedData = (payload) => {
  return { type: colegiosTypes.SET_SORTED_DATA, payload };
}

export const sortData = () => {
  return async (dispatch, getState) => {
    return new Promise( (resolve, reject) => {
      const { colegios, tabSelected, tabs } = getState().colegios
      if(!tabs[tabSelected]){ return null }
      const { sortBy, des, search, searchBy, filters } = tabs[tabSelected].header
      let result = Object.entries(colegios)
      if(search.trim()!==''){
        result = result.filter( ([idColegio, colegio]) => {
          if(searchBy==="codigoColegio"){
            return colegio.id && cleanText(colegio.id).includes(cleanText(search)) 
          }if(searchBy==="provincia"){
            return colegio.provincia && cleanText(provincias[colegio.provincia].texto).includes(cleanText(search)) 
          }else{
            return colegio[searchBy] && cleanText(colegio[searchBy]).includes(cleanText(search)) 
          }
        })
      }
      
      result = result.filter( ([idColegio, colegio])=>{
        if(idColegio==="nuevo")return false
        if(!(filters.provincias.todos.checked || !colegio.provincia || filters.provincias.items[colegio.provincia].checked)) return false
        return true;
      }).sort( ( [idA, a], [idB, b] ) => {
        if(sortBy==='nombre'){
          if (cleanText(a.nombre) > cleanText(b.nombre)) { return 1; }
          if (cleanText(a.nombre) < cleanText(b.nombre)) { return -1; }
        }else if(sortBy==='alumnos'){
          if (Object.keys(a.alumnos).length > Object.keys(b.alumnos).length) { return 1; }
          if (Object.keys(a.alumnos).length < Object.keys(b.alumnos).length) { return -1; }
        }else{
          if (a[sortBy] > b[sortBy]) { return 1; }
          if (a[sortBy] < b[sortBy]) { return -1; }
        }
        return 0;
      }).map(([idColegio]) => idColegio)
      if(des){ result.reverse() }
      dispatch(setSortedData(result))
      dispatch(setNewData({tab: tabSelected, field: 'itemsLoaded', value: addItemsLoaded}))
      return resolve()
    });
  }
};

export function modifyCurrentColegio(payload) {
  return { type: colegiosTypes.MODIFY_CURRENT_COLEGIO, payload };
}

export const saveColegio = () => {
  return async (dispatch, getState) => {
    return new Promise( async(resolve, reject) => {
      let { currentColegio} = getState().colegios
      currentColegio = JSON.parse(JSON.stringify(currentColegio))
      let multiPath = {}
      const id = await db.child(`Colegios`).push().key;
      multiPath[`Colegios/${id}`] = {
        ...currentColegio,
        id,
        timestamp: +new Date,
        inscripciones: { [getState().global.inscripcion]: true }
      }
      multiPath[`Logs/Alumnos/${id}/inscripciones`]={
        [getState().global.inscripcion]:(+Date.now())
      }

      db.update(multiPath)
      .then(()=>{
        return resolve(true)
      })
      .catch(()=>{
        return reject(false)
      })

    });
  }
};