import { pathImagesExamen } from '../../../utils/constans'
import inglesReadingDirections from '../../../images/infoExamen/ingles-reading-directions.png'
import inglesWritingDirections from '../../../images/infoExamen/ingles-reading-directions.png'

var linea = 0;

const resetLinea = () => {
   linea = 0;
   return '';
}
const setLinea = () => {
   return `<span class="container-linea-examen"><span class="linea-examen">${linea+=1}</span></span>`;
}

const setTabulacion = () => {
   return '&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;';
}

export const examenIngles = {
  1: {
     reading: {
        passages: {
           1: {
              informacion: `<b>Questions 1-11 are based on the following passages.</b><br>
                   Passage 1 is adapted from Michael Slezak, “Space Mining: the Next Gold Rush?” ©2013 by New Scientist. Passage<br>
                   2 is from the editors of New Scientist, “Taming the Final Frontier.” ©2013 by New Scientist.`,
              texto: `${resetLinea()}<b>Passage 1</b><br>
                   ${setLinea()} Follow the money and you will end up in space. That’s the message from a first-of-its-kind forum on<br>
                   ${setLinea()} mining beyond Earth.<br><br>
                   ${setLinea()} Convened in Sydney by the Australian Centre for <b>[5]</b> Space Engineering Research, the event brought<br>
                   ${setLinea()} together mining companies, robotics experts, lunar scientists, and government agencies that are all<br>
                   ${setLinea()} working to make space mining a reality.<br><br> 
                   ${setLinea()} The forum comes hot on the heels of the 2012 unveiling of two private asteroid-mining firms.<br>
                   ${setLinea()} Planetary Resources of Washington says it will launch its first prospecting telescopes in two years,<br> 
                   ${setLinea()} while Deep Space Industries of Virginia hopes to be harvesting metals from asteroids by 2020.<br>
                   ${setLinea()} Another commercial venture that sprung up in 2012, Golden Spike of Colorado, will be offering trips<br>
                   ${setLinea()} to the moon, including to potential lunar miners.<br><br>
                   ${setLinea()} Within a few decades, these firms may be meeting earthly demands for precious metals, such as<br>
                   ${setLinea()} platinum and gold, and the rare earth elements vital for personal electronics, such as yttrium and<br>
                   ${setLinea()} lanthanum. But like the gold rush pioneers who transformed the western United States, the first<br>
                   ${setLinea()} space miners won’t just enrich themselves. They also hope to build an off-planet economy free of any<br>
                   ${setLinea()} bonds with Earth, in which the materials extracted and processed from the moon and asteroids are<br>
                   ${setLinea()} delivered for space-based projects.<br><br>
                   ${setLinea()} In this scenario, water mined from other worlds could become the most desired commodity.<br>
                   ${setLinea()} “In the desert, what’s worth more: a kilogram of gold or a kilogram of water?” asks Kris Zacny of<br>
                   ${setLinea()} HoneyBee Robotics in New York. “Gold is useless. Water will let you live.”<br><br>
                   ${setLinea()} Water ice from the moon’s poles could be sent to astronauts on the International Space Station for<br>
                   ${setLinea()} drinking or as a radiation shield. Splitting water into oxygen and hydrogen makes spacecraft fuel, so<br>
                   ${setLinea()} ice-rich asteroids could become interplanetary refuelling stations.<br><br>
                   ${setLinea()} Companies are eyeing the iron, silicon, and aluminium in lunar soil and asteroids, which could be<br>
                   ${setLinea()} used in 3D printers to make spare parts or machinery. Others want to turn space dirt into concrete<br>
                   ${setLinea()} for landing pads, shelters, and roads.<br><br>
                   <b>Passage 2</b><br>
                   ${setLinea()} The motivation for deep-space travel is shifting from discovery to economics. The past year has seen<br>
                   ${setLinea()} a flurry of proposals aimed at bringing celestial riches down to Earth. No doubt this will make a few<br>
                   ${setLinea()} billionaires even wealthier, but we all stand to gain: the mineral bounty and spin-off technologies<br>
                   ${setLinea()} could enrich us all.<br><br> 
                   ${setLinea()} But before the miners start firing up their rockets, we should pause for thought. At first glance, space<br>
                   ${setLinea()} mining seems to sidestep most environmental concerns: there is (probably!) no life on asteroids, and<br>
                   ${setLinea()} thus no habitats to trash. But its consequences —both here on Earth and in space—merit careful<br>
                   ${setLinea()} consideration.<br><br>
                   ${setLinea()} Part of this is about principles. Some will argue that space’s “magnificent desolation” is not ours to<br>
                   ${setLinea()} despoil, just as they argue that our own planet’s poles should remain pristine. Others will suggest<br>
                   ${setLinea()} that glutting ourselves on space’s riches is not an acceptable alternative to developing more<br>
                   ${setLinea()} sustainable ways of earthly life.<br><br>
                   ${setLinea()} History suggests that those will be hard lines to hold, and it may be difficult to persuade the public<br>
                   ${setLinea()} that such barren environments are worth preserving. After all, they exist in vast abundance, and even<br>
                   ${setLinea()} fewer people will experience them than have walked through Antarctica’s icy landscapes.<br>
                   ${setLinea()} There’s also the emerging off-world economy to consider. The resources that are valuable in orbit<br>
                   ${setLinea()} and beyond may be very different to those we prize on Earth. Questions of their stewardship have<br>
                   ${setLinea()} barely been broached—and the relevant legal and regulatory framework is fragmentary, to put it<br>
                   ${setLinea()} mildly.<br><br>
                   ${setLinea()} Space miners, like their earthly counterparts, are often reluctant to engage with such questions.<br>
                   ${setLinea()} One speaker at last week’s space-mining forum in Sydney, Australia, concluded with a plea that<br>
                   ${setLinea()} regulation should be avoided. But miners have much to gain from a broad agreement on the for-<br>
                   ${setLinea()} profit exploitation of space. Without consensus, claims will be disputed, investments risky, and the<br>
                   ${setLinea()} gains made insecure. It is in all of our long-term interests to seek one out.`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: `1. INFORMATION AND IDEAS—EASY<br>
                           In lines 7-9, the author of Passage 1 mentions several companies primarily to`,
                    respuestas: {
                       1: {
                          texto: `note the technological advances that make space mining possible.`,
                          checked: false
                       },
                       2: {
                          texto: `provide evidence of the growing interest in space mining.`,
                          checked: false
                       },
                       3: {
                          texto: `emphasize the large profits to be made from space mining.`,
                          checked: false
                       },
                       4: {
                          texto: `highlight the diverse ways to carry out space mining operations.`,
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: `2. INFORMATION AND IDEAS—EASY<br>
                           The author of Passage 1 indicates that space mining could have which positive effect?`,
                    respuestas: {
                       1: {
                          texto: `It could yield materials important to Earth’s economy.`,
                          checked: false
                       },
                       2: {
                          texto: `It could raise the value of some precious metals on Earth.`,
                          checked: false
                       },
                       3: {
                          texto: `It could create unanticipated technological innovations.`,
                          checked: false
                       },
                       4: {
                          texto: `It could change scientists’ understanding of space resources.`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: `3 INFORMATION AND IDEAS—MEDIUM<br>
                           Which choice provides the best evidence for the answer to the previous question? `,
                    respuestas: {
                       1: {
                          texto: `Lines 11-13 (“Within . . . lanthanum”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 14-16 (“They . . . projects”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 17 (“In this . . . commodity”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 23-24 (“Companies . . . machinery”)`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: `4. RHETORIC—EASY<br>
                           As used in line 11, “demands” most nearly means`,
                    respuestas: {
                       1: {
                          texto: `Offers.`,
                          checked: false
                       },
                       2: {
                          texto: `Claims.`,
                          checked: false
                       },
                       3: {
                          texto: `Inquiries.`,
                          checked: false
                       },
                       4: {
                          texto: `Desires.`,
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: `5. INFORMATION AND IDEAS—EASY<br>
                           What function does the discussion of water in lines 17-22 serve in Passage 1?`,
                    respuestas: {
                       1: {
                          texto: `It continues extended comparison that begins in the previous paragraph.`,
                          checked: false
                       },
                       2: {
                          texto: `It provides an unexpected answer to a question raised in the previous paragraph.`,
                          checked: false
                       },
                       3: {
                          texto: `It offers hypothetical examples supporting a claim made in the previous paragraph.`,
                          checked: false
                       },
                       4: {
                          texto: `It exams possible outcomes of a proposal put forth in the previous paragraph.`,
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: `6. INFORMATION AND IDEAS—MEDIUM<br>
                           The central claim of Passage 2 is that space mining has potential but`,
                    respuestas: {
                       1: {
                          texto: `it will end up encouraging humanity’s reckless treatment of the environment.`,
                          checked: false
                       },
                       2: {
                          texto: `its effects should be thoughtfully considered before it becomes a reality.`,
                          checked: false
                       },
                       3: {
                          texto: `such potential may not include replenishing key resources that are on Earth.`,
                          checked: false
                       },
                       4: {
                          texto: `experts disagree about the commercial viability of the discoveries it could yield.`,
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: `7. RHETORIC—EASY<br>
                           As used in line 38, “hold” most nearly means`,
                    respuestas: {
                       1: {
                          texto: `maintain.`,
                          checked: false
                       },
                       2: {
                          texto: `grip.`,
                          checked: false
                       },
                       3: {
                          texto: `restrain.`,
                          checked: false
                       },
                       4: {
                          texto: `withstand.`,
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: `8. SYNTHESIS—MEDIUM<br>
                           Which statement best describes the relationship between the passages?`,
                    respuestas: {
                       1: {
                          texto: `Passage 2 refutes the central claim advanced in Passage 1.`,
                          checked: false
                       },
                       2: {
                          texto: `Passage 2 illustrates the phenomenon described in more general terms in Passage 1. `,
                          checked: false
                       },
                       3: {
                          texto: `Passage 2 argues against the practicality of the proposals put forth in Passage 1.`,
                          checked: false
                       },
                       4: {
                          texto: `Passage 2 expresses reservations about developments discussed in Passage 1.`,
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: `9. SYTHESIS—HARD<br>
                           The author of Passage 2 would most likely respond to the discussion of the future of space mining in lines 11-16, Passage 1, by claiming that such a future`,
                    respuestas: {
                       1: {
                          texto: `is inconsistent with the sustainable use of space resources.`,
                          checked: false
                       },
                       2: {
                          texto: `will be difficult to bring about in the absence of regulations.`,
                          checked: false
                       },
                       3: {
                          texto: `cannot be attained without technologies that do not yet exist.`,
                          checked: false
                       },
                       4: {
                          texto: `seems certain to affect Earth’s economy in a negative way.`,
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: `10. INFORMATION AND IDEAS—MEDIUM<br>
                           Which choice provides the best evidence for the answer to the previous question?`,
                    respuestas: {
                       1: {
                          texto: `Lines 34-35 (“Some . . . pristine”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 41-42 (“The resources . . . Earth”) `,
                          checked: false
                       },
                       3: {
                          texto: `Lines 46-47 (“One . . . avoided”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 48-49 (“Without . . . insecure”)`,
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: `11. SYNTHESIS—HARD<br>
                           Which point about the resources that will be highly valued in space is implicit in Passage 1 and explicit in Passage 2?`,
                    respuestas: {
                       1: {
                          texto: `They may be different resources from those that are valuable on Earth.`,
                          checked: false
                       },
                       2: {
                          texto: `They will be valuable only if they can be harvested cheaply.`,
                          checked: false
                       },
                       3: {
                          texto: `They are likely to be primarily precious metals and rare earth elements.`,
                          checked: false
                       },
                       4: {
                          texto: `They may increase in value as those same resources become more rare on Earth. `,
                          checked: false
                       }
                    }
                 }
              }
           },
           2: {
              informacion: `<b>Questions 12-21 are based on the following passage.</b><br>
                   This passage is from Carlos Ruiz Zafón, The Angel’s Game. ©2008 by Dragonworks, S.L. Translation ©2009 by<br>
                   Lucia Graves. The narrator, a writer, recalls his childhood in early twentieth-century Barcelona.`,
              texto: `${resetLinea()}${setLinea()} Even then my only friends were made of paper and ink. At school I had learned to read and write long<br>
                   ${setLinea()} before the other children. Where my school friends saw notches of ink on incomprehensible<br>
                   ${setLinea()} pages, I saw light, streets, and people. Words and the mystery of their hidden science fascinated me,<br>
                   ${setLinea()} and I saw in them a key with which I could unlock a boundless world, a safe haven from that home,<br>
                   ${setLinea()} those streets, and those troubled days in which even I could sense that only a limited fortune awaited<br>
                   ${setLinea()} me. My father didn’t like to see books in the house. There was something about them—apart from the<br>
                   ${setLinea()} letters he could not decipher—that offended him. He used to tell me that as soon as I was ten he<br>
                   ${setLinea()} would send me off to work and that I’d better get rid of all my scatterbrained ideas if I didn’t want to<br>
                   ${setLinea()} end up a loser, a nobody. I used to hide my books under the mattress and wait for him to go out or fall<br>
                   ${setLinea()} asleep so that I could read. Once he caught me reading at night and flew into a rage. He tore the book<br>
                   ${setLinea()} from my hands and flung it out of the window.<br><br>
                   ${setLinea()} “If I catch you wasting electricity again, reading all this nonsense, you’ll be sorry.”<br><br>
                   ${setLinea()} My father was not a miser and, despite the hardships we suffered, whenever he could he gave me a<br>
                   ${setLinea()} few coins so that I could buy myself some treats like the other children. He was convinced that I spent<br>
                   ${setLinea()} them on licorice sticks, sunflower seeds, or sweets, but I would keep them in a coffee tin under the<br>
                   ${setLinea()} bed, and when I’d collected four or five reales I’d secretly rush out to buy myself a book.<br><br>
                   ${setLinea()} My favorite place in the whole city was the Sempere & Sons bookshop on Calle Santa Ana. It smelled<br>
                   ${setLinea()} of old paper and dust and it was my sanctuary, my refuge. The bookseller would let me sit on a chair<br>
                   ${setLinea()} in a corner and read any book I liked to my heart’s content. He hardly ever allowed me to pay for the<br>
                   ${setLinea()} books he placed in my hands, but when he wasn’t looking I’d leave the coins I’d managed to collect on<br>
                   ${setLinea()} the counter before I left. It was only small change—if I’d had to buy a book with that pittance, I would<br>
                   ${setLinea()} probably have been able to afford only a booklet of cigarette papers. When it was time for me to<br>
                   ${setLinea()} leave, I would do so dragging my feet, a weight on my soul. If it had been up to me, I would have<br>
                   ${setLinea()} stayed there forever.<br><br>
                   ${setLinea()} One Christmas Sempere gave me the best gift I have ever received. It was an old volume, read and<br>
                   ${setLinea()} experienced to the full.<br><br>
                   ${setLinea()} “Great Expectations, by Charles Dickens,” I read on the cover.<br><br>
                   ${setLinea()} I was aware that Sempere knew a few authors who frequented his establishment and, judging by the<br>
                   ${setLinea()} care with which he handled the volume, I thought perhaps this Mr.Dickens was one of them.<br><br>
                   ${setLinea()} “A friend of yours?”<br><br>
                   ${setLinea()} “A lifelong friend. And from now on, he’s your friend too.”<br><br>
                   ${setLinea()} That afternoon I took my new friend home, hidden under my clothes so that my father wouldn’t<br>
                   ${setLinea()} see it. It was a rainy winter, with days as gray as lead, and I read Great Expectations about nine times,<br>
                   ${setLinea()} partly because I had no other book at hand, partly because I did not think there could be a better one<br>
                   ${setLinea()} in the whole world and I was beginning to suspect that Mr. Dickens had written it just for me. Soon I<br>
                   ${setLinea()} was convinced that I didn’t want to do anything else in life but learn to do what Mr. Dickens had<br>
                   ${setLinea()} done.`,
              preguntas: {
                 1: {
                    indicePregunta: '12',
                    enunciado: `12. INFORMATION AND IDEAS—EASY<br>
                           Over the course of the passage, the main focus shifts from a`,
                    respuestas: {
                       1: {
                          texto: `general discussion of the narrator’s love of reading to a portrayal of an influential incident.`,
                          checked: false
                       },
                       2: {
                          texto: `depiction of the narrator’s father to an examination of an author with whom the narrator becomes enchanted.`,
                          checked: false
                       },
                       3: {
                          texto: `symbolic representation of a skill the narrator possesses to an example of its application.`,
                          checked: false
                       },
                       4: {
                          texto: `tale about the hardships of the narrator’s childhood to an analysis of the effects of those hardships.`,
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '13',
                    enunciado: `13. INFORMATION AND IDEAS—EASY<br>
                           The main purpose of lines 1-6 (“Even . . . awaited me”) is to`,
                    respuestas: {
                       1: {
                          texto: `introduce the characters who play a part in the narrator’s story.`,
                          checked: false
                       },
                       2: {
                          texto: `list the difficult conditions the narrator endured in childhood.`,
                          checked: false
                       },
                       3: {
                          texto: `describe the passion that drives the actions the narrator recounts.`,
                          checked: false
                       },
                       4: {
                          texto: `depict the narrator’s aspirations before he met Sempere.`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '14',
                    enunciado: `14. SYNTHESIS—MEDIUM<br>
                           With which of the following statements about his father would the narrator most likely agree?`,
                    respuestas: {
                       1: {
                          texto: `He lacked affection for the narrator.`,
                          checked: false
                       },
                       2: {
                          texto: `He disliked any unnecessary use of money.`,
                          checked: false
                       },
                       3: {
                          texto: `He would not have approved of Sempere’s gift.`,
                          checked: false
                       },
                       4: {
                          texto: `He objected to the writings of Charles Dickens.`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '15',
                    enunciado: `15. INFORMATION AND IDEAS—EASY<br>
                           Which choice provides the best evidence for the answer to the previous question?`,
                    respuestas: {
                       1: {
                          texto: `Lines 13-14 (“My father . . . children”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 18-19 (“The bookseller . . . content”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 19-20 (“He hardly . . . hands”) `,
                          checked: false
                       },
                       4: {
                          texto: `Lines 32-33 (“That afternoon . . . see it”)`,
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '16',
                    enunciado: `16. SYNTHESIS—MEDIUM<br>
                           It can reasonably be inferred from the passage that the main reason that the narrator considers Great Expectations to be the best gift he ever received is because`,
                    respuestas: {
                       1: {
                          texto: `reading the book convinced him that he wanted to be a writer.`,
                          checked: false
                       },
                       2: {
                          texto: `he’d only ever been given sweets and snacks as gifts in the past.`,
                          checked: false
                       },
                       3: {
                          texto: `the gift meant that Sempere held him in high regard.`,
                          checked: false
                       },
                       4: {
                          texto: `Sempere was a friend of the book’s author.`,
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '17',
                    enunciado: `17. INFORMATION AND IDEAS—MEDIUM<br>
                           Which choice provides the best evidence for the answer to the previous question?`,
                    respuestas: {
                       1: {
                          texto: `Lines 20-21 (“when…left”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 25-26 (“It was…full”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 28-29 (“I was…them”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 35-37 (“Soon…done”)`,
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '18',
                    enunciado: `18. INFORMATION AND IDEAS—MEDIUM<br>
                           The narrator indicates that he pays Sempere `,
                    respuestas: {
                       1: {
                          texto: `less than Sempere expects him to pay for the books.`,
                          checked: false
                       },
                       2: {
                          texto: `nothing, because Sempere won’t take his money.`,
                          checked: false
                       },
                       3: {
                          texto: `the money he makes selling sweets to the other children.`,
                          checked: false
                       },
                       4: {
                          texto: `much less for the books than they are worth.`,
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '19',
                    enunciado: `19. RHETORIC—MEDIUM<br>
                           As used in line 23, “weight” most nearly means `,
                    respuestas: {
                       1: {
                          texto: `bulk.`,
                          checked: false
                       },
                       2: {
                          texto: `burden.`,
                          checked: false
                       },
                       3: {
                          texto: `force.`,
                          checked: false
                       },
                       4: {
                          texto: `clout.`,
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '20',
                    enunciado: `20. RHETORIC—HARD<br>
                           The word “friend” is used twice in line 31 to `,
                    respuestas: {
                       1: {
                          texto: `underline the importance of the narrator’s connection to Sempere.`,
                          checked: false
                       },
                       2: {
                          texto: `stress how friendships helped the narrator deal with his difficult home situation.`,
                          checked: false
                       },
                       3: {
                          texto: `emphasize the emotional connection Sempere feels to reading. `,
                          checked: false
                       },
                       4: {
                          texto: `imply that the narrator’s sentiments caused him to make an irrational decision. `,
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '21',
                    enunciado: `21. INFORMATION AND IDEAS—MEDIUM<br>
                           Which statement best characterizes the relationship between Sempere and Charles Dickens?`,
                    respuestas: {
                       1: {
                          texto: `Sempere models his own writing after Dickens’s style.`,
                          checked: false
                       },
                       2: {
                          texto: `Sempere is an avid admirer of Dickens’s work.`,
                          checked: false
                       },
                       3: {
                          texto: `Sempere feels a personal connection to details of Dickens’s biography.`,
                          checked: false
                       },
                       4: {
                          texto: `Sempere considers himself to be Dickens’s most appreciative reader.`,
                          checked: false
                       }
                    }
                 }
              }
           },
           3: {
              informacion: `<b>Questions 22-30 are based on the following passage and supplementary material.</b><br>
                   This passage is adapted from John Bohannon, “Why You Shouldn’t Trust Internet Comments.” ©2013 by<br>
                   American Association for the Advancement of Science.`,
              texto: `${resetLinea()}${setLinea()} The “wisdom of crowds” has become a mantra of the Internet age. Need to choose a new vacuum<br>
                   ${setLinea()} cleaner? Check out the reviews on online merchant Amazon. But a new study suggests that such<br>
                   ${setLinea()} online scores don’t always reveal the best choice. A massive controlled experiment of Web users<br>
                   ${setLinea()} finds that such ratings are highly susceptible to irrational “herd behavior”—and that the herd can be<br>
                   ${setLinea()} manipulated.<br><br>
                   ${setLinea()} Sometimes the crowd really is wiser than you. The classic examples are guessing the weight of a bull<br>
                   ${setLinea()} or the number of gumballs in a jar. Your guess is probably going to be far from the mark, whereas the<br>
                   ${setLinea()} average of many people’s choices is remarkably close to the true number.<br><br>
                   ${setLinea()} But what happens when the goal is to judge something less tangible, such as the quality or worth of a<br>
                   ${setLinea()} product? According to one theory, the wisdom of the crowd still holds—measuring the aggregate of<br>
                   ${setLinea()} people’s opinions produces a stable, reliable value. Skeptics, however, argue that people’s opinions<br>
                   ${setLinea()} are easily swayed by those of others. So nudging a crowd early on by presenting contrary opinions—<br>
                   ${setLinea()} for example, exposing them to some very good or very bad attitudes—will steer the crowd in a<br>
                   ${setLinea()} different direction. To test which hypothesis is true, you would need to manipulate huge numbers of<br>
                   ${setLinea()} people, exposing them to false information and determining how it affects their opinions.<br><br>
                   ${setLinea()} A team led by Sinan Aral, a network scientist at the Massachusetts Institute of Technology in<br>
                   ${setLinea()} Cambridge, did exactly that. Aral has been secretly working with a popular website that aggregates<br>
                   ${setLinea()} news stories. The website allows users to make comments about news stories and vote each other’s<br>
                   ${setLinea()} comments up or down. The vote tallies are visible as a number next to each comment, and the<br>
                   ${setLinea()} position of the comments is chronological. (Stories on the site get an average of about ten comments<br>
                   ${setLinea()} and about three votes per comment.) It’s a follow-up to his experiment using people’s ratings of<br>
                   ${setLinea()} movies to measure how much individual people influence each other online (answer: a lot). This<br>
                   ${setLinea()} time, he wanted to know how much the crowd influences the individual, and whether it can be<br>
                   ${setLinea()} controlled from outside.<br><br>
                   ${setLinea()} For five months, every comment submitted by a user randomly received an “up” vote (positive); a<br>
                   ${setLinea()} “down” vote (negative); or as a control, no vote at all. The team then observed how users rated those<br>
                   ${setLinea()} comments. The users generated more than 100,000 comments that were viewed more than<br>
                   ${setLinea()} 10 million times and rated more than 300,000 times by other users.<br><br>
                   ${setLinea()} At least when it comes to comments on news sites, the crowd is more herdlike than wise. Comments<br>
                   ${setLinea()} that received fake positive votes from the researchers were 32% more likely to receive more positive<br>
                   ${setLinea()} votes compared with a control, the team reports. And those comments were no more likely than the<br>
                   ${setLinea()} control to be down-voted by the next viewer to see them. By the end of the study, positively<br>
                   ${setLinea()} manipulated comments got an overall boost of about 25%. However, the same did not hold true for<br>
                   ${setLinea()} negative manipulation. The ratings of comments that got a fake down vote were usually negated by<br>
                   ${setLinea()} an up vote by the next user to see them.<br><br>
                   ${setLinea()} “Our experiment does not reveal the psychology behind people’s decisions,” Aral says, “but an<br>
                   ${setLinea()} intuitive explanation is that people are more skeptical of negative social influence. They’re more<br>
                   ${setLinea()} willing to go along with positive opinions from other people.”<br><br>
                   ${setLinea()} Duncan Watts, a network scientist at Microsoft Research in New York City, agrees with that<br>
                   ${setLinea()} conclusion. “[But] one question is whether the positive[herding]bias is specific to this site” or true in<br>
                   ${setLinea()} general, Watts says. He points out that the category of the news items in the experiment had a strong<br>
                   ${setLinea()} effect on how much people could be manipulated. “I would have thought that ‘business’ is pretty<br>
                   ${setLinea()} similar to ‘economics,’ yet they find a much stronger effect (almost 50% stronger) for the former<br>
                   ${setLinea()} than the latter. What explains this difference? If we’re going to apply these findings in the real world<br>
                   ${setLinea()} we’ll need to know the answers.”<br><br>
                   ${setLinea()} Will companies be able to boost their products by manipulating online ratings on a massive scale?<br>
                   ${setLinea()} “That is easier said than done,” Watts says. If people detect—or learn—that comments on a website<br>
                   ${setLinea()} are being manipulated, the herd may spook and leave entirely.<br><br>
                   <img src="${pathImagesExamen}/Ingles/v1-reading-passage-3.png">`,
              preguntas: {
                 1: {
                    indicePregunta: '22',
                    enunciado: `22. INFORMATION AND IDEAS—EASY<br>
                           Over the course of the passage, the main focus shifts from a discussion of an experiment and its results to`,
                    respuestas: {
                       1: {
                          texto: `an explanation of the practical applications of the results.`,
                          checked: false
                       },
                       2: {
                          texto: `a consideration of the questions prompted by the results.`,
                          checked: false
                       },
                       3: {
                          texto: `an analysis of the defects undermining the results.`,
                          checked: false
                       },
                       4: {
                          texto: `a conversation with a scientist who disputes the results.`,
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '23',
                    enunciado: `23. INFORMATION AND IDEAS—MEDIUM<br>
                           The author of the passage suggests that crowds may be more effective at`,
                    respuestas: {
                       1: {
                          texto: `creating controversy than examining an issue in depth`,
                          checked: false
                       },
                       2: {
                          texto: `reinforcing members’ ideas than challenging those ideas.`,
                          checked: false
                       },
                       3: {
                          texto: `arriving at accurate quantitative answers than producing valid qualitative judgments.`,
                          checked: false
                       },
                       4: {
                          texto: `ranking others’ opinions than developing genuinely original positions.`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '24',
                    enunciado: `24. INFORMATION AND IDEAS—MEDIUM<br>
                           Which choice provides the best evidence for the answer to the previous question?`,
                    respuestas: {
                       1: {
                          texto: `Line 6 (“Sometimes . . . you”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 7-8 (“Your . . . number”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 10-11 (“According . . . value”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 14-15 (“To test . . . opinions”)`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '25',
                    enunciado: `25. SYNTHESIS—HARD<br>
                           Which choice best supports the view of the “skeptics” (line 20)?`,
                    respuestas: {
                       1: {
                          texto: `Lines 29-31 (“Comments . . . reports”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 31-32 (“And . . . them”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 34-35 (“The ratings . . . them”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 41-42 (“He . . . manipulated”)`,
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '26',
                    enunciado: `26. RHETORIC—MEDIUM<br>
                           As used in line 46, “boost” most nearly means`,
                    respuestas: {
                       1: {
                          texto: `increase.`,
                          checked: false
                       },
                       2: {
                          texto: `accelerate.`,
                          checked: false
                       },
                       3: {
                          texto: `promote.`,
                          checked: false
                       },
                       4: {
                          texto: `protect.`,
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '27',
                    enunciado: `27. RHETORIC—MEDIUM<br>
                           As used in line 46, “scale” most nearly means`,
                    respuestas: {
                       1: {
                          texto: `level.`,
                          checked: false
                       },
                       2: {
                          texto: `wage.`,
                          checked: false
                       },
                       3: {
                          texto: `interval.`,
                          checked: false
                       },
                       4: {
                          texto: `scheme.`,
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '28',
                    enunciado: `28. SYNTHESIS—MEDIUM<br>
                           In the figure, which category of news has an artificially up-voted mean score of 2.5?`,
                    respuestas: {
                       1: {
                          texto: `Business`,
                          checked: false
                       },
                       2: {
                          texto: `Politics`,
                          checked: false
                       },
                       3: {
                          texto: `Fun`,
                          checked: false
                       },
                       4: {
                          texto: `General news`,
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '29',
                    enunciado: `29. SYNTHESIS—EASY<br>
                           According to the figure, which category of news showed the smallest difference in mean score between artificially up-voted comments and control comments?`,
                    respuestas: {
                       1: {
                          texto: `Culture and society`,
                          checked: false
                       },
                       2: {
                          texto: `Information technology`,
                          checked: false
                       },
                       3: {
                          texto: `Fun`,
                          checked: false
                       },
                       4: {
                          texto: `General news`,
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '30',
                    enunciado: `30. SYNTEHSIS—HARD<br>
                           Data presented in the figure most directly support which idea from the passage?`,
                    respuestas: {
                       1: {
                          texto: `The mean score of artificially down-voted comments is similar to that of the control.`,
                          checked: false
                       },
                       2: {
                          texto: `The patterns observed in the experiment suggest that people are suspicious of negative social influence.`,
                          checked: false
                       },
                       3: {
                          texto: `The positive bias observed in users of the news site may not apply to human behavior in other contexts.`,
                          checked: false
                       },
                       4: {
                          texto: `The type of story being commented on has an impact on the degree to which people can be influenced.`,
                          checked: false
                       }
                    }
                 }
              }
           }
        }
     },
     writing: {
        passages: {
           1: {
              informacion: '<b>Coffee: The Buzz on Beans</b>',
              texto: `${resetLinea()} ${setLinea()} Americans love coffee. <b>1</b> <u>Some</u> days you can find a coffee shop in nearly every American city. But this wasn't always true.<br>
              ${setLinea()} How did coffee, which was first grown in Africa over five hundred years ago, come to America?<br>
              ${setLinea()} The coffee plant, from which makers get the "cherries" that <b>2</b> <u>is dried and roasted</u> into what we call beans, first appeared in the East<br>
              ${setLinea()} African country Ethiopia, in the province of Kaffa. From there, it spread to the Arabian Peninsula, where the coffeehouse, or qahveh<br>
              ${setLinea()} khaneh in Arabic, was very popular. Like spices and cloth, coffee was traded internationally as European explorers reached far lands<br>
              ${setLinea()} and <b>3</b> <u>establishing</u> shipping routes. The first European coffeehouse opened in Venice, Italy, in 1683, and around that time<br>
              ${setLinea()} London <b>4</b> <u>displayed</u> over three hundred coffeehouses.<br>
              ${setLinea()} There is no record of coffee being amongst the cargo of the Mayflower, which reached the New World in 1620. It was not until<br>
              ${setLinea()} 1668 that the first written reference to coffee in America was made. A beverage was being made from roasted beans and flavored with<br>
              ${setLinea()} sugar or honey, and cinnamon. Coffee was then described in the New England colony's official records of 1670. In 1683, William Penn,<br>
              ${setLinea()} who lived in a settlement on the Delaware River, wrote of buying supplies of coffee in a <b>5</b> <u>New York market, he paideighteen</u> shillings and<br>
              ${setLinea()} nine pence per pound.<b>6</b><br>
              ${setLinea()} Coffeehouses like those in Europe were soon established in American colonies, and as America expanded westward, coffee<br>
              ${setLinea()} consumption grew. In their settlement days, <b>7</b> <u>Chicago St. Louis and New Orleans</u> each had famous coffeehouses. By the mid-twentieth<br>
              ${setLinea()} century, coffeehouses were abundant. In places like New York and San Francisco, they became <b>8</b><u>confused</u> with counterculture, as a<br>
              ${setLinea()} place where intellectuals and artists gathered to share ideas. In American homes, coffee was a social lubricant, bringing people together<br>
              ${setLinea()} to socialize as afternoon tea had done in English society. With the invention of the electric coffee pot, it became a common courtesy to<br>
              ${setLinea()} ask a guest if she wanted "coffee or tea?"<b>9</b>
              ${setLinea()} However, by the 1950s, U.S. manufacturing did to coffee what it had done to <b>10</b> <u>other foods; produced</u> it cheaply, mass-marketed<br>
              ${setLinea()} it, and lowered the quality. Coffee was roasted and ground in manufacturing plants and freeze-dried for a long storage life, which<br>
              ${setLinea()} compromised its flavor. An "evangelism" began to bring back the original bracing, dark-roasted taste of coffee, and spread to the rest of<br>
              ${setLinea()} the world. <b>11</b> <u>In every major city of the world, now travelers around the world, expect to be able to grab an uplifting, fresh, and delicious<br>
              ${setLinea()} cup of coffee—and they can.</u>`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'Many',
                          checked: false
                       },
                       3: {
                          texto: 'The',
                          checked: false
                       },
                       4: {
                          texto: 'These',
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'are being dried and roasted',
                          checked: false
                       },
                       3: {
                          texto: 'are dried and roasted',
                          checked: false
                       },
                       4: {
                          texto: 'is being dried and roasted',
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'established',
                          checked: false
                       },
                       3: {
                          texto: 'having established',
                          checked: false
                       },
                       4: {
                          texto: 'was establishing',
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'bragged',
                          checked: false
                       },
                       3: {
                          texto: 'highlighted',
                          checked: false
                       },
                       4: {
                          texto: 'boasted',
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'New York market and William Penn',
                          checked: false
                       },
                       3: {
                          texto: 'New York market so he paid',
                          checked: false
                       },
                       4: {
                          texto: 'New York market, paying',
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: 'Which choice most effectively establishes a summative concluding sentence for the paragraph?',
                    respuestas: {
                       1: {
                          texto: `Coffee's appearance in the historical record shows it was becoming more and more established in the New World.`,
                          checked: false
                       },
                       2: {
                          texto: 'The colonies probably used more tea than coffee since there are records of it being imported from England.',
                          checked: false
                       },
                       3: {
                          texto: 'William Penn founded Pennsylvania Colony, which became the state of Pennsylvania after the Revolutionary War with England ended.',
                          checked: false
                       },
                       4: {
                          texto: 'The Mayflower did carry a number of items that the colonists needed for settlement, including animals and tools.',
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'Chicago, St. Louis, and New Orleans',
                          checked: false
                       },
                       3: {
                          texto: 'Chicago, St. Louis, and, New Orleans',
                          checked: false
                       },
                       4: {
                          texto: 'Chicago St. Louis and, New Orleans',
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'related',
                          checked: false
                       },
                       3: {
                          texto: 'associated',
                          checked: false
                       },
                       4: {
                          texto: 'coupled',
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: 'Which choice most effectively establishes the main topic of the paragraph?',
                    respuestas: {
                       1: {
                          texto: 'There were many coffee shops in New York and in Chicago.',
                          checked: false
                       },
                       2: {
                          texto: 'Electric coffee machines changed how people entertained at home.',
                          checked: false
                       },
                       3: {
                          texto: 'Over time, coffee became a part of everyday American life.',
                          checked: false
                       },
                       4: {
                          texto: 'People went to coffehouses to discuss major issues.',
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'other foods produced',
                          checked: false
                       },
                       3: {
                          texto: 'other foods, produced',
                          checked: false
                       },
                       4: {
                          texto: 'other foods: produced',
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'Now travelers, in every major city of the world, around the world expect to be able to grab an uplifting, fresh, and delicious cup of coffee—and they can.',
                          checked: false
                       },
                       3: {
                          texto: 'Now in every major city of the world, travelers around the world expect to be able to grab an uplifting, fresh, and delicious delicious cup of coffee—and they can.',
                          checked: false
                       },
                       4: {
                          texto: 'Now travelers around the world expect to be able to grab an uplifting, fresh, and delicious cup of coffee in every major city of the world—and they can.',
                          checked: false
                       }
                    }
                 }
              }
           },
           2: {
              informacion: '<b>The UK and the Euro</b>',
              texto: `${resetLinea()}${setLinea()} [1] The United Kingdom is a longstanding member of the European Union (EU), a multinational political organization and<br>
              ${setLinea()} economic world leader <b>1</b><u>elected</u> over the course of the past half-century. [2] However, there is one key feature of the EU in which the UK<br>
              ${setLinea()} does not <b>2</b> <u>participate; the monetary</u> union known as the Eurozone, consisting of countries that share the euro as currency. <b>3</b> [3] While the<br>
              ${setLinea()} nation's public opinion has remained generally supportive of that decision, evidence suggests that the euro's benefits for the UK might, in<br>
              ${setLinea()} fact, outweigh the risks. [4] When the EU first implemented the euro in 1999, intending to strengthen collective economy across the union,<br>
              ${setLinea()} Britain was permitted exclusion and continued using the pound instead. [5] This, UK leaders hoped, would shield Britain from financial<br>
              ${setLinea()} dangers that the euro might suffer. Proponents for avoiding the euro point <b>4</b> <u>to faltering economies in the Eurozone region throughout the<br>
              ${setLinea()} Eurozone.</u> To join a massive, multinational economy would involve surrendering taxable wealth from one's own region to aid impoverished<br>
              ${setLinea()} countries that may be some thousands of miles away. If a few economies in the Eurozone suffer, all of the participating nations suffer, too.<br>
              ${setLinea()} Other proponents point to details of financial policy such as interest rates and territory responsibilities, fearing loss of agency and political<br>
              ${setLinea()} traction.<b>5</b><br>
              ${setLinea()} But complications loom: the UK's current EU status may be untenable. In recent years, EU leaders seem to intend to transition<br>
              ${setLinea()} all members <b>6</b><u>toward the Eurozone, for many reasons,</u> this action appears necessary for protecting nations involved and ensuring the<br>
              ${setLinea()} monetary union's long-term success. These conditions may potentially force the UK to choose either the security of its multidecade EU<br>
              ${setLinea()} membership, or the pound and all it entails for Britain's economy. Enjoying both may not remain possible.<b>7</b><br>
              ${setLinea()} [1] Regarding Britain's intent to be protected from the Eurozone's economic dangers, this hope never quite materialized. [2]<br>
              ${setLinea()} The UK saw economic downturns of its own during the euro's problematic years thus far. [3] Many families in the UK still struggle to pay<br>
              ${setLinea()} their bills in the face of higher than normal unemployment rates. [4] It seems that regardless of shared currency, the economies of Britain<br>
              ${setLinea()} and its Eurozone neighbors are too closely <b>8</b> <u>intertwined</u> for one to remain unscathed by another's crises.<b>9</b><br>
              ${setLinea()} Perhaps this question of economic security has been the wrong one. Due to Britain's location and long-standing trade relationships<br>
              ${setLinea()} with its neighbors, economies will persist to be somewhat reliant on each other, euro or not. <b>10</b> <u>Furthermore</u>,political security, power, and<br>
              ${setLinea()} protection bear more significance for the future. If the UK hopes to maintain and expand its influential presence in world leadership, its<br>
              ${setLinea()} association and close involvement with greater Europe is invaluable. Considering that the euro probably offers a lower risk margin than<br>
              ${setLinea()} many have supposed, the benefits of euro adoption <b>11</b> : <u>to secure EU membership and strengthen its cause</u>, bid Britain carefully<br>
              ${setLinea()} reconsider.
              `,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'determined',
                          checked: false
                       },
                       3: {
                          texto: 'advanced',
                          checked: false
                       },
                       4: {
                          texto: 'built',
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'participate: the monetary',
                          checked: false
                       },
                       3: {
                          texto: 'participate, the monetary',
                          checked: false
                       },
                       4: {
                          texto: 'participate. The monetary',
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: 'To present the ideas of this paragraph in logical order, the most appropriate place for sentence 3 to appear is',
                    respuestas: {
                       1: {
                          texto: 'where it is now.',
                          checked: false
                       },
                       2: {
                          texto: 'after sentence 1.',
                          checked: false
                       },
                       3: {
                          texto: 'after sentence 4.',
                          checked: false
                       },
                       4: {
                          texto: 'after sentence 5.',
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: 'Which choice best completes the sentence?',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'to faltering economies throughout the Eurozone.',
                          checked: false
                       },
                       3: {
                          texto: 'to faltering economies in most if not all Eurozone countries.',
                          checked: false
                       },
                       4: {
                          texto: 'to faltering economies in Eurozone countries throughout Europe.',
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: 'Which statement most clearly communicates the main claim of the paragraph on lines 16-19?',
                    respuestas: {
                       1: {
                          texto: `The UK's taxable wealth would decrease if it assisted impoverished countries.`,
                          checked: false
                       },
                       2: {
                          texto: 'Economic independence from impoverished countries would still be possible.',
                          checked: false
                       },
                       3: {
                          texto: 'The UK would take on significant economic risk if it adopted the euro as its currency.',
                          checked: false
                       },
                       4: {
                          texto: `Euro adoption would require subsequent economic assistance on the UK's behalf.`,
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'toward the Eurozone. For many reasons,',
                          checked: false
                       },
                       3: {
                          texto: 'toward the Eurozone, for many reasons.',
                          checked: false
                       },
                       4: {
                          texto: 'toward the Eurozone. For many reasons.',
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: 'Which choice best summarizes details that support the main claim in this paragraph?',
                    respuestas: {
                       1: {
                          texto: 'The UK wants to maintain the pound as its currency.',
                          checked: false
                       },
                       2: {
                          texto: 'All EU members may soon have to accept the euro.',
                          checked: false
                       },
                       3: {
                          texto: 'The UK faces a difficult decision regarding its EU membership.',
                          checked: false
                       },
                       4: {
                          texto: 'All member nations want to ensure the success of the EU.',
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'disparate',
                          checked: false
                       },
                       3: {
                          texto: 'identical',
                          checked: false
                       },
                       4: {
                          texto: 'relevant',
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: 'Which sentence is least relevant to the central idea of this paragraph?',
                    respuestas: {
                       1: {
                          texto: 'Sentence 1',
                          checked: false
                       },
                       2: {
                          texto: 'Sentence 2',
                          checked: false
                       },
                       3: {
                          texto: 'Sentence 3',
                          checked: false
                       },
                       4: {
                          texto: 'Sentence 4',
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'Or,',
                          checked: false
                       },
                       3: {
                          texto: 'Also,',
                          checked: false
                       },
                       4: {
                          texto: 'However,',
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: '—to secure EU membership and strengthen its cause—',
                          checked: false
                       },
                       3: {
                          texto: ': to secure EU membership and strengthen its cause—',
                          checked: false
                       },
                       4: {
                          texto: '; to secure EU membership and strengthen its cause,',
                          checked: false
                       }
                    }
                 }
              }
           },
           3: {
              informacion: '<b>From Here to the Stars</b>',
              texto: `${resetLinea()}${setLinea()} Gene Kranz hadn't slept in ages. <b>1</b> T<u>he flight director, pacing</u> between rows of monitors in NASA's Mission Control Center, an<br>
              ${setLinea()} impossible problem weighing heavy in his weary mind: Three astronauts were operating a crippled spacecraft nearly 200,000 miles from<br>
              ${setLinea()} Earth. And time was running out.<br>
              ${setLinea()} Kranz was no stranger to <b>2</b> <u>issues</u>. After losing his father at an early age, Kranz turned to the stars for guidance—and found<br>
              ${setLinea()} inspiration. His high school thesis was about the possibility of <b>3</b> <u>space travel; an idea</u> that prompted Kranz to set a path for the stars.<br>
              ${setLinea()} Kranz pursued a degree in aeronautical engineering after high school graduation. Until the Wright brothers had pioneered powered,<br>
              ${setLinea()} controlled flight only half a century earlier, aviation milestones like breaking the sound barrier and World War II changed the future of flight.<br>
              ${setLinea()} Aeronautical engineering required a thorough understanding of <b>4</b> <u>physics—like lift and drag on wings—as well as proficiency</u> in<br>
              ${setLinea()} mathematics to determine maximum weight on an aircraft. After graduating from Saint Louis University's Parks College of Engineering,<br>
              ${setLinea()} Aviation, and Technology, Kranz piloted jets for the Air Force Reserve before performing research and development on missiles and<br>
              ${setLinea()} rockets. Kranz later joined NASA and directed the successful Apollo 11 mission to the moon in 1969.<br>
              ${setLinea()} <b>5</b> One year later, the mood had drastically altered in the Mission Control Center. There were no cheers, no celebratory pats on<br>
              ${setLinea()} the back or teary-eyed congratulations. Coffee and adrenaline fueled the scientists and engineers communicating with the astronauts on<br>
              ${setLinea()} Apollo 13. <b>6</b> <u>Kranz was easy to spot among the avalanche of moving bodies and shifting papers</u>. He was dressed, as ever, in his signature<br>
              ${setLinea()} handmade vest.<b>7</b><br>
              ${setLinea()} Kranz's wife, Marta, had begun making vests at his request in the early '60s. <b>8</b> <u>Their was</u> power in a uniform, something Kranz<br>
              ${setLinea()} understood from his years serving overseas. The vests served not as an authoritative mark or <b>9</b> <u>sartorial</u> flair, but a defining symbol for his<br>
              ${setLinea()} team to rally behind. During the effort to save the Apollo 13 crew, Kranz wore his white vest around the clock like perspiration-mottled<br>
              ${setLinea()} battle armor.<br>
              ${setLinea()} <b>10</b> <u>Among</u> meetings and calculations, Kranz and the NASA staff hatched a wild plan. By using the gravitational force of the<br>
              ${setLinea()} moon, <b>11</b> <u>it</u> could slingshot the injured spacecraft back on an earthbound course. It was a long shot, of course, but also their best and<br>
              ${setLinea()} only one. And, due to the tireless efforts of support staff on earth and the intrepid spirit of the Apollo 13 crew, it worked. Six days after<br>
              ${setLinea()} takeoff, all three astronauts splashed down safely in the Pacific Ocean.`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'The flight director paced',
                          checked: false
                       },
                       3: {
                          texto: 'The pacing flight director',
                          checked: false
                       },
                       4: {
                          texto: 'The flight director pacing',
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'adversity',
                          checked: false
                       },
                       3: {
                          texto: 'deadlines',
                          checked: false
                       },
                       4: {
                          texto: 'maladies',
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'space travel: an idea',
                          checked: false
                       },
                       3: {
                          texto: 'space travel, an idea',
                          checked: false
                       },
                       4: {
                          texto: 'space travel. An idea',
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'physics; like lift and drag on wings, as well as proficiency',
                          checked: false
                       },
                       3: {
                          texto: 'physics like lift and drag on wings, as well as proficiency',
                          checked: false
                       },
                       4: {
                          texto: 'physics: like lift and drag on wings—as well as proficiency',
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: 'Which sentence most effectively establishes the main idea of the paragraph?',
                    respuestas: {
                       1: {
                          texto: 'Without his unusual vest, no one would have noticed Kranz in the crowd.',
                          checked: false
                       },
                       2: {
                          texto: 'Kranz stood out as a pillar of strength in the chaos of the command center.',
                          checked: false
                       },
                       3: {
                          texto: 'During the Apollo 11 mission, Kranz earned the badges of honor that now adorned his vest.',
                          checked: false
                       },
                       4: {
                          texto: 'Kranz possessed more years of experience than anyone in the control center.',
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'Among the avalanche of moving bodies and shifting papers, it is easy to spot Kranz.',
                          checked: false
                       },
                       3: {
                          texto: 'Kranz easily spotted the avalanche of moving bodies and shifting papers.',
                          checked: false
                       },
                       4: {
                          texto: 'Kranz is easy to spot among the avalanche of moving bodies and shifting papers.',
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: 'Which sentence provides effective evidence to support the main focus of the paragraph?',
                    respuestas: {
                       1: {
                          texto: 'The engineers looked to the calm man in the homemade vest.',
                          checked: false
                       },
                       2: {
                          texto: 'Many of the men in the Mission Control Center had lengthy military careers.',
                          checked: false
                       },
                       3: {
                          texto: `Kranz's thoughts returned to the many tribulations he had experienced.`,
                          checked: false
                       },
                       4: {
                          texto: 'Several engineers joined together as a bastion of calm in a sea of uncertainty.',
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: `They're was`,
                          checked: false
                       },
                       3: {
                          texto: 'There was',
                          checked: false
                       },
                       4: {
                          texto: 'They were',
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'sanguine',
                          checked: false
                       },
                       3: {
                          texto: 'military',
                          checked: false
                       },
                       4: {
                          texto: 'martial',
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'In spite of',
                          checked: false
                       },
                       3: {
                          texto: 'Amid',
                          checked: false
                       },
                       4: {
                          texto: 'Between',
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'he',
                          checked: false
                       },
                       3: {
                          texto: 'they',
                          checked: false
                       },
                       4: {
                          texto: 'one',
                          checked: false
                       }
                    }
                 }
              }
           },
           4: {
              informacion: `<b>Edgard Varèse's Influence</b>`,
              texto: `${resetLinea()}${setLinea()} Today's music, from rock to jazz, has many <b>1</b> <u>influences. And perhaps none</u> is as unique as the ideas from French composer<br>
              ${setLinea()} Edgard Varèse. Called "the father of electronic music," he approached compositions from a different theoretical perspective than classical<br>
              ${setLinea()} composers such as Bartók and Debussy. He called his <b>2</b> <u>works "organized sound"; they</u> did not <b>3</b><u>endear</u> melodies but waged assaults of<br>
              ${setLinea()} percussion, piano, and human voices. He thought of sounds as having intelligence and treated music spatially, as "sound objects floating<br>
              ${setLinea()} in space."<br>
              ${setLinea()} His unique vision can be credited to his education in science. Born in 1883 in France, Varèse was raised by a great-uncle and<br>
              ${setLinea()} grandfather in the Burgundy region. He was interested in classical music and composed his first opera as a teenager. While the family<br>
              ${setLinea()} lived <b>4</b> <u>in Italy he studied engineering in Turin, where he</u> learned math and science and was inspired by the work of the artist Leonardo da<br>
              ${setLinea()} Vinci.<br>
              ${setLinea()} In 1903, he returned to France to study music at the Paris Conservatory. There, he composed the radical percussion<br>
              ${setLinea()} performance piece Ionisation, which featured cymbals, snares, bass drum, xylophone, and sirens wailing. Later compositions were scored<br>
              ${setLinea()} for the theremin, a new electronic instrument controlled by <b>5</b> <u>the player's hands</u> waving over its antennae, which sense their position. No<br>
              ${setLinea()} composer had ever scored any music for the theremin before.<br>
              ${setLinea()} In his thirties, Varèse moved to New York City, where he played piano in a café and conducted other composers' works until<br>
              ${setLinea()} his own compositions gained success. His piece Amériques was performed in Philadelphia in 1926. Varèse went on to travel to the<br>
              ${setLinea()} western United States, where he recorded, lectured, and collaborated with other musicians. By the 1950s, he was using tape recordings<br>
              ${setLinea()} in <b>6</b> <u>contention</u> with symphonic performance. His piece Déserts was aired on a radio program amid selections by Mozart and Tchaikovsky<br>
              ${setLinea()} but was received by listeners with hostility. <b>7</b><br>
              ${setLinea()} Varèse's ideas were more forward-thinking than could be realized. One of his most ambitious scores, called Espace, was a<br>
              ${setLinea()} choral symphony with multilingual lyrics, which was to be sung simultaneously by choirs in Paris, Moscow, Peking, and New York. He<br>
              ${setLinea()} wanted the timing to be orchestrated by radio, but radio technology did not support worldwide transmission. If only Varèse <b>8</b> <u>had had</u> the<br>
              ${setLinea()} Internet!<br>
              ${setLinea()} Although many of <b>9</b> <u>their</u> written compositions were lost in a fire in 1918, many modern musicians and composers have been influenced<br>
              ${setLinea()} by Varèse, including Frank Zappa, John Luther Adams, and John Cage, who wrote that Varèse is "more relevant to present musical<br>
              ${setLinea()} necessity than even the Viennese masters."<b>10</b> <u>His impact is undeniable</u>. <b>11</b><br><br>
              <img src="${pathImagesExamen}/Ingles/v1-writing-passage-4.png" >`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'influences, and perhaps none is as',
                          checked: false
                       },
                       3: {
                          texto: 'influences, but perhaps none is as',
                          checked: false
                       },
                       4: {
                          texto: 'influences. Or perhaps none is as',
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'works organized sound: They',
                          checked: false
                       },
                       3: {
                          texto: 'works "organized sound", they',
                          checked: false
                       },
                       4: {
                          texto: 'works—organized sound— they',
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'amplify',
                          checked: false
                       },
                       3: {
                          texto: 'deprive',
                          checked: false
                       },
                       4: {
                          texto: 'employ',
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'in Italy, he studied engineering in Turin, where he',
                          checked: false
                       },
                       3: {
                          texto: 'in Italy he studied engineering in Turin where he',
                          checked: false
                       },
                       4: {
                          texto: 'in Italy, he studied engineering in Turin; where he',
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: `the players' hands`,
                          checked: false
                       },
                       3: {
                          texto: 'the players hands',
                          checked: false
                       },
                       4: {
                          texto: `the player's hands'`,
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'conjunction',
                          checked: false
                       },
                       3: {
                          texto: 'appropriation',
                          checked: false
                       },
                       4: {
                          texto: 'supplication',
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: `Which fact, if added to this paragraph, would best support the author's claims?`,
                    respuestas: {
                       1: {
                          texto: 'The critical response to his 1926 performance in Philadelphia',
                          checked: false
                       },
                       2: {
                          texto: 'The selections by Mozart and Tchaikovsky that were played on the radio',
                          checked: false
                       },
                       3: {
                          texto: 'Which specific states he traveled to in the western United States',
                          checked: false
                       },
                       4: {
                          texto: 'The cities in which the radio program was aired',
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'would have had',
                          checked: false
                       },
                       3: {
                          texto: 'would have',
                          checked: false
                       },
                       4: {
                          texto: 'have had',
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'its',
                          checked: false
                       },
                       3: {
                          texto: 'our',
                          checked: false
                       },
                       4: {
                          texto: 'his',
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: 'Which choice completes the sentence with accurate data based on the graphic?',
                    respuestas: {
                       1: {
                          texto: 'Despite being less famous than Stravinsky or Shostakovich,',
                          checked: false
                       },
                       2: {
                          texto: 'Despite there being fewer surviving works than his contemporaries,',
                          checked: false
                       },
                       3: {
                          texto: 'Even though he wrote pieces using a wider range of instruments than other composers,',
                          checked: false
                       },
                       4: {
                          texto: 'Even though far fewer of his works are now performed compared with those of his contemporaries,',
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: `Which sentence should be added to the end of the paragraph to summarize its central idea?`,
                    respuestas: {
                       1: {
                          texto: `Varèse's love of science and mathematics is shown in his later compositions, but less so in his early works.`,
                          checked: false
                       },
                       2: {
                          texto: `In contrast with his newfound popularity, Varèse's early works have long been ignored due to increasing critical hostility.`,
                          checked: false
                       },
                       3: {
                          texto: 'Varèse and his innovative compositions became an inspiration for artists seeking to challenge traditional musical beliefs.',
                          checked: false
                       },
                       4: {
                          texto: `Though Varèse's contemporary critics failed to call him a "Viennese master," this distinction is changing.`,
                          checked: false
                       }
                    }
                 }
              }
           }
        }
     }
  },
  2: {
     reading: {
        passages: {
           1: {
              informacion: `<b>Questions 1-10 are based on the following passage.</b><br>
                   This passage is from Lydia Minatoya, The Strangeness of Beauty. ©1999 by Lydia Minatoya. The setting is Japan<br>
                   in 1920. Chie and her daughter Naomi are members of the House of Fuji, a noble family.`,
              texto: `${resetLinea()}${setLinea()} Akira came directly, breaking all tradition. Was that it? Had he followed form—had he asked his<br>
                   ${setLinea()} mother to speak to his father to approach a go-between—would Chie have been more receptive?<br><br>
                   ${setLinea()} He came on a winter’s eve. He pounded on the door while a cold rain beat on the shuttered veranda,<br>
                   ${setLinea()} so at first Chie thought him only the wind. The maid knew better. Chie heard her soft scuttling<br>
                   ${setLinea()} footsteps, the creak of the door. Then the maid brought a calling card to the drawing room, for Chie.<br><br>
                   ${setLinea()} Chie was reluctant to go to her guest; perhaps she was feeling too cozy. She and Naomi were reading<br>
                   ${setLinea()} at a low table set atop a charcoal brazier. A thick quilt spread over the sides of the table so their legs<br>
                   ${setLinea()} were tucked inside with the heat.<br><br>
                   ${setLinea()} “Who is it at this hour, in this weather?” Chie questioned as she picked the name card off the maid’s<br>
                   ${setLinea()} lacquer tray.<br><br>
                   ${setLinea()} “Shinoda, Akira. Kobe Dental College,” she read.<br><br>
                   ${setLinea()} Naomi recognized the name. Chie heard a soft intake of air.<br><br>
                   ${setLinea()} “I think you should go,” said Naomi.<br><br>
                   ${setLinea()} Akira was waiting in the entry. He was in his early twenties, slim and serious, wearing the black<br>
                   ${setLinea()} military-style uniform of a student. Ashe bowed—his hands hanging straight down, a<br>
                   ${setLinea()} black cap in one, a yellow oil-paper umbrella in the other—Chie glanced beyond him. In the<br>
                   ${setLinea()} glistening surface of the courtyard’s rain-drenched paving stones, she saw his reflection like a dark<br>
                   ${setLinea()} double.<br><br> 
                   ${setLinea()} “Madame,” said Akira, “forgive my disruption, but I come with a matter of urgency.”<br><br>
                   ${setLinea()} His voice was soft, refined. He straightened and stole a deferential peek at her face.<br>
                   ${setLinea()} In the dim light his eyes shone with sincerity. Chie felt herself starting to like him.<br><br>
                   ${setLinea()} “Come inside, get out of this nasty night. Surely your business can wait for a moment or two.”<br><br>
                   ${setLinea()} “I don’t want to trouble you. Normally I would approach you more properly but I’ve received word of<br>
                   ${setLinea()} a position. I’ve an opportunity to go to America, as dentist for Seattle’s Japanese community.”<br><br>
                   ${setLinea()} “Congratulations,” Chie said with amusement. “That is an opportunity, I’m sure. But how am I<br>
                   ${setLinea()} involved?”<br><br>
                   ${setLinea()} Even noting Naomi’s breathless reaction to the name card, Chie had no idea. Akira’s message,<br>
                   ${setLinea()} delivered like a formal speech, filled her with maternal amusement. You know how children speak<br>
                   ${setLinea()} soearnestly, so hurriedly, so endearingly about things that have no importance in an adult’s mind?<br>
                   ${setLinea()} That’s how she viewed him, as a child.<br><br>
                   ${setLinea()} It was how she viewed Naomi. Even though Naomi was eighteen and training endlessly in the arts<br>
                   ${setLinea()} needed to make a good marriage, Chie had made no effort to find her a husband.<br><br>
                   ${setLinea()} Akira blushed.<br><br>
                   ${setLinea()} “Depending on your response, I may stay in Japan. I’ve come to ask for Naomi’s hand.”<br><br>
                   ${setLinea()} Suddenly Chie felt the dampness of the night. “Does Naomi know anything of your...<br>
                   ${setLinea()} ambitions?”<br><br>
                   ${setLinea()} “We have an understanding. Please don’t judge my candidacy by the unseemliness of this proposal. I<br>
                   ${setLinea()} ask directly because the use of a go-between takes much time. Either method comes down to the<br>
                   ${setLinea()} same thing: a matter of parental approval. If you give your consent, I become Naomi’s yoshi.<sup>*</sup> We’ll<br>
                   ${setLinea()} live in the House of Fuji. Without your consent, I must go to America, to secure a new home for my<br>
                   ${setLinea()} bride.”<br><br>
                   ${setLinea()} Eager to make his point, he’d been looking her full in the face. Abruptly, his voice turned gentle. “I see<br>
                   ${setLinea()} I’ve startled you. My humble apologies. I’ll take no more of your evening. My address is on my card. If<br>
                   ${setLinea()} you don’t wish to contact me, I’ll reapproach you in two weeks’ time. Until then, good night.”<br><br>
                   ${setLinea()} He bowed and left. Taking her ease, with effortless grace, like a cat making off with a fish.<br><br>
                   ${setLinea()} “Mother?” Chie heard Naomi’s low voice and turned from the door. “He has asked you?”<br><br>
                   ${setLinea()} The sight of Naomi’s clear eyes, her dark brows gave Chie strength. Maybe his hopes were<br>
                   ${setLinea()} preposterous.<br><br>
                   ${setLinea()} “Where did you meet such a fellow? Imagine! He thinks he can marry the Fuji heir and take her to<br>
                   ${setLinea()} America all in the snap of his fingers!”<br><br>
                   ${setLinea()} Chie waited for Naomi’s ripe laughter.<br><br>
                   ${setLinea()} Naomi was silent. She stood a full half minute looking straight into Chie’s eyes. Finally, she spoke. “I<br>
                   ${setLinea()} met him at my literary meeting.”<br><br>
                   ${setLinea()} Naomi turned to go back into the house, then stopped.<br><br>
                   ${setLinea()} “Mother.”<br><br>
                   ${setLinea()} “Yes?”<br><br>
                   ${setLinea()} “I mean to have him.”<br><br>
                   ${setLinea()} <span><sup>*</sup>a man who marries a woman of higher status and takes her family’s name</span`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: `1. INFORMATION AND IDEAS—EASY<br>
                       Which choice best describes what happens in the passage?`,
                    respuestas: {
                       1: {
                          texto: `One character argues with another character who intrudes on her home.`,
                          checked: false
                       },
                       2: {
                          texto: `One character receives a surprising request from another character.`,
                          checked: false
                       },
                       3: {
                          texto: `One character reminisces about choices she has made over the years.`,
                          checked: false
                       },
                       4: {
                          texto: `One character criticizes another character for pursuing an unexpected course of action.`,
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: `2. SYNTHESIS—MEDIUM<br>
                       Which choice best describes the developmental pattern of the passage?`,
                    respuestas: {
                       1: {
                          texto: `A careful analysis of a traditional practice`,
                          checked: false
                       },
                       2: {
                          texto: `A detailed depiction of a meaningful encounter`,
                          checked: false
                       },
                       3: {
                          texto: `A definitive response to a series of questions`,
                          checked: false
                       },
                       4: {
                          texto: `A cheerful recounting of an amusing anecdote`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: `3. RHETORIC—HARD<br>
                    As used in line 1 and line 65, “directly” most nearly means`,
                    respuestas: {
                       1: {
                          texto: `frankly.`,
                          checked: false
                       },
                       2: {
                          texto: `confidently.`,
                          checked: false
                       },
                       3: {
                          texto: `without mediation.`,
                          checked: false
                       },
                       4: {
                          texto: `with precision.`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: `4. INFORMATION AND IDEAS—MEDIUM<br>
                       Which reaction does Akira most fear from Chie? `,
                    respuestas: {
                       1: {
                          texto: `She will consider his proposal inappropriate.`,
                          checked: false
                       },
                       2: {
                          texto: `She will mistake his earnestness for immaturity.`,
                          checked: false
                       },
                       3: {
                          texto: `She will consider his unscheduled visit an imposition.`,
                          checked: false
                       },
                       4: {
                          texto: `She will underestimate the sincerity of his emotions.`,
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: `5. INFORMATION AND IDEAS—MEDIUM<br>
                       Which choice provides the best evidence for the answer to the previous question? `,
                    respuestas: {
                       1: {
                          texto: `Line 33 (“His voice . . . refined”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 49-51 (“You . . . mind”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 63-64 (“Please . . . proposal”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 71-72 (“Eager . . . face”)`,
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: `6. RHETORIC—HARD<br>
                       In the passage, Akira addresses Chie with `,
                    respuestas: {
                       1: {
                          texto: `affection but not genuine love.`,
                          checked: false
                       },
                       2: {
                          texto: `objectivity but not complete impartiality.`,
                          checked: false
                       },
                       3: {
                          texto: `amusement but not mocking disparagement.`,
                          checked: false
                       },
                       4: {
                          texto: `respect but not utter deference.`,
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: `7. INFORMATION AND IDEAS—EASY<br>
                       The main purpose of the first paragraph is to `,
                    respuestas: {
                       1: {
                          texto: `describe a culture.`,
                          checked: false
                       },
                       2: {
                          texto: `criticize a tradition.`,
                          checked: false
                       },
                       3: {
                          texto: `question a suggestion.`,
                          checked: false
                       },
                       4: {
                          texto: `analyze a reaction.`,
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: `8. RHETORIC—EASY<br>
                       The main purpose of the first paragraph is to`,
                    respuestas: {
                       1: {
                          texto: `describe a culture.`,
                          checked: false
                       },
                       2: {
                          texto: `criticize a tradition.`,
                          checked: false
                       },
                       3: {
                          texto: `question a suggestion.`,
                          checked: false
                       },
                       4: {
                          texto: `analyze a reaction.`,
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: `9. INFORMATION AND IDEAS—EASY<br>
                       Why does Akira say his meeting with Chie is “a matter of urgency” (line 32)?`,
                    respuestas: {
                       1: {
                          texto: `He fears that his own parents will disapprove of Naomi.`,
                          checked: false
                       },
                       2: {
                          texto: `He worries that Naomi will reject him and marry someone else.`,
                          checked: false
                       },
                       3: {
                          texto: `He has been offered an attractive job in another country.`,
                          checked: false
                       },
                       4: {
                          texto: `He knows that Chie is unaware of his feelings for Naomi.`,
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: `10. INFORMATION AND IDEAS—EASY<br>
                       Which choice provides the best evidence for the answer to the previous question?`,
                    respuestas: {
                       1: {
                          texto: `Line 39 (“I don’t . . . you”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 39-42 (“Normally . . . community”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 58-59 (“Depending . . . Japan”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 72-73 (“I see . . . you”)`,
                          checked: false
                       }
                    }
                 }
              }
           },
           2: {
              informacion: `<b>Questions 11-21 are based on the following passage.</b><br>
                   This passage is adapted from Daniel Chamovitz, What a Plant Knows: A Field Guide to the Senses. ©2012 by<br>
                   Daniel Chamovitz.`,
              texto: `${resetLinea()}${setLinea()} The Venus flytrap [Dionaea muscipula] needs to know when an ideal meal is crawling across its<br>
                   ${setLinea()} leaves. Closing its trap requires a huge expense of energy, and reopening the trap can take several<br>
                   ${setLinea()} hours, so Dionaea only wants to spring closed when it’s sure that the dawdling insect visiting its<br>
                   ${setLinea()} surface is large enough to be worth its time. The large black hairs on their lobes allow the Venus<br>
                   ${setLinea()} flytraps to literally feel their prey, and they act as triggers that spring the trap closed when the<br>
                   ${setLinea()} proper prey makes its way across the trap. If the insect touches just one hair, the trap will not spring<br>
                   ${setLinea()} shut; but a large enough bug will likely touch two hairs within about twenty seconds, and that signal<br>
                   ${setLinea()} springs the Venus flytrap into action.<br><br>
                   ${setLinea()} We can look at this system as analogous to short-term memory. First, the flytrap encodes the<br>
                   ${setLinea()} information (forms the memory) that something (it doesn’t know what) has touched one of its hairs.<br>
                   ${setLinea()} Then it stores this information for a number of seconds(retains the memory) and finally retrieves<br>
                   ${setLinea()} this information (recalls the memory) once a second hair is touched. If a small ant takes a while to get<br>
                   ${setLinea()} from one hair to the next, the trap will have forgotten the first touch by the time the ant brushes up<br>
                   ${setLinea()} against the next hair. In other words, it loses the storage of the information, doesn’t close, and the ant<br>
                   ${setLinea()} happily meanders on. How does the plant encode and store the information from the unassuming<br>
                   ${setLinea()} bug’s encounter with the first hair? How does it remember the first touch in order to react upon the<br>
                   ${setLinea()} second?<br><br>
                   ${setLinea()} Scientists have been puzzled by these questions ever since John Burdon-Sanderson’s early report on<br>
                   ${setLinea()} the physiology of the Venus flytrap in 1882. A century later, Dieter Hodick and Andreas Sievers at the<br>
                   ${setLinea()} University of Bonn in Germany proposed that the flytrap stored information regarding how many<br>
                   ${setLinea()} hairs have been touched in the electric charge of its leaf. Their model is quite elegant in its simplicity.<br>
                   ${setLinea()} In their studies, they discovered that touching a trigger hair on the Venus flytrap causes an electric<br>
                   ${setLinea()} action potential [a temporary reversal in the electrical polarity of a cell membrane] that induces<br>
                   ${setLinea()} calcium channels to open in the trap (this coupling of action potentials and the opening of<br>
                   ${setLinea()} calcium channels is similar to the processes that occur during communication between human<br>
                   ${setLinea()} neurons), thus causing a rapid increase in the concentration of calcium ions.<br><br>
                   ${setLinea()} They proposed that the trap requires a relatively high concentration of calcium in order to close<br>
                   ${setLinea()} and that a single action potential from just one trigger hair being touched does not reach this level.<br>
                   ${setLinea()} Therefore, a second hair needs to be stimulated to push the calcium concentration over this<br>
                   ${setLinea()} threshold and spring the trap. The encoding of the information requires maintaining a high enough<br>
                   ${setLinea()} level of calcium so that a second increase (triggered by touching the second hair) pushes the total<br>
                   ${setLinea()} concentration of calcium over the threshold. As the calcium ion concentrations dissipate over time, if<br>
                   ${setLinea()} the second touch and potential don’t happen quickly, the final concentration after the second trigger<br>
                   ${setLinea()} won’t be high enough to close the trap, and the memory is lost.<br><br>
                   ${setLinea()} Subsequent research supports this model. Alexander Volkov and his colleagues at Oakwood<br>
                   ${setLinea()} University in Alabama first demonstrated that it is indeed electricity that causes the Venus flytrap to<br>
                   ${setLinea()} close. To test the model they rigged up very fine electrodes and applied an electrical current to the<br>
                   ${setLinea()} open lobes of the trap. This made the trap close without any direct touch to its trigger hairs (while<br>
                   ${setLinea()} they didn’t measure calcium levels, the current likely led to increases). When they modified this<br>
                   ${setLinea()} experiment by altering the amount of electrical current, Volkov could determine the exact electrical<br>
                   ${setLinea()} charge needed for the trap to close. As long as fourteen microcoulombs—a tiny bit more than the<br>
                   ${setLinea()} static electricity generated by rubbing two balloons together—flowed between the two electrodes,<br>
                   ${setLinea()} the trap closed. This could come as one large burst or as a series of smaller charges within twenty<br>
                   ${setLinea()} seconds. If it took longer than twenty seconds to accumulate the total charge, the trap would remain<br>
                   ${setLinea()} open.`,
              preguntas: {
                 1: {
                    indicePregunta: '11',
                    enunciado: `11. INFORMATION AND IDEAS—EASY<br>
                       The primary purpose of the passage is to`,
                    respuestas: {
                       1: {
                          texto: `discuss findings that offer a scientific explanation for the Venus flytrap’s closing action.`,
                          checked: false
                       },
                       2: {
                          texto: `present research that suggests that the Venus flytrap’s predatory behavior is both complex and unique among plants.`,
                          checked: false
                       },
                       3: {
                          texto: `identify the process by which the Venus flytrap’s closing action has evolved.`,
                          checked: false
                       },
                       4: {
                          texto: `provide a brief overview of the Venus flytrap and its predatory behavior.`,
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '12',
                    enunciado: `12. INFORMATION AND IDEAS—MEDUM<br>
                       Based on the passage, a significant advantage of the Venus flytrap’s requirement for multiple triggers is that it`,
                    respuestas: {
                       1: {
                          texto: `enables the plant to identify the species of its prey.`,
                          checked: false
                       },
                       2: {
                          texto: `conserves the plant’s calcium reserves.`,
                          checked: false
                       },
                       3: {
                          texto: `safeguards the plant’s energy supply.`,
                          checked: false
                       },
                       4: {
                          texto: `prevents the plant from closing before capturing its prey.`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '13',
                    enunciado: `13. INFORMATION AND IDEAS—MEDIUM<br>
                    Which choice provides the best evidence for the answer to the previous question?`,
                    respuestas: {
                       1: {
                          texto: `Lines 3-7 (“Closing . . . time”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 7-11 (“The large . . . across the trap”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 11-14 (“If the . . . action”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 16-18 (“First . . . hairs”)`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '14',
                    enunciado: `14. RHETORIC—MEDIUM<br>
                    The use of the phrases “dawdling insect” (line 6), “happily meanders” (line 27), and “unassuming bug’s encounter” (lines 28-29) in the first two paragraphs establishes a tone that is`,
                    respuestas: {
                       1: {
                          texto: `academic.`,
                          checked: false
                       },
                       2: {
                          texto: `melodramatic.`,
                          checked: false
                       },
                       3: {
                          texto: `informal.`,
                          checked: false
                       },
                       4: {
                          texto: `mocking.`,
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '15',
                    enunciado: `15. RHETORIC—HARD<br>
                    In the second paragraph (lines 15-31), the discussion of short-term memory primarily functions to`,
                    respuestas: {
                       1: {
                          texto: `clarify an explanation of what prompts the Venus flytrap to close.`,
                          checked: false
                       },
                       2: {
                          texto: `advance a controversial hypothesis about the function of electric charges found in theleaf of the Venus flytrap.`,
                          checked: false
                       },
                       3: {
                          texto: `stress the distinction between the strategies of the Venus flytrap and the strategies of human beings.`,
                          checked: false
                       },
                       4: {
                          texto: `emphasize the Venus flytrap’s capacity for retaining detailed information about its prey.`,
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '16',
                    enunciado: `16. INFORMATION AND IDEAS—EASY<br>
                    According to the passage, which statement best explains why the Venus flytrap requires a second trigger hair to be touched within a short amount of time in order for its trap to close?`,
                    respuestas: {
                       1: {
                          texto: `The second trigger produces an electrical charge that reverses the charge produced by the first trigger.`,
                          checked: false
                       },
                       2: {
                          texto: `The second trigger stabilizes the surge of calcium ions created by the first trigger.`,
                          checked: false
                       },
                       3: {
                          texto: `The second trigger prompts the calcium channels to open.`,
                          checked: false
                       },
                       4: {
                          texto: `The second trigger provides a necessary supplement to the calcium concentration released by the first trigger.`,
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '17',
                    enunciado: `17. INFORMATION AND IDEAS—MEDIUM<br>
                    Which choice describes a scenario in which Hodick and Sievers’s model predicts that a Venus flytrap will NOT close around an insect?`,
                    respuestas: {
                       1: {
                          texto: `A large insect’s second contact with the plant’s trigger hairs results in a total calcium ion concentration above the trap’s threshold.`,
                          checked: false
                       },
                       2: {
                          texto: `A large insect makes contact with a second trigger hair after a period of inactivity during which calcium ion concentrations have diminished appreciably.`,
                          checked: false
                       },
                       3: {
                          texto: `A large insect’s contact with the plant’s trigger hairs causes calcium channels to open in
                          the trap.`,
                          checked: false
                       },
                       4: {
                          texto: `A large insect’s contact with a second trigger hair occurs within ten seconds of its contact with the first trigger hair.`,
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '18',
                    enunciado: `18. RHETORIC—EASY<br>
                    As used in line 67, “demonstrated” most nearly means`,
                    respuestas: {
                       1: {
                          texto: `protested.`,
                          checked: false
                       },
                       2: {
                          texto: `established.`,
                          checked: false
                       },
                       3: {
                          texto: `performed.`,
                          checked: false
                       },
                       4: {
                          texto: `argued.`,
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '19',
                    enunciado: `19. SYNTHESIS—HARD<br>
                    Based on the passage, what potential criticism might be made of Volkov’s testing of Hodick and
                    Sievers’s model?`,
                    respuestas: {
                       1: {
                          texto: `Volkov’s understanding of Hodick and Sievers’s model was incorrect.`,
                          checked: false
                       },
                       2: {
                          texto: `Volkov’s measurements did not corroborate a central element of Hodick and Sievers’s model.`,
                          checked: false
                       },
                       3: {
                          texto: `Volkov’s direct application of an electrical current would have been objectionable to Hodick and Sievers.`,
                          checked: false
                       },
                       4: {
                          texto: `Volkov’s technology was not available to Hodick and Sievers.`,
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '20',
                    enunciado: `20. INFORMATION AND IDEAS—MEDIUM<br>
                    Which choice provides the best evidence for the answer to the previous question?`,
                    respuestas: {
                       1: {
                          texto: `Lines 66-69 (“Alexander . . . close”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 69-71 (“To test . . . trap”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 71-74 (“This . . . increases”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 74-77 (“When . . . close”)`,
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '21',
                    enunciado: `21. SYNTHESIS—MEDIUM<br>
                    Based on the passage, in studying the Venus flytrap, Volkov and his colleagues made the most extensive use of which type of evidence?`,
                    respuestas: {
                       1: {
                          texto: `Mathematical models to predict the electrical charge required to close the Venus flytrap`,
                          checked: false
                       },
                       2: {
                          texto: `Analysis of data collected from previous researchers’ work involving the Venus flytrap’s response to electricity`,
                          checked: false
                       },
                       3: {
                          texto: `Information obtained from monitoring the Venus flytrap’s response to varying amounts of electrical current`,
                          checked: false
                       },
                       4: {
                          texto: `Published theories of scientists who developed earlier models of the Venus flytrap`,
                          checked: false
                       }
                    }
                 }
              }
           },
           3: {
              informacion: `<b>Questions 22-30 are based on the following passages.</b><br>
                   Passage 1 is adapted from Catharine Beecher, Essay on Slavery and Abolitionism. Originally published in 1837.<br>
                   Passage 2 is adapted from Angelina E. Grimké, Letters to Catharine Beecher. Originally published in 1838. Grimké encouraged Southern women to oppose slavery publicly.<br>
                   Passage 1 is Beecher’s response to Grimké’s views. Passage 2 is Grimké’s response to Beecher.`,
              texto: `${resetLinea()}<b>Passage 1</b><br>
                   ${setLinea()} Heaven has appointed to one sex the superior, and to the other the subordinate station, and this<br>
                   ${setLinea()} without any reference to the character or conduct of either. It is therefore as much for the dignity as<br>
                   ${setLinea()} it is for the interest of females, in all respects to conform to the duties of this relation. . . . But while<br>
                   ${setLinea()} woman holds a subordinate relation in society to the other sex, it is not because it was designed that<br>
                   ${setLinea()} her duties or her influence should be any the less important, or all-pervading. But it was designed<br>
                   ${setLinea()} that the mode of gaining influence and of exercising power should be altogether different and<br>
                   ${setLinea()} peculiar...<br><br>
                   ${setLinea()} A man may act on society by the collision of intellect, in public debate; he may urge his measures<br>
                   ${setLinea()} by a sense of shame, by fear and by personal interest; he may coerce by the combination of public<br>
                   ${setLinea()} sentiment; he may drive by physical force, and he does not outstep the boundaries of his sphere. But<br>
                   ${setLinea()} all the power, and all the conquests that are lawful to woman, are those only which appeal to the<br>
                   ${setLinea()} kindly, generous, peaceful and benevolent principles.<br><br>
                   ${setLinea()} Woman is to win every thing by peace and love; by making herself so much respected, esteemed and<br>
                   ${setLinea()} loved, that to yield to her opinions and to gratify her wishes, will be the free-will offering of the heart.<br>
                   ${setLinea()} But this is to be all accomplished in the domestic and social circle. There let every woman become so<br>
                   ${setLinea()} cultivated and refined in intellect, that her taste and judgment will be respected; so benevolent in<br>
                   ${setLinea()} feeling and action; that her motives will be reverenced;—so unassuming and unambitious, that<br>
                   ${setLinea()} collision and competition will be banished;—so “gentle and easy to be entreated,” as that every heart<br>
                   ${setLinea()} will repose in her presence; then, the fathers, the husbands, and the sons, will find an influence<br>
                   ${setLinea()} thrown around them, to which they will yield not only willingly but proudly...<br><br>
                   ${setLinea()} A woman may seek the aid of co-operation and combination among her own sex, to assist her in her<br>
                   ${setLinea()} appropriate offices of piety, charity, maternal and domestic duty; but whatever, in any measure,<br>
                   ${setLinea()} throws a woman into the attitude of a combatant, either for herself or others—whatever binds her in<br>
                   ${setLinea()} a party conflict—whatever obliges her in any way to exert coercive influences, throws her out of her<br>
                   ${setLinea()} appropriate sphere. If these general principles are correct, they are entirely opposed to the plan of<br>
                   ${setLinea()} arraying females in any Abolition movement.<br><br>
                   <b>Passage 2</b><br>
                   ${setLinea()} The investigation of the rights of the slave has led me to a better understanding of my own. I have<br>
                   ${setLinea()} found the Anti-Slavery cause to be the high school of morals in our land—the school in which human<br>
                   ${setLinea()} rights are more fully investigated, and better understood and taught, than in any other. Here a great<br>
                   ${setLinea()} fundamental principle is uplifted and illuminated, and from this central light, rays innumerable<br>
                   ${setLinea()} stream all around.<br><br>
                   ${setLinea()} Human beings have rights, because they are moral beings: the rights of all men grow out of their<br>
                   ${setLinea()} moral nature; and as all men have the same moral nature, they have essentially the same rights.<br>
                   ${setLinea()} These rights may be wrested from the slave, but they cannot be alienated: his title to himself is as<br>
                   ${setLinea()} perfect now, as is that of Lyman Beecher:<sup>1</sup> it is stamped on his moral being, and is, like it,<br>
                   ${setLinea()} imperishable. Now if rights are founded in the nature of our moral being, then the mere circumstance<br>
                   ${setLinea()} of sex does not give to man higher rights and responsibilities, than to woman. To suppose that it does,<br>
                   ${setLinea()} would be to deny the self-evident truth, that the “physical constitution is the mere instrument of the<br>
                   ${setLinea()} moral nature.” To suppose that it does, would be to break up utterly the relations, of the two natures,<br>
                   ${setLinea()} and to reverse their functions, exalting the animal nature into a monarch, and humbling the moral<br>
                   ${setLinea()} into a slave; making the former a proprietor, and the latter its property.<br><br>
                   ${setLinea()} When human beings are regarded as moral beings, sex, instead of being enthroned upon the summit,<br>
                   ${setLinea()} administering upon rights and responsibilities, sinks into insignificance and nothingness. My<br>
                   ${setLinea()} doctrine then is, that whatever it is morally right for man to do, it is morally right for woman to do.<br>
                   ${setLinea()} Our duties originate, not from difference of sex, but from the diversity of our relations in life, the<br>
                   ${setLinea()} various gifts and talents committed to our care, and the different eras in which we live.<br>
                   ${setLinea()} <span><sup>1</sup> Lyman Beecher was a famous minister and the father of Catharine Beecher.</span> `,
              preguntas: {
                 1: {
                    indicePregunta: '22',
                    enunciado: `22. INFORMATION AND IDEAS—EASY<br>
                    In Passage 1, Beecher makes which point about the status of women relative to that of men?`,
                    respuestas: {
                       1: {
                          texto: `Women depend on men for their safety and security, but men are largely independent of women.`,
                          checked: false
                       },
                       2: {
                          texto: `Women are inferior to men, but women play a role as significant as that played by men.`,
                          checked: false
                       },
                       3: {
                          texto: `Women have fewer rights than men do, but women also have fewer responsibilities.`,
                          checked: false
                       },
                       4: {
                          texto: `Women are superior to men, but tradition requires women to obey men.`,
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '23',
                    enunciado: `23. SYNTHESIS—MEDIUM<br>
                    Which choice provides the best evidence for the answer to the previous question?`,
                    respuestas: {
                       1: {
                          texto: `Lines 6-10 (“But . . . all-pervading”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 13-14 (“A man . . . debate”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 16-18 (“he may coerce . . . sphere”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 41-46 (“but whatever . . . sphere”)`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '24',
                    enunciado: `24. INFORMATION AND IDEAS—MEDIUM<br>
                    In Passage 1, Beecher implies that women’s effect on public life is largely`,
                    respuestas: {
                       1: {
                          texto: `overlooked, because few men are interested in women’s thoughts about politics.`,
                          checked: false
                       },
                       2: {
                          texto: `indirect, because women exert their influence within the home and family life.`,
                          checked: false
                       },
                       3: {
                          texto: `unnecessary, because men are able to govern society themselves.`,
                          checked: false
                       },
                       4: {
                          texto: `symbolic, because women tend to be more idealistic about politics than men are.`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '25',
                    enunciado: `25. RHETORIC—EASY<br>
                    As used in line 2, “station” most nearly means`,
                    respuestas: {
                       1: {
                          texto: `region.`,
                          checked: false
                       },
                       2: {
                          texto: `studio.`,
                          checked: false
                       },
                       3: {
                          texto: `district.`,
                          checked: false
                       },
                       4: {
                          texto: `rank.`,
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '26',
                    enunciado: `26. RHETORIC—MEDIUM<br>
                    As used in line 12, “peculiar” most nearly means`,
                    respuestas: {
                       1: {
                          texto: `eccentric.`,
                          checked: false
                       },
                       2: {
                          texto: `surprising.`,
                          checked: false
                       },
                       3: {
                          texto: `distinctive.`,
                          checked: false
                       },
                       4: {
                          texto: `infrequent.`,
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '27',
                    enunciado: `27. INFORMATION AND IDEAS—MEDIUM<br>
                    What is Grimké’s central claim in Passage 2?`,
                    respuestas: {
                       1: {
                          texto: `The rights of individuals are not determined by race or gender.`,
                          checked: false
                       },
                       2: {
                          texto: `Men and women must learn to work together to improve society.`,
                          checked: false
                       },
                       3: {
                          texto: `Moral rights are the most important distinction between human beings and animals.`,
                          checked: false
                       },
                       4: {
                          texto: `Men and women should have equal opportunities to flourish.`,
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '28',
                    enunciado: `28. INFORMATION AND IDEAS—EASY<br>
                    In Passage 2, Grimké makes which point about human rights?`,
                    respuestas: {
                       1: {
                          texto: `They are viewed differently in various cultures around the world.`,
                          checked: false
                       },
                       2: {
                          texto: `They retain their moral authority regardless of whether they are recognized by law.`,
                          checked: false
                       },
                       3: {
                          texto: `They are sometimes at odds with moral responsibilities.`,
                          checked: false
                       },
                       4: {
                          texto: `They have become more advanced and refined throughout history.`,
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '29',
                    enunciado: `29. SYNTHESIS—HARD<br>
                    Which choice provides the best evidence for the answer to the previous question?`,
                    respuestas: {
                       1: {
                          texto: `Lines 58-61 (“Human . . . same rights”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 61-65 (“These . . . imperishable”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 71-76 (“To suppose . . . property”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 77-81 (“When . . . nothingness”)`,
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '30',
                    enunciado: `30. SYNTHESIS—MEDIUM<br>
                    Which choice best states the relationship between the two passages?`,
                    respuestas: {
                       1: {
                          texto: `Passage 2 illustrates the practical difficulties of a proposal made in Passage 1.`,
                          checked: false
                       },
                       2: {
                          texto: `Passage 2 takes issue with the primary argument of Passage 1.`,
                          checked: false
                       },
                       3: {
                          texto: `Passage 2 provides a historical context for the perspective offered in Passage 1.`,
                          checked: false
                       },
                       4: {
                          texto: `Passage 2 elaborates upon several ideas implied in Passage 1.`,
                          checked: false
                       }
                    }
                 }
              }
           }
        }
     },
     writing: {
        passages: {
           1: {
              informacion: '<b>Will You Succeed with Your Start-Up?</b>',
              texto: `${resetLinea()}${setLinea()} According to research from Harvard Business School, the majority of small businesses <b>1</b> <u>fail in fact</u> the success rate for a first-<br>
              ${setLinea()} time company owner is a meager 18 percent. With odds so dismal, why would anyone become a business entrepreneur?<br>
              ${setLinea()} Veteran entrepreneurs achieve a higher 30 percent success rate, so the most predictive factor for success appears to be the number of<br>
              ${setLinea()} innovations that a person has "pushed out." More specifically, the people who succeed at building a robust start-up are the ones who<br>
              ${setLinea()} have previously tried. Finally, many entrepreneurs <b>2</b> <u>grab</u> the idea for their business by solving practical problems, and it's more than luck;<br>
              ${setLinea()} 320 new entrepreneurs out of 100,000 do succeed by starting a company at the right time in the right industry. <b>3</b><br>
              ${setLinea()} Mitch Gomez is evidence of this data. He <b>4</b> <u>did graduate</u> from college with a degree in accounting. "I quickly realized that I have too big of<br>
              ${setLinea()} a personality to be content practicing accounting," he laughs. First, he built a successful insurance claims <b>5</b> <u>service, and next</u> founded his<br>
              ${setLinea()} own independent insurance agency. "I continually employ my accounting skills, but I've ascertained that I'm an even more effective<br>
              ${setLinea()} salesperson."<br>
              ${setLinea()} Similarly, Barbara Vital, the woman behind Vital Studio, explains, "I love spending as much time with my family as possible."<br>
              ${setLinea()} Vital saw an opportunity to <b>6</b> <u>launch</u> a monogramming business when her two young sons started school, so she founded a company that<br>
              ${setLinea()} offers monogrammed backpacks and water bottles for kids, as well as <b>7</b> <u>totes, rain boots; and</u> baseball caps for college students. What is<br>
              ${setLinea()} the secret to Vital's success? "I'm always learning how to incorporate social media and add functionality to my product website to keep<br>
              ${setLinea()} customers happy," she says.<br>
              ${setLinea()} Finally, Chris Roth is an entrepreneur who can step out of his comfort zone. Always seeking a new <b>8</b> <u>challenge his<br>
              ${setLinea()} company</u> designed and manufactured technology to keep the nozzles of water misting systems clean. Roth has also established a<br>
              ${setLinea()} corporate travel agency and a truck customization company, most recently claiming he has become an innovator who beat the odds by<br>
              ${setLinea()} "striving to serve customers better than my competition." <b>9</b><br>
              ${setLinea()} Gomez, Vital, and Roth <b>10/b> <u>agrees</u> that although being an entrepreneur can be a formidable challenge, exceptionally skillful<br>
              ${setLinea()} entrepreneurs have important strategies for success, including stretching <b>11</b> <u>his</u> personal boundaries and recovering from failures. "And<br>
              ${setLinea()} nothing beats being your own boss," adds Gomez.`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'fail, in fact,',
                          checked: false
                       },
                       3: {
                          texto: 'fail; in fact,',
                          checked: false
                       },
                       4: {
                          texto: 'fail: in fact',
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'derive',
                          checked: false
                       },
                       3: {
                          texto: 'achieve',
                          checked: false
                       },
                       4: {
                          texto: 'grasp',
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: 'Which sentence could be added to the beginning of the paragraph to most effectively establish the central idea?',
                    respuestas: {
                       1: {
                          texto: 'Many people desire the freedom of being their own boss, but to be successful, an entrepreneur must also be productive persistent, and creative.',
                          checked: false
                       },
                       2: {
                          texto: 'The Small Business Administration defines a small business as one with fewer than 500 employees and less than $7 million in sales annually.',
                          checked: false
                       },
                       3: {
                          texto: 'Many small businesses fail because company founders are not realistic about the amount of time it takes for a company to become profitable.',
                          checked: false
                       },
                       4: {
                          texto: `Running a small business can take up a lot more time than punching a clock for someone else and might not be enjoyable for everyone.`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'has graduated',
                          checked: false
                       },
                       3: {
                          texto: 'graduated',
                          checked: false
                       },
                       4: {
                          texto: 'would have graduated',
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'service. And next',
                          checked: false
                       },
                       3: {
                          texto: 'service and next',
                          checked: false
                       },
                       4: {
                          texto: 'service; and next',
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: `NO CHANGE`,
                          checked: false
                       },
                       2: {
                          texto: 'present',
                          checked: false
                       },
                       3: {
                          texto: 'propel',
                          checked: false
                       },
                       4: {
                          texto: 'impact',
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'totes; rain boots; and',
                          checked: false
                       },
                       3: {
                          texto: 'totes, rain boots, and,',
                          checked: false
                       },
                       4: {
                          texto: 'totes, rain boots, and',
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'challenge: his company',
                          checked: false
                       },
                       3: {
                          texto: 'challenge; his company',
                          checked: false
                       },
                       4: {
                          texto: 'challenge, his company',
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: 'Which sentence could be added to this paragraph to provide support for the central idea?',
                    respuestas: {
                       1: {
                          texto: 'Large companies often employ corporate travel agencies to arrange travel for their employees and clients.',
                          checked: false
                       },
                       2: {
                          texto: 'Savvy entrepreneurs know which risks are worth taking and which risks can tank their business before their doors open.',
                          checked: false
                       },
                       3: {
                          texto: `Now Roth's small business installs water misters on restaurant patios and even sets up misting stations at outdoor music festivals.`,
                          checked: false
                       },
                       4: {
                          texto: 'Many new small businesses fail because company founders fail to do market research and identify the needs of their community.',
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'agree',
                          checked: false
                       },
                       3: {
                          texto: 'should agree',
                          checked: false
                       },
                       4: {
                          texto: 'had agreed',
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'their',
                          checked: false
                       },
                       3: {
                          texto: 'our',
                          checked: false
                       },
                       4: {
                          texto: 'her',
                          checked: false
                       }
                    }
                 }
              }
           },
           2: {
              informacion: '<b>DNA Analysis in a Day</b>',
              texto: `${resetLinea()}${setLinea()} Jane Saunders, a forensic DNA specialist, arrives at work and finds a request waiting for her: She needs to determine if the<br>
              ${setLinea()} DNA of a fingernail with a few skin cells on it <b>1</b> <u>match</u> any records in the criminal database.<br>
              ${setLinea()} "Human DNA is a long, double-stranded <b>2</b> <u>molecule; each strand consists</u> of a complementary set of nucleotides," she<br>
              ${setLinea()} explains. "DNA has four nucleotides: <b>3</b> <u>adenine (A), thymine (T), guanine (G) and cytosine (C)</u>. On each strand is a sequence of nucleotides<br>
              ${setLinea()} that 'match,' or pair up with the nucleotides on the other, or complementary, strand. <b>4</b> <u>As a result</u>, when there is an adenine on one<br>
              ${setLinea()} strand, there is a thymine on the complementary strand, and where there is guanine on one strand, there is cytosine on the<br>
              ${setLinea()} complementary strand."<br>
              ${setLinea()} She begins by <b>5</b> <u>moving</u> the DNA from the rest of the sample, transferring it to a <b>6</b> <u>reaction tube. She adds</u> a solution of<br>
              ${setLinea()} primers, DNA polymerase, and nucleotides. Her goal is to separate the two strands of the DNA molecules and then make complementary<br>
              ${setLinea()} copies of each strand.<br>
              ${setLinea()} After mixing the primers, DNA polymerase, and nucleotides with the evidence DNA, Saunders closes the reaction tube and<br>
              ${setLinea()} puts it in a thermocycler. It is programmed to raise the temperature to 94°C to separate the double strands into single strands, and then<br>
              ${setLinea()} lower the temperature to 59°C to attach the primers to the single strands. Finally, it raises the temperature to 72°C for the DNA<br>
              ${setLinea()} polymerase to build the complementary strands. The thermocycler holds each temperature for one minute and repeats the cycle of three<br>
              ${setLinea()} temperatures for at least 30 cycles. At the end of each cycle, the number of DNA segments containing the sequence marked by the<br>
              ${setLinea()} primers doubles. If the original sample contains only 100 DNA strands, the <b>7</b> <u>absolute final solution</u> will have billions of segments. <b>8</b><br>
              ${setLinea()} [1] After a short lunch break, Saunders needs to separate and identify the copied DNA segments. [2] She had used primers that bind to 13<br>
              ${setLinea()} specific sites in human DNA called short tandem repeats, or STRs. [3] The 13 STRs are segments of four nucleotides that repeat, such as<br>
              ${setLinea()} GATAGATAGATA. [4] "Now here's where the real magic happens!" Saunders says excitedly. [5] "Most DNA is identical for all humans. [6]<br>
              ${setLinea()} But STRs vary greatly. [7] The chances of any two humans—other than identical twins—having the same set of 13 STRs is less than one<br>
              ${setLinea()} in one trillion." <b>9</b><br>
              ${setLinea()} Saunders knows that the detectives will be <b>10</b> <u>prepared</u> to hear her findings, so she sits down at her desk to compare her results<br>
              ${setLinea()} with the criminal database in the hopes of finding a match. <b>11</b> <u>Is it possible that too much time is spent identifying DNA in cases that are<br>
              ${setLinea()} relatively easy to solve?</u>`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'matches',
                          checked: false
                       },
                       3: {
                          texto: 'has matched',
                          checked: false
                       },
                       4: {
                          texto: 'will be matching',
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'molecule, each strand consists',
                          checked: false
                       },
                       3: {
                          texto: 'molecule each strand consists',
                          checked: false
                       },
                       4: {
                          texto: 'molecule but each strand consists',
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'adenine (A), thymine (T), guanine (G), and cytosine (C)',
                          checked: false
                       },
                       3: {
                          texto: 'adenine (A), thymine (T) guanine (G) and cytosine (C)',
                          checked: false
                       },
                       4: {
                          texto: `adenine (A) thymine (T), guanine (G) and cytosine (C)`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'Specifically,',
                          checked: false
                       },
                       3: {
                          texto: 'However,',
                          checked: false
                       },
                       4: {
                          texto: 'Similarly,',
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'reviewing',
                          checked: false
                       },
                       3: {
                          texto: 'changing',
                          checked: false
                       },
                       4: {
                          texto: 'detaching',
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: 'Which choice most effectively combines the sentences at the underlined portion?',
                    respuestas: {
                       1: {
                          texto: `reaction tube since she adds`,
                          checked: false
                       },
                       2: {
                          texto: 'reaction tube, however, she adds',
                          checked: false
                       },
                       3: {
                          texto: 'reaction tube, and adding',
                          checked: false
                       },
                       4: {
                          texto: 'reaction tube, she adds',
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'absolutely the final solution',
                          checked: false
                       },
                       3: {
                          texto: 'the final solution',
                          checked: false
                       },
                       4: {
                          texto: 'the most final solution',
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: 'Which sentence could be added to the beginning of the paragraph to most effectively establish the central idea?',
                    respuestas: {
                       1: {
                          texto: 'The process of testing the DNA includes several steps and many changes in temperature.',
                          checked: false
                       },
                       2: {
                          texto: 'The object of testing the DNA is to recreate many strands of the DNA in question.',
                          checked: false
                       },
                       3: {
                          texto: 'Saunders uses a variety of machines in order to analyze the DNA.',
                          checked: false
                       },
                       4: {
                          texto: 'Saunders would be unable to identify the DNA without the thermocycler.',
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: 'Where should sentence 1 be placed to make the paragraph feel cohesive?',
                    respuestas: {
                       1: {
                          texto: 'Where it is now',
                          checked: false
                       },
                       2: {
                          texto: 'After sentence 2',
                          checked: false
                       },
                       3: {
                          texto: `After sentence 3`,
                          checked: false
                       },
                       4: {
                          texto: 'After sentence 4',
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'eager',
                          checked: false
                       },
                       3: {
                          texto: 'impatient',
                          checked: false
                       },
                       4: {
                          texto: 'conditioned',
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: 'Which choice most effectively establishes a concluding sentence for the paragraph and the passage?',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'It takes a good deal of work and expense to identify DNA in the world of modern forensics.',
                          checked: false
                       },
                       3: {
                          texto: 'She takes pride in the fact that her scientific expertise plays such a key role in bringing criminals to justice.',
                          checked: false
                       },
                       4: {
                          texto: 'She marvels at how far science has come in DNA analysis.',
                          checked: false
                       }
                    }
                 }
              }
           },
           3: {
              informacion: '<b>The UN: Promoting World Peace</b>',
              texto: `${resetLinea()}${setLinea()} The United Nations (UN) is perhaps the most important political contribution of the 20th century. Some may argue that the<br>
              ${setLinea()} work of the UN <b>1</b> ; <u>an international peacekeeping organization—</u>has proven futile, given persisting global conflict. But the UN's worldwide<br>
              ${setLinea()} influence demands a closer look. This organization's global impact is undeniable. The UN is a strong political organization determined to<br>
              ${setLinea()} create opportunities for its member nations to enjoy a peaceful and productive world. <b>2</b><br>
              ${setLinea()} <b>3</b> <u>Decades ago</u>, provoked by the events of World Wars I and II, world leaders began imagining a politically neutral force for<br>
              ${setLinea()} international peace. The UN was born in 1945 with 51 participating nations. It was to be a collective political authority for global peace<br>
              ${setLinea()} and security. Today, 193 nations are UN members. <b>4</b> <u>In keeping with the original hope, the UN still strives toward peaceful international<br>
              ${setLinea()} relations</u>.
              ${setLinea()} Understandably, no single organization can perfectly solve the world's countless, complex problems. But the UN has offered<br>
              ${setLinea()} consistent relief for many of the past half-century's most difficult disasters and conflicts. It also provides a safe space for international<br>
              ${setLinea()} conversation. Moreover, it advocates for issues such as justice, trade, hunger relief, human rights, health, and gender <b>5</b> <u>equality, the<br>
              ${setLinea()} UN</u> also coordinates care for those displaced by disaster and conflict, <b>6</b> <u>dictates</u> environmental protection, and works toward conflict<br>
              ${setLinea()} reconciliation.<br>
              ${setLinea()} <b>7</b> <b>8</b> <u>The year 2014 witnessed the UN peacekeeping force grow to over 100,000 strong</u>. These uniformed, volunteer, civilian<br>
              ${setLinea()} personnel represent 128 nations. The UN's budget has also grown over the years to support an international court system, as well as<br>
              ${setLinea()} countless agencies, committees, and centers addressing sociopolitical topics. Today's UN does big things, and it functions with<br>
              ${setLinea()} remarkable organization and efficiency. Critics highlight shortcomings to discount the UN's effectiveness. But considering the countless<br>
              ${setLinea()} disasters to which the UN has responded over its six decades of existence, today's world might enjoy <b>9</b> <u>far less peace, freedom, and<br>
              ${setLinea()} safety</u> without the UN.<br>
              ${setLinea()} [1] From promoting overarching sociopolitical change to offering food and care for displaced groups, the UN serves to protect<br>
              ${setLinea()} human rights. [2] Equally <b>10</b> <u>quotable</u> are its initiatives to foster international collaboration, justice, and peace. [3] The UN provided aid to<br>
              ${setLinea()} the Philippines after the disastrous 2013 typhoon. [4]Certainly, this work is not finished. [5] But no other organization compares with the<br>
              ${setLinea()} work and influence of the UN. [6] This brave endeavor to insist on and strive for peace, whatever the obstacles, has indeed united<br>
              ${setLinea()} hundreds of once-divided nations. [7] Today, with eleven Nobel Peace Prizes to its name, the UN is undoubtedly an irreplaceable and<br>
              ${setLinea()} profoundly successful force for peace. <b>11</b>writing<br><br>
              <img src "${pathImagesExamen}/Ingles/v2-writing-passage-3.png">`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: '—an international peacekeeping organization;',
                          checked: false
                       },
                       3: {
                          texto: '—an international peacekeeping organization—',
                          checked: false
                       },
                       4: {
                          texto: '; an international peacekeeping organization,',
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: 'Which concluding sentence, if added to the paragraph, most effectively establishes the main claim of the paragraph?',
                    respuestas: {
                       1: {
                          texto: 'The UN is an organization dedicated to advancing social and political justice around the world.',
                          checked: false
                       },
                       2: {
                          texto: 'Those who argue otherwise are not well educated about geopolitical issues in the 20th century or today.',
                          checked: false
                       },
                       3: {
                          texto: 'The UN has had its share of corruption over the years, but it has a well-earned reputation of effectively settling international disputes.',
                          checked: false
                       },
                       4: {
                          texto: `A better understanding of the UN suggests that the UN enables far greater peace in today's world than could have been possible otherwise.`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'Recently,',
                          checked: false
                       },
                       3: {
                          texto: 'Consequently,',
                          checked: false
                       },
                       4: {
                          texto: `In other words,`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'In having kept with the original hope, the UN still strives toward peaceful international relations.',
                          checked: false
                       },
                       3: {
                          texto: 'In keeping with the original hope, the UN still strived toward peaceful international relations.',
                          checked: false
                       },
                       4: {
                          texto: 'In keeping with the original hope, the UN still strove toward peaceful international relations.',
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'equality. The UN',
                          checked: false
                       },
                       3: {
                          texto: 'equality: the UN',
                          checked: false
                       },
                       4: {
                          texto: 'equality, The UN',
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: `NO CHANGEs`,
                          checked: false
                       },
                       2: {
                          texto: 'prefers',
                          checked: false
                       },
                       3: {
                          texto: 'promotes',
                          checked: false
                       },
                       4: {
                          texto: 'celebrates',
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: 'Which topic sentence would most effectively express the central idea of the paragraph?',
                    respuestas: {
                       1: {
                          texto: `The UN's budget, goals, and personnel count have significantly expanded with time to meet more needs.`,
                          checked: false
                       },
                       2: {
                          texto: 'The UN has developed over the years, but critics charge it has met with limited success.',
                          checked: false
                       },
                       3: {
                          texto: 'The responsibilities of the UN have expanded in recent years in response to challenging events.',
                          checked: false
                       },
                       4: {
                          texto: 'The UN has maintained a quiet but effective voice on the world stage in spite of criticism.',
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: 'Which choice best completes the sentence with accurate data based on the graphic?',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'The year 2010 led to an increase of approximately 100,000 in the UN peacekeeping force.',
                          checked: false
                       },
                       3: {
                          texto: 'The year 2010 saw the UN peacekeeping force from the previous decade grow to approximately 100,000 strong.',
                          checked: false
                       },
                       4: {
                          texto: 'The year 2010 saw the UN peacekeeping force decrease to just over 100,000 strong.',
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'considerably less peace, less freedom, and less safety',
                          checked: false
                       },
                       3: {
                          texto: `much less peace, less freedom, and less safety`,
                          checked: false
                       },
                       4: {
                          texto: 'significantly less peace and freedom, and less safety',
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'luminous',
                          checked: false
                       },
                       3: {
                          texto: 'noteworthy',
                          checked: false
                       },
                       4: {
                          texto: 'repeatable',
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: 'Which sentence should be removed to improve the overall focus of the concluding paragraph?',
                    respuestas: {
                       1: {
                          texto: 'Sentence 1',
                          checked: false
                       },
                       2: {
                          texto: 'Sentence 3',
                          checked: false
                       },
                       3: {
                          texto: 'Sentence 5',
                          checked: false
                       },
                       4: {
                          texto: 'Sentence 6',
                          checked: false
                       }
                    }
                 }
              }
           },
           4: {
              informacion: '<b>The Art of Collecting</b>',
              texto: `${resetLinea()}${setLinea()} At an art exhibition for artist Henri Matisse, enthusiasts can also view a black and white photograph of two siblings. These<br>
              ${setLinea()} sisters, wearing Victorian-style dresses and top hats, are the renowned art collectors Claribel and Etta Cone. When Etta passed away in<br>
              ${setLinea()} 1949, she <b>1</b><u>bequeathed</u> some 3,000 objects to the Baltimore Museum of Art (BMA). Now, works from the Cone Collection, internationally<br>
              ${setLinea()} renowned and consisting of masterpieces by early 20th century artists, travel on loan from BMA so that people can experience the Cone<br>
              ${setLinea()} sisters' visionary passion for and dedication to modern art. <b>2</b><br>
              ${setLinea()} What made the Cone sisters innovative was their recognition of the value of art pieces by virtually unknown avant-garde artists<br>
              ${setLinea()} of their time, such as Pablo Picasso. Critics failed to understand the Cones' <b>3</b> <u>tastes and such opinions</u> did not squelch the sisters'<br>
              ${setLinea()} passion for collecting. According to Katy Rothkopf, senior curator at the BMA, Matisse's use of vibrant color, for example, was initially<br>
              ${setLinea()} shocking. "At first the Cones … really found [the art] quite scary," states Rothkopf. However, the siblings befriended Matisse and other<br>
              ${setLinea()} artists, gaining respect for the painters' unorthodox experimentation. As the Cones began buying and collecting art, <b>4</b> <u>there</u> selections<br>
              ${setLinea()} improved.<br>
              ${setLinea()} "It took a lot of gall—guts—to paint it," Matisse once said about a controversial painting, "but much more to buy it." Claribel<br>
              ${setLinea()} and Etta had that kind of gall. <b>5</b> <u>Each had took risks</u> by not purchasing traditional landscape paintings and instead amassing works that at<br>
              ${setLinea()} the time were considered contemptuous and wild.<br>
              ${setLinea()} [1] A further legacy of the Cone Collection was its documentation of post-World War I Europe. [2] The art the Cones<br>
              ${setLinea()} collected <b>6</b> <u>suggested</u> changes in Europe, such as the increasing use of machines in contemporary life and the emergence of modern<br>
              ${setLinea()} thinking. [3] Traditional limitations in art were overcome by experimental forms and new media, allowing artists to explore their creativity.<br>
              ${setLinea()} [4] Today, there are even more experimental forms of art than there were after World War I. <b>7</b><br>
              ${setLinea()} <b>8</b> <u>Additionally in visiting Paris</u>, Budapest, Athens, Cairo, and Shanghai, the Cones represented the beginning of the new<br>
              ${setLinea()} woman at the turn of the century. <b>9</b> <u>Though</u> their unconventional lifestyle, the far-seeing Cone sisters experienced freedom from narrower<br>
              ${setLinea()} roles. They avoided the gross inequalities between genders by becoming connoisseurs of radical art. <b>10</b><br>
              ${setLinea()} Public acceptance of the <b>11</b> <u>Cone's</u> avant-garde collection testifies to their accomplishments. While the estimated value of their artwork is<br>
              ${setLinea()} one billion dollars, their larger contribution is inestimable. As bold patrons, the Cones advanced appreciation for modern art for<br>
              ${setLinea()} generations to come.`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'liquidated',
                          checked: false
                       },
                       3: {
                          texto: 'delivered',
                          checked: false
                       },
                       4: {
                          texto: 'allotted',
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: 'Which choice most effectively establishes the central idea of the paragraph?',
                    respuestas: {
                       1: {
                          texto: 'Henri Matisse was a well-known supporter of female artists and art patrons, and he revealed these unconventional attitudes in his work.',
                          checked: false
                       },
                       2: {
                          texto: `Together the Cones, supported by the wealth from their family's textile business, gathered one of the finest collections of French art in the United States.`,
                          checked: false
                       },
                       3: {
                          texto: 'The Cones became great contributors to the Baltimore Museum of Art, and their renowned exhibition was praised by artists around the globe.',
                          checked: false
                       },
                       4: {
                          texto: `During this time period, only the wealthy could afford to purchase original artworks, and the Cones became famous for spending their entire fortune on art.`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'tastes, so such opinions',
                          checked: false
                       },
                       3: {
                          texto: 'tastes therefore such opinions',
                          checked: false
                       },
                       4: {
                          texto: `tastes, but such opinions`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: `they're`,
                          checked: false
                       },
                       3: {
                          texto: 'their',
                          checked: false
                       },
                       4: {
                          texto: 'her',
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'They took risks',
                          checked: false
                       },
                       3: {
                          texto: 'They have taken risks',
                          checked: false
                       },
                       4: {
                          texto: 'Each will take risks',
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: `NO CHANGEs`,
                          checked: false
                       },
                       2: {
                          texto: 'depicted',
                          checked: false
                       },
                       3: {
                          texto: 'referenced',
                          checked: false
                       },
                       4: {
                          texto: 'divulged',
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: 'Which sentence should be deleted from the paragraph to improve its focus?',
                    respuestas: {
                       1: {
                          texto: `Sentence 1`,
                          checked: false
                       },
                       2: {
                          texto: 'Sentence 2',
                          checked: false
                       },
                       3: {
                          texto: 'Sentence 3',
                          checked: false
                       },
                       4: {
                          texto: 'Sentence 4',
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'Additionally, in visiting: Paris,',
                          checked: false
                       },
                       3: {
                          texto: 'Additionally, in visiting Paris,',
                          checked: false
                       },
                       4: {
                          texto: 'Additionally in visiting Paris',
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'Therefore',
                          checked: false
                       },
                       3: {
                          texto: `Thorough`,
                          checked: false
                       },
                       4: {
                          texto: 'Through',
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: `What changes to the paragraph would best strengthen the author's claims?`,
                    respuestas: {
                       1: {
                          texto: 'The author should define the terms new woman and narrower roles.',
                          checked: false
                       },
                       2: {
                          texto: 'The author should list more nations and cities visited by the Cone sisters.',
                          checked: false
                       },
                       3: {
                          texto: 'The precise centuries referenced by turn of the century should be included.',
                          checked: false
                       },
                       4: {
                          texto: `The author should add reactions from contemporary critics to the Cones' travels.`,
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: `Cones'`,
                          checked: false
                       },
                       3: {
                          texto: 'Cones',
                          checked: false
                       },
                       4: {
                          texto: `Cones's`,
                          checked: false
                       }
                    }
                 }
              }
           }
        }
     }

  },
  3: {
     reading: {
        passages: {
           1: {
              informacion: `<b>Questions 1-11 are based on the following passage and supplementary material.</b><br>
                   This passage is adapted from Francis J. Flynn and Gabrielle S. Adams, "Money Can't Buy Love: Asymmetric<br>
                   Beliefs about Gift Price and Feelings of Appreciation." ©2008 by Elsevier Inc`,
              texto: `${resetLinea()}${setLinea()} ${setTabulacion()} Every day, millions of shoppers hit the stores in full force—both online and on foot—<br>
                   ${setLinea()} searching frantically for the perfect gift. Last year, Americans spent over $30 billion at retail stores in<br>
                   ${setLinea()} the month of 5 December alone. Aside from purchasing holiday<br>
                   ${setLinea()} gifts, most people regularly buy presents for other occasions throughout the year, including<br>
                   ${setLinea()} weddings, birthdays, anniversaries, graduations, and baby showers. This frequent experience of gift-<br>
                   ${setLinea()} giving can engender ambivalent feelings in gift-givers. Many relish the opportunity to buy presents<br>
                   ${setLinea()} because gift-giving offers a powerful means to build stronger bonds with one’s closest peers. At the<br>
                   ${setLinea()} same time, many dread the thought of buying gifts; they worry that their purchases will disappoint<br>
                   ${setLinea()} rather than delight the intended recipients.<br>
                   ${setLinea()} ${setTabulacion()} Anthropologists describe gift-giving as a positive social process, serving various political,<br>
                   ${setLinea()} religious, and psychological functions. Economists, however, offer a less favorable view. According to<br>
                   ${setLinea()} Waldfogel (1993), gift-giving represents an objective waste of resources. People buy gifts that<br>
                   ${setLinea()} recipients would not choose to buy on their own, or at least not spend as much money to purchase (a<br>
                   ${setLinea()} phenomenon referred to as  ‘‘the deadweight loss of Christmas”). To wit, givers are likely to spend<br>
                   ${setLinea()} $100 to purchase a gift that receivers would spend only $80 to buy themselves. This ‘‘deadweight<br>
                   ${setLinea()} loss” suggests that gift-givers are not very good at predicting what gifts others will appreciate. That<br>
                   ${setLinea()} in itself is not surprising to social psychologists. Research has found that people often struggle to<br>
                   ${setLinea()} take account of others’ perspectives— their insights are subject to egocentrism, social projection, and<br>
                   ${setLinea()} multiple attribution errors.<br>
                   ${setLinea()} ${setTabulacion()} What is surprising is that gift-givers have considerable experience acting as both gift-givers<br>
                   ${setLinea()} and gift-recipients, but nevertheless tend to overspend each time they set out to purchase a<br>
                   ${setLinea()} meaningful gift. In the present research, we propose a unique psychological explanation for this over<br>
                   ${setLinea()} spending problem—i.e., that gift-givers equate how much they spend with how much recipients will<br>
                   ${setLinea()} appreciate the gift (the more expensive the gift, the stronger a gift-recipient’s feelings of<br>
                   ${setLinea()} appreciation). Although a link between gift price and feelings of appreciation might seem intuitive to<br>
                   ${setLinea()} gift-givers, such an assumption may be unfounded. Indeed, we propose that gift-recipients will be<br>
                   ${setLinea()} less inclined to base their feelings of appreciation on the magnitude of a gift than givers assume.<br>
                   ${setLinea()} ${setTabulacion()} Why do gift-givers assume that gift price is closely linked to gift-recipients’ feelings of<br>
                   ${setLinea()} appreciation? Perhaps givers believe that bigger (i.e., more expensive) gifts convey stronger signals<br>
                   ${setLinea()} of thoughtfulness and consideration. According to Camerer (1988) and others, gift-giving represents<br>
                   ${setLinea()} a symbolic ritual, whereby gift-givers attempt to signal their positive attitudes toward the intended<br>
                   ${setLinea()} recipient and their willingness to invest resources in a future relationship. In this sense, gift-givers<br>
                   ${setLinea()} may be motivated to spend more money on a gift in order to send a “stronger signal” to their<br>
                   ${setLinea()} intended recipient. As for gift-recipients, they may not construe smaller and larger gifts as<br>
                   ${setLinea()} representing smaller and larger signals of thoughtfulness and consideration.<br>
                   ${setLinea()} ${setTabulacion()} The notion of gift-givers and gift-recipients being unable to account for the other party’s<br>
                   ${setLinea()} perspective seems puzzling because people slip in and out of these roles every day, and, in some<br>
                   ${setLinea()} cases, multiple times in the course of the same day. Yet, despite the extensive experience that people<br>
                   ${setLinea()} have as both givers and receivers, they often struggle to transfer information gained from one role<br>
                   ${setLinea()} (e.g., as a giver) and apply it in another, complementary role (e.g., as a receiver). In theoretical terms,<br>
                   ${setLinea()} people fail to utilize information about their own preferences and experiences in order to produce<br>
                   ${setLinea()} more efficient outcomes in their exchange relations. In practical terms, people spend hundreds of<br>
                   ${setLinea()} dollars each year on gifts, but somehow never learn to calibrate their gift expenditures according to<br>
                   ${setLinea()} personal insight.<br><br>
                   <img src="${pathImagesExamen}/Ingles/v3-reading-passage-1.png" >`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: `1. RHETORIC—MEDIUM<br>
                    The authors most likely use the examples in lines 1-9 of the passage (“Every . . . showers”) to highlight the`,
                    respuestas: {
                       1: {
                          texto: `regularity with which people shop for gifts.`,
                          checked: false
                       },
                       2: {
                          texto: `recent increase in the amount of money spent on gifts.`,
                          checked: false
                       },
                       3: {
                          texto: `anxiety gift shopping causes for consumers.`,
                          checked: false
                       },
                       4: {
                          texto: `number of special occasions involving gift-giving.`,
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: `2. RHETORIC—EASY<br>
                    In line 10, the word “ambivalent” most nearly means`,
                    respuestas: {
                       1: {
                          texto: `unrealistic.`,
                          checked: false
                       },
                       2: {
                          texto: `conflicted.`,
                          checked: false
                       },
                       3: {
                          texto: `apprehensive.`,
                          checked: false
                       },
                       4: {
                          texto: `supportive.`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: `3. SYNTHESIS—MEDIUM<br>
                    The authors indicate that people value gift-giving because they feel it`,
                    respuestas: {
                       1: {
                          texto: `functions as a form of self-expression.`,
                          checked: false
                       },
                       2: {
                          texto: `is an inexpensive way to show appreciation.`,
                          checked: false
                       },
                       3: {
                          texto: `requires the gift-recipient to reciprocate.`,
                          checked: false
                       },
                       4: {
                          texto: `can serve to strengthen a relationship.`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: `4. SYNTHESIS—HARD<br>
                    Which choice provides the best evidence for the answer to the previous question?`,
                    respuestas: {
                       1: {
                          texto: `Lines 10-13 (“Many . . . peers”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 22-23 (“People . . . own”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 31-32 (“Research . . . perspectives”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 44-47 (“Although . . . unfounded”)`,
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: `5. SYNTHESIS—HARD<br>
                    The “social psychologists” mentioned in paragraph 2 (lines 17-34) would likely describe the “deadweight loss” phenomenon as`,
                    respuestas: {
                       1: {
                          texto: `predictable.`,
                          checked: false
                       },
                       2: {
                          texto: `questionable.`,
                          checked: false
                       },
                       3: {
                          texto: `disturbing.`,
                          checked: false
                       },
                       4: {
                          texto: `unprecedented.`,
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: `6. INFORMATION AND IDEAS—MEDIUM<br>
                    The passage indicates that the assumption made by gift-givers in lines 41-44 may be`,
                    respuestas: {
                       1: {
                          texto: `insincere.`,
                          checked: false
                       },
                       2: {
                          texto: `unreasonable.`,
                          checked: false
                       },
                       3: {
                          texto: `incorrect.`,
                          checked: false
                       },
                       4: {
                          texto: `substantiated.`,
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: `7. SYNTHESIS—MEDIUM<br>
                    Which choice provides the best evidence for the answer to the previous question?`,
                    respuestas: {
                       1: {
                          texto: `Lines 53-55 (“Perhaps . . . consideration”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 55-60 (“According . . . relationship”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 63-65 (“As . . . consideration”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 75-78 (“In . . . relations”)`,
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: `8. RHETORIC—EASY<br>
                    As it is used in line 54, “convey” most nearly means`,
                    respuestas: {
                       1: {
                          texto: `transport.`,
                          checked: false
                       },
                       2: {
                          texto: `counteract.`,
                          checked: false
                       },
                       3: {
                          texto: `exchange.`,
                          checked: false
                       },
                       4: {
                          texto: `communicate.`,
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: `9. INFORMATIONA ND IDEAS—HARD<br>
                    The authors refer to work by Camerer and others (line 56) in order`,
                    respuestas: {
                       1: {
                          texto: `offer an explanation.`,
                          checked: false
                       },
                       2: {
                          texto: `introduce an argument.`,
                          checked: false
                       },
                       3: {
                          texto: `question a motive.`,
                          checked: false
                       },
                       4: {
                          texto: `support a conclusion.`,
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: `10. INFORMATION AND IDEAS—MEDIUM<br>
                    The graph following the passage offers evidence that gift-givers base their predictions of how much a gift will be appreciated on`,
                    respuestas: {
                       1: {
                          texto: `the appreciation level of the gift-recipients.`,
                          checked: false
                       },
                       2: {
                          texto: `the monetary value of the gift.`,
                          checked: false
                       },
                       3: {
                          texto: `their own desires for the gifts they purchase.`,
                          checked: false
                       },
                       4: {
                          texto: `their relationship with the gift-recipients.`,
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: `11. SYNTHESIS—HARD<br>
                    The authors would likely attribute the differences in gift-giver and recipient mean appreciation as represented in the graph to`,
                    respuestas: {
                       1: {
                          texto: `an inability to shift perspective.`,
                          checked: false
                       },
                       2: {
                          texto: `an increasingly materialistic culture.`,
                          checked: false
                       },
                       3: {
                          texto: `a growing opposition to gift-giving.`,
                          checked: false
                       },
                       4: {
                          texto: `a misunderstanding of intentions.`,
                          checked: false
                       }
                    }
                 }
              }
           },
           2: {
              informacion: `<b>Questions 12-21 are based on the following passages.</b><br>
                   These passages are adapted from the Lincoln‐Douglas debates. Passage 1 is from a statement by Stephen<br>
                   Douglas. Passage 2 is from a statement by Abraham Lincoln. Douglas and Lincoln engaged in a series of debates<br>
                   while competing for a US Senate seat in 1858.`,
              texto: `${resetLinea()}<b>Passage 1</b><br>
                   ${setLinea()} Mr. Lincoln likens that bond of the Federal Constitution, joining Free and Slave States together, to a<br>
                   ${setLinea()} house divided against itself, and says that it is contrary to the law of God, and cannot stand.<br>
                   ${setLinea()} When did he learn, and by what authority does he proclaim, that this Government is contrary to the<br>
                   ${setLinea()} law of God and cannot stand? It has stood thus divided into Free and Slave States from its<br>
                   ${setLinea()} organization up to this day. During that period we have increased from four millions to thirty<br>
                   ${setLinea()} millions of people; we have extended our territory from the Mississippi to the Pacific Ocean; we have<br>
                   ${setLinea()} acquired the Floridas and Texas, and other territory sufficient to double our geographical extent; we<br>
                   ${setLinea()} have increased in population, in wealth, and in power beyond any example on earth; we have risen<br>
                   ${setLinea()} from a weak and feeble power to become the terror and admiration of the civilized world; and all this<br>
                   ${setLinea()} has been done under a Constitution which Mr. Lincoln, in substance, says is in violation of the law of<br>
                   ${setLinea()} God; and under a Union divided into Free and Slave States, which Mr. Lincoln thinks, because of such<br>
                   ${setLinea()} division, cannot stand. Surely, Mr. Lincoln is a wiser man than those who framed the Government...<br>
                   ${setLinea()} ${setTabulacion()} I now come back to the question, why cannot this Union exist forever, divided into Free and<br>
                   ${setLinea()} Slave States, as our fathers made it? It can thus exist if each State will carry out the principles upon<br>
                   ${setLinea()} which our institutions were founded; to wit, the right of each State to do as it pleases, without<br>
                   ${setLinea()} meddling with its neighbors. Just act upon that great principle, and this Union will not only live<br>
                   ${setLinea()} forever, but it will extend and expand until it covers the whole continent, and makes this confederacy<br>
                   ${setLinea()} one grand, ocean-bound Republic. We must bear in mind that we are yet a young nation, growing<br>
                   ${setLinea()} with a rapidity unequalled in the history of the world, that our national increase is great, and that the<br>
                   ${setLinea()} emigration from the old world is increasing, requiring us to expand and acquire new territory from<br>
                   ${setLinea()} time to time, in order to give our people land to live upon. If we live upon the principle of State rights<br>
                   ${setLinea()} and State sovereignty, each State regulating its own affairs and minding its own business, we can go on<br>
                   ${setLinea()} and extend indefinitely, just as fast and as far as we need the territory...<br><br>
                   <b>Passage 2</b><br>
                   ${setLinea()} In complaining of what I said in my speech at Springfield, in which he says I accepted my nomination<br>
                   ${setLinea()} for the Senatorship . . . he again quotes that portion in which I said that “a house divided against itself<br>
                   ${setLinea()} cannot stand.” Let me say a word in regard to that matter. He tries to persuade us that there must be<br>
                   ${setLinea()} a variety in the different institutions of the States of the Union; that that variety necessarily proceeds<br>
                   ${setLinea()} from the variety of soil, climate, of the face of the country, and the difference in the natural features of<br>
                   ${setLinea()} the States. I agree to all that. Have these very matters ever produced any difficulty among us? Not at<br>
                   ${setLinea()} all. Have we ever had any quarrel over the fact that they have laws in Louisiana designed to regulate<br>
                   ${setLinea()} the commerce that springs from the production of sugar? Or because we have a different class<br>
                   ${setLinea()} relative to the production of flour in this State? Have they produced any differences? Not at all. They<br>
                   ${setLinea()} are the very cements of this Union. They don’t make the house a “house divided against itself.” They<br>
                   ${setLinea()} are the props that hold up the house and sustain the Union.<br>
                   ${setLinea()} ${setTabulacion()} But has it been so with this element of slavery? Have we not always had quarrels and<br>
                   ${setLinea()} difficulties over it? And when will we cease to have quarrels over it? Like causes produce like effects.<br>
                   ${setLinea()} It is worth while to observe that we have generally had comparative peace upon the slavery question,<br>
                   ${setLinea()} and that there has been no cause for alarm until it was excited by the effort to spread it into new<br>
                   ${setLinea()} territory. Whenever it has been limited to its present bounds, and there has been no effort to spread<br>
                   ${setLinea()} it, there has been peace. All the trouble and convulsion has proceeded from efforts to spread it over<br>
                   ${setLinea()} more territory. It was thus at the date of the Missouri Compromise. It was so again with the<br>
                   ${setLinea()} annexation of Texas; so with the territory acquired by the Mexican War; and it is so now. Whenever<br>
                   ${setLinea()} there has been an effort to spread it there has been agitation and resistance. . . . Do you think that the<br>
                   ${setLinea()} nature of man will be changed, that the same causes that produced agitation at one time will not have<br>
                   ${setLinea()} the same effect at another?`,
              preguntas: {
                 1: {
                    indicePregunta: '12',
                    enunciado: `12. INFORMATION AND IDEAS—EASY<br>
                    In the first paragraph of Passage 1, the main purpose of Douglas’s discussion of the growth of the territory and population of the United States is to`,
                    respuestas: {
                       1: {
                          texto: `provide context for Douglas’s defense of continued expansion.`,
                          checked: false
                       },
                       2: {
                          texto: `suggest that the division into free and slave states does not endanger the Union.`,
                          checked: false
                       },
                       3: {
                          texto: `imply that Lincoln is unaware of basic facts concerning the country.`,
                          checked: false
                       },
                       4: {
                          texto: `account for the image of the United States as powerful and admirable.`,
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '13',
                    enunciado: `13. INFORMATION AND IDEAS—HARD<br>
                    What does Passage 1 suggest about the US government’s provisions for the institution of slavery, as framed in the Constitution?`,
                    respuestas: {
                       1: {
                          texto: `They included no means for reconciling differences between free states and slave states.`,
                          checked: false
                       },
                       2: {
                          texto: `They anticipated the Union’s expansion into western territories.`,
                          checked: false
                       },
                       3: {
                          texto: `They provided a good basic structure that does not need to be changed.`,
                          checked: false
                       },
                       4: {
                          texto: `They were founded on an assumption that slavery was necessary for economic growth.`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '14',
                    enunciado: `14. SYNTHESIS—MEDIUM<br>
                    Which choice provides the best evidence for the answer to the previous question?`,
                    respuestas: {
                       1: {
                          texto: `Lines 10-16 (“we have . . . earth”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 25-27 (“I now . . . made it”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 35-39 (“We must . . . increasing”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 41-45 (“If we . . . territory”)`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '15',
                    enunciado: `15. RHETORIC—EASY<br>
                    As used in line 67, “element” most nearly means`,
                    respuestas: {
                       1: {
                          texto: `ingredient.`,
                          checked: false
                       },
                       2: {
                          texto: `environment.`,
                          checked: false
                       },
                       3: {
                          texto: `factor.`,
                          checked: false
                       },
                       4: {
                          texto: `quality.`,
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '16',
                    enunciado: `16. SYNTHESIS—MEDIUM<br>
                    Based on Passage 2, Lincoln would be most likely to agree with which claim about the controversy over slavery?`,
                    respuestas: {
                       1: {
                          texto: `It can be ended only if Northern states act unilaterally to abolish slavery throughout the United States.`,
                          checked: false
                       },
                       2: {
                          texto: `It would abate if attempts to introduce slavery to regions where it is not practiced were abandoned.`,
                          checked: false
                       },
                       3: {
                          texto: `It has been exacerbated by the ambiguity of laws regulating the holding of slaves.`,
                          checked: false
                       },
                       4: {
                          texto: `It is fueled in part by differences in religion and social values from state to state.`,
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '17',
                    enunciado: `17. SYNTHESIS—HARD<br>
                    Which choice provides the best evidence for the answer to the previous question?`,
                    respuestas: {
                       1: {
                          texto: `Lines 56-61 (“I agree . . . sugar”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 64-66 (“They don’t . . . Union”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 74-76 (“Whenever . . . peace”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 83-86 (“Do you . . . another”)`,
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '18',
                    enunciado: `18. RHETORIC—MEDIUM<br>
                    As used in line 84, “nature” most nearly means`,
                    respuestas: {
                       1: {
                          texto: `force.`,
                          checked: false
                       },
                       2: {
                          texto: `simplicity.`,
                          checked: false
                       },
                       3: {
                          texto: `world.`,
                          checked: false
                       },
                       4: {
                          texto: `character.`,
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '19',
                    enunciado: `19. SYNTHESIS—HARD<br>
                    Which choice identifies a central tension between the two passages?`,
                    respuestas: {
                       1: {
                          texto: `Douglas proposes changes to federal policies on slavery, but Lincoln argues that such changes would enjoy no popular support.`,
                          checked: false
                       },
                       2: {
                          texto: `Douglas expresses concerns about the economic impact of abolition, but Lincoln dismisses those concerns as irrelevant.`,
                          checked: false
                       },
                       3: {
                          texto: `Douglas criticizes Lincoln for finding fault with the Constitution, and Lincoln argues that this criticism misrepresents his position.`,
                          checked: false
                       },
                       4: {
                          texto: `Douglas offers an interpretation of federal law that conflicts with Lincoln’s, and Lincoln implies that Douglas’s interpretation is poorly reasoned.`,
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '20',
                    enunciado: `20. INFORMATION AND IDEAS—MEDIUM<br>
                    Both passages discuss the issue of slavery in relationship to`,
                    respuestas: {
                       1: {
                          texto: `the expansion of the Union.`,
                          checked: false
                       },
                       2: {
                          texto: `questions of morality.`,
                          checked: false
                       },
                       3: {
                          texto: `religious toleration.`,
                          checked: false
                       },
                       4: {
                          texto: `laws regulating commerce.`,
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '21',
                    enunciado: `21. SYNTHESIS—MEDIUM<br>
                    In the context of each passage as a whole, the questions in lines 25-27 of Passage 1 and lines 67-69 of Passage 2 primarily function to help each speaker`,
                    respuestas: {
                       1: {
                          texto: `cast doubt on the other’s sincerity.`,
                          checked: false
                       },
                       2: {
                          texto: `criticize the other’s methods.`,
                          checked: false
                       },
                       3: {
                          texto: `reproach the other’s actions.`,
                          checked: false
                       },
                       4: {
                          texto: `undermine the other’s argument.`,
                          checked: false
                       }
                    }
                 }
              }
           },
           3: {
              informacion: `<b>Questions 22-30 are based on the following passage.</b><br>
                   This passage is adapted from William Maxwell, The Folded Leaf. ©1959 by William Maxwell. Originally published<br>
                   in 1945.`,
              texto: `${resetLinea()}${setLinea()} ${setTabulacion()} The Alcazar Restaurant was on Sheridan Road near Devon Avenue. It was long and narrow,<br>
                   ${setLinea()} with tables for two along the walls and tables for four down the middle. The decoration was art<br>
                   ${setLinea()} moderne, except for the series of murals depicting the four seasons, and the sick ferns in the front<br>
                   ${setLinea()} window. Lymie sat down at the second table from the cash register, and ordered his dinner. The<br>
                   ${setLinea()} history book, which he propped against the catsup and the glass sugar bowl, had been used by others<br>
                   ${setLinea()} before him. Blank pages front and back were filled in with maps, drawings, dates, comic cartoons, and<br>
                   ${setLinea()} organs of the body; also with names and messages no longer clear and never absolutely legible. On<br>
                   ${setLinea()} nearly every other page there was some marginal notation, either in ink or in very hard pencil. And<br>
                   ${setLinea()} unless someone had upset a glass of water, the marks on page 177 were from tears.<br>
                   ${setLinea()} ${setTabulacion()} While Lymie read about the Peace of Paris, signed on the thirtieth of May, 1814, between<br>
                   ${setLinea()} France and the Allied powers, his right hand managed again and again to bring food up to his mouth.<br>
                   ${setLinea()} Sometimes he chewed, sometimes he swallowed whole the food that he had no idea he was eating.<br>
                   ${setLinea()} The Congress of Vienna met, with some allowance for delays, early in November of the same year,<br>
                   ${setLinea()} and all the powers engaged in the war on either side sent plenipotentiaries. It was by far the most<br>
                   ${setLinea()} splendid and important assembly ever convoked to discuss and determine the affairs of Europe. The<br>
                   ${setLinea()} Emperor of Russia, the King of Prussia, the Kings of Bavaria, Denmark, and Wurttemberg, all were<br>
                   ${setLinea()} present in person at the court of the Emperor Francis I in the Austrian capital. When Lymie put down<br>
                   ${setLinea()} his fork and began to count them off, one by one, on the fingers of his left hand, the waitress, whose<br>
                   ${setLinea()} name was Irma, thought he was through eating and tried to take his plate away. He stopped her.<br>
                   ${setLinea()} Prince Metternich (his right thumb) presided over the Congress, and Prince Talleyrand (the index<br>
                   ${setLinea()} finger) represented France.<br>
                   ${setLinea()} ${setTabulacion()} A party of four, two men and two women, came into the restaurant, all talking at once, and<br>
                   ${setLinea()} took possession of the center table nearest Lymie. The women had shingled hair and short tight<br>
                   ${setLinea()} skirts which exposed the underside of their knees when they sat down. One of the women had the<br>
                   ${setLinea()} face of a young boy but disguised by one trick or another (rouge, lipstick, powder, wet bangs<br>
                   ${setLinea()} plastered against the high forehead, and a pair of long pendent earrings) to look like a woman of<br>
                   ${setLinea()} thirty-five, which as a matter of fact she was. The men were older. They laughed more than there<br>
                   ${setLinea()} seemed any occasion for, while they were deciding between soup and shrimp cocktail, and their<br>
                   ${setLinea()} laughter was too loud. But it was the women’s voices, the terrible not quite sober pitch of the<br>
                   ${setLinea()} women’s voices which caused Lymie to skim over two whole pages without knowing what was on<br>
                   ${setLinea()} them. Fortunately he realized this and went back. Otherwise he might never have known about the<br>
                   ${setLinea()} secret treaty concluded between England, France, and Austria, when the pretensions of Prussia and<br>
                   ${setLinea()} Russia, acting in concert, seemed to threaten a renewal of the attack. The results of the Congress<br>
                   ${setLinea()} were stated clearly at the bottom of page 67 and at the top of page 68, but before Lymie got halfway<br>
                   ${setLinea()} through them, a coat that he recognized as his father’s was hung on the hook next to his chair. Lymie<br>
                   ${setLinea()} closed the book and said, “I didn’t think you were coming.”<br>
                   ${setLinea()} ${setTabulacion()} Time is probably no more unkind to sporting characters than it is to other people, but<br>
                   ${setLinea()} physical decay unsustained by respectability is somehow more noticeable. Mr. Peters’ hair was<br>
                   ${setLinea()} turning gray and his scalp showed through on top. He had lost weight also; he no longer filled out his<br>
                   ${setLinea()} clothes the way he used to. His color was poor, and the flower had disappeared from his buttonhole.<br>
                   ${setLinea()} In its place was an American Legion button.<br>
                   ${setLinea()} ${setTabulacion()} Apparently he himself was not aware that there had been any change. He straightened his tie<br>
                   ${setLinea()} self-consciously and when Irma handed him a menu, he gestured with it so that the two women at<br>
                   ${setLinea()} the next table would notice the diamond ring on the fourth finger of his right hand. Both of these<br>
                   ${setLinea()} things, and also the fact that his hands showed signs of the manicurist, one can blame on the young<br>
                   ${setLinea()} man who had his picture taken with a derby hat on the back of his head, and also sitting with a girl in<br>
                   ${setLinea()} the curve of the moon. The young man had never for one second deserted Mr. Peters. He was always<br>
                   ${setLinea()} there, tugging at Mr. Peters’ elbow, making him do things that were not becoming in a man of forty-<br>
                   ${setLinea()} five.`,
              preguntas: {
                 1: {
                    indicePregunta: '22',
                    enunciado: `22. INFORMATION AND IDEAS—EASY<br>
                    Over the course of the passage, the primary focus shifts from`,
                    respuestas: {
                       1: {
                          texto: `Lymie’s inner thoughts to observations made by the other characters.`,
                          checked: false
                       },
                       2: {
                          texto: `an exchange between strangers to a satisfying personal relationship.`,
                          checked: false
                       },
                       3: {
                          texto: `the physical setting of the scene to the different characters’ personality traits.`,
                          checked: false
                       },
                       4: {
                          texto: `Lymie’s experience reading a book to descriptions of people in the restaurant.`,
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '23',
                    enunciado: `23. INFORMATION AND IDEAS—MEDIUM<br>
                    The main purpose of the first paragraph is to`,
                    respuestas: {
                       1: {
                          texto: `introduce the passage’s main character by showing his nightly habits.`,
                          checked: false
                       },
                       2: {
                          texto: `indicate the date the passage takes place by presenting period details.`,
                          checked: false
                       },
                       3: {
                          texto: `convey the passage’s setting by describing a place and an object.`,
                          checked: false
                       },
                       4: {
                          texto: `foreshadow an event that is described in detail later in the passage.`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '24',
                    enunciado: `24. SYNTHESIS—MEDIUM<br>
                    It can reasonably be inferred that Irma, the waitress, thinks Lymie is “through eating” (line 37) because`,
                    respuestas: {
                       1: {
                          texto: `he has begun reading his book.`,
                          checked: false
                       },
                       2: {
                          texto: `his plate is empty.`,
                          checked: false
                       },
                       3: {
                          texto: `he is no longer holding his fork.`,
                          checked: false
                       },
                       4: {
                          texto: `he has asked her to clear the table.`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '25',
                    enunciado: `25. INFORMATION AND IDEAS—HARD<br>
                    Lymie’s primary impression of the “party of four” (line 42) is that they`,
                    respuestas: {
                       1: {
                          texto: `are noisy and distracting.`,
                          checked: false
                       },
                       2: {
                          texto: `are a refreshing change from the other customers. `,
                          checked: false
                       },
                       3: {
                          texto: `resemble characters from his history book.`,
                          checked: false
                       },
                       4: {
                          texto: `represent glamour and youth.`,
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '26',
                    enunciado: `26. SYNTHESIS—MEDIUM<br>
                    Which choice provides the best evidence for the answer to the previous question`,
                    respuestas: {
                       1: {
                          texto: `Lines 45-47 (“The women . . . down”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 47-52 (“One . . . was”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 55-59 (“But . . . them”)`,
                          checked: false
                       },
                       4: {
                          texto: `Line 69 (“Lymie . . . book”)`,
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '27',
                    enunciado: `27. INFORMATION AND IDEAS—EASY<br>
                    The narrator indicates that Lymie finally closes the history book because`,
                    respuestas: {
                       1: {
                          texto: `his father has joined him at the table.`,
                          checked: false
                       },
                       2: {
                          texto: `the people at the other table are too disruptive.`,
                          checked: false
                       },
                       3: {
                          texto: `he has finished the chapter about the Congress.`,
                          checked: false
                       },
                       4: {
                          texto: `he is preparing to leave the restaurant.`,
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '28',
                    enunciado: `28. SYNTHESIS—MEDIUM<br>
                    The primary impression created by the narrator’s description of Mr. Peters in lines 74-79 is that he is`,
                    respuestas: {
                       1: {
                          texto: `healthy and fit.`,
                          checked: false
                       },
                       2: {
                          texto: `angry and menacing.`,
                          checked: false
                       },
                       3: {
                          texto: `nervous and hesitant.`,
                          checked: false
                       },
                       4: {
                          texto: `aging and shriveled.`,
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '29',
                    enunciado: `29. INFORMATION AND IDEAS—EASY<br>
                    The main idea of the last paragraph is that Mr. Peters`,
                    respuestas: {
                       1: {
                          texto: `neglects to spend any time with his family members.`,
                          checked: false
                       },
                       2: {
                          texto: `behaves as if he is a younger version of himself.`,
                          checked: false
                       },
                       3: {
                          texto: `is very conscious of symbols of wealth and power.`,
                          checked: false
                       },
                       4: {
                          texto: `is preoccupied with the knowledge that he is growing old.`,
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '30',
                    enunciado: `30. SYNTHESIS—MEDIUM<br>
                    Which choice best supports the conclusion that Mr. Peters wants to attract attention?`,
                    respuestas: {
                       1: {
                          texto: `Lines 80-81 (“Apparently . . . change”)`,
                          checked: false
                       },
                       2: {
                          texto: `Lines 81-85 (“He straightened . . . hand”)`,
                          checked: false
                       },
                       3: {
                          texto: `Lines 90-91 (“The young . . . Mr. Peters”)`,
                          checked: false
                       },
                       4: {
                          texto: `Lines 91-93 (“He was . . . forty-five”)`,
                          checked: false
                       }
                    }
                 }
              }
           }
        }
     },
     writing: {
        passages: {
           1: {
              informacion: '<b>Success in Montreal</b>',
              texto: `${resetLinea()}${setLinea()} The Montreal Protocol on Substances That Deplete the Ozone Layer is an international treaty that was created to ensure that<br>
              ${setLinea()} steps would be taken to reverse damage to Earth's ozone <b>1</b> <u>layer</u> and preventing future damage. <b>2</b><u>It was signed in 1987. This<br>
              ${setLinea()} document</u> created restrictions on chemicals that were known to be dangerous to the protective barrier that the ozone layer offers<br>
              ${setLinea()} Earth. Without the ozone layer, the sun's dangerous UV rays would alter our climate so drastically, life on land and in water would<br>
              ${setLinea()} cease to exist.<br>
              ${setLinea()} A hole in Earth's ozone layer was discovered over Antarctica <b>3</b> <u>as long as two years prior</u> to the signing of the treaty. The<br>
              ${setLinea()} discovery brought the human impact on the environment to the forefront <b>4</b> of <u>international conversation, the massive hole</u> was<br>
              ${setLinea()} evidence that a global response was necessary and that large-scale action was needed. The Montreal Protocol became effective<br>
              ${setLinea()} January 1, 1989, and nearly 100 gases deemed dangerous to the ozone layer have been phased out. As a result, <b>5</b> <u>the size of the<br>
              ${setLinea()} ozone hole decreased significantly during the 1990s</u>.<br>
              ${setLinea()} Now that a substantial amount of time has passed since the treaty was put into place, the effects can begin to be <b>6</b> <u>looked at</u>.<br>
              ${setLinea()} As a part of the treaty, the Montreal Protocol's Scientific Assessment Panel was created to gauge <b>7</b> <u>their</u> effect on the hole in the<br>
              ${setLinea()} ozone layer.
              ${setLinea()} The Panel has since reported the results every four years. The Panel predicts that the ozone layer will return to its former state<br>
              ${setLinea()} of health by 2060-2075.<b>8</b><br>
              ${setLinea()} While the treaty is already an obvious success, work continues to ensure that human strides in technology and industry do not<br>
              ${setLinea()} reverse the healing process. The Montreal Protocol's Multilateral Fund was established to help developing countries transition away<br>
              ${setLinea()} from the consumption and production of harmful chemicals. So far, over $3 billion has been invested by the Fund. The developing<br>
              ${setLinea()} countries are referred to as "Article 5 countries."<b>9</b><br>
              ${setLinea()} [1] The Montreal Protocol is a living document. [2] A current amendment proposition has been put forth by the United States,<br>
              ${setLinea()} Mexico, and Canada jointly. [3] It aims to cut down on harmful gases that were put into use as an alternative to the gases specified<br>
              ${setLinea()} in the original Montreal Protocol treaty. [4] It has been amended four times since its inception. [5] Combating the erosion of our<br>
              ${setLinea()} ozone layer will take time and flexibility, but the research is clear: If humans stay conscious of what we emit into the atmosphere, we<br>
              ${setLinea()} can not only stall the damage we have done in the past, but we can <b>10</b> <u>change</u> it.<b>11</b><br><br>
              ${setLinea()} Adapted from Ozone Hole Watch, NASA Goddard Space Flight Center.`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'to prevent',
                          checked: false
                       },
                       3: {
                          texto: 'prevented',
                          checked: false
                       },
                       4: {
                          texto: 'was preventing',
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: 'Which choice most effectively combines the sentences in the underlined portion?',
                    respuestas: {
                       1: {
                          texto: 'Signed in 1987, this document',
                          checked: false
                       },
                       2: {
                          texto: `Because it was signed in 1987, this document`,
                          checked: false
                       },
                       3: {
                          texto: 'It was signed in 1987, and this document',
                          checked: false
                       },
                       4: {
                          texto: `It was signed in 1987 so this document`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'long ago, two years prior',
                          checked: false
                       },
                       3: {
                          texto: 'two years prior',
                          checked: false
                       },
                       4: {
                          texto: `years prior`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: `international conversation, yet the massive hole`,
                          checked: false
                       },
                       3: {
                          texto: 'international conversation. The massive hole',
                          checked: false
                       },
                       4: {
                          texto: 'international conversation, so the massive hole',
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: 'Which choice completes the sentence with accurate data based on the graphic?',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'the average size of the ozone hole leveled off beginning in the 1990s.',
                          checked: false
                       },
                       3: {
                          texto: 'the average size of the ozone hole decreased beginning in the 2000s.',
                          checked: false
                       },
                       4: {
                          texto: 'the average size of the ozone hole increased beginning in the 1980s.',
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: `NO CHANGEs`,
                          checked: false
                       },
                       2: {
                          texto: 'controlled',
                          checked: false
                       },
                       3: {
                          texto: 'measured',
                          checked: false
                       },
                       4: {
                          texto: 'governed',
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: `NO CHANGE`,
                          checked: false
                       },
                       2: {
                          texto: 'its',
                          checked: false
                       },
                       3: {
                          texto: `it's`,
                          checked: false
                       },
                       4: {
                          texto: 'there',
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: 'Which choice could be added to paragraph 3 to most effectively convey its central idea?',
                    respuestas: {
                       1: {
                          texto: `It is the Panel's current estimation that the ozone layer is beginning to heal, but the rate of progress is slow.`,
                          checked: false
                       },
                       2: {
                          texto: 'The Panel meets once a year to assess the increase or decrease of each gas that has been identified as dangerous.',
                          checked: false
                       },
                       3: {
                          texto: 'Of much concern to the Panel was the effect of ultraviolet radiation on the ozone layer.',
                          checked: false
                       },
                       4: {
                          texto: 'The Panel has recently updated procedures for the nomination and selection of its membership.',
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: 'Which sentence in paragraph 4 provides the least support for the central idea of the paragraph?',
                    respuestas: {
                       1: {
                          texto: 'While the treaty is already an obvious success, work continues to ensure that human strides in technology and industry do not reverse the healing process.',
                          checked: false
                       },
                       2: {
                          texto: `The Montreal Protocol's Multilateral Fund was established to help developing countries transition away from the consumption and production of harmful chemicals.`,
                          checked: false
                       },
                       3: {
                          texto: `So far, over $3 billion has been invested by the Fund.`,
                          checked: false
                       },
                       4: {
                          texto: 'The developing countries are referred to as Article 5 countries.',
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: ``,
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'switch',
                          checked: false
                       },
                       3: {
                          texto: 'invert',
                          checked: false
                       },
                       4: {
                          texto: `reverse`,
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: 'For the sake of cohesion of this paragraph, sentence 4 should be placed',
                    respuestas: {
                       1: {
                          texto: 'where it is now.',
                          checked: false
                       },
                       2: {
                          texto: `before sentence 1.`,
                          checked: false
                       },
                       3: {
                          texto: 'after sentence 1.',
                          checked: false
                       },
                       4: {
                          texto: `before sentence 3.`,
                          checked: false
                       }
                    }
                 }
              }
           },
           2: {
              informacion: '<b>Sorting Recyclables for Best Re-Use</b>',
              texto: `${resetLinea()}${setLinea()} From the time a plastic container is thrown into a recycling bin to the time the plastic <b>1</b> <u>are</u> actually recycled, it passes through<br>
              ${setLinea()} several sorting cycles. In addition to being separated from the non-plastic items, the plastics themselves must be <b>2</b> <u>detached</u>,<br>
              ${setLinea()} because not all plastics are alike, making some easier to recycle than others.<br>
              ${setLinea()} During manual sorting, people <b>3</b> <u>very thoroughly check</u> the numbers on the bottom of each plastic item. The numbers indicate<br>
              ${setLinea()} the type of plastic each is made from. Some sorting can be automated by using machines that can detect the composition of the<br>
              ${setLinea()} plastic. The detectors in these machines use infrared light to characterize and sort the plastics, similar to how a human might use<br>
              ${setLinea()} visible light to sort materials by their color. By either method, the plastics can eventually be arranged into bins or piles<br>
              ${setLinea()} corresponding to the recycling code numbers running from one to seven. <b>4</b><br>
              ${setLinea()} In some cases, plastics are further sorted by the method by which they were manufactured. <b>5</b> <u>However</u>, <b>6</b> <u,>bottles, tubs and<br>
              ${setLinea()} trays</u> are typically <b>7</b><u>made from either PET (polyethylene terephthalate) or HDPE (high density polyethylene), two of the least<br>
              ${setLinea()} recovered plastics</u>. Bottles are produced by a process called blow-molding, in which the plastic is heated until soft, then blown up,<br>
              ${setLinea()} much like a balloon, while being pushed against a mold. Tubs and trays are usually made by a process called injection molding, in<br>
              ${setLinea()} which the plastic is heated until it can be pushed through nozzles into a mold. Different additives are added to the plastics<br>
              ${setLinea()} before <b>8</b> <u>molding. It depends</u> on the method. Since the additives for injection molding might not be suitable for blow-molding of the<br>
              ${setLinea()} recycled plastic, PET and HDPE bottles are often separated out from the other PET and HDPE plastics.<br>
              ${setLinea()} While the numbers 1 through 6 indicate a <b>9</b> specific plastic, number 7 indicates that the plastic is either one of many other<br>
              ${setLinea()} plastics, or that it is a blend of plastics. These plastics are more difficult to recycle, as different amounts of the various types of<br>
              ${setLinea()} number 7 plastics will be sent to recycling each day. They are typically used for products in which the plastic will be mixed with<br>
              ${setLinea()} other materials.<br>
              ${setLinea()} Although there are many types of plastics to be found in a typical recycling bin, each one can play a part in a<br>
              ${setLinea()} recycled <b>10</b><u>product, the many</u> cycles of sorting guarantee that each piece can be correctly processed and sent off for re-use.<b>11</b><br><br>
              <img src="${pathImagesExamen}/Ingles/v3-writing-passage-2.png">`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'is',
                          checked: false
                       },
                       3: {
                          texto: 'has been',
                          checked: false
                       },
                       4: {
                          texto: 'will be',
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: `demolished`,
                          checked: false
                       },
                       3: {
                          texto: 'flanked',
                          checked: false
                       },
                       4: {
                          texto: `categorized`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'completely and thoroughly check',
                          checked: false
                       },
                       3: {
                          texto: 'thoroughly check',
                          checked: false
                       },
                       4: {
                          texto: `make sure to thoroughly check`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: 'Which sentence could be added to the paragraph to most effectively establish the central idea?',
                    respuestas: {
                       1: {
                          texto: 'Special machines have been developed to assist in sorting plastics.',
                          checked: false
                       },
                       2: {
                          texto: `Sorting by hand is less efficient than using machines to sort plastics.`,
                          checked: false
                       },
                       3: {
                          texto: 'Classifying plastics can be done manually or by machines.',
                          checked: false
                       },
                       4: {
                          texto: 'Plastics are widely used today, so they need to be recycled.',
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'For example,',
                          checked: false
                       },
                       3: {
                          texto: 'Consequently,',
                          checked: false
                       },
                       4: {
                          texto: 'Similarly,',
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: `NO CHANGEs`,
                          checked: false
                       },
                       2: {
                          texto: 'bottles, tubs, and trays',
                          checked: false
                       },
                       3: {
                          texto: 'bottles tubs and trays',
                          checked: false
                       },
                       4: {
                          texto: 'bottles, tubs, and, trays',
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: 'Which choice completes the sentence with accurate data based on the graphic?',
                    respuestas: {
                       1: {
                          texto: `NO CHANGE`,
                          checked: false
                       },
                       2: {
                          texto: 'made from PET (polyethylene terephthalate) or HDPE (high density polyethylene), the two most recovered plastics after the leading type, LDPE',
                          checked: false
                       },
                       3: {
                          texto: 'made from PP (polypropene) or PS (polystyrene), the two most recovered plastics after the leading type, PVC',
                          checked: false
                       },
                       4: {
                          texto: 'made from PP (polypropene) or PS (polystyrene), the two most recovered plastics after the leading type, EPS',
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: 'Which choice most effectively combines the sentences at the underlined portion?',
                    respuestas: {
                       1: {
                          texto: 'molding, however, it depends',
                          checked: false
                       },
                       2: {
                          texto: 'molding, depending',
                          checked: false
                       },
                       3: {
                          texto: 'molding despite depending',
                          checked: false
                       },
                       4: {
                          texto: 'molding, it depends',
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'vague',
                          checked: false
                       },
                       3: {
                          texto: `common`,
                          checked: false
                       },
                       4: {
                          texto: 'pending',
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: ``,
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'product the many',
                          checked: false
                       },
                       3: {
                          texto: 'product. The many',
                          checked: false
                       },
                       4: {
                          texto: `product, so the many`,
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: 'Which choice most effectively establishes a summative concluding sentence for the paragraph?',
                    respuestas: {
                       1: {
                          texto: 'Sorting ensures that plastics will not linger in the landfills, but continue to be of use.',
                          checked: false
                       },
                       2: {
                          texto: `Sorting different types of plastics is done in many ways, either by hand or machine.'`,
                          checked: false
                       },
                       3: {
                          texto: 'Oftentimes, people are required to sort their own plastics by type.',
                          checked: false
                       },
                       4: {
                          texto: `There are many different kinds of plastics, and each one is useful.`,
                          checked: false
                       }
                    }
                 }
              }
           },
           3: {
              informacion: `<b>Interpreter at America's Immigrant Gateway</b>`,
              texto: `${resetLinea()}${setLinea()} Throughout his career as an interpreter at America's largest immigrant processing station, Kaufman has spent many ferry rides<br>
              ${setLinea()} mentally preparing himself for the vivid realities of his job. Although some of his contemporaries might consider his work menial or<br>
              ${setLinea()} inconsequential, he cherishes his opportunity to witness and contribute to the unfolding stories of countless immigrants. These<br>
              ${setLinea()} immigrant stories, Kaufman knows, hold <b>1</b><u>great significance for his and American history</u>. Most of the brave, sea-worn travelers who<br>
              ${setLinea()} disembark at Ellis Island will soon depart as new Americans, <b>2</b> <u>lugging all there courage</u>, hope, and worldly possessions into New<br>
              ${setLinea()} York City. Many <b>3</b> <u>will remain in the city and some other people will disperse across the</u> nation. </b>4</b><br>
              ${setLinea()} [1] The year is 1907: the busiest year Kaufman, or Ellis Island, has seen. [2] One and a quarter million immigrants have been<br>
              ${setLinea()} admitted to the U.S. this year. [3] Only about 2 percent of Ellis Island's immigrants are denied, typically for perceived potential<br>
              ${setLinea()} criminal or public health threats. [4] The rest will establish life in America, although not without difficulty and perseverance. [5] At the<br>
              ${setLinea()} immigration station, Kaufman regularly sees the range of raw human emotion, from deep, exhausted grief to powerful hope. [6] He<br>
              ${setLinea()} has witnessed it all. <b>5</b><br>
              ${setLinea()} <b>6</b> <u>Many Ellis Island interpreters were born to European immigrants</u>. <b>7</b> <u>His heritage, and surrounding community, enabled him to<br>
              ${setLinea()} learn six languages</u>.Fluency in six languages is typical for Ellis Island interpreters, although Kaufman knows some who speak as<br>
              ${setLinea()} many as twelve or thirteen. Kaufman knows that in some ways, his ability to listen and translate effectively can impact the course of<br>
              ${setLinea()} an immigrant's future. For this reason, he constantly hones his language skills, picking up various <b>8</b><u>shades</u> and dialects in hopes to<br>
              ${setLinea()} better help those he serves.<br>
              ${setLinea()} Kaufman assists colleagues at every checkpoint. Ellis Island is equipped with a hospital, dining room, and boarding room, in<br>
              ${setLinea()} addition to the more central processing facilities. <b>9</b> This morning, he helps an Italian family discuss their child's health with nurses.<br>
              ${setLinea()} Later, he translates for a Polish woman who expects to meet her brother soon. When Kaufman meets immigrants whose language<br>
              ${setLinea()} he cannot speak, he finds another interpreter <b>10</b> <u>to help speak to them instead of him doing it</u>.<br>
              ${setLinea()} To some extent, Kaufman sees himself distinctly in the shoes of these immigrants. He intimately knows the reality that almost<br>
              ${setLinea()} all Americans, somewhere in their ancestry, were aliens in this nation. With every encounter, Kaufman hopes that these immigrants<br>
              ${setLinea()} will soon find whatever they crossed oceans to seek. He hopes, as he still does for his own family, that life in America will someday<br>
              ${setLinea()} render the <b>11</b> <u>advantages</u> of leaving home worthwhile.`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: `great significance for his—and America's—history.`,
                          checked: false
                       },
                       3: {
                          texto: `great significance for his: and America's history.`,
                          checked: false
                       },
                       4: {
                          texto: `great significance for his, and America's, history.`,
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: `lugging all they're courage,`,
                          checked: false
                       },
                       3: {
                          texto: 'lugging all their courage,',
                          checked: false
                       },
                       4: {
                          texto: `lugging all there are courage,`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'will remain in the city, but other people will nonetheless disperse across the',
                          checked: false
                       },
                       3: {
                          texto: 'will remain in the city; many others will disperse across the',
                          checked: false
                       },
                       4: {
                          texto: `will remain in the city, though yet others will disperse across the`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: 'Which sentence, if added to the beginning of paragraph 1, would set the appropriate tone for the remainder of the paragraph?',
                    respuestas: {
                       1: {
                          texto: 'Among the many diverse and fascinating possibilities for a career, David Kaufman chose language interpretation.',
                          checked: false
                       },
                       2: {
                          texto: `Many people never consider language interpretation as a job, but David Kaufman knows all about it.`,
                          checked: false
                       },
                       3: {
                          texto: 'All jobs come with difficulties, and David Kaufman believes language interpretation is no different.',
                          checked: false
                       },
                       4: {
                          texto: `A pale horizon meets the early-morning sky as David Kaufman's commuter ferry crosses the New York Harbor, bound for Ellis Island.`,
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: 'For the sake of the cohesion of the paragraph, sentence 1 should be placed',
                    respuestas: {
                       1: {
                          texto: 'where it is now.',
                          checked: false
                       },
                       2: {
                          texto: 'after sentence 2.',
                          checked: false
                       },
                       3: {
                          texto: 'after sentence 3.',
                          checked: false
                       },
                       4: {
                          texto: 'after sentence 4.',
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: 'Which sentence most effectively establishes the central idea of the paragraph?',
                    respuestas: {
                       1: {
                          texto: `NO CHANGEs`,
                          checked: false
                       },
                       2: {
                          texto: 'Like many Ellis Island interpreters, Kaufman was born to European immigrants.',
                          checked: false
                       },
                       3: {
                          texto: 'Language ability was especially important among Ellis Island interpreters.',
                          checked: false
                       },
                       4: {
                          texto: 'Some accused children of European immigrants of having an unfair advantage in getting jobs at Ellis Island.',
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: `NO CHANGE`,
                          checked: false
                       },
                       2: {
                          texto: 'His heritage, and surrounding community enabled him to learn six languages.',
                          checked: false
                       },
                       3: {
                          texto: 'His heritage and surrounding community, enabled him to learn six languages.',
                          checked: false
                       },
                       4: {
                          texto: 'His heritage and surrounding community enabled him to learn six languages.',
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'meanings',
                          checked: false
                       },
                       3: {
                          texto: 'tricks',
                          checked: false
                       },
                       4: {
                          texto: 'nuances',
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: 'Which sentence, if added at this point, would best support the central idea of the paragraph?',
                    respuestas: {
                       1: {
                          texto: 'Kaufman is one of an army of Ellis Island employees spread around the enormous compound.',
                          checked: false
                       },
                       2: {
                          texto: 'From medical screening to records confirmation to inspection, Kaufman interprets as needs arise.',
                          checked: false
                       },
                       3: {
                          texto: `Sometimes, Kaufman feels the stress of being pulled in many different directions, but ultimately he finds his job worthwhile.`,
                          checked: false
                       },
                       4: {
                          texto: 'Kaufman and his colleagues work, eat, and practically live together, making them feel closer than typical coworkers.',
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: ``,
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'to help speak instead of him.',
                          checked: false
                       },
                       3: {
                          texto: 'helping him out with speaking.',
                          checked: false
                       },
                       4: {
                          texto: `to help.`,
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: `journeys'`,
                          checked: false
                       },
                       3: {
                          texto: 'difficulties',
                          checked: false
                       },
                       4: {
                          texto: `penalties`,
                          checked: false
                       }
                    }
                 }
              }
           },
           4: {
              informacion: '<b>Software Sales: A Gratifying Career</b>',
              texto: `${resetLinea()}${setLinea()} Ever since she was a young girl, Stephanie Morales took on the role of family problem-solver. <b>1</b> <u>She remembers her brother<br>
              ${setLinea()} never being able to find his favorite movie when he wanted to watch it: So, she alphabetized the family DVD<br>
              ${setLinea()} collection</u>. <b>2</b> <u>"They're</u> about efficiency and what makes sense to a user," Morales says, "and putting systems in place so that using<br>
              ${setLinea()} something becomes effortless."<br>
              ${setLinea()} Growing up, Morales became notorious amongst her friends as the one to plan parties and trips, and she was always voted<br>
              ${setLinea()} team captain because everyone knew she could see the big picture and enact a plan. <b>3</b> <u>After college, she tried a career in interior<br>
              ${setLinea()} design, but homes and offices didn't excite her</u>. "I didn't have a passion for furniture or architecture. I knew there must be a field out<br>
              ${setLinea()} there that really tapped into my particular skill set," Morales says.<br>
              ${setLinea()} [1]To her surprise, that career turned out to be software consulting. [2] Morales returned from a backpacking trip around<br>
              ${setLinea()} Europe to her parents' New Hampshire home, needing income. [3] New Hampshire also has many fine backpacking<br>
              ${setLinea()} trails. <b>4</b> [4] <u>Although she had no direct experience in the field, Morales convinced</u> a family friend to hire her as a software consultant<br>
              ${setLinea()} to work with new clients. <b>5</b><br>
              ${setLinea()} <b>6</b> <u>"I had helped many of my friends with their computers. Knowing my interpersonal skills were strong," Morales says</u>.<br>
              ${setLinea()} <b>7</b> For example, Morales worked with a manufacturing company that was growing quickly but had trouble <b>8</b>  <u>maintaining</u> employees.<br>
              ${setLinea()} The company's human resources department could not keep up with regular payroll and billing, plus running advertisements and<br>
              ${setLinea()} interviewing potential replacement employees. Morales used staff management software to gather data about employee<br>
              ${setLinea()} satisfaction. Analysis showed that employees found the shift work too challenging for their schedules. The company changed the<br>
              ${setLinea()} hours of the morning and evening shifts to meet employees' needs, which led to fewer workers leaving the company.<br>
              ${setLinea()} Nowadays, Morales works with what she calls "big data," such as information about consumer habits gathered through a<br>
              ${setLinea()} supermarket membership card. These stores of information are a treasure trove to Morales, because they tell the story of how<br>
              ${setLinea()} people interact with the world around them. She uses the information data to make changes—just like alphabetizing a DVD<br>
              ${setLinea()} collection. Her goal is to <b>9</b> <u>vacillate</u> into the health care field, where she wants to bring the benefits of technology to people's<br>
              ${setLinea()} physical and mental well-being. <b>10</b><br>
              ${setLinea()} "Our health is extremely important, and every time someone fills a prescription online or has a follow-up visit with their doctor,<br>
              ${setLinea()} that's information about the efficacy of the medication. The technological revolution has the power <b>11</b> <u>to quicken doctor's visits,<br>
              ${setLinea()} improve the care we get, and even save lives</u>."`,
              preguntas: {
                 1: {
                    indicePregunta: '1',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'She remembers her brother never being able to find his favorite movie, when he wanted to watch it so she alphabetized the family DVD collection.',
                          checked: false
                       },
                       3: {
                          texto: 'She remembers her brother never being able to find his favorite movie; when he wanted to watch it, so she alphabetized the family DVD collection.',
                          checked: false
                       },
                       4: {
                          texto: 'She remembers her brother never being able to find his favorite movie when he wanted to watch it, so she alphabetized the family DVD collection.',
                          checked: false
                       }
                    }
                 },
                 2: {
                    indicePregunta: '2',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: `It's`,
                          checked: false
                       },
                       3: {
                          texto: 'Their',
                          checked: false
                       },
                       4: {
                          texto: `Its`,
                          checked: false
                       }
                    }
                 },
                 3: {
                    indicePregunta: '3',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: `After college, she tried a career in interior design, but homes and offices, didn't excite her.`,
                          checked: false
                       },
                       3: {
                          texto: `After college, she tried a career in interior design but homes, and offices didn't excite her.`,
                          checked: false
                       },
                       4: {
                          texto: `After college she tried a career, in interior design, but homes and offices didn't excite her.`,
                          checked: false
                       }
                    }
                 },
                 4: {
                    indicePregunta: '4',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: `Although she had no direct experience in the field, Morales convinces`,
                          checked: false
                       },
                       3: {
                          texto: 'Although she has no direct experience in the field, Morales convinced',
                          checked: false
                       },
                       4: {
                          texto: 'Although she will have no direct experience in the field, Morales convinces',
                          checked: false
                       }
                    }
                 },
                 5: {
                    indicePregunta: '5',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'Sentence 1',
                          checked: false
                       },
                       2: {
                          texto: 'Sentence 2',
                          checked: false
                       },
                       3: {
                          texto: 'Sentence 3',
                          checked: false
                       },
                       4: {
                          texto: 'Sentence 4',
                          checked: false
                       }
                    }
                 },
                 6: {
                    indicePregunta: '6',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: `NO CHANGEs`,
                          checked: false
                       },
                       2: {
                          texto: 'I had helped many of my friends; with their computers and I knew my interpersonal skills were strong, Morales says.',
                          checked: false
                       },
                       3: {
                          texto: 'I had helped many of my friends with their computers; knew my interpersonal skills were strong, Morales says.',
                          checked: false
                       },
                       4: {
                          texto: 'I had helped many of my friends with their computers, and I knew my interpersonal skills were strong, Morales says.',
                          checked: false
                       }
                    }
                 },
                 7: {
                    indicePregunta: '7',
                    enunciado: 'Which choice most effectively establishes the topic sentence of the paragraph?',
                    respuestas: {
                       1: {
                          texto: `Because she was willing to work in a factory, she was able to achieve success as a consultant.`,
                          checked: false
                       },
                       2: {
                          texto: `Morales's management of data led to the success of the company's advertising campaign.`,
                          checked: false
                       },
                       3: {
                          texto: 'Where the job really matched up with her strengths was in problem-solving and finding creative solutions.',
                          checked: false
                       },
                       4: {
                          texto: `Morales's advice to the human resources department resulted in higher wages for employees.`,
                          checked: false
                       }
                    }
                 },
                 8: {
                    indicePregunta: '8',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'retaining',
                          checked: false
                       },
                       3: {
                          texto: 'constraining',
                          checked: false
                       },
                       4: {
                          texto: 'detaining',
                          checked: false
                       }
                    }
                 },
                 9: {
                    indicePregunta: '9',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: 'convert',
                          checked: false
                       },
                       3: {
                          texto: `transition`,
                          checked: false
                       },
                       4: {
                          texto: 'fluctuate',
                          checked: false
                       }
                    }
                 },
                 10: {
                    indicePregunta: '10',
                    enunciado: `Which choice best supports the topic sentence of the paragraph?`,
                    respuestas: {
                       1: {
                          texto: 'For example, Morales is also interested in whether people pay for their medications with credit or debit cards.',
                          checked: false
                       },
                       2: {
                          texto: 'For example, Morales spends countless hours walking through discount stores surveying the customers.',
                          checked: false
                       },
                       3: {
                          texto: `For example, Morales still gets great satisfaction from organizing her friends' and family's DVD collections.`,
                          checked: false
                       },
                       4: {
                          texto: `For example, Morales can use "big data" to determine how many patients from a particular clinic use online automated refills.`,
                          checked: false
                       }
                    }
                 },
                 11: {
                    indicePregunta: '11',
                    enunciado: '',
                    respuestas: {
                       1: {
                          texto: 'NO CHANGE',
                          checked: false
                       },
                       2: {
                          texto: `to quicken doctor's visits improve the care we get, and even save lives.'`,
                          checked: false
                       },
                       3: {
                          texto: `to quicken doctor's visits, improve the care we get, and even, save lives.`,
                          checked: false
                       },
                       4: {
                          texto: `to quicken doctor's visits; improve the care we get, and even save lives.`,
                          checked: false
                       }
                    }
                 }
              }
           }
        }
     }
  }
}

export const respuestasIngles = {
   1: {
      reading: {
         passages: {
            1: {
               preguntas: {
                  1: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '2'
                  },
                  2: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '1'
                  },
                  3: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '1'
                  },
                  4: {
                     rama: 'retorica',
                     idRespuestaCorrecta: '4'
                  },
                  5: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '3'
                  },
                  6: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '2'
                  },
                  7: {
                     rama: 'retorica',
                     idRespuestaCorrecta: '1'
                  },
                  8: {
                     rama: 'sintesis',
                     idRespuestaCorrecta: '4'
                  },
                  9: {
                     rama: 'sintesis',
                     idRespuestaCorrecta: '2'
                  },
                  10: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '4'
                  },
                  11: {
                     rama: 'sintesis',
                     idRespuestaCorrecta: '1'
                  }
               }
            },
            2: {
               preguntas: {
                  1: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '1'
                  },
                  2: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '3'
                  },
                  3: {
                     rama: 'sintesis',
                     idRespuestaCorrecta: '3'
                  },
                  4: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '4'
                  },
                  5: {
                     rama: 'sintesis',
                     idRespuestaCorrecta: '1'
                  },
                  6: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '4'
                  },
                  7: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '4'
                  },
                  8: {
                     rama: 'retorica',
                     idRespuestaCorrecta: '2'
                  },
                  9: {
                     rama: 'retorica',
                     idRespuestaCorrecta: '3'
                  },
                  10: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '2'
                  }
               }
            },
            3: {
               preguntas: {
                  1: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '2'
                  },
                  2: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '3'
                  },
                  3: {
                     rama: 'comprension',
                     idRespuestaCorrecta: '2'
                  },
                  4: {
                     rama: 'sintesis',
                     idRespuestaCorrecta: '1'
                  },
                  5: {
                     rama: 'retorica',
                     idRespuestaCorrecta: '3'
                  },
                  6: {
                     rama: 'retorica',
                     idRespuestaCorrecta: '1'
                  },
                  7: {
                     rama: 'sintesis',
                     idRespuestaCorrecta: '2'
                  },
                  8: {
                     rama: 'sintesis',
                     idRespuestaCorrecta: '4'
                  },
                  9: {
                     rama: 'sintesis',
                     idRespuestaCorrecta: '4'
                  }
               }
            }
         }
      },
      writing: {
         passages: {
            1: {
               preguntas: {
                  1: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '4'
                  },
                  2: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '3'
                  },
                  3: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '2'
                  },
                  4: {
                     rama: 'vocabulario',
                     idRespuestaCorrecta: '4'
                  },
                  5: {
                     rama: 'writing',
                     idRespuestaCorrecta: '4'
                  },
                  6: {
                     rama: 'writing',
                     idRespuestaCorrecta: '1'
                  },
                  7: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '2'
                  },
                  8: {
                     rama: 'vocabulario',
                     idRespuestaCorrecta: '3'
                  },
                  9: {
                     rama: 'writing',
                     idRespuestaCorrecta: '3'
                  },
                  10: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '4'
                  },
                  11: {
                     rama: 'writing',
                     idRespuestaCorrecta: '4'
                  }
               }
            },
            2: {
               preguntas: {
                  1: {
                     rama: 'vocabulario',
                     idRespuestaCorrecta: '4'
                  },
                  2: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '2'
                  },
                  3: {
                     rama: 'writing',
                     idRespuestaCorrecta: '4'
                  },
                  4: {
                     rama: 'writing',
                     idRespuestaCorrecta: '2'
                  },
                  5: {
                     rama: 'writing',
                     idRespuestaCorrecta: '3'
                  },
                  6: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '2'
                  },
                  7: {
                     rama: 'writing',
                     idRespuestaCorrecta: '3'
                  },
                  8: {
                     rama: 'vocabulario',
                     idRespuestaCorrecta: '1'
                  },
                  9: {
                     rama: 'writing',
                     idRespuestaCorrecta: '3'
                  },
                  10: {
                     rama: 'writing',
                     idRespuestaCorrecta: '4'
                  },
                  11: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '2'
                  }
               }
            },
            3: {
               preguntas: {
                  1: {
                     rama: 'writing',
                     idRespuestaCorrecta: '2'
                  },
                  2: {
                     rama: 'vocabulario',
                     idRespuestaCorrecta: '2'
                  },
                  3: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '3'
                  },
                  4: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '1'
                  },
                  5: {
                     rama: 'writing',
                     idRespuestaCorrecta: '2'
                  },
                  6: {
                     rama: 'writing',
                     idRespuestaCorrecta: '1'
                  },
                  7: {
                     rama: 'writing',
                     idRespuestaCorrecta: '1'
                  },
                  8: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '3'
                  },
                  9: {
                    rama: 'vocabulario',
                    idRespuestaCorrecta: '1'
                  },
                  10: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '4'
                  },
                  11: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '3'
                  }
               }
            },
            4: {
               preguntas: {
                  1: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '3'
                  },
                  2: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '1'
                  },
                  3: {
                     rama: 'vocabulario',
                     idRespuestaCorrecta: '4'
                  },
                  4: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '2'
                  },
                  5: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '1'
                  },
                  6: {
                     rama: 'vocabulario',
                     idRespuestaCorrecta: '2'
                  },
                  7: {
                     rama: 'writing',
                     idRespuestaCorrecta: '1'
                  },
                  8: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '1'
                  },
                  9: {
                     rama: 'gramatica',
                     idRespuestaCorrecta: '4'
                  },
                  10: {
                     rama: 'writing',
                     idRespuestaCorrecta: '2'
                  },
                  11: {
                     rama: 'writing',
                     idRespuestaCorrecta: '3'
                  }
               }
            }
         }
      }
   },
   2: {
      reading: {
         passages: {
            1: {
               preguntas: {
                  1: {
                     idRespuestaCorrecta: '2'
                  },
                  2: {
                     idRespuestaCorrecta: '2'
                  },
                  3: {
                     idRespuestaCorrecta: '3'
                  },
                  4: {
                     idRespuestaCorrecta: '1'
                  },
                  5: {
                     idRespuestaCorrecta: '3'
                  },
                  6: {
                     idRespuestaCorrecta: '4'
                  },
                  7: {
                     idRespuestaCorrecta: '4'
                  },
                  8: {
                    idRespuestaCorrecta: '2'
                  },
                  9: {
                     idRespuestaCorrecta: '3'
                  },
                  10: {
                     idRespuestaCorrecta: '2'
                  }
               }
            },
            2: {
               preguntas: {
                  1: {
                     idRespuestaCorrecta: '1'
                  },
                  2: {
                     idRespuestaCorrecta: '3'
                  },
                  3: {
                    idRespuestaCorrecta: '1'
                  },
                  4: {
                     idRespuestaCorrecta: '3'
                  },
                  5: {
                     idRespuestaCorrecta: '1'
                  },
                  6: {
                     idRespuestaCorrecta: '4'
                  },
                  7: {
                     idRespuestaCorrecta: '2'
                  },
                  8: {
                    idRespuestaCorrecta: '2'
                  },
                  9: {
                    idRespuestaCorrecta: '2'
                  },
                  10: {
                     idRespuestaCorrecta: '3'
                  },
                  11: {
                     idRespuestaCorrecta: '3'
                  }
               }
            },
            3: {
               preguntas: {
                  1: {
                     idRespuestaCorrecta: '2'
                  },
                  2: {
                    idRespuestaCorrecta: '1'
                  },
                  3: {
                     idRespuestaCorrecta: '2'
                  },
                  4: {
                    idRespuestaCorrecta: '4'
                  },
                  5: {
                     idRespuestaCorrecta: '3'
                  },
                  6: {
                     idRespuestaCorrecta: '1'
                  },
                  7: {
                    idRespuestaCorrecta: '2'
                  },
                  8: {
                     idRespuestaCorrecta: '2'
                  },
                  9: {
                     idRespuestaCorrecta: '2'
                  }
               }
            }
         }
      },
      writing: {
         passages: {
            1: {
               preguntas: {
                  1: {
                     idRespuestaCorrecta: '3'
                  },
                  2: {
                     idRespuestaCorrecta: '2'
                  },
                  3: {
                     idRespuestaCorrecta: '1'
                  },
                  4: {
                     idRespuestaCorrecta: '3'
                  },
                  5: {
                     idRespuestaCorrecta: '3'
                  },
                  6: {
                     idRespuestaCorrecta: '1'
                  },
                  7: {
                     idRespuestaCorrecta: '4'
                  },
                  8: {
                     idRespuestaCorrecta: '4'
                  },
                  9: {
                     idRespuestaCorrecta: '3'
                  },
                  10: {
                     idRespuestaCorrecta: '2'
                  },
                  11: {
                     idRespuestaCorrecta: '2'
                  }
               }
            },
            2: {
               preguntas: {
                  1: {
                     idRespuestaCorrecta: '2'
                  },
                  2: {
                     idRespuestaCorrecta: '1'
                  },
                  3: {
                     idRespuestaCorrecta: '2'
                  },
                  4: {
                     idRespuestaCorrecta: '2'
                  },
                  5: {
                     idRespuestaCorrecta: '4'
                  },
                  6: {
                     idRespuestaCorrecta: '3'
                  },
                  7: {
                     idRespuestaCorrecta: '3'
                  },
                  8: {
                     idRespuestaCorrecta: '1'
                  },
                  9: {
                     idRespuestaCorrecta: '1'
                  },
                  10: {
                     idRespuestaCorrecta: '2'
                  },
                  11: {
                     idRespuestaCorrecta: '3'
                  }
               }
            },
            3: {
               preguntas: {
                  1: {
                     idRespuestaCorrecta: '3'
                  },
                  2: {
                    idRespuestaCorrecta: '4'
                  },
                  3: {
                    idRespuestaCorrecta: '1'
                  },
                  4: {
                     idRespuestaCorrecta: '1'
                  },
                  5: {
                    idRespuestaCorrecta: '2'
                  },
                  6: {
                     idRespuestaCorrecta: '3'
                  },
                  7: {
                    idRespuestaCorrecta: '1'
                  },
                  8: {
                    idRespuestaCorrecta: '3'
                  },
                  9: {
                    idRespuestaCorrecta: '1'
                  },
                  10: {
                    idRespuestaCorrecta: '3'
                  },
                  11: {
                    idRespuestaCorrecta: '2'
                  }
               }
            },
            4: {
               preguntas: {
                  1: {
                    idRespuestaCorrecta: '1'
                  },
                  2: {
                    idRespuestaCorrecta: '3'
                  },
                  3: {
                    idRespuestaCorrecta: '4'
                  },
                  4: {
                     idRespuestaCorrecta: '3'
                  },
                  5: {
                     idRespuestaCorrecta: '2'
                  },
                  6: {
                    idRespuestaCorrecta: '2'
                  },
                  7: {
                    idRespuestaCorrecta: '4'
                  },
                  8: {
                     idRespuestaCorrecta: '3'
                  },
                  9: {
                     idRespuestaCorrecta: '4'
                  },
                  10: {
                     idRespuestaCorrecta: '1'
                  },
                  11: {
                     idRespuestaCorrecta: '2'
                  }
               }
            }
         }
      }
 
   },
   3: {
      reading: {
         passages: {
            1: {
               preguntas: {
                  1: {
                    idRespuestaCorrecta: '1'
                  },
                  2: {
                    idRespuestaCorrecta: '2'
                  },
                  3: {
                     idRespuestaCorrecta: '4'
                  },
                  4: {
                     idRespuestaCorrecta: '1'
                  },
                  5: {
                     idRespuestaCorrecta: '1'
                  },
                  6: {
                     idRespuestaCorrecta: '3'
                  },
                  7: {
                     idRespuestaCorrecta: '3'
                  },
                  8: {
                     idRespuestaCorrecta: '4'
                  },
                  9: {
                   idRespuestaCorrecta: '1'
                  },
                  10: {
                    idRespuestaCorrecta: '2'
                  },
                  11: {
                     idRespuestaCorrecta: '1'
                  }
               }
            },
            2: {
               preguntas: {
                  1: {
                    idRespuestaCorrecta: '2'
                  },
                  2: {
                     idRespuestaCorrecta: '3'
                  },
                  3: {
                     idRespuestaCorrecta: '2'
                  },
                  4: {
                     idRespuestaCorrecta: '3'
                  },
                  5: {
                     idRespuestaCorrecta: '2'
                  },
                  6: {
                     idRespuestaCorrecta: '3'
                  },
                  7: {
                     idRespuestaCorrecta: '4'
                  },
                  8: {
                    idRespuestaCorrecta: '3'
                  },
                  9: {
                     idRespuestaCorrecta: '1'
                  },
                  10: {
                     idRespuestaCorrecta: '4'
                  }
               }
            },
            3: {
               preguntas: {
                  1: {
                     idRespuestaCorrecta: '4'
                  },
                  2: {
                     idRespuestaCorrecta: '3'
                  },
                  3: {
                     idRespuestaCorrecta: '3'
                  },
                  4: {
                    idRespuestaCorrecta: '1'
                  },
                  5: {
                     idRespuestaCorrecta: '3'
                  },
                  6: {
                     idRespuestaCorrecta: '1'
                  },
                  7: {
                    idRespuestaCorrecta: '4'
                  },
                  8: {
                     idRespuestaCorrecta: '2'
                  },
                  9: {
                     idRespuestaCorrecta: '2'
                  }
               }
            }
         }
      },
      writing: {
         passages: {
            1: {
               preguntas: {
                  1: {
                     idRespuestaCorrecta: '2'
                  },
                  2: {
                     idRespuestaCorrecta: '1'
                  },
                  3: {
                    idRespuestaCorrecta: '3'
                  },
                  4: {
                     idRespuestaCorrecta: '3'
                  },
                  5: {
                    idRespuestaCorrecta: '2'
                  },
                  6: {
                    idRespuestaCorrecta: '3'
                  },
                  7: {
                    idRespuestaCorrecta: '2'
                  },
                  8: {
                     idRespuestaCorrecta: '1'
                  },
                  9: {
                    idRespuestaCorrecta: '4'
                  },
                  10: {
                     idRespuestaCorrecta: '4'
                  },
                  11: {
                    idRespuestaCorrecta: '3'
                  }
               }
            },
            2: {
               preguntas: {
                  1: {
                     idRespuestaCorrecta: '2'
                  },
                  2: {
                    idRespuestaCorrecta: '4'
                  },
                  3: {
                    idRespuestaCorrecta: '3'
                  },
                  4: {
                     idRespuestaCorrecta: '3'
                  },
                  5: {
                     idRespuestaCorrecta: '2'
                  },
                  6: {
                    idRespuestaCorrecta: '2'
                  },
                  7: {
                     idRespuestaCorrecta: '2'
                  },
                  8: {
                    idRespuestaCorrecta: '2'
                  },
                  9: {
                    idRespuestaCorrecta: '1'
                  },
                  10: {
                     idRespuestaCorrecta: '3'
                  },
                  11: {
                     idRespuestaCorrecta: '1'
                  }
               }
            },
            3: {
               preguntas: {
                  1: {
                    idRespuestaCorrecta: '2'
                  },
                  2: {
                     idRespuestaCorrecta: '3'
                  },
                  3: {
                     idRespuestaCorrecta: '3'
                  },
                  4: {
                     idRespuestaCorrecta: '4'
                  },
                  5: {
                     idRespuestaCorrecta: '1'
                  },
                  6: {
                     idRespuestaCorrecta: '2'
                  },
                  7: {
                     idRespuestaCorrecta: '4'
                  },
                  8: {
                     idRespuestaCorrecta: '4'
                  },
                  9: {
                     idRespuestaCorrecta: '2'
                  },
                  10: {
                    idRespuestaCorrecta: '4'
                  },
                  11: {
                     idRespuestaCorrecta: '3'
                  }
               }
            },
            4: {
               preguntas: {
                  1: {
                     idRespuestaCorrecta: '4'
                  },
                  2: {
                    idRespuestaCorrecta: '2'
                  },
                  3: {
                    idRespuestaCorrecta: '1'
                  },
                  4: {
                     idRespuestaCorrecta: '1'
                  },
                  5: {
                     idRespuestaCorrecta: '3'
                  },
                  6: {
                     idRespuestaCorrecta: '4'
                  },
                  7: {
                     idRespuestaCorrecta: '3'
                  },
                  8: {
                    idRespuestaCorrecta: '2'
                  },
                  9: {
                    idRespuestaCorrecta: '3'
                  },
                  10: {
                    idRespuestaCorrecta: '4'
                  },
                  11: {
                     idRespuestaCorrecta: '1'
                  }
               }
            }
         }
      }
   }
 }

export const directionsIngles = {
   reading: {
      text:`
         <h1>Section 1: READING</h1>
         <h4>35 minutes</h4>
         <h4>30 questions</h4>
         <h4>3 passages (10 questions per passage)</h4>
      `,
      directions: inglesReadingDirections
   },

   writing: {
      text:`
         <h1>Section 2: WRITING AND LANGUAGE</h1>
         <h4>35 minutes</h4>
         <h4>44 questions</h4>
         <h4>4 passages</h4>
      `,
      directions: inglesWritingDirections
   }

}