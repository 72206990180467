import React, { useState, useEffect, memo } from 'react'
import Input from '../../../../../Shared/Input'
import InputMenu from '../../../../../Shared/InputMenu'
import Switch from '../../../../../Shared/Switch'
import UpdateStateInputs from '../../../../../Shared/UpdateStateInputs'
import { generateInglesExamen, generateMatematicasExamen } from '../../../../../../utils/functions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../utils/firebase'
import _ from 'underscore'
import Checkbox from '../../../../../Shared/Checkbox'

const InformacionAlumnos = memo((props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})
  const [allChecked, setAllChecked] = useState(false)

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  useEffect(()=>{
    if(Object.keys(state.alumnosHabilitados).length===Object.keys(props.alumnos).length){
      setAllChecked(true)
    }else{
      setAllChecked(false)
    }
  }, [state.alumnosHabilitados])

  const saveData = () => {
    var multiPath = {};
    multiPath[`Colegios/${props.id}/examen/alumnosHabilitados`] = state.alumnosHabilitados
    Object.entries(state.alumnosHabilitados).forEach(([idAlumno])=>{
      let examen = null
      if(!props.alumnosStore[idAlumno].inscripcionAlumno.sat.examen){
        examen = {
          ingles : generateInglesExamen(),
          matematicas: {
            3: { preguntas: generateMatematicasExamen(3)},
            4: { preguntas:generateMatematicasExamen(4) }
          }
        }
        if(examen){
          multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${idAlumno}/sat/examen`] = examen
        }
      }
    })

    db.update(multiPath)
    .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  }

  const changeChecked = (value, idAlumno) => {
    var alumnosHabilitados = {
      ...state.alumnosHabilitados,
      [idAlumno]: value
    }
    if(!value) delete alumnosHabilitados[idAlumno]
    setState({...state, alumnosHabilitados})
  }

  const changeCheckedAll = (value) => {
    var alumnosHabilitados = {...state.alumnosHabilitados}
    Object.entries(props.alumnos).forEach(([idAlumno, alumno])=>{
      if(value){
        alumnosHabilitados = {...alumnosHabilitados, [idAlumno]: true}
      }else{
        delete alumnosHabilitados[idAlumno]
      }
    })
    setAllChecked(value)
    setState({...state, alumnosHabilitados})
  }

  const getAlumnosTipoInges = () => {
    var examen1 = []
    var examen2 = []
    var examen3 = []
    Object.entries(state.alumnos).map(([idAlumno])=>{
      const alumno = props.alumnosStore[idAlumno];
      if(alumno.inscripcionAlumno?.sat?.examen?.ingles?.modelo){
        if(alumno.inscripcionAlumno?.sat?.examen?.ingles?.modelo===1){
          examen1.push({nombre: alumno.informacionAlumno.nombreCompleto, tipo:1})
        }else if(alumno.inscripcionAlumno?.sat?.examen?.ingles?.modelo===2){
          examen2.push({nombre: alumno.informacionAlumno.nombreCompleto, tipo:2})
        }else if(alumno.inscripcionAlumno?.sat?.examen?.ingles?.modelo===3){
          examen3.push({nombre: alumno.informacionAlumno.nombreCompleto, tipo:3})
        }
      }
    })

  }


  return(
    <div className='sub-container-informacion-alumno'>

      {edited && !props.block && <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> }

      <p className='title-informacion-alumno'>1. Información del colegio</p>

      <table id='table-listado-alumnos'>
        <thead>
          <tr>
            <th className="checkbox-col-alum">
              <div className="center-checkbox">
                <Checkbox checked={allChecked} changeValue={(value) => changeCheckedAll(value)}/>
              </div>
            </th>
            <th className="nombre-col-alum">Nombre</th>
            <th className="dni-col-alum">DNI</th>
            <th className="ingles-model-col-alum">Inglés</th>
            <th className="sat-model-col-alum">SAT</th>
          </tr>
        </thead>
        <tbody>
          { Object.entries(state.alumnos).map(([idAlumno, value])=>{
              const alumno = props.alumnosStore[idAlumno];
              if(alumno.inscripcionAlumno.datosColegio.colegio.id!==props.id){
                return null
              }
              return(
                <tr key={idAlumno} data-id={idAlumno} className={`${props.alumnoSelected===idAlumno?'active-row-table':''}`}>
                  <td>
                    <div className="center-checkbox">
                      <Checkbox checked={state.alumnosHabilitados[idAlumno]} changeValue={(value) => changeChecked(value, idAlumno)}/>
                    </div>
                  </td>
                  <td>
                    <div className='edit-container'>
                      <span className='width_100 camelcase'> {alumno.informacionAlumno.nombreCompleto.toLowerCase()}</span>
                    </div>
                  </td>
                  <td>
                    <div className='edit-container'>
                      <span className='width_100'> {alumno.informacionAlumno.dni} </span>
                    </div>
                  </td>
                  <td className="ingles-model-col-alum">
                      <span className='width_100'> {alumno.inscripcionAlumno?.sat?.examen?.ingles?.modelo ?? '-'} </span>
                  </td>
                  <td className="sat-model-col-alum">
                    <div className='align-center'> <Switch class_div={'switch-table'} value={alumno.inscripcionAlumno.isSatDone()}/> </div>
                  </td>
                </tr>
              )
            }) 
          }
        </tbody>
      </table>
      {/* <button onClick={getAlumnosTipoInges}>Examenes</button> */}
    </div>
  )
},
(prevProps, nextProps) => nextProps.alumnosHabilitados === prevProps.alumnosHabilitados
)
const mapStateToProps = ({ alumnos, global }) => {
  return {
    alumnosStore: alumnos.alumnos,
    alumnoSelected: alumnos.alumnoSelected,
    inscripcion: global.inscripcion
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(InformacionAlumnos);
