import Alumno from './Alumno'
import InscripcionAcademica from './InscripcionAcademica'
import InscripcionDeportiva from './InscripcionDeportiva';
import { opcionesPreferenciaPaises, opcionesCuestionario, opcionesPreferenciaPaisesIds} from '../utils/constans'

var cuestionarioInicializacion = {
  1:opcionesCuestionario.preguntas[1].respuestasIniciales,
  2:opcionesCuestionario.preguntas[2].respuestasIniciales,
  3:opcionesCuestionario.preguntas[3].respuestasIniciales,
  4:opcionesCuestionario.preguntas[4].respuestasIniciales,
  5:opcionesCuestionario.preguntas[5].respuestasIniciales,
  6:opcionesCuestionario.preguntas[6].respuestasIniciales,
  7:opcionesCuestionario.preguntas[7].respuestasIniciales,
  8:opcionesCuestionario.preguntas[8].respuestasIniciales,
  9:opcionesCuestionario.preguntas[9].respuestasIniciales,
  10:opcionesCuestionario.preguntas[10].respuestasIniciales,
}

class InscripcionAlumno {

  cursoActual = {
    curso: ""
  };
  cursoAnterior = {
    mediaGlobal : "",
    mediaIngles : "",
    mediaMatematicas : ""
  };
  inscripcion = {
    tipo : "",
    beca : {
      academica: InscripcionAcademica,
      deportiva: InscripcionDeportiva
      
    }
  };
  estudiarFueraSpain = "";
  ingles = {
    nivel: "",
    titulacion: ""
  };
  tipoCarrera = "";
  bachilleratoDual = false;
  sat = {
    notas : null,
    examen: null
  };

  datosColegio = {
    colegio: {
      id: null,
      nombre: "",
      codigo: "",
      tipoColegio: "",
      emailTutor: "",
    },
    ciudad: "",
    sedeSat: "",
  }

  preferenciaPaises = opcionesPreferenciaPaises
  endesa = false

  cuestionario = {
    1:opcionesCuestionario.preguntas[1].respuestasIniciales,
    2:opcionesCuestionario.preguntas[2].respuestasIniciales,
    3:opcionesCuestionario.preguntas[3].respuestasIniciales,
    4:opcionesCuestionario.preguntas[4].respuestasIniciales,
    5:opcionesCuestionario.preguntas[5].respuestasIniciales,
    6:opcionesCuestionario.preguntas[6].respuestasIniciales,
    7:opcionesCuestionario.preguntas[7].respuestasIniciales,
    8:opcionesCuestionario.preguntas[8].respuestasIniciales,
    9:opcionesCuestionario.preguntas[9].respuestasIniciales,
    10:opcionesCuestionario.preguntas[10].respuestasIniciales,
  }

  constructor(obj){
    if(obj){
      this.cursoActual = obj.cursoActual ?? this.cursoActual ;
      this.cursoAnterior = obj.cursoAnterior ?? this.cursoAnterior;
      this.inscripcion = {
        tipo: obj.inscripcion?.tipo,
        beca: {
          academica: obj.inscripcion?.beca.academica ? new InscripcionAcademica(obj.inscripcion.beca.academica) : new InscripcionAcademica(),
          deportiva: obj.inscripcion?.beca.deportiva ? new InscripcionDeportiva(obj.inscripcion.beca.deportiva) : new InscripcionDeportiva()
        }
      };
      
      this.estudiarFueraSpain = obj.estudiarFueraSpain ?? this.estudiarFueraSpain
      this.ingles = obj.ingles ?? this.ingles
      this.tipoCarrera = obj.tipoCarrera ?? obj.tipoCarrera;
      this.bachilleratoDual = obj.bachilleratoDual ?? this.bachilleratoDual
      this.sat = {
        notas: obj?.sat?.notas ?? this.sat.notas, 
        examen: obj?.sat?.examen ?? this.sat.examen  
      }
      this.datosColegio = obj.datosColegio ?? this.datosColegio
      this.endesa = obj.endesa ?? this.endesa
      this.preferenciaPaises = obj.preferenciaPaises ?? this.preferenciaPaises
      this.cuestionario = obj.cuestionario ?? this.cuestionario
    }
  }

  isValidCuestionario = (panel = 2) => {
    var isValid = true;
    //revisar que todas las preguntas de la pregunta 2 estan contestadas
    if(panel===5){
      isValid = !this.cuestionario[5].some(r=>{return r.nota==="0" || (r.nota!=='nunca' && r.gusto==="0")})
    }else if(this.cuestionario[panel].includes("0")){
      isValid=false
    }
    return isValid
  }

  isSatDone = () => {
    let sat = false
    if(!this.sat || !this.sat.examen) return false

    sat = Object.entries(this.sat.examen.ingles.reading.passages).some(([idPassage, passage])=>{
      return Object.entries(passage.preguntas).some(([idPregunta, pregunta]) => pregunta.respuestaSelected.idRespuesta !== -1 )
    })

    if(!sat){
      sat = Object.entries(this.sat.examen.ingles.writing.passages).some(([idPassage, passage])=>{
        return Object.entries(passage.preguntas).some(([idPregunta, pregunta]) => pregunta.respuestaSelected.idRespuesta !== -1 )
      })
    }

    if(!sat){
      sat = Object.entries(this.sat.examen.matematicas).some(([idModelo, modelo])=>{
        return Object.entries(modelo.preguntas).some(([idPregunta, pregunta]) => pregunta.respuestaSelected )
      })
    }

    return sat
  }
  
  getValoresCuestionario = () => {
    const { puntuaciones, preguntas } = opcionesCuestionario
    let valores = {
      habilidadesGenerales:{
        "HG-L":0,
        "HG-LM":0,
        "HG-VE":0,
        "HG-Ie":0,
        "HG-Ia":0,
        "HG-N":0,
        "HG-M":0,
        "HG-K":0,
      },
      interesesGenerales:{
        "IG-L":0,
        "IG-LM":0,
        "IG-VE":0,
        "IG-Ie":0,
        "IG-Ia":0,
        "IG-N":0,
        "IG-M":0,
        "IG-K":0,
      },
      habilidadAcademica:{
        "HA-CS-J":0,
        "HA-CS-E":0,
        "HA-CS-AP":0,
        "HA-CS-C":0,
        "HA-A-EM":0,
        "HA-A-P":0,
        "HA-A-D":0,
        "HA-H-FI":0,
        "HA-H-H":0,
        "HA-C-S":0,
        "HA-C-A":0,
        "HA-C-E":0,
        "HA-IA":0,
        "HA-D":0,
      },
      interesAcademico:{
        "IA-CS-J":0,
        "IA-CS-E":0,
        "IA-CS-AP":0,
        "IA-CS-C":0,
        "IA-A-EM":0,
        "IA-A-P":0,
        "IA-A-D":0,
        "IA-H-FI":0,
        "IA-H-H":0,
        "IA-C-S":0,
        "IA-C-A":0,
        "IA-C-E":0,
        "IA-IA":0,
        "IA-D":0,
      },
      valoraEnelTrabajo:{
        "VT-TL":0,
        "VT-I":0,
        "VT-TS":0,
        "VT-P":0,
        "VT-A":0,
        "VT-C":0,
        "VT-RS":0,
        "VT-Are":0,
        "VT-SO":0,
        "VT-BS":0,
        "VT-Aru":0,
      },
      perfilesTrabajoPersonal:{
        "Per-SA-Social":0,
        "Per-SA-Autonomo":0,
        "Per-PRp-Prestigioso":0,
        "Per-PRp-RelacionPersonal":0,
        "Per-CR-Creativo":0,
        "Per-CR-Rutinario":0,
        "PER-EE-Emprendedor":0,
        "PER-EE-Estabilidad":0
      }

    }
    var id = 0
    var respuestas = {}
    //recorremos todas las respuestas del cuestionario una vez respondido a todo
    Object.entries(this.cuestionario).forEach(([i,array])=>{
      
      array.forEach((valor,posicion)=>{
        var tipo = preguntas[i].tipo
        if(tipo==="reordenar" && preguntas[i].respuestas.length===6){
          //este metodo es para la ultima pregunta de ordenar que tiene 6 opciones
          respuestas[(+valor)] = {}
          respuestas[(+valor)].idPregunta=(+valor);
          respuestas[(+valor)].valor=6-posicion
          id = (+id)+1
        }else if(tipo==="reordenar"){
          respuestas[(+valor)] = {}
          respuestas[(+valor)].idPregunta=(+valor);
          respuestas[(+valor)].valor=8-posicion
          id = (+id)+1
        }else if(tipo==="multiple"){
          id = (+id)+1
          var value = 0
          if(valor.nota==="nunca"){
            value = 0
          }else if((+valor.nota)<6){
            value = 0
          }else if((+valor.nota)===6){
            value = 1
          }else if((+valor.nota)===7){
            value = 2
          }else if((+valor.nota)===8){
            value = 3
          }else if((+valor.nota)>8){
            value = 4
          }
          respuestas[id] = {}
          respuestas[id].idPregunta=id;
          respuestas[id].valor=value
          respuestas[id].gusto = (+valor.gusto)
        }else{
          id = (+id)+1
          respuestas[id] = {}
          respuestas[id].idPregunta=id;
          respuestas[id].valor=valor
        }
        
      })
    })
    Object.entries(valores).forEach(([i,categoria])=>{
      Object.entries(categoria).forEach(([idValor,value])=>{
        //buscamos que id's hacen falta para crear este valor
        var idsNecesarios = puntuaciones[idValor].ids
        var resultado = 0
        idsNecesarios.forEach((id)=>{
          
          if(id.includes('-b')){
            id = (+id.substring(0, id.indexOf('-b')))
            resultado = (+respuestas[id].gusto)+resultado
          }if(puntuaciones[idValor].idsInversos){
            if(puntuaciones[idValor].idsInversos.includes(id)){
              id = (+id)
              resultado = (4-(+respuestas[id].valor)+1) +resultado
            }else{
              id = (+id)
              resultado = (+respuestas[id].valor)+resultado
            }
          }
          else{
            id = (+id)
            resultado = (+respuestas[id].valor)+resultado
          }
        })
      })
      
    })
    const sumarResultados  = (valores) => {
      let suma = 0;
      valores.forEach((valor) => {
        if(valor.toString().endsWith('b')){
          suma = suma + (+respuestas[valor.replace('b','')].gusto)
        }else{
          suma = suma + (+respuestas[valor].valor)
        }
      });
      return suma
    }
    let resultado = {
      'HG-L': sumarResultados([9,10,25,26,41]),
      'HG-LM':sumarResultados([11,12,27,28,42]),
      'HG-VE':sumarResultados([13,14,29,30,43]),
      'HG-Ie':sumarResultados([15,16,31,32,44]),
      'HG-Ia':sumarResultados([17,18,33,34,45]),
      'HG-N':sumarResultados([19,20,35,36,46]),
      'HG-M':sumarResultados([21,22,37,38,47]),
      'HG-K':sumarResultados([23,24,39,40,48]),
      'IG-L':sumarResultados([1,9,10,63,64]),
      'IG-LM':sumarResultados([2,11,12,65,66]),

      'IG-VE':sumarResultados([3,13,14,67,68]),
      'IG-Ie':sumarResultados([4,15,16,69,70]),
      'IG-Ia':sumarResultados([5,17,18,71,72]),
      'IG-N':sumarResultados([6,19,20,73,74]),
      'IG-M':sumarResultados([7,21,22,75,76]),
      'IG-K':sumarResultados([8,23,24,77,78]),

      'HA-CS-J':sumarResultados([24,26,31,32,41,44,49,"49b",51,"51b"]),
      'HA-CS-E':sumarResultados([27,28,44,52,"52b",55,"55b"]),
      'HA-CS-AP':sumarResultados([36,37,38,39,49,"49b",50,"50b",56,"56b"]),
      'HA-CS-C':sumarResultados([31,32,45,52,"52b"]),
      'HA-A-EM':sumarResultados([37,38,40,47,61,"61b",62,"62b"]),
      'HA-A-P':sumarResultados([29,39,43,58,"58b",62]),
      'HA-A-D':sumarResultados([29,39,43,58,"58b",59,"59b",62,"62b"]),
      'HA-H-FI':sumarResultados([25,26,41,49,"49b",50,"50b",51,"51b",54,"54b"]),
      'HA-H-H':sumarResultados([25,26,41,49,"49b",51,"51b",53,"53b",54,"54b"]),
      'HA-C-S':sumarResultados([31,32,46,56,"56b",57,"57b"]),
      'HA-C-A':sumarResultados([35,36,46]),
      'HA-C-E':sumarResultados([27,28,46,55,"55b",56,"56b",57,"57b"]),
      'HA-IA':sumarResultados([27,28,29,30,39,42,43,55,"55b",56,"56b",58,"58b",59,"59b"]),
      'HA-D':sumarResultados([39,40,48,60,"60b"]),

      'IA-CS-J':sumarResultados([1,9,10,49,'49b',51,'51b',63,64,69,70,71]),
      'IA-CS-E':sumarResultados([2,18,52,'52b',55,'55b',65,66]),
      'IA-CS-AP':sumarResultados([4,5,15,16,17,51,'51b',63,65,69,70,72]),
      'IA-CS-C':sumarResultados([16,51,'51b',63,64,66,68]),
      'IA-A-EM':sumarResultados([7,10,21,22,61,'61b',62,'62b',68,75,76,77]),
      'IA-A-P':sumarResultados([3,13,14,58,'58b',62,'62b',67,68,77]),
      'IA-A-D':sumarResultados([3,13,14,58,'58b',59,'59b',62,'62b',67,68,77]),
      'IA-H-FI':sumarResultados([1,9,10,49,'49b',50,'50b',51,'51b',54,'54b',63,64,]),
      'IA-H-H':sumarResultados([1,9,10,49,'49b',51,'51b',53,'53b',54,'54b',63,71]),
      'IA-C-S':sumarResultados([4,11,12,13,15,16,18,19,20,56,'56b',57,'57b',66,69,70,72,74]),
      'IA-C-A':sumarResultados([6,11,12,13,19,20,56,'56b',57,'57b',66,73,74]),
      'IA-C-E':sumarResultados([2,3,11,12,13,19,20,55,'55b',56,'56b',57,'57b',65,74]),
      'IA-IA':sumarResultados([2,3,11,12,13,14,55,'55b',56,'56b',58,'58b',59,'59b',65,66,67,74]),
      'IA-D':sumarResultados([8,13,28,29,60,'60b']),

      'VT-TL':sumarResultados([79,80,101]),
      'VT-I':sumarResultados([81,82,102]),
      'VT-TS':sumarResultados([83,84,103]),
      'VT-P':sumarResultados([85,86,104]),
      'VT-A':sumarResultados([87,88,105]),
      'VT-C':sumarResultados([89,90,106]),
      'VT-RS':sumarResultados([91,92,107]),
      'VT-Are':sumarResultados([93,94,108]),
      'VT-SO':sumarResultados([95,96,109]),
      'VT-BS':sumarResultados([97,98,110]),
      'VT-Aru':sumarResultados([99,100,111]),

      'Per-SA-S':sumarResultados([91,92,107,15,16,31,32,44]),
      'Per-SA-A':sumarResultados([81,82,102]),

      'Per-PRp-P':sumarResultados([93,94,108,97,98,110]),
      'Per-PRp-Rp':sumarResultados([81,82,102, 87,88,105 , 93,94,108]),
      
      'Per-CR-C':sumarResultados([89,90,106]),
      'Per-CR-R':sumarResultados([95,96,109 , 99,100,111]),

      'PER-EE-Em':sumarResultados([79,80,101 , 81,82,102 , 93,94,108]),
      'PER-EE-Es':sumarResultados([95,96,109 , 97,98,110]),

      'Pro-M':respuestas[112].valor,
      'Pro-P':respuestas[113].valor,
      'O-1':respuestas[114].valor==="false"?"NO":"SI",
      'O-2':respuestas[115].valor==="false"?"NO":"SI",
      'O-3':respuestas[116].valor==="false"?"NO":"SI",
      'O-4':respuestas[117].valor==="false"?"NO":"SI",
      'O-5':respuestas[118].valor==="false"?"NO":"SI",
      'O-6':respuestas[119].valor==="false"?"NO":"SI",
      'O-7':respuestas[120].valor==="false"?"NO":"SI",
      'O-8':respuestas[121].valor==="false"?"NO":"SI",
      'O-9':respuestas[122].valor==="false"?"NO":"SI",
      'O-10':respuestas[123].valor==="false"?"NO":"SI",
      'O-11':respuestas[124].valor==="false"?"NO":"SI",

      'P-1':opcionesPreferenciaPaisesIds[(+respuestas[125].valor)].text, //respuestas[125].valor,
      'P-2':opcionesPreferenciaPaisesIds[(+respuestas[126].valor)].text,
      'P-3':opcionesPreferenciaPaisesIds[(+respuestas[127].valor)].text,
      'P-4':opcionesPreferenciaPaisesIds[(+respuestas[128].valor)].text,
      'P-5':opcionesPreferenciaPaisesIds[(+respuestas[129].valor)].text,
      'P-6':opcionesPreferenciaPaisesIds[(+respuestas[130].valor)].text

    }
    return resultado
  }
}

export default InscripcionAlumno