import React, { memo } from 'react'
import InformacionPersonal from './InformacionPersonal'
import InformacionDireccion from './InformacionDireccion'
import InformacionTutor from './InformacionTutor'
import InformacionAcademica from './InformacionAcademica'
import ScrollContainer from '../../../../../Shared/ScrollContainer'
import { useEffect } from 'react'
const PanelPerfil = ({alumno}) => {
  return(
    <ScrollContainer _class = {`container-table`}>
      <div className='container-informacion-alumno'>
        <InformacionPersonal 
          alumno = {alumno}
          idAlumno = {alumno.id}
          nombre = {alumno.informacionAlumno.nombre}
          apellidos = {alumno.informacionAlumno.apellidos}
          email = {alumno.informacionAlumno.email}
          telefono = {alumno.informacionAlumno.telefono ?? ''}
          dni = {alumno.informacionAlumno.dni}
          password = {alumno.informacionAlumno.password ?? ''}
          fechaNacimiento = {alumno.informacionAlumno.fechaNacimiento}
          sexo = {alumno.informacionAlumno.sexo}
          colaboraciones = {alumno.inscripcionAlumno.inscripcion.beca[alumno.inscripcionAlumno.inscripcion.tipo==="Deportiva"?'deportiva':'academica'].colaboraciones}
          imagen = {alumno.inscripcionAlumno.inscripcion.beca[alumno.inscripcionAlumno.inscripcion.tipo==="Deportiva"?'deportiva':'academica'].imagen}
        />

        <InformacionDireccion 
          idAlumno={alumno.id}
          direccion={alumno.informacionAlumno.direccion}
          piso={alumno.informacionAlumno.piso}
          numero={alumno.informacionAlumno.numero}
          letra={alumno.informacionAlumno.letra}
          codigoPostal={alumno.informacionAlumno.codigoPostal}
          ciudad={alumno.informacionAlumno.ciudad}
          provincia={alumno.informacionAlumno.provincia}
        />

        <InformacionTutor
          idAlumno={alumno.id}
          nombre={alumno.informacionTutor.nombre}
          apellidos={alumno.informacionTutor.apellidos}
          email={alumno.informacionTutor.email}
          telefono={alumno.informacionTutor.telefono.toString()}
        />

        <InformacionAcademica
          idAlumno={alumno.id}
          alumno={alumno}
          preferenciaPaises={alumno.inscripcionAlumno.preferenciaPaises}
          tipoInscripcion={alumno.inscripcionAlumno.inscripcion.tipo}
          cursoActual={alumno.inscripcionAlumno.cursoActual.curso}
          mediaGlobal={alumno.inscripcionAlumno.cursoAnterior.mediaGlobal}
          mediaMates={alumno.inscripcionAlumno.cursoAnterior.mediaMatematicas}
          mediaIngles={alumno.inscripcionAlumno.cursoAnterior.mediaIngles}
          nivelIngles={alumno.inscripcionAlumno.ingles.nivel}
          titulacionIngles={alumno.inscripcionAlumno.ingles.titulacion}
          estudiarFueraSpain={alumno.inscripcionAlumno.estudiarFueraSpain}
          tipoCarrera={alumno.inscripcionAlumno.tipoCarrera}
          bachilleratoDual={alumno.inscripcionAlumno.bachilleratoDual}
        />

      </div>
    </ScrollContainer>
  )
}

export default PanelPerfil