import React, { useLayoutEffect, useState } from 'react'
import { connect } from "react-redux";
import HeaderW2A from '../../W2AViews/HeaderW2A'
import FooterW2A from '../../W2AViews/FooterW2A'
import {Stepper, Step} from '@material-ui/core';
import PanelInformativo from './Panels/PanelInformativo/PanelInformativo'
import FaseComun from './Panels/Fases/FaseComun/FaseComun'
import FaseAcademica from './Panels/Fases/FaseAcademica/FaseAcademica'
import FaseDeportiva from './Panels/Fases/FaseDeportiva/FaseDeportiva';
import InicioCuestionario from './Panels/Cuestionario/InicioCuestionario';
import PreguntaReordenar from './Panels/Cuestionario/PreguntaReordenar'
import PreguntaCheckBox from './Panels/Cuestionario/PreguntaCheckBox'
import Pregunta5 from './Panels/Cuestionario/Pregunta5'
import Pregunta10 from './Panels/Cuestionario/Pregunta10'
import UsuarioRepetido from '../Inscripcion/Panels/PanelInformativo/UsuarioRepetido'
import GuardadoCorrectamente from './Panels/PanelInformativo/GuardadoCorrectamente'
import { bindActionCreators } from 'redux';
import { saveCuestionario, setCurrentAlumno } from '../../../redux/actions/alumnosActions';
import { pathFormularioModifyAlumno, pathFormularioNewAlumno } from '../../../utils/constans'
import Alumno from '../../../models/Alumno';
import { DoneRounded } from '@material-ui/icons';
import LogosContainer from '../../Shared/LogosContainer'
const Inscripcion = ({currentAlumno, actions}) => {

  let [panelSelected, setPanelSelected] = useState(0)
  const [usuarioRepetido, setUsuarioRepetido] = useState(false)
  const [inicioCuestionario, setInicioCuestionario] = useState(false)
  const [modificacionGuardado, setModificacionGuardado] = useState(false)
  const [steps, setSteps] = useState([1,2,3,4,5,6,7,8,9,10,11,12])

  useLayoutEffect(() => {

    if(window.location.pathname===pathFormularioModifyAlumno){
      setPanelSelected(1)
      if(currentAlumno.inscripcionAlumno.isValidCuestionario()){
        setSteps([1,2])
      }
    }else if(window.location.pathname===pathFormularioNewAlumno){
      actions.setCurrentAlumno(new Alumno())
    }
  }, [])

  const changePanelSelected = () => {
    setPanelSelected(++panelSelected)
  }
  const changePanelSelectedBack = () => {
    setPanelSelected(--panelSelected)
  }

  const saveCuestionario = async () => {
    const ok = await actions.saveCuestionario()
    if(ok){
      changePanelSelected()
    }else{

    }
  }

  return(
    <div className='bg-w2a'>
      <HeaderW2A categoria='Programa Unidream 2020' />

      {modificacionGuardado && 
        <GuardadoCorrectamente />
      }

      { usuarioRepetido && !modificacionGuardado &&
        <UsuarioRepetido cancel={()=>setUsuarioRepetido(false)}/>
      }

      { !usuarioRepetido && !modificacionGuardado &&

        <div className="formulario_fase_comun pr">

          {/* FASE COMUN */}
          {panelSelected === 0 && <PanelInformativo setPanelSelected={changePanelSelected}/>}
          {panelSelected === 1 && <FaseComun setPanelSelected={changePanelSelected} setPanelSelectedBack={changePanelSelectedBack} setUsuarioRepetido={()=>setUsuarioRepetido(true)}/>}

          {/* FASE ACADEMICA O DEPORTIVA */}
          {panelSelected === 2 && currentAlumno.inscripcionAlumno.inscripcion.tipo==="Académica" && <FaseAcademica setModificacionGuardado={()=>setModificacionGuardado(true)} setPanelSelected={changePanelSelected} setPanelSelectedBack={changePanelSelectedBack} setUsuarioRepetido={()=>setUsuarioRepetido(true)}/>}
          {panelSelected === 2 && currentAlumno.inscripcionAlumno.inscripcion.tipo==="Deportiva" && <FaseDeportiva setModificacionGuardado={()=>setModificacionGuardado(true)} setPanelSelected={changePanelSelected} setPanelSelectedBack={changePanelSelectedBack} setUsuarioRepetido={()=>setUsuarioRepetido(true)}/>}

          {/* CUESTIONARIO */}
          {panelSelected === 3 && !inicioCuestionario && <InicioCuestionario initForm={()=>setInicioCuestionario(true)}/>}
          {panelSelected === 3 && inicioCuestionario && <PreguntaReordenar idPregunta={1} prioritario={"Me apetece más"} noPrioritario={"No me apetece nada"} setPanelSelected={changePanelSelected} />}
          {panelSelected === 4 && <PreguntaCheckBox idPregunta={2} setPanelSelected={changePanelSelected} setPanelSelectedBack={changePanelSelectedBack} />}
          {panelSelected === 5 && <PreguntaCheckBox idPregunta={3} setPanelSelected={changePanelSelected} setPanelSelectedBack={changePanelSelectedBack} />}
          {panelSelected === 6 && <PreguntaReordenar idPregunta={4} prioritario={"Se me da genial"} noPrioritario={"Se me da fatal"} setPanelSelected={changePanelSelected} setPanelSelectedBack={changePanelSelectedBack}/>}
          {panelSelected === 7 && <Pregunta5 idPregunta={5} setPanelSelected={changePanelSelected} setPanelSelectedBack={changePanelSelectedBack}/>}
          {panelSelected === 8 && <PreguntaCheckBox idPregunta={6} setPanelSelected={changePanelSelected} setPanelSelectedBack={changePanelSelectedBack} />}
          {panelSelected === 9 && <PreguntaCheckBox idPregunta={7} setPanelSelected={changePanelSelected} setPanelSelectedBack={changePanelSelectedBack} />}
          {panelSelected === 10 && <PreguntaReordenar idPregunta={8} prioritario={"Totalmente prioritario para mi"} noPrioritario={"Nada prioritario para mi"} setPanelSelected={changePanelSelected} setPanelSelectedBack={changePanelSelectedBack}/>}
          {panelSelected === 11 && <Pregunta10 idPregunta={9} setPanelSelected={changePanelSelected} setPanelSelectedBack={changePanelSelectedBack}/>}
          {panelSelected === 12 && <PreguntaReordenar idPregunta={10} prioritario={"Me gustaría más"} noPrioritario={"Me gustaría menos"} endCuestionario={true} setPanelSelected={changePanelSelected} setPanelSelectedBack={changePanelSelectedBack} saveCuestionario={saveCuestionario}/>}

          {panelSelected === 13 && <GuardadoCorrectamente />}

          { panelSelected>0 && panelSelected < 13 && !(panelSelected===3 && !inicioCuestionario) && !modificacionGuardado &&
            <div className="stepper-inscripcion">
              <Stepper nonLinear activeStep={panelSelected}>
                {steps.map( index => {
                  return (
                    <Step key={index}>
                      <div className={`container-step ${+panelSelected===index?'active-bubble':''} ${index < panelSelected?'pregunta-contestada':''}`}>
                        {index < panelSelected?
                          <div className="icon-done"><DoneRounded /></div> :
                          <span>{index}</span>
                        }
                      </div>
                    </Step>)
                })}
              </Stepper>
            </div> 
          }
          

          <div id="cargando-formulario-alumnos" className="cargando-formulario" >
            <div className='container-loading-firm-alum'>
              <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
          </div>


          <LogosContainer />

        </div>
        
      }
     <FooterW2A />
    </div>
  )
}

const mapStateToProps = ({ alumnos }) => {
  return {
    currentAlumno: alumnos.currentAlumno,
  };
}
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      saveCuestionario,
      setCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(Inscripcion);
