import React, { useState, useEffect } from 'react'
import { validarDni, onFocusInscripciones, onBlurInscripciones, validarTelefono, validFechaNacimiento } from '../../../../../../utils/functions'
import { opcionesLugarExperiencia, opcionesMesesExperiencia, opcionesObjetivoExperiencia } from '../../../../../../utils/constans'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../../redux/actions/alumnosActions';
import ListaOpciones from '../../../Shared/ListaOpciones'
import Switch from '../../../../../Shared/Switch'

const ExperienciaInternacional = (props) => {
  
  const { errors } = props

  const [showOptionsLugar, setShowOptionsLugar] = useState(false)

  const [showOptionsNumMeses, setShowOptionsNumMeses] = useState(false)
  const [showOptionsObjetivo, setShowOptionsObjetivo] = useState(false)


  const callbackSwitch = (json) => {
    if (json.id === 'experienciaExtranjero') {
      props.actions.modifyCurrentAlumno({value: json.value, path:'inscripcionAlumno.inscripcion.beca.academica.experienciaExtranjero'})
    }
  }

  return(
    <div>
      <h3 className="title_form">Experiencia internacional</h3>
      <div className="display_inline_flex width_100 item-switch-form">
        <span className="span_academica margin_auto_vertical">¿Has vivido una experiencia relevante en el extranjero?</span>
        <div className="display_inline_flex">
          <span className="info_academica margin_auto_vertical">no</span>
          <Switch class_div={'switch_academica'} callbackSwitch={callbackSwitch} json={{ id: 'experienciaExtranjero' }} value={props.experienciaExtranjero} />
          <span className="info_academica margin_auto_vertical">si</span>
        </div>
      </div>

      {/*DONDE*/}
      {props.experienciaExtranjero &&
        <div className={`pr pointer select_form text-large-forms  ${errors.lugarExperiencia?'obligatorio':''}`} onClick={()=>setShowOptionsLugar(true)}>
          <div className="item_selected">{props.lugarExperiencia}</div>
          <label className="label_form pointer">¿DÓNDE?</label>
          {showOptionsLugar && 
            <ListaOpciones 
              editOpciones={(value) => setShowOptionsLugar(value)} 
              opciones={opcionesLugarExperiencia}
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value,path:'inscripcionAlumno.inscripcion.beca.academica.lugarExperiencia'}) } 
              opcionSelected={props.lugarExperiencia}
            />}
        </div>
      }

      {/*meses*/}
      {props.experienciaExtranjero &&
        <div className={`pr pointer select_form text-large-forms  ${errors.mesesExperiencia?'obligatorio':''}`} onClick={()=>setShowOptionsNumMeses(true)}>
          <div className="item_selected">{props.mesesExperiencia}</div>
          <label className="label_form pointer">NÚMERO DE MESES</label>
          {showOptionsNumMeses &&
            <ListaOpciones 
              editOpciones={(value) => setShowOptionsNumMeses(value)} 
              opciones={opcionesMesesExperiencia}
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.inscripcion.beca.academica.mesesExperiencia'}) } 
              opcionSelected={props.mesesExperiencia}
            /> }
        </div>
      }

      {/*objetivo*/}
      {props.experienciaExtranjero &&
        <div className={`pr pointer select_form text-large-forms  ${errors.objetivoExperiencia?'obligatorio':''}`} onClick={()=>setShowOptionsObjetivo(true)}>
          <div className="item_selected">{props.objetivoExperiencia}</div>
          <label className="label_form pointer">¿EN QUÉ CONSISTIÓ?</label>
          {showOptionsObjetivo &&
            <ListaOpciones 
              editOpciones={(value) => setShowOptionsObjetivo(value)} 
              opciones={opcionesObjetivoExperiencia}
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.inscripcion.beca.academica.objetivoExperiencia'}) } 
              opcionSelected={props.objetivoExperiencia}
            />}
        </div>
      }

      {/*DONDE*/}
      {props.experienciaExtranjero &&
        <div className={`pr container-ta ${errors.experienciaDescripcion?'obligatorio':''}`}>
          <label className="label_form pr">Explícanos en qué ha consistido tu experiencia internacional</label>
          <textarea onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ta-forms`} value={props.experienciaDescripcion} onChange={e=>props.actions.modifyCurrentAlumno({value:e.target.value,path:'inscripcionAlumno.inscripcion.beca.academica.experienciaDescripcion'})}/>
        </div>
      }


    </div>
  )
}



const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(ExperienciaInternacional);