import React, { useState, useLayoutEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { setCurrentUser } from '../../../redux/actions/userActions'
import { checkPermissions } from './PrivateUtils';
import { isLogged } from '../../../utils//functions'

import User from '../../../models/User';

import { db, signOut } from '../../../utils/firebase';
import { pathSignin } from '../../../utils/constans';


const PrivateRoute = ({ component: Component, currentUser, access, actions, location,...rest}) => {

  const [loading, setLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState({pathname:pathSignin, state: { redirect: location.pathname }})

  useLayoutEffect(() => {

    if(!currentUser.isAuthenticated){
      isLogged()
      .then(({email})=>{
        return new Promise( (resolve, reject) => {
          db.child('Empleados').orderByChild('email').equalTo(email).limitToLast(1).once("value", snapshot => {
            if(!snapshot.exists()) return reject({code: 3023, msg:'Existe email pero no existe empleado, por lo que quiere decir que se ha logeado siendo un alumno'})
            const user = Object.entries(snapshot.val())[0][1]
            actions.setCurrentUser({...user, isAuthenticated: true})
            setLoading(false);
            resolve()
          })
        })
      })
      .catch(err => {
        if(err.code===3023){
          signOut()
        }
        //setRedirectTo({pathname:pathSignin, state: { redirect }})
        setLoading(false);
      })
    }
    if (currentUser.isAuthenticated) setLoading(false);
  }, [currentUser.isAuthenticated]);

  return (
    <Route
      {...rest}
      render={props =>
        loading ?
          <div>Loading...</div>
        : currentUser.isAuthenticated && checkPermissions(currentUser.roles, access) ? 
            <Component {...props} />
          :
            <Redirect to={redirectTo} />
      }
    />
  );
};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setCurrentUser
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(PrivateRoute);