import React from 'react';
import { connect } from 'react-redux';
import ScrollContainer from '../../../../../Shared/ScrollContainer'
import InformacionExamen from './InformacionExamen'
import InformacionAlumnos from './InformacionAlumnos'
const PanelExamen = ({colegio, block}) => {
  return(
    <ScrollContainer _class={`container-table`}>
      <div className='container-informacion-alumno'>
        <InformacionExamen
          habilitado={colegio.examen.habilitado}
          fechaExamen={colegio.examen.fechaExamen} 
          horaExamen={colegio.examen.horaExamen} 
          id={colegio.id}
        />
        <InformacionAlumnos
          id={colegio.id}
          alumnos={colegio.alumnos}
          alumnosHabilitados={colegio.examen.alumnosHabilitados}
        />
      </div>
    </ScrollContainer>
  )
}

const mapStateToProps = (state) => {return{ 
    colegioSeleccionado: state.global.colegioSeleccionado, 
    alumnos: state.global.alumnos, 
    alumnoSeleccionado: state.global.alumnoSeleccionado,
    alumnosColegios: state.global.alumnosColegios,  
  }}

export default connect(mapStateToProps)(PanelExamen);
