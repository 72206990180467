import React, { memo } from 'react'
import ScrollContainer from '../../../../../../Shared/ScrollContainer'
import Evaluacion from './Evaluacion'
import InformacionDeporte from './InformacionDeporte'
import InformacionAlumno from './InformacionAlumno'
const BecaDeportiva = memo(({alumno}) => {

  return(
    <ScrollContainer _class = {`container-table`}>
      <div className='container-informacion-alumno'>

        <Evaluacion
          idAlumno={alumno.id}
          status={alumno.inscripcionAlumno.inscripcion.beca.deportiva.evaluacionW2A.status}
          comentario={alumno.inscripcionAlumno.inscripcion.beca.deportiva.evaluacionW2A.comentario}
          nivel={alumno.inscripcionAlumno.inscripcion.beca.deportiva.evaluacionW2A.nivel.toString() }
          categoria={ alumno.inscripcionAlumno.inscripcion.beca.deportiva.evaluacionW2A.categoria }
        />

        <InformacionDeporte
          idAlumno={alumno.id}
          deporte={alumno.inscripcionAlumno.inscripcion.beca.deportiva.deporte}
          categoria={alumno.inscripcionAlumno.inscripcion.beca.deportiva.categoria}
          club={ alumno.inscripcionAlumno.inscripcion.beca.deportiva.club }
          federado={ alumno.inscripcionAlumno.inscripcion.beca.deportiva.federado }
          tipoDeporte={ alumno.inscripcionAlumno.inscripcion.beca.deportiva.tipoDeporte }
          division={ alumno.inscripcionAlumno.inscripcion.beca.deportiva.division }
          ranking={ alumno.inscripcionAlumno.inscripcion.beca.deportiva.ranking }
        />

        <InformacionAlumno
          idAlumno={alumno.id}
          sexo={alumno.informacionAlumno.sexo}
          categoria={alumno.inscripcionAlumno.inscripcion.beca.deportiva.categoria}
          informacionAdicional={alumno.inscripcionAlumno.inscripcion.beca.deportiva.informacionAdicional }
          csd={ alumno.inscripcionAlumno.inscripcion.beca.deportiva.csd }
        />

      </div>
    </ScrollContainer>
  )
})

export default BecaDeportiva