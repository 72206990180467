import React, {useEffect} from 'react'

const PanelConfirmacionAutorizaciones = ({rejected, acepted}) => {

  useEffect(()=>{
    document.querySelector('html').style.overflow = 'hidden'
    return(()=>{
      document.querySelector('html').style.overflow = 'auto'
    })
  }, [])

  return(
    <div className="panel-confirmacion-autorizacion" >
      <div className="subpanel-autorizacion">
        <div className="icon-autorizaciones"><i className="material-icons">info</i></div>
        <div className="text-autorizacions">Recuerda que participas en el programa con la finalidad de recibir tu informe con recomendaciones universitarias e información por parte de las universidades y entidades colaboradoras del programa, por lo que es importante contar con tu consentimiento.</div>
        <div className="container-btns-aceptaciones">
          <div onClick={rejected}>No gracias</div>
          <div onClick={acepted}>Acepto</div>
        </div>
      </div>
    </div>
  )
}

export default PanelConfirmacionAutorizaciones;