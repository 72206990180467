import React, { memo } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { setColegioSelected, setTabSelected } from '../../../../../../redux/actions/colegiosActions'
import { setNotification } from '../../../../../../redux/actions/globalActions'

import { provincias } from '../../../../../../utils/constans'

const ItemLista = memo(({colegio, colegioSelected, actions}) => {

  const selectColegio = (tab) => {
    if(Object.keys(colegio.alumnos).length===0 && tab==="alumnos"){
      actions.setNotification({ visibility: true, text: "No se han inscrito alumnos en este colegio", status: "close", moment: Date.now() })
      return false
    }
    actions.setColegioSelected(colegio.id)
    actions.setTabSelected(tab)
  }
  
  return(
    <tr data-id={colegio.id} className={`${colegioSelected===colegio.id?'active-row-table':''}`}>

      <td className='colegios-listado-codigo' >
        <div className='edit-container'> <span className='width_100'> {colegio.codigo ?? ""} </span> </div>
      </td>

      <td className='colegios-listado-nombre'>
        <div className='edit-container'><span>{colegio.nombre.toLowerCase()}</span></div>
      </td>

      <td className='colegios-listado-provincia'>
        <div className='edit-container'><span> {provincias[colegio.provincia]?.texto ?? ""} </span></div>
      </td>

      <td className='colegios-listado-alumnos' onClick={()=>{selectColegio('alumnos')}}>
        <div className='align-center'> <span>{Object.keys(colegio.alumnos).length}</span></div>
      </td>

      <td className='colegios-listado-more' onClick={()=>selectColegio('info')} >
        <i className="material-icons align-center">chevron_right</i>
      </td>

    </tr>

  )
})

const mapStateToProps = ({ colegios }) => {
  return {
    colegioSelected: colegios.colegioSelected,
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setColegioSelected,
      setTabSelected,
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(ItemLista);