import React, { useState, useEffect, memo } from 'react'
import Input from '../../../../../../Shared/Input'
import InputMenu from '../../../../../../Shared/InputMenu'
import UpdateStateInputs from '../../../../../../Shared/UpdateStateInputs'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../../utils/firebase'
import _ from 'underscore'
import { opcionesLugarExperiencia, opcionesObjetivoExperiencia, opcionesMesesExperiencia } from '../../../../../../../utils/constans'
import { getNumber } from '../../../../../../../utils/functions'
import Textarea from '../../../../../../Shared/Textarea';
import Switch from '../../../../../../Shared/Switch';

const InformacionVoluntariado = memo((props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  const saveData = () => {

    if(state.voluntariado && !state.voluntariadoDescripcion.trim()){
      props.actions.setNotification({ visibility: true, text: "Exiten errores en los campos", status: "close", moment: Date.now() })
      return false
    }
    var multiPath = {};
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/voluntariado`] = state.voluntariado;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/voluntariadoDescripcion`] = state.voluntariadoDescripcion.trim();
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/practicas`] = state.practicas;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/academica/informacionAdicional`] = state.informacionAdicional.trim();

    db.update(multiPath)
    .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  }
  

  return(
    <div className='sub-container-informacion-alumno'>

      {edited && <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> }

      <p className="title-informacion-alumno">4. Voluntariado</p>

      <div className="col-2-input">
        <div className={`display_flex container-simple-input`}>
          <div className="title-input align-center mg-right-10 pdd-v-0"> Voluntariado: </div>
          <span className="options-switch">NO</span>
          <Switch class_div="switch-table" json={{ id: "voluntariado" }} value={state.voluntariado} callbackSwitch={json => { setState({ ...state, voluntariado: json.value }); }} />
          <span className="options-switch">SI</span>
        </div>

        <div className={`display_flex container-simple-input`}>
          <div className="title-input align-center mg-right-10 pdd-v-0"> Prácticas: </div>
          <span className="options-switch">NO</span>
          <Switch class_div="switch-table" json={{ id: "practicas" }} value={state.practicas} callbackSwitch={json => { setState({ ...state, practicas: json.value }); }} />
          <span className="options-switch">SI</span>
        </div>
      </div>

      {state.voluntariado && (
        <Textarea _class_input={ !state.voluntariadoDescripcion.trim() ? "error-form-input error-bg" : '' } _class="pdd-top-10" title="Descripción del voluntariado" text={state.voluntariadoDescripcion} changeValue={voluntariadoDescripcion => { setState({ ...state, voluntariadoDescripcion }); }} />
      )}

      <Textarea _class="pdd-top-10" title="Información adicional sobre curriculum, notas o experiencia" text={state.informacionAdicional} changeValue={informacionAdicional => { setState({ ...state, informacionAdicional }); }} />

    </div>
  )
})

const mapStateToProps = ({ global }) => {
  return {
    inscripcion: global.inscripcion
  };
}
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(InformacionVoluntariado);