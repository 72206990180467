import React from 'react'
import InformacionColegio from './InformacionColegio'
import InformacionResponsable from './InformacionResponsable'
import ScrollContainer from '../../../../../Shared/ScrollContainer'
const PanelInformacion = ({colegio, block}) => {
  return(
    <ScrollContainer _class={`container-table`}>
      <div className='container-informacion-alumno'>
        <InformacionColegio 
          id={colegio.id}
          nombre={colegio.nombre.toLowerCase()}
          direccion={colegio.direccion}
          fundacion={colegio.fundacion}
          provincia={colegio.provincia}
          participacionAnterior={colegio.participacionAnterior}
          block={block}
        />
        <InformacionResponsable 
          id={colegio.id}
          title='2. Responsable 1'
          nombre={colegio.responsables[0].nombre.toLowerCase() ?? ""}
          apellidos={colegio.responsables[0].apellidos.toLowerCase() ?? ""}
          email={colegio.responsables[0].email ?? ""}
          telefonoMovil={colegio.responsables[0].telefonoMovil ?? ""}
          telefonoFijo={colegio.responsables[0].telefonoFijo ?? ""}
          cargo={colegio.responsables[0].cargo ?? ""}
          horarioPreferente={colegio.responsables[0].horarioPreferente ?? ""}
          responsable={0}
          required={true}
          block={block}
        />
        <InformacionResponsable 
          id={colegio.id}
          title='3. Responsable 2'
          nombre={colegio.responsables[1].nombre.toLowerCase() ?? ""}
          apellidos={colegio.responsables[1].apellidos.toLowerCase() ?? ""}
          email={colegio.responsables[1].email ?? ""}
          telefonoMovil={colegio.responsables[1].telefonoMovil ?? ""}
          telefonoFijo={colegio.responsables[1].telefonoFijo ?? ""}
          cargo={colegio.responsables[1].cargo ?? ""}
          horarioPreferente={colegio.responsables[1].horarioPreferente ?? ""}
          responsable={1}
          required={false}
          block={block}
        />
      </div>
    </ScrollContainer>
  )
}

export default PanelInformacion