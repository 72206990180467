import React, { useState, useEffect, memo } from 'react'
import Input from '../../../../../Shared/Input'
import InputMenu from '../../../../../Shared/InputMenu'
import Switch from '../../../../../Shared/Switch'
import UpdateStateInputs from '../../../../../Shared/UpdateStateInputs'
import { isEmail, isTelefono, isValidFechaNacimiento, cleanDNI, isCorrectDni, validFecha } from '../../../../../../utils/functions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../utils/firebase'
import _ from 'underscore'
import { genderOptions } from '../../../../../../utils/constans'

const InformacionTutor = memo((props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  const saveData = () => {

    if(!state.nombre.trim() || !state.apellidos.trim() || !isEmail(state.email) || !isTelefono(state.telefono)){
      props.actions.setNotification({ visibility: true, text: "Exiten errores en los campos", status: "close", moment: Date.now() })
      return false;
    }

    var multiPath = {};
    multiPath[`Alumnos/${props.idAlumno}/informacionTutor/nombre`] = state.nombre;
    multiPath[`Alumnos/${props.idAlumno}/informacionTutor/apellidos`] = state.apellidos;
    multiPath[`Alumnos/${props.idAlumno}/informacionTutor/email`] = state.email;
    multiPath[`Alumnos/${props.idAlumno}/informacionTutor/telefono`] = state.telefono;

    db.update(multiPath)
    .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  
  }
  

  return(
    <div className='sub-container-informacion-alumno'>

      {edited? <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> :null }

      <p className='title-informacion-alumno'>3. Información del tutor</p>

      <div className='col-2-input'>
        <Input title='Nombre' _class_container={!state.nombre.trim()?'error-form-input':null} text={state.nombre} changeValue={nombre=>setState({...state, nombre})}/>
        <Input title='Apellidos'_class_container={!state.apellidos.trim()?'error-form-input':null} text={state.apellidos} changeValue={apellidos=>setState({...state, apellidos})}/>
      </div>

      <div className='col-2-input'>
        <Input type='email' _class_container={!state.email.trim() || !isEmail(state.email)?'error-form-input':null} title='Dirección de correo'  text={state.email} changeValue={email=>setState({...state, email})}/>
        <Input type='phone' title='Teléfono móvil' _class_container={!state.telefono.trim() || !isTelefono(state.telefono)?'error-form-input':null} _class_input='dni-input' text={state.telefono} changeValue={telefono=>setState({...state, telefono})}/>
      </div>
    </div>
  )
})

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(InformacionTutor);