import React from 'react'
import { connect } from "react-redux";
import Colegios from './Colegios/Colegios'
import Alumnos from './Alumnos/Alumnos'
const Panels = ({ tabHome }) => {
  
  return(
    <div className="container-paneles">
      {tabHome==="colegios" && <Colegios />}
      {tabHome==="alumnos" && <Alumnos />}
    </div>
  )
}

function mapStateToProps({ global }) {
  return {
    tabHome: global.tabHome,
  };
}

export default connect(mapStateToProps)(Panels);