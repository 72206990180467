
class InscripcionDeportiva {

  deporte = "";
  club = "";
  tipoDeporte = "";
  categoria ="";
  csd = false;

  federado = "";
  division = "";
  ranking = "";

  informacionAdicional = "";
  colaboraciones  = false;
  imagen  = false;
  terminos = false;

  evaluacionW2A = {
    habilidades: "",
    status: "",
    comentario: "",
    nivel: 0,
    categoria: ""
  } 

  
  constructor(obj){
      if(obj){
        this.deporte = obj.deporte ?? this.deporte
        this.club = obj.club ?? this.club
        this.tipoDeporte = obj.tipoDeporte ?? this.tipoDeporte
        this.categoria = obj.categoria ?? this.categoria
        this.csd = obj.csd ?? this.csd

        this.federado = obj.federado ?? this.federado
        this.division = obj.division ?? this.division
        this.ranking = obj.ranking ?? this.ranking

        this.informacionAdicional= obj.informacionAdicional ?? this.informacionAdicional
        this.colaboraciones= obj.colaboraciones ?? this.colaboraciones
        this.imagen = obj.imagen ?? this.imagen
        this.terminos = obj.terminos ?? this.terminos
        this.evaluacionW2A = { 
          habilidades: obj.evaluacionW2A?.habilidades ?? this.evaluacionW2A, 
          status: obj.evaluacionW2A?.status ?? this.evaluacionW2A.status, 
          comentario: obj.evaluacionW2A?.comentario ?? this.evaluacionW2A.comentario, 
          nivel: obj.evaluacionW2A?.nivel ?? this.evaluacionW2A.nivel,
          categoria: obj.evaluacionW2A?.categoria ?? this.evaluacionW2A.categoria
        }
      }
  }

  isValid = () => {
    var isValid = true;
    if(this.deporte.trim()===""){
      isValid = false
    }
    if(this.club.trim()===""){
      isValid = false
    }
    if(this.categoria.trim()===""){
      isValid = false
    }
    if(this.tipoDeporte.trim()===""){
      isValid = false
    }
    if(this.tipoDeporte.trim()===""){
      isValid = false
    }
    if(this.tipoDeporte.trim()==="Equipo" && this.division.trim()===""){
      isValid = false
    }
    if(this.tipoDeporte.trim()==="Individual" && this.ranking.trim()===""){
      isValid = false
    }
    if(this.informacionAdicional.trim()===""){
      isValid = false
    }

    if(!this.terminos){
      isValid=false;
    }
    return {isValid}
  }

}

export default InscripcionDeportiva