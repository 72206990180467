import React, { useState, useEffect, memo } from 'react'
import Input from '../../../../../../Shared/Input'
import InputMenu from '../../../../../../Shared/InputMenu'
import UpdateStateInputs from '../../../../../../Shared/UpdateStateInputs'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../../utils/firebase'
import _ from 'underscore'
import { opcionesListaStatus, opcionesValoracionDeportivaWA2 } from '../../../../../../../utils/constans'
import { getNumber } from '../../../../../../../utils/functions'
import Textarea from '../../../../../../Shared/Textarea';

const Evaluacion = memo((props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  const saveData = () => {
    let multiPath = {}
    if (!state.nivel.trim()) {
      multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/evaluacionW2A/nivel`] = 0;
    } else {
      multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/evaluacionW2A/nivel`] = (+getNumber(state.nivel));
    }
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/evaluacionW2A/status`] = state.status;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/evaluacionW2A/categoria`] = state.categoria;
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/inscripcion/beca/deportiva/evaluacionW2A/comentario`] = state.comentario;
    db.update(multiPath)
    .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  }
  

  return(
    <div className='sub-container-informacion-alumno'>

      {edited? <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> :null }

      <p className="title-informacion-alumno">1. Evaluacion W2A</p>

      <InputMenu _class="div_text_mitad" list={opcionesListaStatus} pointInfo={true}
        point_class={ opcionesListaStatus[state.status] ? opcionesListaStatus[state.status].class : false } type="object"
        title="Estado del alumno"
        text={ opcionesListaStatus[state.status] ? opcionesListaStatus[state.status].valor : "" }
        changeValue={status => setState({...state, status })}
      />

      <div className="col-2-input">
        <Input title="Nivel" text={state.nivel} type={"float"} _class_container={ !state.nivel.trim() ? "error-form-input":''} changeValue={nivel => setState({ ...state, nivel }) } />
        <InputMenu list={opcionesValoracionDeportivaWA2} title="Valoración de nivel según W2A" _class_container={state.categoria ? "error-form-input" : ''} text={state.categoria ?? false} changeValue={categoria => setState({ ...state, categoria })} />
      </div>

      <Textarea _class="pdd-top-10" title="Comentario" text={state.comentario} changeValue={comentario => { setState({ ...state, comentario }); }} />
    </div>
  )
})

const mapStateToProps = ({ global }) => {
  return {
    inscripcion: global.inscripcion
  };
}
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(Evaluacion);