import React, { useState, useEffect } from 'react'
import { validarDni, onFocusInscripciones, onBlurInscripciones, validNumber } from '../../../../../../utils/functions'
import { opcionesTipoInscripcion, opcionesCursoActual, opcionesNivelIngles, opcionesTitulacionIngles, opcionesEstudiarFueraSpain, opcionesTipoCarrera } from '../../../../../../utils/constans'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../../redux/actions/alumnosActions';
import ListaOpciones from '../../../Shared/ListaOpciones'
import Switch from '../../../../../Shared/Switch'

const DatosAcademicos = (props) => {

  const { errors } = props

  const [showOptionsTipoInscripcion, setShowOptionsTipoInscripcion] = useState(false)
  const [showOptionsCurso, setShowOptionsCurso] = useState(false)
  const [showOptionsInglesNivel, setShowOptionsInglesNivel] = useState(false)
  const [showOptionsTitulacionIngles, setShowOptionsTitulacionIngles] = useState(false)
  const [showOptionsestudiarFueraSpain, setShowOptionsestudiarFueraSpain] = useState(false)
  const [showOptionsTipoCarrera, setShowOptionsTipoCarrera] = useState(false)


  const callbackSwitch = (json) => {
    if (json.id === 'bachilleratoDual') {
      props.actions.modifyCurrentAlumno({value: json.value, path:'inscripcionAlumno.bachilleratoDual'})
    }
  }

  return(
    <div>

      <h3 className="title_form"><span className="fw_thin">Datos</span> académicos</h3>

      <div className="pr width_100 colums_form_2">
        {/*TIPO DE INSCRIPCION*/}
        <div className={`pr pointer select_form colums_form_2_margin_right ${errors.tipoInscripcion ? 'obligatorio' : ''}`} onClick={()=>setShowOptionsTipoInscripcion(true)}>
          <div className="item_selected">{props.tipoInscripcion}</div>
          <label className="label_form pointer">Tipo de inscripción</label>
          {showOptionsTipoInscripcion &&
            <ListaOpciones 
              editOpciones={(value) => setShowOptionsTipoInscripcion(value)} 
              opciones={opcionesTipoInscripcion} 
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.inscripcion.tipo'}) } 
              opcionSelected={props.tipoInscripcion} 
            /> 
          }
        </div>
        
        {/* BACHILLERATO DUAL*/}
        <div className="display_inline_flex parent_academica colums_form_2_margin_left width_100 container-switch-inscripcion">
          <span className="span_academica margin_auto_vertical">¿Estudiante de Bachillerato <span className="color_secundario">DUAL</span>?</span>
          <div className="display_inline_flex">
            <span className="info_academica margin_auto_vertical">no</span>
            <Switch class_div={'switch_academica'} callbackSwitch={callbackSwitch} json={{ id: 'bachilleratoDual' }} value={props.bachilleratoDual} />
            <span className="info_academica margin_auto_vertical">si</span>
          </div>
        </div>
      </div>

      <div className="pr width_100 colums_form_2">
        {/*CURSO ACTUAL*/}
        <div className={`pr pointer select_form colums_form_2_margin_right  ${errors.cursoActual ?'obligatorio':''}`} onClick={() => setShowOptionsCurso(true)}>
          <div className="item_selected">{props.cursoActual}</div>
          <label className="label_form pointer">Curso actual del alumno</label>
          {showOptionsCurso &&
            <ListaOpciones 
              editOpciones={value => setShowOptionsCurso(value)} 
              opciones={opcionesCursoActual} 
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.cursoActual.curso'}) } 
              opcionSelected={props.cursoActual} 
            /> 
          }
        </div>
        
        <div className="pr width_100 colums_form_2_margin_left">
          <input onFocus={e => onFocusInscripciones(e)} onBlur={e => onBlurInscripciones(e)} className={`input_form ${errors.mediaGlobal ? 'obligatorio' : ''}`} value={props.mediaGlobal} 
            onChange={e => props.actions.modifyCurrentAlumno({value: validNumber(e.target.value), path:'inscripcionAlumno.cursoAnterior.mediaGlobal'})} />
          <label className="label_form">Nota media global (curso anterior)</label>
        </div>
      </div>


      <div className="pr width_100 colums_form_2">
        {/*NOTA MEDIA MATES */}
        <div className="pr width_100 colums_form_2_margin_right">
          <input onFocus={e => onFocusInscripciones(e)} onBlur={e => onBlurInscripciones(e)} className={`input_form ${errors.mediaMatematicas ? 'obligatorio' : ''}`} value={props.mediaMatematicas} 
            onChange={e => props.actions.modifyCurrentAlumno({value: validNumber(e.target.value), path:'inscripcionAlumno.cursoAnterior.mediaMatematicas'})} />
          <label className="label_form">Nota media matemáticas (curso anterior)</label>
        </div>
        {/*NOTA MEDIA INGLES */}
        <div className="pr width_100 colums_form_2_margin_left">
          <input onFocus={e => onFocusInscripciones(e)} onBlur={e => onBlurInscripciones(e)} className={`input_form ${errors.mediaIngles ? 'obligatorio' : ''}`} value={props.mediaIngles} 
            onChange={e => props.actions.modifyCurrentAlumno({value: validNumber(e.target.value),path:'inscripcionAlumno.cursoAnterior.mediaIngles'})} />
          <label className="label_form">Nota media de inglés (curso anterior)</label>
        </div>
      </div>


      <div className="pr width_100 colums_form_2">
        {/*NIVEL DE INGLES*/}
        <div className={`pr pointer select_form colums_form_2_margin_right ${errors.nivelIngles ? 'obligatorio' : ''}`} onClick={ () => setShowOptionsInglesNivel(true)}>
          <div className="item_selected">{props.nivelIngles}</div>
          <label className="label_form pointer">Nivel de inglés del Alumno</label>
          {showOptionsInglesNivel &&
            <ListaOpciones 
              editOpciones={(value) => setShowOptionsInglesNivel(value)} 
              opciones={opcionesNivelIngles} 
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.ingles.nivel'}) } 
              opcionSelected={props.nivelIngles} 
            />
          }
        </div>
        {/*Máxima titulación acreditada de inglés*/}
        <div className={`pr pointer select_form colums_form_2_margin_left ${errors.titulacionIngles  ? 'obligatorio' : ''}`} onClick={() => setShowOptionsTitulacionIngles(true) }>
          <div className="item_selected">{props.titulacionIngles}</div>
          <label className="label_form pointer">Máxima titulación acreditada de inglés</label>
          {showOptionsTitulacionIngles &&
            <ListaOpciones 
              editOpciones={(value) => setShowOptionsTitulacionIngles(value)} 
              opciones={opcionesTitulacionIngles} 
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.ingles.titulacion'})} 
              opcionSelected={props.titulacionIngles} /> 
          }
        </div>
      </div>

      
      <div className="pr width_100 colums_form_2">
        {/*ESTUDIAR EEUU*/}
        <div className={`pr pointer select_form colums_form_2_margin_right ${errors.estudiarFueraSpain ? 'obligatorio' : ''}`} onClick={ () => setShowOptionsestudiarFueraSpain(true)}>
          <div className="item_selected">{props.estudiarFueraSpain}</div>
          <label className="label_form pointer">¿Quieres estudiar tu carrera fuera de España?</label>
          {showOptionsestudiarFueraSpain && 
            <ListaOpciones 
              editOpciones={(value) => setShowOptionsestudiarFueraSpain(value)} 
              opciones={opcionesEstudiarFueraSpain} 
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.estudiarFueraSpain'}) } 
              opcionSelected={props.estudiarFueraSpain} 
            /> 
          }
        </div>
        {/*TIPO CARRERA*/}
        <div className={`pr pointer select_form colums_form_2_margin_left ${errors.tipoCarrera ? 'obligatorio' : ''}`} onClick={() => setShowOptionsTipoCarrera(true) }>
          <div className="item_selected">{props.tipoCarrera}</div>
          <label className="label_form pointer">¿QUÉ TIPO DE CARRERA ESTUDIARÍAS?</label>
          {showOptionsTipoCarrera && 
            <ListaOpciones 
              editOpciones={(value) => setShowOptionsTipoCarrera(value)} 
              opciones={opcionesTipoCarrera} 
              opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.tipoCarrera'})} 
              opcionSelected={props.tipoCarrera} /> 
          }
        </div>
        
      </div>


    </div>
  )
}



const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(DatosAcademicos);