import React, { useState, useEffect } from 'react'
import Input from '../../../../../Shared/Input'
import InputMenu from '../../../../../Shared/InputMenu'
import Switch from '../../../../../Shared/Switch'
import UpdateStateInputs from '../../../../../Shared/UpdateStateInputs'
import { provincias } from '../../../../../../utils/constans'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../utils/firebase'
import _ from 'underscore'

const InformacionColegio = (props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  const saveData = () => {
    const { nombre, fundacion, direccion, provincia, participacionAnterior } = state
    if(!nombre.trim() || !fundacion.trim() || !direccion.trim()){
      props.actions.setNotification({ visibility: true, text: "Exiten errores en los campos", status: "close", moment: Date.now() })
      return false;
    }

    var multiPath = {};
    multiPath[`Colegios/${props.id}/nombre`]=state.nombre;
    multiPath[`Colegios/${props.id}/direccion`]=state.direccion;
    multiPath[`Colegios/${props.id}/provincia`]=state.provincia;
    multiPath[`Colegios/${props.id}/fundacion`]=state.fundacion;
    multiPath[`Colegios/${props.id}/participacionAnterior`]=state.participacionAnterior;

    db.update(multiPath)
    .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  }


  return(
    <div className='sub-container-informacion-alumno'>

      { edited && !props.block && <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> }

      <p className='title-informacion-alumno'>1. Información del colegio</p>

      <Input type='block' _class='div_text_mitad' _class_input='dni-input' title='Código' text={state.id} />

      <div className='col-2-input'>
        <Input type={`${props.block?'block':''}`} _class_input='camelcase' _class_container={state.nombre.trim()===''?'error-form-input':""} title='Nombre del colegio' text={state.nombre} changeValue={nombre=>{setState({...state, nombre})}}/>
      </div>
      
      <div className='col-2-input'>
        <Input type={`${props.block?'block':''}`} _class_container={state.direccion.trim()===''?'error-form-input':""} title='Dirección' text={state.direccion} changeValue={direccion=>{setState({...state, direccion})}}/>
      </div>

      <div className='col-2-input'>
        <InputMenu type={`${props.block?'block':'object'}`} list={provincias} title='Provincia' text={provincias[state.provincia].texto} changeValue={(provincia)=>setState({...state, provincia})}/>
        <Input type={`${props.block?'block':''}`} title='Grupo educativo o fundación'  text={state.fundacion} changeValue={fundacion=>{setState({...state, fundacion})}}/>
      </div>

      <div className='col-2-input'>
        <div className='display_flex container-simple-input pdd-top-40'>
          <div className="title-input align-center mg-right-10 pdd-v-0">¿Ha participado anteriormente?</div>
          <span className='options-switch'>NO</span>
          <Switch class_div='switch-table' block={props.block} json={{id:'participacionAnterior'}} value={state.participacionAnterior} callbackSwitch={({value})=>{setState({...state, participacionAnterior:value})}}/>
          <span className='options-switch'>SI</span>
        </div>

      </div>

    </div>
  )
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(InformacionColegio);