import React from 'react'
import ScrollContainer from '../../../../../Shared/ScrollContainer'

const PanelCuestionario = ({alumno}) => {
  return(
    <ScrollContainer _class = {`container-table`}>
      <div className='container-informacion-alumno'>
        <div className='sub-container-informacion-alumno'>
          <p className='title-informacion-alumno'>1. Valores del cuestionario</p>
          <div className='container-results-cuestionario'>
            {
              Object.entries(alumno.inscripcionAlumno.getValoresCuestionario()).map(([k,r])=>{
                return(
                  <div key={k} className='item-results-cuestionario'>
                    <div className='valor-cuestionario'>{r}</div>
                    <div className='title-cuestionario'>{k}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </ScrollContainer>
  )
}

export default PanelCuestionario