import React, { useState } from 'react'
import { opcionesCuestionario } from '../../../../../utils/constans'
import RadioButton from '../../Shared/RadioButton'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../redux/actions/alumnosActions';
import ErrorFormulario from '../../Shared/ErrorFormulario'


const PreguntaCheckBox = ({idPregunta,currentAlumno, actions, setPanelSelectedBack, setPanelSelected}) => {
  
  const [errors, setErrors] = useState(false)
  const [showMensaje, setShowMensaje] = useState(false)

  const callbackCheckItem = (json) =>{
    if(!currentAlumno.inscripcionAlumno.cuestionario[idPregunta]) return false
    var cuestionario = {...currentAlumno.inscripcionAlumno.cuestionario}
    cuestionario[idPregunta][json.idArrayPregunta] = `${json.value}`
    actions.modifyCurrentAlumno({value:cuestionario, path:'inscripcionAlumno.cuestionario'})
  }

  const nextPanel = () => {
    if(!currentAlumno.inscripcionAlumno.isValidCuestionario(idPregunta)){
      setErrors(true)
      setShowMensaje(true)
      return false
    }
    setPanelSelected()
  }

  return(
    <div>
      <div className="pregunta-texto">{opcionesCuestionario.preguntas[idPregunta].pregunta}</div>
      <div className="contenedor-preguntas-selectivas">
        {currentAlumno.inscripcionAlumno.cuestionario[idPregunta].map((r, k2) => {
          if(!opcionesCuestionario.preguntas[idPregunta]) return false
          let respuesta = opcionesCuestionario.preguntas[idPregunta].respuestas[k2]
          return (
            <div className={`item-preguntas-selectivas ${ errors && r==="0" ? "error-respuesta" : "" }`} key={k2} >
              <div className="texto-preguntas-selectivas"> {respuesta.text} </div>
              <div className="opciones-preguntas-selectivas">
                <div>
                  <div className="id-radio-btn">1</div>
                  <RadioButton callbackCheckItem={callbackCheckItem}
                    json={{ value: "1", idArrayPregunta: k2 }}
                    checked={r === "1"}
                  />
                </div>
                <div>
                  <div className="id-radio-btn">2</div>
                  <RadioButton callbackCheckItem={callbackCheckItem}
                    json={{ value: "2", idArrayPregunta: k2 }}
                    checked={r === "2"}
                  />
                </div>
                <div>
                  <div className="id-radio-btn">3</div>
                  <RadioButton callbackCheckItem={callbackCheckItem}
                    json={{ value: "3", idArrayPregunta: k2 }}
                    checked={r === "3"}
                  />
                </div>
                <div>
                  <div className="id-radio-btn">4</div>
                  <RadioButton callbackCheckItem={callbackCheckItem}
                    json={{ value: "4", idArrayPregunta: k2 }}
                    checked={r === "4"}
                  />
                </div>
              </div>
            </div>
          )
        })}
        {showMensaje &&
          <>
            <br/>
            <ErrorFormulario updateError={()=>setShowMensaje(false)} />
          </>
        }
      </div>


      



      <div className='direccions_panel'>
        <div className="btn_send_form btn-volver-form pointer noselect float_atras" onClick={setPanelSelectedBack}> Atrás </div>
        <div className='btn_send_form pointer noselect float_siguiente' onClick={nextPanel}>Siguiente</div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ alumnos }) => {
  return {
    currentAlumno: alumnos.currentAlumno,
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(PreguntaCheckBox);