
class Responsable {
  nombre = "";
  apellidos = "";
  email= "";
  telefonoMovil = "";
  telefonoFijo = "";
  cargo = "";
  horarioPreferente = ""

  constructor(obj){
    if(obj){
      this.nombre = obj.nombre ?? this.nombre
      this.apellidos = obj.apellidos ?? this.apellidos
      this.email = obj.email ?? this.email
      this.telefonoMovil = obj.telefonoMovil ?? this.telefonoMovil
      this.telefonoFijo = obj.telefonoFijo ?? this.telefonoFijo
      this.cargo = obj.cargo ?? this.cargo
      this.horarioPreferente = obj.horarioPreferente ?? this.horarioPreferente
    }
  }
}

export default Responsable;