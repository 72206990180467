import React, { useState, useEffect, memo } from 'react'
import Input from '../../../../../Shared/Input'
import InputMenu from '../../../../../Shared/InputMenu'
import Switch from '../../../../../Shared/Switch'
import UpdateStateInputs from '../../../../../Shared/UpdateStateInputs'
import { isEmail, isTelefono, isValidFechaNacimiento, cleanDNI, isCorrectDni, validFecha } from '../../../../../../utils/functions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../utils/firebase'
import _ from 'underscore'
import { genderOptions } from '../../../../../../utils/constans'

const Sat = memo((props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  const saveData = () => {
    var multiPath = {};
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/nombre`]=state.nombre.trim();
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/apellidos`]=state.apellidos.trim()
    // db.update(multiPath)
    // .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    // .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  }
  
  const isRepeatDNI = () => Object.entries(props.alumnos).some(([idAlumno, alum]) => alum.id!==props.alumno.id && alum.informacionAlumno.dni.toUpperCase() === state.dni.toUpperCase())

  return(
    <div className='sub-container-informacion-alumno'>

      {/* {edited? <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> :null } */}

      <p className='title-informacion-alumno'>1. Notas Sat</p>

      <Input type='block' _class='div_text_mitad' _class_input='dni-input' title='Total' text={`${+state.ingles + +state.matematicas}`} />

      <div className='col-2-input'>
        <Input type='block' title='Inglés' text={state.ingles} changeValue={ingles=>setState({...state, ingles})}/>
        <Input type='block' title='Matemáticas' text={state.matematicas} changeValue={matematicas=>setState({...state, matematicas})}/>
      </div>

    </div>
  )
})

const mapStateToProps = ({ alumnos }) => {
  return {
    alumnos: alumnos.alumnos
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(Sat);