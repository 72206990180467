

export const globalTypes = {
  SET_TAB_HOME: "SET_TAB_HOME",
  SET_NOTIFICATION: "SET_NOTIFICATION"
}
export function setTabHome(payload) {
  return { type: globalTypes.SET_TAB_HOME, payload };
}
export const setNotification = (payload) => {
  return { type: globalTypes.SET_NOTIFICATION, payload }
}