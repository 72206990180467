import React from 'react'
import HeaderListado from './Headers/HeaderListado/HeaderListado'
import PanelListadoGeneral from './Panels/PanelListado/ListadoGeneral/PanelListado'
import PanelListadoDeportivo from './Panels/PanelListado/ListadoDeportivo/PanelListado'
import PanelPerfil from './Panels/PanelPerfil/PanelPerfil'
import PanelBeca from './Panels/PanelBeca/PanelBeca'
import Sat from './Panels/Sat/Sat'
import PanelInformacionColegio from '../Colegios/Panels/PanelInformacion/PanelInformacion'
import PanelCuestionario from './Panels/PanelCuestionario/PanelCuestionario'
import PanelColegioNuevo from './Panels/PanelColegioNuevo/PanelColegioNuevo'
import { connect } from "react-redux";
//import { bindActionCreators } from 'redux';

const Alumnos = ({tabSelected, colegios, alumnos, viewSelected, alumnoSelected}) => {
  
  return(
    <div className={`panel-colegios`} >
      <HeaderListado />
      
      {tabSelected==="listado" && viewSelected==="general" && <PanelListadoGeneral />}
      {tabSelected==="listado" && viewSelected==="deportiva" && <PanelListadoDeportivo />}
      {tabSelected==="perfil" && alumnos[alumnoSelected] && <PanelPerfil alumno={alumnos[alumnoSelected]} />}
      {tabSelected==="beca" && alumnos[alumnoSelected] && <PanelBeca alumno={alumnos[alumnoSelected]} />}
      {tabSelected==="sat" && alumnos[alumnoSelected] && <Sat alumno={alumnos[alumnoSelected]} />}

      {tabSelected==="colegio" && alumnos[alumnoSelected] && (
        alumnos[alumnoSelected].inscripcionAlumno.datosColegio.colegio.id!=="nuevo"?
          <PanelInformacionColegio block={true} colegio={colegios[alumnos[alumnoSelected].inscripcionAlumno.datosColegio.colegio.id]} />
        : 
          <PanelColegioNuevo datosColegio={alumnos[alumnoSelected].inscripcionAlumno.datosColegio} />
      )}
      {tabSelected==="cuestionario" && alumnos[alumnoSelected] && <PanelCuestionario alumno={alumnos[alumnoSelected]} />}

    </div>
  )
}

const mapStateToProps = ({ alumnos, colegios }) => {
  return {
    tabSelected: alumnos.tabSelected,
    alumnos: alumnos.alumnos,
    alumnoSelected: alumnos.alumnoSelected,
    viewSelected: alumnos.tabs.listado.header.viewSelected,
    colegios: colegios.colegios
  };
}

export default connect(mapStateToProps)(Alumnos);