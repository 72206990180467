import React from 'react';

const RadioButton = ({checked, json, callbackCheckItem}) => {
  const checkItem = (e) => {
    if(json){
      /*let newJson = {
        ...json,
        value: !checked
        }*/
      callbackCheckItem(json)
    }else{
      callbackCheckItem(true)
    }
  }
  return(
    <div onClick={checkItem}  data-checked={checked}>
      <i className={`material-icons radio-btn-select ${checked?'color_corporativo':''}`}>{checked?'radio_button_checked':'radio_button_unchecked'}</i>
    </div>
  )
}

export default RadioButton;
