import React from 'react'
import { opcionesCuestionario } from '../../../../../utils/constans'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../redux/actions/alumnosActions';


const PreguntaReordenar = ({idPregunta, prioritario, noPrioritario, currentAlumno, actions, setPanelSelectedBack, setPanelSelected, endCuestionario, saveCuestionario}) => {

  const onDragEnd = (result, id) => {
    const { destination, source /*draggableId*/ } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if(!currentAlumno.inscripcionAlumno.cuestionario[idPregunta]) return false

    var cuestionario = {...currentAlumno.inscripcionAlumno.cuestionario}

    var itemMoved = cuestionario[idPregunta][source.index];
    cuestionario[idPregunta].splice(source.index, 1);
    cuestionario[idPregunta].splice(destination.index, 0, itemMoved);
    actions.modifyCurrentAlumno({value:cuestionario, path:'inscripcionAlumno.cuestionario'})
  };

  const newxtPanel = () => {
    if(endCuestionario){
      saveCuestionario()
    }else{
      setPanelSelected()
    }
  }

  return(
    <div>
      <div >
        <div>
          <div className="pregunta-texto">
            {opcionesCuestionario.preguntas[idPregunta].pregunta}
          </div>

          <div className='reordenar-title-prioritario-true'>{prioritario}</div>
          <div className={'center-tag'}>            
            <DragDropContext onDragEnd={result => onDragEnd(result, 1)}>
              <Droppable droppableId={'preguntaCuestionario1'}>
                {provided => (
                  <div className="display_inline_grid" ref={provided.innerRef} {...provided.droppableProps} >
                    {currentAlumno.inscripcionAlumno.cuestionario[idPregunta].map(
                      (r, k) => {
                        if(!opcionesCuestionario.preguntas[idPregunta])return false
                        let respuesta = opcionesCuestionario.preguntas[idPregunta].respuestas.find((r2)=>{ return r2.idPregunta===r })
                        return (
                          <Draggable draggableId={r} index={k} key={r}>
                            {(provided, snapshot) => (
                              <div ref={provided.innerRef} key={k} className={`item-pregunta-drag ${ snapshot.isDragging ? "shadow" : "" }`} {...provided.dragHandleProps} {...provided.draggableProps}>
                                <div className="num-respuest">{k + 1}</div>
                                <div className="text-respuest">{respuesta?respuesta.text:''}</div>
                                <div className="icon-respuest">
                                  <i className="material-icons"> drag_handle </i>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      }
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className='reordenar-title-prioritario-false'>{noPrioritario}</div>
          
          <div className='direccions_panel'>
            {idPregunta>1&& <div className="btn_send_form btn-volver-form pointer noselect float_atras btn-volver-form" onClick={setPanelSelectedBack}> Atrás </div> }
            <div className='btn_send_form pointer noselect float_siguiente' onClick={newxtPanel}>
              {`${endCuestionario?"Guardar":"Siguiente"}`}
              </div>
          </div>


        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ alumnos }) => {
  return {
    currentAlumno: alumnos.currentAlumno,
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(PreguntaReordenar);
