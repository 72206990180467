import { colegiosTypes } from '../actions/colegiosActions';
import dotProp from 'dot-prop-immutable';
import { filtrosColegiosListado, addItemsLoaded } from '../../utils/constans'
import Colegio from '../../models/Colegio'

var initialState = {
  colegios: {},
  colegioSelected: null, 
  sortedColegios: [],
  tabSelected: "listado",
  currentColegio: new Colegio(),
  tabs: {
    listado: {
      header: {
        search: "",
        searchBy: "nombre",
        searchOpciones: {
          'codigoColegio': {valor:'código'},
          'nombre':{valor:'nombre'},
          'provincia':{valor:'provincia'}
        },
        sortBy: "nombre",
        des: false,
        itemsLoaded: addItemsLoaded,
        filters: filtrosColegiosListado
      }
    }
  }
}

export default function (state = initialState, action) {
  switch (action.type) {

    case colegiosTypes.SET_COLEGIOS:{
      return dotProp.set(state, `colegios`, action.payload);
    }
    case colegiosTypes.CHANGE_HEADER:{
      const { tab, field, value, des} = action.payload
      let newState = dotProp.set(state, `tabs.${tab}.header.${field}`, value);
      if(field==="sortBy"){
        newState = dotProp.set(newState, `tabs.${tab}.header.des`, des);
      }
      return newState
    }
    case colegiosTypes.SET_TAB_SELECTED:{
      return dotProp.set(state, `tabSelected`, action.payload);
    }
    case colegiosTypes.SET_SORTED_DATA:{
      return dotProp.set(state, `sortedColegios`, action.payload);
    }
    case colegiosTypes.SET_COLEGIO_SELECTED:{
      return dotProp.set(state, `colegioSelected`, action.payload);
    }
    case colegiosTypes.SET_CURRENT_COLEGIO:{
      return {...state, currentColegio: new Colegio(action.payload) }
    }
    case colegiosTypes.MODIFY_CURRENT_COLEGIO:{
      const { path, value } = action.payload
      let currentColegio = dotProp.set(state.currentColegio, `${path}`, value)
      return {...state, currentColegio: new Colegio(currentColegio)}
    }


    default:{
      return state;
    }
  }
}