import React, { useLayoutEffect, useEffect, useState } from 'react'
import SideBar from '../SideBar/SideBar'
import Panels from './Panels/Panels'
import { db } from '../../utils/firebase'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { getColegios } from '../../redux/actions/colegiosActions'
import { getAlumnos, getInscripcionesAlumnos } from '../../redux/actions/alumnosActions'


const Home = ({alumnos, colegios, actions}) => {

  useLayoutEffect(()=>{
    getData()
  }, [])

  const getData = async () => {
    await actions.getColegios({sort: true})
    await actions.getAlumnos({sort: true})
    await actions.getInscripcionesAlumnos()
  }

  
  const getInscripciones = () => {
    
    
  }

  return(
    <div className="container-app">
      <SideBar />
      <Panels />
    </div>
  )
}

function mapStateToProps({ global, colegios, alumnos }) {
  return {
    //inscripcion: global.inscripcion,
    colegios: colegios.colegios,
    alumnos: alumnos.alumnos
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      getColegios,
      getAlumnos,
      getInscripcionesAlumnos
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(Home);