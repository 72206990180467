class User {

  isAuthenticated = false
  id = null
  apellidos = ""
  email = ""
  foto = ""
  nombre = ""
  roles = []
  username = ""

  constructor(obj){
    if(obj){
      this.isAuthenticated = obj.isAuthenticated ?? this.isAuthenticated
      this.id = obj.id_empleado ?? this.id
      this.apellidos = obj.apellidos ?? this.apellidos
      this.email = obj.email ?? this.email
      this.foto = obj.foto ?? this.foto
      this.nombre = obj.nombre ?? this.nombre
      this.roles = [obj.role] ?? this.roles
      this.username = obj.username ?? this.username
    }
  }

}

export default User