import React, { useState, memo } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Switch from '../../../../../../Shared/Switch'
import { setAlumnoSelected, setTabSelected } from '../../../../../../../redux/actions/alumnosActions'
import { setNotification } from '../../../../../../../redux/actions/globalActions';
import { opcionesListaStatus } from '../../../../../../../utils/constans'
import { db } from '../../../../../../../utils/firebase'
import ListPopUp from '../../../../../../Shared/ListPopUp'

const ItemLista = memo(({alumno, status, alumnoSelected, inscripcion, actions}) => {

const [showStatus, setShowStatus] = useState(false)

  const selectAlumno = (tab) => {
    actions.setAlumnoSelected(alumno.id)
    actions.setTabSelected(tab)
  }

  const changeStatus = ( id, obj) =>{
    var multiPath = {}
    if(status && status === id){
      multiPath[`Inscripciones/Alumnos/${inscripcion}/${alumno.id}/inscripcion/beca/deportiva/evaluacionW2A/status`] = "";
    }else{
      multiPath[`Inscripciones/Alumnos/${inscripcion}/${alumno.id}/inscripcion/beca/deportiva/evaluacionW2A/status`] = id;
    }
    db.update(multiPath)
    .then(() => actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  }
  
  return(
    <tr data-id={alumno.id} className={`${status ?? ''} ${alumnoSelected===alumno.id?'active-row-table':''}`}>

        <td className='lb-enlaces-free-status pr' onClick={()=>setShowStatus(true)}>
          <div className={`status-point punto-status ${status==='no_interes'?'opcion_no_interes':''} ${status==='contrato'?'opcion_contrato':''} ${status==='contactado'?'opcion_contactado':''} ${status==='muy_interesado'?'opcion_muy_interesado':''} ${status==='futuro'?'opcion_a_futuro':''}      `} ></div>
          {showStatus &&
            <ListPopUp
              cerrarClick={true}
              puntoInformativo={true}
              selectOpcion = {(id, obj)=>changeStatus(id, obj)}
              optionSelected={status ?? null}
              options={opcionesListaStatus}
              title='Estados del deportista'
              _class='position-pop-up-status-alumnos'
              _class_div={`max-width min-width-305`}
              onClose={()=>setShowStatus(false)}
            />
          }

        </td>

        <td>
          <div className='edit-container'>
            <span className='width_100'>{alumno.informacionAlumno.nombre.toLowerCase()} {alumno.informacionAlumno.apellidos.toLowerCase()}</span>
          </div>
        </td>

        <td>
          <div className='edit-container'>
            <span className='width_100'>{alumno.inscripcionAlumno.inscripcion.beca.deportiva.deporte}</span>
          </div>
        </td>


        <td>
          <div className='edit-container'>
            <span className='width_100'>{alumno.inscripcionAlumno.inscripcion.beca.deportiva.club ?? '-'}</span>
          </div>
        </td>

        <td>
          <div className='edit-container'>
            <span className='width_100'>{alumno.informacionAlumno.fechaNacimiento ?? ''}</span>
          </div>
        </td>

        <td>
          <div className='edit-container'>
            <span className='width_100'>{alumno.informacionTutor.telefono ?? ''}</span>
          </div>
        </td>

        <td className='next-view' onClick={()=>{selectAlumno('beca')}}>
          <i className="material-icons align-center">chevron_right</i>
        </td>


    </tr>

  )
})

const mapStateToProps = ({ alumnos, global }) => {
  return {
    alumnoSelected: alumnos.alumnoSelected,
    inscripcion:  global.inscripcion
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setAlumnoSelected,
      setTabSelected,
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(ItemLista);