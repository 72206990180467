import React, { useState, useEffect } from 'react'
import { validarDni, onFocusInscripciones, onBlurInscripciones, validarTelefono, validFechaNacimiento } from '../../../../../../utils/functions'
import { opcionesTiposActividadAcademica, opcionesYearsDedication, opcionesHorasSemanales } from '../../../../../../utils/constans'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../../redux/actions/alumnosActions';
import ListaOpciones from '../../../Shared/ListaOpciones'
import Switch from '../../../../../Shared/Switch'

const Extraescolares = (props) => {
  
  const { errors } = props

  const [showOptionsActividad, setShowOptionsActividad] = useState(false)
  const [showOptionsDedicationYears, setShowOptionsDedicationYears] = useState(false)
  const [showOptionsSiguePracticando, setShowOptionsSiguePracticando] = useState(false)

  const callbackSwitch = (json) => {
    if (json.id === 'siguePracticando') {
      props.actions.modifyCurrentAlumno({value: json.value, path:'inscripcionAlumno.inscripcion.beca.academica.siguePracticando'})
    }
  }

  return(
    <div>
      <h3 className="title_form">Extraescolares no deportivas</h3>
      {/*TIPO DE ACTIVIDAD*/}
      <div className={`pr pointer select_form text-large-forms  ${errors.tipoActividad?'obligatorio':''}`} onClick={()=>setShowOptionsActividad(true)}>
        <div className="item_selected">{props.tipoActividad}</div>
        <label className="label_form pointer">Tipo de actividad realizada (elegir aquella a la que mas tiempo dediques)</label>
        {showOptionsActividad &&
          <ListaOpciones 
            editOpciones={(value) => setShowOptionsActividad(value)} 
            opciones={opcionesTiposActividadAcademica}
            opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.inscripcion.beca.academica.tipoActividad'}) } 
            opcionSelected={props.tipoActividad}
          />}
      </div>

      {/*NOMBRE DE LA ACTIVIDAD */}
      <div className="pr">
        <input onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ${errors.nombreActividad?'obligatorio':''}`} value={props.nombreActividad} 
          onChange={e=>props.actions.modifyCurrentAlumno({value:e.target.value, path:'inscripcionAlumno.inscripcion.beca.academica.nombreActividad'})}
          />
        <label className="label_form">Nombre de la actividad realizada</label>
      </div>

      {/*TIPO DE ACTIVIDAD*/}
      <div className={`pr pointer select_form text-large-forms  ${errors.dedicationYears?'obligatorio':''}`} onClick={()=>setShowOptionsDedicationYears(true)}>
        <div className="item_selected">{props.dedicationYears}</div>
        <label className="label_form pointer">Años de dedicación</label>
        {showOptionsDedicationYears &&
          <ListaOpciones 
            editOpciones={(value) => setShowOptionsDedicationYears(value)} 
            opciones={opcionesYearsDedication}
            opcionSelectedUpdate={(value) => props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.inscripcion.beca.academica.dedicationYears'}) } 
            opcionSelected={props.dedicationYears}
          />}
      </div>

      {/**/}
      <div className="display_inline_flex width_100 item-switch-form">
        <span className="span_academica margin_auto_vertical">¿Lo sigues practicando actualmente?</span>
        <div className="display_inline_flex">
          <span className="info_academica margin_auto_vertical">no</span>
          <Switch class_div={'switch_academica'} callbackSwitch={callbackSwitch} json={{ id: 'siguePracticando' }} value={props.siguePracticando} />
          <span className="info_academica margin_auto_vertical">si</span>
        </div>
      </div>

      {props.siguePracticando &&
        <div className={`pr pointer select_form  ${errors.horasSemanales?'obligatorio':''}`} 
          onClick={()=>setShowOptionsSiguePracticando(true)}
        >
          <div className="item_selected">{props.horasSemanales}</div>
          <label className="label_form pointer">Horas de práctica semanales este año</label>
          {showOptionsSiguePracticando && 
            <ListaOpciones 
              editOpciones={(value)=>setShowOptionsSiguePracticando(value)} 
              opciones={opcionesHorasSemanales} 
              opcionSelectedUpdate={(value)=>props.actions.modifyCurrentAlumno({value, path:'inscripcionAlumno.inscripcion.beca.academica.horasSemanales'}) } 
              opcionSelected={props.horasSemanales}
            />
          }
        </div>
      }



      {/*CENTRO*/}
      <div className="pr">
        <input onFocus={e=>{onFocusInscripciones(e)}} onBlur={e=>{onBlurInscripciones(e)}} className={`input_form ${errors.centroActividad?'obligatorio':''}`} value={props.centroActividad} 
          onChange={e=>props.actions.modifyCurrentAlumno({value:e.target.value,path:'inscripcionAlumno.inscripcion.beca.academica.centroActividad'})}
        />
        <label className="label_form">Centro en el que lo realizas</label>
      </div>

    </div>
  )
}



const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(Extraescolares);