import React, { useState, useEffect, memo } from 'react'
import Input from '../../../../../Shared/Input'
import InputMenu from '../../../../../Shared/InputMenu'
import Switch from '../../../../../Shared/Switch'
import UpdateStateInputs from '../../../../../Shared/UpdateStateInputs'
import { isEmail, isTelefono, isValidFechaNacimiento, cleanDNI, isCorrectDni, validFecha } from '../../../../../../utils/functions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../utils/firebase'
import _ from 'underscore'
import { opcionesCursoActual, opcionesNivelIngles, opcionesTitulacionIngles, opcionesEstudiarFueraSpain, opcionesTipoCarrera} from '../../../../../../utils/constans'

const InformacionAcademica = (props) => {
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})

  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])

  const saveData = () => {

    if(
      !state.tipoInscripcion.trim() || 
      !state.cursoActual.trim() || 
      !state.mediaGlobal.trim() ||
      !state.mediaMates.trim() ||
      !state.mediaIngles.trim() ||
      !state.nivelIngles.trim() ||
      !state.titulacionIngles.trim() ||
      !state.estudiarFueraSpain.trim() ||
      !state.tipoCarrera.trim()
      ){
      props.actions.setNotification({ visibility: true, text: "Exiten errores en los campos", status: "close", moment: Date.now() })
      return false;
    }

    var multiPath = {};
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/cursoActual/curso`]=state.cursoActual.trim()
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/cursoAnterior/mediaGlobal`]=state.mediaGlobal.trim()
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/cursoAnterior/mediaMatematicas`]=state.mediaMates.trim()
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/cursoAnterior/mediaIngles`]=state.mediaIngles.trim()
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/ingles/nivel`]=state.nivelIngles.trim()
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/ingles/titulacion`]=state.titulacionIngles.trim()
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/estudiarFueraSpain`]=state.estudiarFueraSpain.trim()
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/tipoCarrera`]=state.tipoCarrera.trim()
    multiPath[`Inscripciones/Alumnos/${props.inscripcion}/${props.idAlumno}/bachilleratoDual`]=state.bachilleratoDual
    
    db.update(multiPath)
    .then(() => props.actions.setNotification({ visibility: true, text: "Actualizado correctamente", status: "done", moment: Date.now() }) )
    .catch( err => props.actions.setNotification({ visibility: true, text: "Ha ocurrido algún error", status: "close", moment: Date.now() }) )
  
  }
  
  return(
    <div className='sub-container-informacion-alumno'>

      {edited? <UpdateStateInputs saveData={saveData} undoData={()=>setState({...props})}/> :null }

      <p className='title-informacion-alumno'>4. Información académica</p>

      <div className='col-2-input'>
        <Input type='block' title='Tipo de inscripción' text={state.tipoInscripcion}/>
        
        <div className='display_flex container-simple-input pdd-top-40'>
          <div className="title-input align-center mg-right-10 pdd-v-0">Bachillerato DUAL:</div>
          <span className='options-switch'>NO</span>
          <Switch class_div='switch-table' json={{id:'bachilleratoDual'}} value={state.bachilleratoDual} callbackSwitch={json=>setState({...state, bachilleratoDual:json.value})}/>
          <span className='options-switch'>SI</span>
        </div>

      </div>

      <div className='col-2-input'>
        <InputMenu list={opcionesCursoActual} title='Curso actual del alumno'  text={state.cursoActual} changeValue={(cursoActual)=>setState({...state, cursoActual})}/>
        <Input type='float' _class_container={!state.mediaGlobal.trim()?'error-form-input':null} title='Nota media global del curso anterior' text={state.mediaGlobal} changeValue={mediaGlobal=>setState({...state, mediaGlobal})}/>
      </div>

      <div className='col-2-input'>
        <Input type='float' _class_container={!state.mediaMates.trim()?'error-form-input':null} title='Nota media de matemáticas del curso anterior'  text={state.mediaMates} changeValue={mediaMates=>setState({...state, mediaMates})}/>
        <Input type='float' _class_container={!state.mediaIngles.trim()?'error-form-input':null} title='Nota media de matemáticas del curso anterior'  text={state.mediaIngles} changeValue={mediaIngles=>setState({...state, mediaIngles})}/>
      </div>

      <div className='col-2-input'>
        <InputMenu list={opcionesNivelIngles} title='Nivel de inglés'  text={state.nivelIngles} changeValue={(nivelIngles)=>setState({...state, nivelIngles})}/>
        <InputMenu list={opcionesTitulacionIngles} title='Máxima titulación acreditada de inglés' _class_input={state.titulacionIngles!=='Ninguna'?'dni-input':''} text={state.titulacionIngles} changeValue={(titulacionIngles)=>setState({...state, titulacionIngles})}/>
      </div>

      <div className='col-2-input'>
        <InputMenu list={opcionesEstudiarFueraSpain} title='¿Quiere estudiar fuera de España?'  text={state.estudiarFueraSpain} changeValue={(estudiarFueraSpain)=>setState({...state, estudiarFueraSpain})}/>
        <InputMenu list={opcionesTipoCarrera} title='¿Qué tipo de carrera estudiaría?' text={state.tipoCarrera} changeValue={(tipoCarrera)=>setState({...state, tipoCarrera})}/>
      </div>

      <div className='col-2-input'>
        <div className='container-simple-input'>
          <div className="title-input ">Preferencia países:</div>
          <div className="preferencia-paises-container">
            {state.preferenciaPaises.map((pais,index)=>{
              return(
                <div>
                  <div>{index+1}.</div>
                  <div>{pais.text}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ global }) => {
  return {
    inscripcion: global.inscripcion
  };
}
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setNotification
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(InformacionAcademica);