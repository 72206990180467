import mates3Notes from '../../../images/infoExamen/mates-3-notes.png'
import mates4Notes from '../../../images/infoExamen/mates-3-notes.png'
import matesReferences from '../../../images/infoExamen/mates-references.png'

export const examenMatematicas = {
  3: {
      preguntas: {
          1: {
              rama: 'Heart of Algebra',
              dificultad: '1',
              tipo: {
                  1: {
                      respuestas: ['19', '18', '17', '16']
                  },
                  2: {
                      respuestas: ['15', '16', '14', '13']
                  },
                  3: {
                      respuestas: ['9', '11', '8', '12']
                  }
              }
          },
          2: {
              rama: 'Heart of Algebra',
              dificultad: '1',
              tipo: {
                  1: {
                      respuestas: ['-4', '2', '-2', '4']
                  },
                  2: {
                      respuestas: ['-6', '6', '3', '-3']
                  },
                  3: {
                      respuestas: ['8', '4', '-4', '-8']
                  }
              }
          },
          3: {
              rama: 'Heart of Algebra',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['$850', '$750', '$2000', '$950']
                  },
                  2: {
                      respuestas: ['$3.35', '$2.60', '$2.85', '$2.60']
                  },
                  3: {
                      respuestas: ['$1,350', '$1,500', '$1,650', '$1,450']
                  }
              }
          },
          4: {
              rama: 'Additional Topics in Math',
              dificultad: '1',
              tipo: {
                  1: {
                      respuestas: ['100', '90', '80', '110']
                  },
                  2: {
                      respuestas: ['24', '18', '22', '28']
                  },
                  3: {
                      respuestas: ['4', '8', '16', '2']
                  }
              }
          },
          5: {
              rama: 'Passport to Advanced Math',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  },
                  2: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  },
                  3: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  }
              }
          },
          6: {
              rama: 'Heart of Algebra',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['2 ≤ x ≤ 8', '-2 ≤ x ≤ 2', '2 ≤ x ≤ 5', '0 ≤ x ≤ 8']
                  },
                  2: {
                      respuestas: ['-2.5', '2.5', '-0.5', '-1']
                  },
                  3: {
                      respuestas: ['47', '24', '48', '143']
                  }
              }
          },
          7: {
              rama: 'Heart of Algebra',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  },
                  2: {
                      respuestas: ['49', '81', '36', '64']
                  },
                  3: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  }
              }
          },
          8: {
              rama: 'Passport to Advanced Math',
              dificultad: '2',
              tipo: {
                  1: {
                      input:true
                  },
                  2: {
                      input:true
                  },
                  3: {
                      input:true
                  }
              }
          },
          9: {
              rama: 'Heart of Algebra',
              dificultad: '2',
              tipo: {
                  1: {
                      input:true
                  },
                  2: {
                      input:true
                  },
                  3: {
                      input:true
                  }
              }
          },
          10: {
              rama: 'Additional Topics in Math',
              dificultad: '2',
              tipo: {
                  1: {
                      input:true
                  },
                  2: {
                      input:true
                  },
                  3: {
                      input:true
                  }
              }
          }
      }
  },
  4: {
      preguntas: {
          1: {
              rama: 'Heart of Algebra',
              dificultad: '1',
              tipo: {
                  1: {
                      respuestas: ['11', '22', '-11', '10']
                  },
                  2: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  },
                  3: {
                      respuestas: ['-1', '1', '0', '2']
                  }
              }
          },
          2: {
              rama: 'Problem Solving and Data Analysis',
              dificultad: '1',
              tipo: {
                  1: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  },
                  2: {
                      respuestas: ['y=-x+3', 'y=x+3', 'y=x+1', 'y=3x-1']
                  },
                  3: {
                      respuestas: ['y=5x+6', 'y=4x+7', 'y=8x', 'y=6x+5']
                  }
              }
          },
          3: {
              rama: 'Problem Solving and Data Analysis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['$80', '$160', '$100', '$40']
                  },
                  2: {
                      respuestas: ['66', '65', '67', '69']
                  },
                  3: {
                      respuestas: ['33', '30', '35', '34']
                  }
              }
          },
          4: {
              rama: 'Heart of Algebra',
              dificultad: '1',
              tipo: {
                  1: {
                      respuestas: ['7', '5', '4', '6']
                  },
                  2: {
                      respuestas: ['3', '4', '2', '1']
                  },
                  3: {
                      respuestas: ['6', '-3', '-6', '0']
                  }
              }
          },
          5: {
              rama: 'Problem Solving and Data Analysis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['3,600', '1,800', '1,350', '2,200']
                  },
                  2: {
                      respuestas: ['21', '18', '24', '19']
                  },
                  3: {
                      respuestas: ['12,000', '6,000', '31,500', '4,500']
                  }
              }
          },
          6: {
              rama: 'Additional Topics in Math',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['8', '16', '32', '4']
                  },
                  2: {
                      respuestas: ['12', '13', '14', '16']
                  },
                  3: {
                      respuestas: ['105', '110', '75', '95']
                  }
              }
          },
          7: {
              rama: 'Problem Solving and Data Analysis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['2', '6', '5', '3']
                  },
                  2: {
                      respuestas: ['29', '31', '41', '39']
                  },
                  3: {
                      respuestas: ['17,500', '22,000', '16,000', '15,000']
                  }
              }
          },
          8: {
              rama: 'Problem Solving and Data Analysis',
              dificultad: '3',
              tipo: {
                  1: {
                      respuestas: ['110', '92', '105', '215']
                  },
                  2: {
                      respuestas: ['$123', '$52', '$108', '$115']
                  },
                  3: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  }
              }
          },
          9: {
              rama: 'Passport to Advanced Math',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  },
                  2: {
                      respuestas: ['I and III only', 'I only', 'None of them', 'II and III only']
                  },
                  3: {
                      respuestas: ['-2', '1', '0', '-1']
                  }
              }
          },
          10: {
              rama: 'Heart of Algebra',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['(0,3)', '(-3,0)', '(6,-3)', '(36,-6)']
                  },
                  2: {
                      respuestas: ['32', '128', '-28', '-32']
                  },
                  3: {
                      respuestas: ['(16,-4)', '(-16,4)', '(8, 4)', '(4, 16)']
                  }
              }
          },
          11: {
              rama: 'Passport to Advanced Math',
              dificultad: '3',
              tipo: {
                  1: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  },
                  2: {
                      respuestas: ['-3', '-4', '0', '1']
                  },
                  3: {
                      respuestas: ['j(x) and h(x) are parallel', 'j(x) and h(x) are perpendicular', 'Those lines have at least one point of intersection', 'Those lines have infinite crossing points.']
                  }
              }
          },
          12: {
              rama: 'Problem Solving and Data Analysis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  },
                  2: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  },
                  3: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  }
              }
          },
          13: {
              rama: 'Problem Solving and Data Analysis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['Females taking Algebra II', 'Males taking Algebra II', 'Females taking Geometry', 'Males taking Algebra I']
                  },
                  2: {
                      respuestas: ['There are a few homes that are valued much less than the rest.', 'There are a few homes that are valued much more than the rest', 'The homes have values that are close to each other', 'Many of the homes have values between $110,000 and $145,000']
                  },
                  3: {
                      respuestas: ['a', 'b', 'c', 'd'],
                      foto: true
                  }
              }
          },
          14: {
              rama: 'Problem Solving and Data Analysis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['C=3h+5', 'h=3C', 'C=5h⁡+5', 'C=5h-3']
                  },
                  2: {
                      respuestas: ['150', '152', '148', '153']
                  },
                  3: {
                      respuestas: ['1987', '1990', '1985', '1983']
                  }
              }
          },
          15: {
              rama: 'Problem Solving and Data Analysis',
              dificultad: '3',
              tipo: {
                  1: {
                      respuestas: ['6%', '8%', '12%', '17%']
                  },
                  2: {
                      respuestas: ['78%', '82%', '85%', '72%']
                  },
                  3: {
                      respuestas: ['31%', '28%', '35%', '40%']
                  }
              }
          },
          16: {
              rama: 'Problem Solving and Data Analysis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestas: ['6', '8', '7', '9']
                  },
                  2: {
                      respuestas: ['Number of installations (in hundreds)', 'Number of installations (in tens)', 'Number of installations (in thousands)', 'Number of installations (in tens of thousands)']
                  },
                  3: {
                      respuestas: ['mean > median > mode', 'mean < mode', 'mode > median', 'mean > mode > median']
                  }
              }
          },
          17: {
              rama: 'Heart of Algebra',
              dificultad: '2',
              tipo: {
                  1: {
                      input:true
                  },
                  2: {
                      input:true
                  },
                  3: {
                      input:true
                  }
              }
          },
          18: {
              rama: 'Additional Topics in Math',
              dificultad: '2',
              tipo: {
                  1: {
                      input:true
                  },
                  2: {
                      input:true
                  },
                  3: {
                      input:true
                  }
              }
          },
          19: {
              rama: 'Problem Solving and Data Analysis',
              dificultad: '2',
              tipo: {
                  1: {
                      input:true
                  },
                  2: {
                      input:true
                  },
                  3: {
                      input:true
                  }
              }
          },
          20: {
              rama: 'Problem Solving and Data Analysis',
              dificultad: '2',
              tipo: {
                  1: {
                      input:true
                  },
                  2: {
                      input:true
                  },
                  3: {
                      input:true
                  }
              }
          }
      }
  }
}

export const respuestasMatematicas = {
  3: {
      preguntas: {
          1: {
              rama: 'algebra',
              dificultad: '1',
              tipo: {
                  1: {
                      respuestaCorrecta: '19'
                  },
                  2: {
                      respuestaCorrecta: '15'
                  },
                  3: {
                      respuestaCorrecta: '9'
                  }
              }
          },
          2: {
              rama: 'algebra',
              dificultad: '1',
              tipo: {
                  1: {
                      respuestaCorrecta: '-4'
                  },
                  2: {
                      respuestaCorrecta: '-6'
                  },
                  3: {
                      respuestaCorrecta: '8'
                  }
              }
          },
          3: {
              rama: 'algebra',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: '$850'
                  },
                  2: {
                      respuestaCorrecta: '$3.35'
                  },
                  3: {
                      respuestaCorrecta: '$1,350'
                  }
              }
          },
          4: {
              rama: 'trigonometria',
              dificultad: '1',
              tipo: {
                  1: {
                      respuestaCorrecta: '100'
                  },
                  2: {
                      respuestaCorrecta: '24'
                  },
                  3: {
                      respuestaCorrecta: '4'
                  }
              }
          },
          5: {
              rama: 'avanzadas',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: 'a',
                  },
                  2: {
                      respuestaCorrecta: 'a',
                  },
                  3: {
                      respuestaCorrecta: 'a',
                  }
              }
          },
          6: {
              rama: 'algebra',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: '2 ≤ x ≤ 8',
                  },
                  2: {
                      respuestaCorrecta: '-2.5',
                  },
                  3: {
                      respuestaCorrecta: '47',
                  }
              }
          },
          7: {
              rama: 'algebra',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: 'a',
                  },
                  2: {
                      respuestaCorrecta: '49',
                  },
                  3: {
                      respuestaCorrecta: 'a',
                  }
              }
          },
          8: {
              rama: 'avanzadas',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta:'8'
                  },
                  2: {
                      respuestaCorrecta:'2'
                  },
                  3: {
                      respuestaCorrecta:'8'
                  }
              }
          },
          9: {
              rama: 'algebra',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta:'7'
                  },
                  2: {
                      respuestaCorrecta:'1'
                  },
                  3: {
                      respuestaCorrecta:'5'
                  }
              }
          },
          10: {
              rama: 'trigonometria',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta:'7'
                  },
                  2: {
                      respuestaCorrecta:'4'
                  },
                  3: {
                      respuestaCorrecta:'5',
                  }
              }
          }
      }
  },
  4: {
      preguntas: {
          1: {
              rama: 'algebra',
              dificultad: '1',
              tipo: {
                  1: {
                      respuestaCorrecta: '11'
                  },
                  2: {
                      respuestaCorrecta: 'a'
                  },
                  3: {
                      respuestaCorrecta: '-1'
                  }
              }
          },
          2: {
              rama: 'analisis',
              dificultad: '1',
              tipo: {
                  1: {
                      respuestaCorrecta: 'a'
                  },
                  2: {
                      respuestaCorrecta: 'y=-x+3'
                  },
                  3: {
                      respuestaCorrecta: 'y=5x+6'
                  }
              }
          },
          3: {
              rama: 'analisis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: '$80'
                  },
                  2: {
                      respuestaCorrecta: '66'
                  },
                  3: {
                      respuestaCorrecta: '33'
                  }
              }
          },
          4: {
              rama: 'algebra',
              dificultad: '1',
              tipo: {
                  1: {
                      respuestaCorrecta: '7'
                  },
                  2: {
                      respuestaCorrecta: '3'
                  },
                  3: {
                      respuestaCorrecta: '6'
                  }
              }
          },
          5: {
              rama: 'analisis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: '3,600'
                  },
                  2: {
                      respuestaCorrecta: '21'
                  },
                  3: {
                      respuestaCorrecta: '12,000'
                  }
              }
          },
          6: {
              rama: 'trigonometria',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: '8'
                  },
                  2: {
                      respuestaCorrecta: '12'
                  },
                  3: {
                      respuestaCorrecta: '105'
                  }
              }
          },
          7: {
              rama: 'analisis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: '2'
                  },
                  2: {
                      respuestaCorrecta: '29'
                  },
                  3: {
                      respuestaCorrecta: '17,500'
                  }
              }
          },
          8: {
              rama: 'analisis',
              dificultad: '3',
              tipo: {
                  1: {
                      respuestaCorrecta: '110'
                  },
                  2: {
                      respuestaCorrecta: '$123'
                  },
                  3: {
                      respuestaCorrecta: 'a'
                  }
              }
          },
          9: {
              rama: 'avanzadas',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: 'a'
                  },
                  2: {
                      respuestaCorrecta: 'I and III only'
                  },
                  3: {
                      respuestaCorrecta: '-2'
                  }
              }
          },
          10: {
              rama: 'algebra',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: '(0,3)'
                  },
                  2: {
                      respuestaCorrecta: '32'
                  },
                  3: {
                      respuestaCorrecta: '(16,-4)'
                  }
              }
          },
          11: {
              rama: 'avanzadas',
              dificultad: '3',
              tipo: {
                  1: {
                      respuestaCorrecta: 'a'
                  },
                  2: {
                      respuestaCorrecta: '-3'
                  },
                  3: {
                      respuestaCorrecta: 'j(x) and h(x) are parallel'
                  }
              }
          },
          12: {
              rama: 'analisis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: 'a'
                  },
                  2: {
                      respuestaCorrecta: 'a'
                  },
                  3: {
                      respuestaCorrecta: 'a'
                  }
              }
          },
          13: {
              rama: 'analisis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: 'Females taking Algebra II'
                  },
                  2: {
                      respuestaCorrecta: 'There are a few homes that are valued much less than the rest.'
                  },
                  3: {
                      respuestaCorrecta: 'a'
                  }
              }
          },
          14: {
              rama: 'analisis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: 'C=3h+5'
                  },
                  2: {
                      respuestaCorrecta: '150'
                  },
                  3: {
                      respuestaCorrecta: '1987'
                  }
              }
          },
          15: {
              rama: 'analisis',
              dificultad: '3',
              tipo: {
                  1: {
                      respuestaCorrecta: '6%'
                  },
                  2: {
                      respuestaCorrecta: '78%'
                  },
                  3: {
                      respuestaCorrecta: '31%'
                  }
              }
          },
          16: {
              rama: 'analisis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta: '6'
                  },
                  2: {
                      respuestaCorrecta: 'Number of installations (in hundreds)'
                  },
                  3: {
                      respuestaCorrecta: 'mean > median > mode'
                  }
              }
          },
          17: {
              rama: 'algebra',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta:'17'
                  },
                  2: {
                      respuestaCorrecta:'13'
                  },
                  3: {
                      respuestaCorrecta:'15'
                  }
              }
          },
          18: {
              rama: 'trigonometria',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta:'97'
                  },
                  2: {
                      respuestaCorrecta:'6'
                  },
                  3: {
                      respuestaCorrecta:'6'
                  }
              }
          },
          19: {
              rama: 'analisis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta:'75'
                  },
                  2: {
                      respuestaCorrecta:'90'
                  },
                  3: {
                      respuestaCorrecta:'66'
                  }
              }
          },
          20: {
              rama: 'analisis',
              dificultad: '2',
              tipo: {
                  1: {
                      respuestaCorrecta:'5'
                  },
                  2: {
                      respuestaCorrecta:'20,000'
                  },
                  3: {
                      respuestaCorrecta:'70'
                  }
              }
          }
      }
  }
}

export const directionsMates = {
    3: {
        text:`
            <h1>Section 3: Math Test NO CALCULATOR</h1>
            <h4>15 minutes</h4>
            <h4>10 questions</h4>
        `,
        notes: mates3Notes,
        references: matesReferences
    },
 
    4: {
       text:`
          <h1>Section 4: Math Test WITH CALCULATOR</h1>
          <h4>30 minutes</h4>
          <h4>20 questions</h4>
       `,
       notes: mates4Notes,
       references: matesReferences
    }
 
 }