import React, { useState, useEffect, useLayoutEffect, useRef} from 'react'
import { cleanProtocol } from '../../utils/functions'
import Search from '../../images/search.svg';

const initialState = {
  visible:true,
  search:'',
  correctNewItem: false
}
const ListPopUp = (props) => {

  const refElement = useRef()
  const clickOutSide = ({target}) => !refElement.current?.contains(target) && cancelarPopUp()
  useLayoutEffect(()=>{
    document.addEventListener('mousedown',clickOutSide, false)
    return () =>document.removeEventListener('mousedown',clickOutSide, false)
  },[])

  const [state, setState] = useState({...props, ...initialState})
  useEffect(()=>{
    setState({...props, ...initialState})
  }, [props])

  const clickLink = (e,id,obj) => {
    e.preventDefault();
    e.stopPropagation();
    props.selectOpcion(id, obj)
    if(props.cerrarClick){
      cancelarPopUp()
    }
  }

  const cancelarPopUp = () =>{
    setState({...state, visible: false})
    setTimeout(() => props.onClose() , 500);
  }

  const changeNew = (text) => {
    if(props.obligacion==='link'){
      var correctNewItem = true
      if(!text.trim() && !text.trim().startsWith('http://') && !text.trim().startsWith('https://')) { correctNewItem=false }
      if(!text.trim() && !text.includes('.') ) { correctNewItem=false }
      setState({...state, searchNew:text,correctNewItem})
    }else{
      setState({...state, searchNew:text,correctNewItem:text.trim()!==''?true:false})
    }
  }
  const enterKeyNew = (event) => {
    if(event.key === 'Enter' && state.correctNewItem && props.searchNew!==state.searchNew && state.searchNew.trim()!==''){
      props.guardarNew(state.searchNew);
      cancelarPopUp()
    }
  }

  return(
    <div className={`container-opt-search ${props._class}`}  ref={refElement}>

      <div className={`opciones-search-popup ${props._class_div?props._class_div:''} ${state.visible?'opciones-search-show-popup':''}`}>
        <div className={`${props._class_container?props._class_container:''}`}>
          {props.title &&  <div className='title-pop-up'>{props.title}</div> }

          {props.buscar &&
            <li className={``}>
              <img className='icon-search-panel' src={Search} alt=''/>
              <input placeholder={props.placeholderBuscar?props.placeholderBuscar:''} value={state.search} onChange={(e)=>setState({...state, search:e.target.value})}/>
            </li>
          }

          {props.new &&
            <li className={`${props._class_new?props._class_new:''}`}>
              <i className="material-icons align-center new-destino">forward</i>
              <input onKeyPress={ event => enterKeyNew(event)} className='break_sentence' placeholder={props.placeholderNew?props.placeholderNew:''} value={state.searchNew} onChange={(e)=>changeNew(e.target.value)}/>
              {state.correctNewItem && props.searchNew!==state.searchNew && state.searchNew.trim()!=='' ?
                <i onClick={()=>{props.guardarNew(state.searchNew);cancelarPopUp()}} className="material-icons align-center save-destino">save</i> : null
              }
            </li>
          }

          {Object.entries(state.options).map(([k,o])=>{
            if(state.search.trim()!=='' && !o.valor.includes(state.search))return null
            return(
              props.tag && props.tag==='a'?
                <li  key={k} onClick={(e)=>{clickLink(e,k,o)}} ><a href={o.valor} className={`${state.optionSelected===k || o.selected?'color-azul':''}`} onClick={(e)=>{clickLink(e,k,o)}}>{props.cleanLink?cleanProtocol(o.valor):o.valor}</a></li>
              :
                props.cerrarClick ?
                  <li onClick={()=>{props.selectOpcion(k,o);cancelarPopUp() } } className={`${state.optionSelected===k || o.selected?'color-azul':''} ${props.puntoInformativo?'display_flex':''} `} key={k}>
                  {props.puntoInformativo?
                    <div className={`points_lista punto-status ${o.class}`}></div>
                  :null}
                  {o.valor}
                  </li>
                :
                  <li onClick={()=>props.selectOpcion(k,o)} className={`${state.optionSelected===k?'color-azul':''}`} key={k}>{o.valor}</li>


            )
          })}

          {props.opcionEliminar?
            <span className='eliminar_pop_up' onClick={()=>props.eliminar()}>{props.titleEliminar}</span>
            :null}

        </div>
      </div>
    </div>
  )
}

export default ListPopUp