import React, { useEffect } from 'react';

const NotFound = ({ history }) => {
  useEffect(() => {
    //history.push('/');
  });
  return (
    <div className='not-found-container'>
      <div>404</div>
      <h1>Not found</h1>

    </div>
  );
};

export default NotFound;